/* eslint-disable array-callback-return */
import * as React from 'react'
import { Loader, Grid, Button, Checkbox, Icon, Divider } from 'semantic-ui-react'
import { getInvoices, downloadInvoice, getInfoUser } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { InvoiceFormattedModel, InvoiceListModel } from '../types/InvoiceModel'
import { UserModel } from '../types/UserModel'

interface CreateTroubleshootingProps {
  auth: Auth,
  history: any
}

interface CreateTroubleshootingState {
  loadingButton: boolean,
  loading: boolean,
  invoices: InvoiceFormattedModel[],
  checked: boolean,
  idcheck: string,
  update: boolean,
  downloadUrl: string,
  formInput: string,
  usergroup: string,
  emailUser: string
}

export class InvoiceList extends React.PureComponent<
  CreateTroubleshootingProps,
  CreateTroubleshootingState
> {
  state: CreateTroubleshootingState = {
    loadingButton: false,
    loading: true,
    invoices: [],
    checked: false,
    idcheck: 'none',
    update: false,
    downloadUrl: 'none',
    formInput: '',
    usergroup: '',
    emailUser: ''
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.update === this.state.update) {
      let invoiceArrFormatted: any[] = []
      try {
  const invoices: InvoiceListModel[] = await getInvoices(this.props.auth.getIdToken())
  for (let index = 0; index < invoices.length; index++) {    
    let email = invoices[index].email.S
    let invoicesObjList: any[] = invoices[index].invoices.L
    if (this.state.usergroup === 'admins' || this.state.usergroup === 'goldenadmins') {
for (let j = 0; j < invoicesObjList.length; j++) {  
  const invoiceObj = invoicesObjList[j];
  let invoiceDate = invoiceObj.M.period.S.split(' - ')
  invoiceDate = invoiceDate[0]
  let invoiceNumber = invoiceObj.M.invoiceNumber.S.split('-')
  invoiceNumber = invoiceNumber[1]
  const invoiceFormatted: InvoiceFormattedModel = {
    email: email,
    date: invoiceDate,
    number: invoiceNumber,
    id: invoiceObj.M.invoiceNumber.S
  }
  invoiceArrFormatted.push(invoiceFormatted)
}
  } else {
if (this.state.emailUser === email) {
    for (let j = 0; j < invoicesObjList.length; j++) {  
      const invoiceObj = invoicesObjList[j];
      let invoiceDate = invoiceObj.M.period.S.split(' - ')
      invoiceDate = invoiceDate[0]
      let invoiceNumber = invoiceObj.M.invoiceNumber.S.split('-')
      invoiceNumber = invoiceNumber[1]
      const invoiceFormatted: InvoiceFormattedModel = {
        email: email,
        date: invoiceDate,
        number: invoiceNumber,
        id: invoiceObj.M.invoiceNumber.S,
        charge: invoiceObj.M.amount.N
      }
      invoiceArrFormatted.push(invoiceFormatted)
    }  
  }
  }
}
  if (invoiceArrFormatted && invoiceArrFormatted.length > 1) {
    invoiceArrFormatted.sort((a, b) => {
      return parseInt(b.number) - parseInt(a.number)
    })
  }
  this.setState({ invoices: invoiceArrFormatted, update: !prevState.update })
    } catch (e) {
      console.log(e)
    }
    }
  }

  async componentDidMount() {
    let invoiceArrFormatted: any[] = []
    try {
      const userid: string = this.props.auth.getIdToken().payload.sub
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
const emailUser = userPaymentInfo.email
      let usergroup = userPaymentInfo.usergroup
let exgroup = userPaymentInfo.exgroup
if (exgroup !== 'none') {
usergroup = exgroup
}
      const invoices: InvoiceListModel[] = await getInvoices(this.props.auth.getIdToken())
for (let index = 0; index < invoices.length; index++) {
  let email = invoices[index].email.S
  let invoicesObjList: any[] = invoices[index].invoices.L
  if (usergroup === 'admins' || usergroup === 'goldenadmins') {
for (let j = 0; j < invoicesObjList.length; j++) {  
const invoiceObj = invoicesObjList[j];
let invoiceDate = invoiceObj.M.period.S.split(' - ')
invoiceDate = invoiceDate[0]
let invoiceNumber = invoiceObj.M.invoiceNumber.S.split('-')
invoiceNumber = invoiceNumber[1]
const invoiceFormatted: InvoiceFormattedModel = {
  email: email,
  date: invoiceDate,
  number: invoiceNumber,
  id: invoiceObj.M.invoiceNumber.S
}
invoiceArrFormatted.push(invoiceFormatted)
}
} else {
  if (email === emailUser) {
  for (let j = 0; j < invoicesObjList.length; j++) {  
    const invoiceObj = invoicesObjList[j];
    let invoiceDate = invoiceObj.M.period.S.split(' - ')
    invoiceDate = invoiceDate[0]
    let invoiceNumber = invoiceObj.M.invoiceNumber.S.split('-')
    invoiceNumber = invoiceNumber[1]
    const invoiceFormatted: InvoiceFormattedModel = {
      email: email,
      date: invoiceDate,
      number: invoiceNumber,
      id: invoiceObj.M.invoiceNumber.S,
      charge: invoiceObj.M.amount.N
    }
    invoiceArrFormatted.push(invoiceFormatted)
    }
  }
}
}
if (invoiceArrFormatted && invoiceArrFormatted.length > 1) {
  invoiceArrFormatted.sort((a, b) => {
    return parseInt(b.number) - parseInt(a.number)
  })
}
this.setState({ invoices: invoiceArrFormatted, loading: false, usergroup, emailUser })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
  }
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ formInput: event.target.value })
}

  handleDownload = async () => {
    window.open(this.state.downloadUrl, '_blank', 'noopener,noreferrer');
  }

  toggle = async (id: string, email: string) => {
    let idch = 'none'
    if (!this.state.checked) {
idch = id
    }
  this.setState((prevState: any) => ({ checked: !prevState.checked, idcheck: idch }))
  if (idch !== 'none') {
this.setState({loadingButton: true})
    const downloadUrl = await downloadInvoice(this.props.auth.getIdToken(), {id: idch, email})
    this.setState({loadingButton: false, downloadUrl})
  }  
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Invoices
        </Loader>
      </Grid.Row>
    )
  }

  renderInvoiceList() {
      return (
        <div>
            <HeaderCustom headerType="invoice_status"/>
            <Divider style={{margin: '0.3em'}} />
            <Grid container  verticalAlign="middle" relaxed columns="equal" style={{ backgroundColor: "white"}}>        
          <Grid.Row columns={1} style={{margin: "0em 0em 0em", backgroundColor: '#E4DCDB'}}>
          <Grid.Column style={{padding:'0.2em'}}>
            {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDownload}>
          <Icon name='cloud download' />
        </Button>}
        {(this.state.idcheck !== 'none') && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDownload}>
          <Icon name='cloud download' />
        </Button>}
        </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} style={{margin: "0em 0em 0.1em", backgroundColor: '#E4DCDB'}}>
          <Grid.Column  textAlign="center" style={{padding:'0.2em'}}>
          <input
                  style={{width: '100%', maxWidth: '15em'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
        </Grid.Column>
                </Grid.Row>
                </Grid>
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1}>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '0.7rem', padding: '0.2em'}}>Invoices Created</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3} style={{minWidth: '60em'}}>
                  {(this.state.usergroup === 'goldenadmins' || this.state.usergroup === 'admins') && <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Email</Grid.Column>}
                  {(this.state.usergroup !== 'goldenadmins' && this.state.usergroup !== 'admins') && <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Amount</Grid.Column>}
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Date</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Select</Grid.Column>
                </Grid.Row>
                {this.state.invoices.map((invoice) => {
if (!this.state.formInput) {
                  return (
                  <Grid.Row columns={3}  style={{minWidth: '60em'}}>
                    {(this.state.usergroup === 'goldenadmins' || this.state.usergroup === 'admins') && <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.email}</Grid.Column>}
                    {(this.state.usergroup !== 'goldenadmins' && this.state.usergroup !== 'admins') && <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.charge}</Grid.Column>}
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.date}</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
                  {(this.state.idcheck === invoice.id) && <Checkbox
                  checked={this.state.checked}
            onClick={() => {this.toggle(invoice.id, invoice.email)}}
          />}
          {(this.state.idcheck !== invoice.id) && <Checkbox
                  checked={false}
            onClick={() => {this.toggle(invoice.id, invoice.email)}}
          />}
          </Grid.Column>
                  </Grid.Row>
                  )
          } else {
            if (this.state.usergroup === 'goldenadmins' || this.state.usergroup === 'admins') {
            if (invoice.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
            return (
              <Grid.Row columns={3}  style={{minWidth: '60em'}}>
                <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.email}</Grid.Column>
                <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.date}</Grid.Column>
                <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
              {(this.state.idcheck === invoice.id) && <Checkbox
              checked={this.state.checked}
        onClick={() => {this.toggle(invoice.id, invoice.email)}}
      />}
      {(this.state.idcheck !== invoice.id) && <Checkbox
              checked={false}
        onClick={() => {this.toggle(invoice.id, invoice.email)}}
      />}
      </Grid.Column>
              </Grid.Row>
              )
      }
    } else {
      if (invoice.date.toLowerCase().includes(this.state.formInput.toLowerCase())) {
        return (
          <Grid.Row columns={3}  style={{minWidth: '60em'}}>
            <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.charge}</Grid.Column>
            <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{invoice.date}</Grid.Column>
            <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
          {(this.state.idcheck === invoice.id) && <Checkbox
          checked={this.state.checked}
    onClick={() => {this.toggle(invoice.id, invoice.email)}}
  />}
  {(this.state.idcheck !== invoice.id) && <Checkbox
          checked={false}
    onClick={() => {this.toggle(invoice.id, invoice.email)}}
  />}
  </Grid.Column>
          </Grid.Row>
          )
  }
    }
          }
                })}
                {this.state.invoices.length === 0 && <Grid.Row columns={3} style={{minWidth: '60em'}}>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                  </Grid.Row>}
                </Grid>
                </div>
      )
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return this.renderInvoiceList()
}

}