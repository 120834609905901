import React, {useEffect, useState} from "react";
import { Button, Popup, Form, Grid, Image, Label, TextArea, Modal, Header, Icon } from 'semantic-ui-react'
import { createGolden } from '../api/users-api'
const { newGoldenBadgeSrc } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

const inputStyle = {
  fontSize: '0.8rem',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#b6b6b3',
  },
  '::placeholder': {
    color: '#b6b6b3',
  },
  textAlign: 'center',
  border: '0em'
}

const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

export const GoldenContainerNew = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('IT');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [phone, setPhone] = useState('');
  const [province, setProvince] = useState('');
  const [prefix, setPrefix] = useState('39');
  const [description, setDescription] = useState('');
  const [countryselect, setCountrySelect] = useState('IT,39');
const [processMessage, setProcessMsg] = useState('Do not REFRESH or CLOSE the page! Creating Atlanticoin Account')

useEffect(() => {
const interval = setInterval(() => {
if (processMessage === 'Do not REFRESH or CLOSE the page! Creating Atlanticoin Account...') {
setProcessMsg('Do not REFRESH or CLOSE the page! Creating Atlanticoin Account')
} else {
  let Msg = processMessage + '.'
setProcessMsg(Msg)
}
}, 800);
return () => clearInterval(interval);
}, [])

  const handleCityChange = (event) => {
    setCity(event.target.value);
  }
  
  const handleCountryChange = (event) => {
    let arr = event.target.value.split(',')
    let countryVar = arr[0]
    let prefixVar = arr[1]
    setCountry(countryVar)
    setPrefix(prefixVar)
    setCountrySelect(event.target.value)
  }
  
  const handleAddress1Change = (event) => {
    setAddress1(event.target.value)
  }
  
  const handleAddress2Change = (event) => {
   setAddress2(event.target.value)
  }
  
  const handlePostalcodeChange = (event) => {
    setPostalcode(event.target.value)
  }

  const handleProvinceChange = (event) => {
    setProvince(event.target.value)
  }
  
  const handleNameChange = (event) => {
    setName(event.target.value)
  }
  
  const handleSurnameChange = (event) => {
    setSurname(event.target.value)
  }

  const handlePhoneChange = (event) => {
    let arr = event.target.value.split(' ')
    setPhone(arr[1])
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (props.infoObj.group === 'companies') {
      if (!name || !surname || !address1 || !city || !country || !postalcode || !province || !description || !phone) {
        alert('All fields are Required!')
        return
          }
    } else {
      if (!name || !surname || !address1 || !city || !country || !postalcode || !province || !phone) {
        alert('All fields are Required!')
        return
          }
    }    
    setLoading(true);
    console.log(country)
    try {
      let goldenResult = null
      const street = address2 ? address1 + ' ' + address2 : address1
      const phonePrefixed = '+' + prefix + ' ' + phone
      if (props.infoObj.group === 'companies') {
      let requestObj = {
companyName: props.infoObj.company_name,
email: props.infoObj.email,
companyOwnerFirstname: name,
companyOwnerLastname: surname,
companyDescription: description,
country: country,
city: city,
street: street,
zipCode: postalcode,
phone: phonePrefixed,
province
        }
     goldenResult = await createGolden(props.token, requestObj)
    } else {
      let requestObj = {
       email: props.infoObj.email,
       firstname: name,
       lastname: surname,
       country: country,
       city: city,
       street: street,
       zipCode: postalcode,
       phone: phonePrefixed,
       province
               }
               goldenResult = await createGolden(props.token, requestObj)
    }   
      setLoading(false)
      if (goldenResult.status === 'error') {
setOpen(true)
      } else {
        props.history.replace(`/golden/${'activation'}/confirmation`)
      }      
    } catch (error) {
      setLoading(false)
      console.log(error)
      alert(error.message)
    }
  }

  return (
    <div>
<Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em'}}>
              <Grid.Row columns={1} centered >
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form onSubmit={handleSubmit} style={{ margin: '0 auto' }}>
    <Popup content='Boost your training with Virtual Vip and buy nice products on Atlanticoin Platform!' trigger={
      <div style={divBadgeStyle}>
      <Image size='small' centered src={newGoldenBadgeSrc} />
      </div>
    } />       
      <div style={divStyle}>
      {props.infoObj.group === 'employees' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Full Name</label>}
      {props.infoObj.group === 'companies' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Full Name of Company Owner</label>}
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Name (Required)"
                value={name}
                onChange={handleNameChange}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Surname (Required)"
                value={surname}
                onChange={handleSurnameChange}
              />
      </div>
      <div style={divStyle}>
      {props.infoObj.group === 'employees' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Address Details</label>}
      {props.infoObj.group === 'companies' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Company Address Details</label>}
      <input
      className='inputform'
      style={inputStyle}
                placeholder="City (Required)"
                value={city}
                onChange={handleCityChange}
              />              
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Address line 1 (Required)"
                value={address1}
                onChange={handleAddress1Change}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Address line 2"
                value={address2}
                onChange={handleAddress2Change}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Province (Required)"
                value={province}
                onChange={handleProvinceChange}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Postal code (Required)"
                value={postalcode}
                onChange={handlePostalcodeChange}
              />
      </div>
      <div style={divStyle}>
      {props.infoObj.group === 'employees' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Country</label>}
      {props.infoObj.group === 'companies' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Company Country</label>}
      <select value={countryselect} onChange={handleCountryChange}
           style={{width: '100%', margin: 'auto'}} >
              <option style={inputStyle} value="AF,93">Afghanistan</option>
<option style={inputStyle} value="AL,355">Albania</option>
<option style={inputStyle} value="DZ,213">Algeria</option>
<option style={inputStyle} value="AS,1684">American Samoa</option>
<option style={inputStyle} value="AD,376">Andorra</option>
<option style={inputStyle} value="AG,244">Angola</option>
<option style={inputStyle} value="AI,1264">Anguilla</option>
<option style={inputStyle} value="AG,1268">Antigua &amp; Barbuda</option>
<option style={inputStyle} value="AR,54">Argentina</option>
<option style={inputStyle} value="AA,374">Armenia</option>
<option style={inputStyle} value="AW,297">Aruba</option>
<option style={inputStyle} value="AU,61">Australia</option>
<option style={inputStyle} value="AT,43">Austria</option>
<option style={inputStyle} value="AZ,994">Azerbaijan</option>
<option style={inputStyle} value="BS,1242">Bahamas</option>
<option style={inputStyle} value="BH,973">Bahrain</option>
<option style={inputStyle} value="BD,880">Bangladesh</option>
<option style={inputStyle} value="BB,1246">Barbados</option>
<option style={inputStyle} value="BY,375">Belarus</option>
<option style={inputStyle} value="BE,32">Belgium</option>
<option style={inputStyle} value="BZ,501">Belize</option>
<option style={inputStyle} value="BJ,229">Benin</option>
<option style={inputStyle} value="BM,1441">Bermuda</option>
<option style={inputStyle} value="BT,975">Bhutan</option>
<option style={inputStyle} value="BO,591">Bolivia</option>
<option style={inputStyle} value="BA,387">Bosnia &amp; Herzegovina</option>
<option style={inputStyle} value="BW,267">Botswana</option>
<option style={inputStyle} value="BR,55">Brazil</option>
<option style={inputStyle} value="BC,246">British Indian Ocean Ter</option>
<option style={inputStyle} value="BN,673">Brunei</option>
<option style={inputStyle} value="BG,359">Bulgaria</option>
<option style={inputStyle} value="BF,226">Burkina Faso</option>
<option style={inputStyle} value="BI,257">Burundi</option>
<option style={inputStyle} value="KH,855">Cambodia</option>
<option style={inputStyle} value="CM,237">Cameroon</option>
<option style={inputStyle} value="CA,1">Canada</option>
<option style={inputStyle} value="CV,238">Cape Verde</option>
<option style={inputStyle} value="KY,1345">Cayman Islands</option>
<option style={inputStyle} value="CF,236">Central African Republic</option>
<option style={inputStyle} value="TD,235">Chad</option>
<option style={inputStyle} value="CL,56">Chile</option>
<option style={inputStyle} value="CN,86">China</option>
<option style={inputStyle} value="CI,61">Christmas Island</option>
<option style={inputStyle} value="CS,61">Cocos Island</option>
<option style={inputStyle} value="CO,57">Colombia</option>
<option style={inputStyle} value="CC,269">Comoros</option>
<option style={inputStyle} value="CK,682">Cook Islands</option>
<option style={inputStyle} value="CR,506">Costa Rica</option>
<option style={inputStyle} value="HR,385">Croatia</option>
<option style={inputStyle} value="CU,53">Cuba</option>
<option style={inputStyle} value="CB,599">Curacao</option>
<option style={inputStyle} value="CY,357">Cyprus</option>
<option style={inputStyle} value="CZ,420">Czech Republic</option>
<option style={inputStyle} value="DK,45">Denmark</option>
<option style={inputStyle} value="DJ,253">Djibouti</option>
<option style={inputStyle} value="DM,1767">Dominica</option>
<option style={inputStyle} value="DO,1809">Dominican Republic</option>
<option style={inputStyle} value="TM,670">East Timor</option>
<option style={inputStyle} value="EC,593">Ecuador</option>
<option style={inputStyle} value="EG,20">Egypt</option>
<option style={inputStyle} value="SV,503">El Salvador</option>
<option style={inputStyle} value="GQ,240">Equatorial Guinea</option>
<option style={inputStyle} value="ER,291">Eritrea</option>
<option style={inputStyle} value="EE,372">Estonia</option>
<option style={inputStyle} value="ET,251">Ethiopia</option>
<option style={inputStyle} value="FA,500">Falkland Islands</option>
<option style={inputStyle} value="FO,298">Faroe Islands</option>
<option style={inputStyle} value="FJ,679">Fiji</option>
<option style={inputStyle} value="FI,358">Finland</option>
<option style={inputStyle} value="FR,33">France</option>
<option style={inputStyle} value="PF,689">French Polynesia</option>
<option style={inputStyle} value="GA,241">Gabon</option>
<option style={inputStyle} value="GM,220">Gambia</option>
<option style={inputStyle} value="GE,995">Georgia</option>
<option style={inputStyle} value="DE,49">Germany</option>
<option style={inputStyle} value="GH,233">Ghana</option>
<option style={inputStyle} value="GI,350">Gibraltar</option>
<option style={inputStyle} value="GR,30">Greece</option>
<option style={inputStyle} value="GL,299">Greenland</option>
<option style={inputStyle} value="GD,1473">Grenada</option>
<option style={inputStyle} value="GU,1671">Guam</option>
<option style={inputStyle} value="GT,502">Guatemala</option>
<option style={inputStyle} value="GN,224">Guinea</option>
<option style={inputStyle} value="GY,592">Guyana</option>
<option style={inputStyle} value="HT,509">Haiti</option>
<option style={inputStyle} value="HN,504">Honduras</option>
<option style={inputStyle} value="HK,852">Hong Kong</option>
<option style={inputStyle} value="HU,36">Hungary</option>
<option style={inputStyle} value="IS,354">Iceland</option>
<option style={inputStyle} value="IN,91">India</option>
<option style={inputStyle} value="ID,62">Indonesia</option>
<option style={inputStyle} value="IA,98">Iran</option>
<option style={inputStyle} value="IQ,964">Iraq</option>
<option style={inputStyle} value="IR,353">Ireland</option>
<option style={inputStyle} value="IL,972">Israel</option>
<option style={inputStyle} value="IT,39">Italy</option>
<option style={inputStyle} value="JM,1876">Jamaica</option>
<option style={inputStyle} value="JP,81">Japan</option>
<option style={inputStyle} value="JO,962">Jordan</option>
<option style={inputStyle} value="KZ,7">Kazakhstan</option>
<option style={inputStyle} value="KE,254">Kenya</option>
<option style={inputStyle} value="KI,686">Kiribati</option>
<option style={inputStyle} value="NK,850">Korea North</option>
<option style={inputStyle} value="KS,82">Korea South</option>
<option style={inputStyle} value="KW,965">Kuwait</option>
<option style={inputStyle} value="KG,996">Kyrgyzstan</option>
<option style={inputStyle} value="LA,856">Laos</option>
<option style={inputStyle} value="LV,371">Latvia</option>
<option style={inputStyle} value="LB,961">Lebanon</option>
<option style={inputStyle} value="LS,266">Lesotho</option>
<option style={inputStyle} value="LR,231">Liberia</option>
<option style={inputStyle} value="LY,218">Libya</option>
<option style={inputStyle} value="LI,423">Liechtenstein</option>
<option style={inputStyle} value="LT,370">Lithuania</option>
<option style={inputStyle} value="LU,352">Luxembourg</option>
<option style={inputStyle} value="MO,853">Macau</option>
<option style={inputStyle} value="MK,389">Macedonia</option>
<option style={inputStyle} value="MG,261">Madagascar</option>
<option style={inputStyle} value="MY,60">Malaysia</option>
<option style={inputStyle} value="MW,265">Malawi</option>
<option style={inputStyle} value="MV,960">Maldives</option>
<option style={inputStyle} value="ML,223">Mali</option>
<option style={inputStyle} value="MT,356">Malta</option>
<option style={inputStyle} value="MH,692">Marshall Islands</option>
<option style={inputStyle} value="MR,222">Mauritania</option>
<option style={inputStyle} value="MU,230">Mauritius</option>
<option style={inputStyle} value="ME,262">Mayotte</option>
<option style={inputStyle} value="MX,52">Mexico</option>
<option style={inputStyle} value="MD,373">Moldova</option>
<option style={inputStyle} value="MC,377">Monaco</option>
<option style={inputStyle} value="MN,976">Mongolia</option>
<option style={inputStyle} value="MS,1664">Montserrat</option>
<option style={inputStyle} value="MA,212">Morocco</option>
<option style={inputStyle} value="MZ,258">Mozambique</option>
<option style={inputStyle} value="MM,95">Myanmar</option>
<option style={inputStyle} value="NA,264">Nambia</option>
<option style={inputStyle} value="NU,674">Nauru</option>
<option style={inputStyle} value="NP,977">Nepal</option>
<option style={inputStyle} value="AN,599">Netherland Antilles</option>
<option style={inputStyle} value="NL,31">Netherlands (Holland, Europe)</option>
<option style={inputStyle} value="NC,687">New Caledonia</option>
<option style={inputStyle} value="NZ,64">New Zealand</option>
<option style={inputStyle} value="NI,505">Nicaragua</option>
<option style={inputStyle} value="NE,227">Niger</option>
<option style={inputStyle} value="NG,234">Nigeria</option>
<option style={inputStyle} value="NW,683">Niue</option>
<option style={inputStyle} value="NO,47">Norway</option>
<option style={inputStyle} value="OM,968">Oman</option>
<option style={inputStyle} value="PK,92">Pakistan</option>
<option style={inputStyle} value="PW,680">Palau Island</option>
<option style={inputStyle} value="PS,970">Palestine</option>
<option style={inputStyle} value="PA,507">Panama</option>
<option style={inputStyle} value="PG,675">Papua New Guinea</option>
<option style={inputStyle} value="PY,595">Paraguay</option>
<option style={inputStyle} value="PE,51">Peru</option>
<option style={inputStyle} value="PH,63">Philippines</option>
<option style={inputStyle} value="PO,64">Pitcairn Island</option>
<option style={inputStyle} value="PL,48">Poland</option>
<option style={inputStyle} value="PT,351">Portugal</option>
<option style={inputStyle} value="PR,1787">Puerto Rico</option>
<option style={inputStyle} value="QA,974">Qatar</option>
<option style={inputStyle} value="ME,382">Republic of Montenegro</option>
<option style={inputStyle} value="RS,381">Republic of Serbia</option>
<option style={inputStyle} value="RE,262">Reunion</option>
<option style={inputStyle} value="RO,40">Romania</option>
<option style={inputStyle} value="RU,7">Russia</option>
<option style={inputStyle} value="RW,250">Rwanda</option>
<option style={inputStyle} value="NT,590">St Barthelemy</option>
<option style={inputStyle} value="HE,290">St Helena</option>
<option style={inputStyle} value="KN,1869">St Kitts-Nevis</option>
<option style={inputStyle} value="LC,1758">St Lucia</option>
<option style={inputStyle} value="MB,590">St Maarten</option>
<option style={inputStyle} value="PM,508">St Pierre &amp; Miquelon</option>
<option style={inputStyle} value="VC,1784">St Vincent &amp; Grenadines</option>
<option style={inputStyle} value="SO,685">Samoa</option>
<option style={inputStyle} value="SM,378">San Marino</option>
<option style={inputStyle} value="ST,239">Sao Tome &amp; Principe</option>
<option style={inputStyle} value="SA,966">Saudi Arabia</option>
<option style={inputStyle} value="SN,221">Senegal</option>
<option style={inputStyle} value="RS,381">Serbia</option>
<option style={inputStyle} value="SC,248">Seychelles</option>
<option style={inputStyle} value="SL,232">Sierra Leone</option>
<option style={inputStyle} value="SG,65">Singapore</option>
<option style={inputStyle} value="SK,421">Slovakia</option>
<option style={inputStyle} value="SI,386">Slovenia</option>
<option style={inputStyle} value="SB,677">Solomon Islands</option>
<option style={inputStyle} value="OI,252">Somalia</option>
<option style={inputStyle} value="ZA,27">South Africa</option>
<option style={inputStyle} value="ES,34">Spain</option>
<option style={inputStyle} value="LK,94">Sri Lanka</option>
<option style={inputStyle} value="SD,249">Sudan</option>
<option style={inputStyle} value="SR,597">Suriname</option>
<option style={inputStyle} value="SZ,268">Swaziland</option>
<option style={inputStyle} value="SE,46">Sweden</option>
<option style={inputStyle} value="CH,41">Switzerland</option>
<option style={inputStyle} value="SY,963">Syria</option>
<option style={inputStyle} value="TW,886">Taiwan</option>
<option style={inputStyle} value="TJ,992">Tajikistan</option>
<option style={inputStyle} value="TZ,255">Tanzania</option>
<option style={inputStyle} value="TH,66">Thailand</option>
<option style={inputStyle} value="TG,228">Togo</option>
<option style={inputStyle} value="TK,690">Tokelau</option>
<option style={inputStyle} value="TO,676">Tonga</option>
<option style={inputStyle} value="TT,1868">Trinidad &amp; Tobago</option>
<option style={inputStyle} value="TN,216">Tunisia</option>
<option style={inputStyle} value="TR,90">Turkey</option>
<option style={inputStyle} value="TU,993">Turkmenistan</option>
<option style={inputStyle} value="TC,1649">Turks &amp; Caicos Is</option>
<option style={inputStyle} value="TV,688">Tuvalu</option>
<option style={inputStyle} value="UG,256">Uganda</option>
<option style={inputStyle} value="UA,380">Ukraine</option>
<option style={inputStyle} value="AE,971">United Arab Emirates</option>
<option style={inputStyle} value="GB,44">United Kingdom</option>
<option style={inputStyle} value="US,1">United States of America</option>
<option style={inputStyle} value="UY,598">Uruguay</option>
<option style={inputStyle} value="UZ,998">Uzbekistan</option>
<option style={inputStyle} value="VU,678">Vanuatu</option>
<option style={inputStyle} value="VS,379">Vatican City State</option>
<option style={inputStyle} value="VE,58">Venezuela</option>
<option style={inputStyle} value="VN,84">Vietnam</option>
<option style={inputStyle} value="WF,681">Wallis &amp; Futana Is</option>
<option style={inputStyle} value="YE,967">Yemen</option>
<option style={inputStyle} value="ZM,260">Zambia</option>
<option style={inputStyle} value="ZW,263">Zimbabwe</option>
            </select>
      </div>
      {props.infoObj.group === 'companies' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Company Description</label>
              <TextArea
              className='inputform'
              style={inputStyle}
              placeholder="Describe your company... (Required)"
              value={description}
              onChange={
                (e, { value }) => {
                  console.log(e)
                  let message = ""
                  if (value) {
                    if (typeof value === 'string') {                      
                      let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
                    }
                  }
                  setDescription(message)
                }
              }
            />
      </div>}
      <div style={divStyle}>
      {props.infoObj.group === 'employees' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Phone Number</label>}
      {props.infoObj.group === 'companies' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Company Phone Number</label>}
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Phone Number (Required)"
                value={'+' + prefix + ' ' + phone}
                onChange={handlePhoneChange}
              />
      </div>
      <Button
            fluid
            color="orange"
            type="submit"
            loading={loading}
            style={{marginTop: '0.3em'}}
          >
            Confirm
            </Button>
    </Form>
    {!loading && <Label size="large" pointing='above' basic color='violet'>Create your Atlanticoin Account</Label>}
    {loading && <Label size="large" pointing='above' basic color='violet'>Do not Refresh or Close the page! Creating Atlanticoin Account...</Label>}
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />The Golden Coin System is temporary unavailable</Header>
      
      <Modal.Content>
          <p>
            This process is disabled for Security reasons.
          </p>
          <p>
            Please try again later or contact the Support Team for more Info.
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={() => setOpen(false)}>
         <Icon name='checkmark' /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
    </div>
  );
};