import { Grid, Label, Icon, Message } from 'semantic-ui-react';
import React, { useEffect, useState } from "react";
import { PayPalButtons,	usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { paymentConfirmation, saveUserEdit } from '../api/users-api'

const ButtonWrapper = ({ infoPay, type, vault, history, token, upgrade, contract }) => {
	const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState(false);
    const [multiUpgrade, setMultiUpgrade] = useState(false);
    const [multiUpgradeId, setMultiUpgradeId] = useState('none');

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                vault,
                intent: type,
            },
        });
    }, [type]);
    console.log('mounting paypal button');
    console.log('type: ' + type)
    console.log('upgrade: ' + upgrade)
    console.log('infopayObj', {infoPay})
    if (multiUpgrade) {
return (
    <div>
<Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
              <Grid.Column verticalAlign="middle" textAlign="center">
                {!warning && <Message size='large'  color='violet' style={{marginBottom: '-0.6em'}}><Message.Header>Attention! MultiStep process: STEP 2</Message.Header><p>This type of Upgrade is a Two Step process. The Second and Final Step is the Subscription Approval</p></Message>}
                {warning && <Message size='large'  warning style={{marginBottom: '-0.6em'}}><Message.Header>Attention!</Message.Header><p>Follow Paypal instructions to complete the Payment</p></Message>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
  <Icon size='huge' color='violet' name='angle double down' style={{marginBottom: '0.3em'}} />
      {(isPending || loading) && <div className="spinner" />}
            <PayPalButtons
createSubscription={(data, actions) => {
    setLoading(true);
        return actions.subscription
        .create({
            plan_id: infoPay.planid,
            start_time: infoPay.oldexpiration.toISOString()
        }).catch((e) => {
            setLoading(false);
            console.log(e);
            console.log('replaced!');
            history.replace('/payment/error');
        });               
}}
onApprove={async function(data, actions) {
    console.log('You have successfully created subscription ' + data.subscriptionID + ' actions: ' + actions);
    // Your code here after create the order
    try {
       let key = await paymentConfirmation(token, {...infoPay, orderid: data.subscriptionID, upgrade, upgradeid: multiUpgradeId })
       setLoading(false);
       let dataArr = infoPay.usergroup + ',' + key;
       localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
    } catch (error) {
        console.log(error);
        setLoading(false);
        console.log('replaced!');
        history.replace('/payment/error');
    }
  }}
  onCancel={function (data) {
    setLoading(false);
    setWarning(true);
        setTimeout(() => {
            setWarning(false) 
        }, 3000);
        // Show a cancel page, or return to cart
        console.log(data); 
}}
onError={function (data) {
    setLoading(false);
    // Show a cancel page, or return to cart
    console.log(data);
console.log('replaced!');
history.replace('/payment/error');
  }}
style={{
    label: "subscribe",
}}
/>             
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            </div>
)
    }
    if (upgrade !== 'none') {
        if (infoPay.renew === 'no') {
            return (
                <div>
                <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                {!warning && <Message size='large'  color='violet' style={{marginBottom: '-0.6em'}}><Message.Header>Paypal Gateway</Message.Header>Choose your Payment Option</Message>}
                {warning && <Message size='large'  warning style={{marginBottom: '-0.6em'}}><Message.Header>Attention!</Message.Header><p>Follow Paypal instructions to complete the Payment</p></Message>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
  <Icon size='huge' color='violet' name='angle double down' style={{marginBottom: '0.3em'}} />
      {(isPending || loading) && <div className="spinner" />}
            <PayPalButtons
                createOrder={function (data, actions) {
                    setLoading(true);
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: upgrade,
                                    },
                                },
                            ],
                        })
                        .catch((e) => {
                            setLoading(false);
                            console.log(e);
                            console.log('replaced!');
                            history.replace('/payment/error');  
                        });
                }}
                onApprove={function (data, actions) {
                    console.log('You have successfully approved your order.');
                    return actions.order.capture().then(async function(details) {
    let orderid = details.purchase_units[0].payments.captures[0].id;
    try {
       let key = await paymentConfirmation(token, {...infoPay, orderid, upgrade, upgradeid: orderid })
       setLoading(false);
       let dataArr = infoPay.usergroup + ',' + key;
       localStorage.setItem('showContract', contract);
       history.replace(`/payment/${dataArr}/confirmation`);
    } catch (error) {
        console.log(error);
        setLoading(false);
        console.log('replaced!');
                            history.replace('/payment/error');
    }
                    });
                }}
                onCancel={function (data) {
                    setLoading(false);
                    setWarning(true);
                    setTimeout(() => {
                        setWarning(false) 
                    }, 3000);
                    // Show a cancel page, or return to cart
                    console.log(data);
                }}
                onError={function (data) {
                    setLoading(false);
                    // Show a cancel page, or return to cart
                    console.log(data);
          console.log('replaced!');
          history.replace('/payment/error');
                  }}
            />
</Grid.Column>
              </Grid.Row>
            </Grid>
            </div>);
        } else {
            return (
                <div>
<Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
              <Grid.Column verticalAlign="middle" textAlign="center">
                {!warning && <Message size='large'  color='violet' style={{marginBottom: '-0.6em'}}><Message.Header>Attention! MultiStep process: STEP 1</Message.Header><p>This type of Upgrade is a Two Step process. The First Step is the Payment Confirmation</p></Message>}
                {warning && <Message size='large'  warning style={{marginBottom: '-0.6em'}}><Message.Header>Attention!</Message.Header><p>Follow Paypal instructions to complete the Payment</p></Message>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
  <Icon size='huge' color='violet' name='angle double down' style={{marginBottom: '0.3em'}} />
      {(isPending || loading) && <div className="spinner" />}
            <PayPalButtons
                createOrder={function (data, actions) {
                    setLoading(true);                    
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: upgrade,
                                    },
                                },
                            ],
                        })
                        .catch((e) => {
                            setLoading(false);
                            console.log(e);
                            console.log('replaced!');
                            history.replace('/payment/error');  
                        });
                }}
                onApprove={function (data, actions) {
                    console.log('You have successfully approved your order.');
                    return actions.order.capture().then(async function(details) {
    let orderid = details.purchase_units[0].payments.captures[0].id;
    dispatch({
        type: "resetOptions",
        value: {
            ...options,
            vault: true,
            intent: 'subscription',
        },
    });
    setMultiUpgradeId(orderid);
    setMultiUpgrade(true);    
                    });
                }}
                onCancel={function (data) {
                    setLoading(false);
                    setWarning(true);
                    setTimeout(() => {
                        setWarning(false) 
                    }, 3000);
                    // Show a cancel page, or return to cart
                    console.log(data);
                }}
                onError={function (data) {
                    setLoading(false);
                    // Show a cancel page, or return to cart
                    console.log(data);
          console.log('replaced!');
          history.replace('/payment/error');
                  }}
            />
            </Grid.Column>
              </Grid.Row>            
            </Grid>
            </div>);
        }  
    } else {
    if (type === 'capture') {
        return (
            <div>
            <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
              <Grid.Column verticalAlign="middle" textAlign="center">
                {!warning && <Message size='large'  color='violet' style={{marginBottom: '-0.6em'}}><Message.Header>Paypal Gateway</Message.Header>Choose your Payment Option</Message>}
                {warning && <Message size='large'  warning style={{marginBottom: '-0.6em'}}><Message.Header>Attention!</Message.Header><p>Follow Paypal instructions to complete the Payment</p></Message>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
  <Icon size='huge' color='violet' name='angle double down' style={{marginBottom: '0.3em'}} />
  {(isPending || loading) && <div className="spinner" />}
        <PayPalButtons
            createOrder={function (data, actions) {
                setLoading(true);
                return actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    value: infoPay.charge,
                                },
                            },
                        ],
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log(e);
                        console.log('replaced!');
                        history.replace('/payment/error'); 
                    });
            }}
            onApprove={function (data, actions) {
                console.log('You have successfully approved your order.');
                return actions.order.capture().then(async function(details) {
let orderid = details.purchase_units[0].payments.captures[0].id;
try {
   let key = await paymentConfirmation(token, {...infoPay, orderid, upgrade: 'none'})
   setLoading(false);
   let dataArr = infoPay.usergroup + ',' + key;
   localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
} catch (error) {
    console.log(error);
    setLoading(false);
    console.log('replaced!');
    history.replace('/payment/error');
}
                });
            }}
            onCancel={function (data) {
                setLoading(false);
                setWarning(true);
                    setTimeout(() => {
                        setWarning(false) 
                    }, 3000);
                    // Show a cancel page, or return to cart
                    console.log(data);
            }}
            onError={function (data) {
                setLoading(false);
                // Show a cancel page, or return to cart
                console.log(data);
      console.log('replaced!');
      history.replace('/payment/error');
              }}
        />
        </Grid.Column>
              </Grid.Row>            
            </Grid>
        </div>);
    } else {
        return (
            <div>
<Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                {(infoPay.useredit === 'no' && !warning) && <Message size='large'  color='violet' style={{marginBottom: '-0.6em'}}><Message.Header>Paypal Gateway</Message.Header>Start your Subscription Process</Message>}
                {(infoPay.useredit === 'no' && warning) && <Message size='large'  warning style={{marginBottom: '-0.6em'}}><Message.Header>Attention!</Message.Header><p>Follow Paypal instructions to complete the Payment</p></Message>}
  </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
  {infoPay.useredit === 'no' && <Icon size='huge' color='violet' name='angle double down' style={{marginBottom: '0.3em'}} />}
  {(isPending || loading) && <div className="spinner" />}
        {infoPay.useredit === 'no' && <PayPalButtons
            createSubscription={(data, actions) => {
                setLoading(true);
                if (infoPay.start_date === 'none') {
                    return actions.subscription
                    .create({
                        plan_id: infoPay.planid
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log(e);
                        console.log('replaced!');
                        history.replace('/payment/error');
                    });
                } else {
                    return actions.subscription
                    .create({
                        plan_id: infoPay.planid,
                        start_time: infoPay.start_date
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log(e);
                        console.log('replaced!');
                        history.replace('/payment/error');
                    });
                }               
            }}
            onApprove={async function(data, actions) {
                console.log('You have successfully created subscription ' + data.subscriptionID + ' actions: ' + actions);
                // Your code here after create the order
                try {
                    if (infoPay.useredit === 'yes') {
                        await saveUserEdit(token, infoPay.renew, infoPay.usergroup, infoPay.method, infoPay.email, data.subscriptionID, infoPay.charge)
                        setLoading(false);
                        let dataArr = 'none,none';
                        localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
                    } else {
                        let key = await paymentConfirmation(token, {...infoPay, orderid: data.subscriptionID, upgrade: 'none'})
                        setLoading(false);
                        let dataArr = infoPay.usergroup + ',' + key;
                        localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
                    }                    
                 } catch (error) {
                     console.log(error);
                     setLoading(false);
                     console.log('replaced!');
                     history.replace('/payment/error');
                 }
              }}
              onCancel={function (data) {
                setLoading(false);
                setWarning(true);
                    setTimeout(() => {
                        setWarning(false) 
                    }, 3000);
                    // Show a cancel page, or return to cart
                    console.log(data); 
            }}
            onError={function (data) {
                setLoading(false);
                // Show a cancel page, or return to cart
                console.log(data);
      console.log('replaced!');
      history.replace('/payment/error');
              }}
            style={{
                label: "subscribe",
            }}
        />}
        {infoPay.useredit === 'yes' && <PayPalButtons
            createSubscription={(data, actions) => {
                setLoading(true);
                if (infoPay.start_date === 'none') {
                    return actions.subscription
                    .create({
                        plan_id: infoPay.planid
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log(e);
                        console.log('replaced!');
                        history.replace('/payment/error');
                    });
                } else {
                    return actions.subscription
                    .create({
                        plan_id: infoPay.planid,
                        start_time: infoPay.start_date
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log(e);
                        console.log('replaced!');
                        history.replace('/payment/error');
                    });
                }               
            }}
            onApprove={async function(data, actions) {
                console.log('You have successfully created subscription ' + data.subscriptionID + ' actions: ' + actions);
                // Your code here after create the order
                try {
                    if (infoPay.useredit === 'yes') {
                        await saveUserEdit(token, infoPay.renew, infoPay.usergroup, infoPay.method, infoPay.email, data.subscriptionID, infoPay.charge)
                        setLoading(false);
                        let dataArr = 'none,none';
                        localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
                    } else {
                        let key = await paymentConfirmation(token, {...infoPay, orderid: data.subscriptionID, upgrade: 'none'})
                        setLoading(false);
                        let dataArr = infoPay.usergroup + ',' + key;
                        localStorage.setItem('showContract', contract);
                        history.replace(`/payment/${dataArr}/confirmation`);
                    }                    
                 } catch (error) {
                     console.log(error);
                     setLoading(false);
                     console.log('replaced!');
                     history.replace('/payment/error');
                 }
              }}
              onCancel={function (data) {
                setLoading(false);
                setWarning(true);
                    setTimeout(() => {
                        setWarning(false) 
                    }, 3000);
                    // Show a cancel page, or return to cart
                    console.log(data);
            }}
            onError={function (data) {
                setLoading(false);
                // Show a cancel page, or return to cart
                console.log(data);
      console.log('replaced!');
      history.replace('/payment/error');
              }}
            style={{
                label: "subscribe",
                layout: "horizontal",
                tagline: false
            }}
        />}
        {(infoPay.useredit === 'yes' && !warning) && <Label size="medium" pointing='above' basic color='violet'>Activate Auto-Renew with your Paypal account</Label>}
  {(infoPay.useredit === 'yes' && warning) && <Label size="medium" pointing='above' basic color='red'>Follow Paypal instructions to Confirm the changes</Label>}
</Grid.Column>
              </Grid.Row>
            </Grid>
        </div>);
    }
}
}

export default ButtonWrapper;