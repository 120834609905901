import React from "react";
import { Grid, Icon, Card, Label } from 'semantic-ui-react'
import './styles/Chat.css'

const ChatLayout = ({data}) => {
  return (<Grid>{data.map((obj) => {
    let direction = 'right';
    let color = '#6435c9';
    let colorMessage = 'violet';
let user = obj.name
let userDescription = 'Support'
if (obj.group !== 'admins') {
userDescription = 'Customer'
direction = 'left';
color = '#dc6b33';
colorMessage = 'orange';
}
let timeDate = new Date(obj.timeStamp)
let timeStamp = timeDate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
timeStamp += ' ' + timeDate.getHours() + ':' + timeDate.getMinutes()
    return (
      <Grid.Row columns={2}>
            {direction === 'left' && <Grid.Column width={2} verticalAlign="middle" style={{marginRight:'-0.6em'}}>
           <Card style={{backgroundColor: '#E4DCDB', boxShadow: 'none'}}>
           <Card.Content textAlign="center">
           <Icon size='big' name='user' color={colorMessage}/>           
<Card.Header style={{fontSize: '0.5rem', fontWeight:'bold', color:color}}>
{user}
</Card.Header>
<Card.Description style={{fontSize: '0.5rem', color:color}}>
{userDescription}
</Card.Description>          
           </Card.Content>
           </Card>
              </Grid.Column>}
                {direction === 'left' && <Grid.Column width={14}>
                <Card fluid style={{backgroundColor: '#E4DCDB', boxShadow: 'none'}}>                
                <Card.Content textAlign={direction}>
                <Label basic pointing={direction} size='huge' style={{fontSize: '0.6rem'}} color={colorMessage}>
{obj.message}
</Label>
<Card.Meta style={{fontSize: '0.5rem', color:color}}>
{timeStamp}
</Card.Meta>
           </Card.Content>
                </Card>
</Grid.Column>}
                {direction === 'right' && <Grid.Column width={14}>
                <Card fluid style={{backgroundColor: '#E4DCDB', boxShadow: 'none'}}>
                <Card.Content textAlign={direction}>
                <Label basic pointing={direction} size='huge' style={{fontSize: '0.6rem'}} color={colorMessage}>
{obj.message}
</Label>
<Card.Meta style={{fontSize: '0.5rem', color:color}}>
{timeStamp}
</Card.Meta>
           </Card.Content>
                </Card>
</Grid.Column>}
                {direction === 'right' && <Grid.Column width={2} verticalAlign="middle" style={{marginLeft:'-0.6em'}}>
           <Card style={{backgroundColor: '#E4DCDB', boxShadow: 'none'}}>          
           <Card.Content textAlign="center">
           <Icon size='big' name='user md' color={colorMessage}/>
<Card.Header style={{fontSize: '0.5rem', fontWeight:'bold', color:color}}>
{user}
</Card.Header>
<Card.Description style={{fontSize: '0.5rem', color:color}}>
{userDescription}
</Card.Description>          
           </Card.Content>
           </Card>
              </Grid.Column>}
          </Grid.Row>)
    })}</Grid>)
};

export default ChatLayout;