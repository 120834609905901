import * as React from 'react'
import { Form, Header, Button, Divider, Grid, TextArea, Card } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { ClipboardCopy } from './ClipboardCopy'
import { createTicket } from '../api/users-api'
import { TicketMessageModelApi } from '../types/TicketMessageModel'

interface TroubleshootingProps {
  match: {
    params: {
      infoArray: string
    }
  },
  history: any,
  auth: Auth
}

interface TroubleshootingState {
  message: any,
  loading: boolean,
  subject: string,
  ticketId: string
}

export class TroubleshootingProcess extends React.PureComponent<
TroubleshootingProps,
TroubleshootingState
> {
  state: TroubleshootingState = {
    message: '',
    loading: false,
    subject: 'Registration',
    ticketId: 'none'
  }

  handleReturn = () => {
    this.setState({message: ''})
    this.props.history.replace(`/ticketcreate`)
  }
  handleSubjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ subject: event.target.value })
  }
  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    try {
      if (!this.state.message || !this.state.subject) {
        alert('All details should be provided')
        return
      }
      this.setUploadState(true)
      const date = new Date().getTime()
      const arrInfo = this.props.match.params.infoArray.split(',')
      const ticketObj: TicketMessageModelApi = {
type: 'ticket',
email: arrInfo[1],
subject: this.state.subject,
message: this.state.message,
timeStamp: date.toString(),
group: 'users',
name: arrInfo[0],
status: 'OPEN'
      }
      const ticketId = await createTicket(this.props.auth.getIdToken(), 
      ticketObj
      )
this.setState({ticketId})
this.setUploadState(false)
    } catch (e) {
      this.setUploadState(false)
      this.props.history.replace(`/ticketcreate`)
    }
  }

  setUploadState(loading: boolean) {
    this.setState({
      loading
    })
  }

  render() {
    return (
      <div>
        <HeaderCustom headerType="ticket_process"/>

        {this.state.ticketId === 'none' && <Form onSubmit={this.handleSubmit}>
        <Form.Field>
            <label>Problem Subject</label>
            <select
            style={{width: '100%'}}
              value={this.state.subject}
              onChange={this.handleSubjectChange}
            >
              <option value="Registration">Registration</option>
              <option value="Conversation">Conversation</option>
              <option value="Training">Training</option>
              <option value="Microphone">Microphone</option>
              <option value="Points">Points</option>
              <option value="Coins">Coins</option>
              <option value="Payment">Payment</option>
              <option value="Coupons">Coupons</option>
              <option value="Marketing">Marketing</option>
              <option value="Other">Other</option>
            </select>
          </Form.Field>
          <Form.Field>
          <label>Problem Description</label>
            <TextArea
              placeholder="Describe your problem..."
              value={this.state.message}
              onChange={
                (e, { value }) => {
                  console.log(e)
                  this.setState({ message: value})
                }
              }
            />
          </Form.Field>
          {this.renderButton()}
        </Form>}
        {this.state.ticketId !== 'none' && <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
            <Header size="large" color='violet'>
    Ticket submitted!
<Header.Subheader style={{fontSize: '0.9rem' }}>
This is your Ticket ID
</Header.Subheader>
  </Header>
  </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <ClipboardCopy copyText={this.state.ticketId} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
          <Grid.Column>
            <Card centered circular raised style={{backgroundColor: '#dc6b33' }}><Card.Content textAlign='center' style={{fontWeight: "bold", fontSize: '0.6rem', color: '#FFFFFF'}}>Keep the code safe, it could be requested by the Support Team</Card.Content></Card>
</Grid.Column>
          </Grid.Row>
          </Grid>}
        <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
      </div>
    )
  }
  renderButton() {
    return (
      <Button color="violet" size="big" loading={this.state.loading} type="submit">
        Submit Ticket
      </Button>
    )
  }

}
