import React, { Component } from 'react'
import { UsersList } from './components/UsersList'
import { Router, Link, Route, Switch } from 'react-router-dom'
import { Sidebar, Grid, Menu, Segment, Image, Button, Form, Header, Loader, Card, Message } from 'semantic-ui-react'
import { CreateUser } from './components/CreateUser'
import { EmailUser } from './components/EmailUser'
import { InfoUser } from './components/InfoUser'
import { DeleteUser } from './components/DeleteUser'
import { CampaignProcess } from './components/CampaignProcess'
import { Auth } from './auth/Auth'
import { Paymentconfirmation } from './components/Paymentconfirmation'
import { Paymentfailure } from './components/Paymentfailure'
import { Paymentgateway } from './components/Paymentgateway'
import { PaymentProcess } from './components/PaymentProcess'
import { PaymentList } from './components/PaymentList'
import { CreateCoupon } from './components/CreateCoupon'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Input from '@material-ui/core/Input'
import { getInfoUser } from './api/users-api'
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { PaymentAdmins } from './components/PaymentAdmins'
import { CreateCampaign } from './components/CreateCampaign'
import { Campaignconfirmation } from './components/Campaignconfirmation'
import { Campaignfailure } from './components/Campaignfailure'
import './App.css'
import { OpenTroubleshooting } from './components/OpenTroubleshooting'
import { TroubleshootingProcess } from './components/TroubleshootingProcess'
import { CreateTroubleshooting } from './components/CreateTroubleshooting'
import { RedemptionList } from './components/RedemptionList'
import {Footer} from './components/Footer'
import { PaymentSave } from './components/PaymentSave'
import { Goldenconfirmation } from './components/Goldenconfirmation'
import { GoldenBoostResNew } from './components/GoldenBoostRestyledNewLogo'
import { GoldenCoinResNew } from './components/GoldenCoinRestyledNewLogo'
import { CouponContainerNew } from './components/CreateCouponProcess'
import { Backendfailure } from './components/Backendfailure'
import { InvoiceList } from './components/InvoiceList'
import { Couponconfirmation } from './components/Couponconfirmation'

export interface AppProps {
  auth: Auth,
  history: any,
  location: any
}

export interface AppState {
  activeItem: string,
  visible: boolean,
  loading: boolean,
  username: string,
  password: string,
  newpwd: string,
  confirmpwd: string,
  showpwd: boolean,
  showpwdconfirm: boolean,
  loadingpage: boolean,
  update: boolean,
  usergroup: string,
  exgroup: string,
  warning: string,
  updateCheck: boolean
}

const {g_adminsCouponsAv, paypalClientId, vvipUrl, logoLarge, currency, environment, goldAvailable, paymentAdminsAvailable, invoiceAvailable} = require(`./config-${process.env.REACT_APP_AWS_ACCOUNT}`)

const options = {
  "client-id": paypalClientId,
  intent: "capture",
  vault: false,
  currency,
  'disable-funding': 'card'
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)
    this.state = {
      activeItem: 'Users',
      visible: false,
      loading: false,
      username: '',
      password: '',
      newpwd: '',
      confirmpwd: '',
      showpwd: false,
      showpwdconfirm: false,
      loadingpage: false,
      update: false,
      usergroup: 'admins',
      exgroup: 'none',
      warning: 'none',
      updateCheck: false
    }
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePusher = this.handlePusher.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleItemClickToUsers = this.handleItemClickToUsers.bind(this)
    this.handleItemClickToCoupons = this.handleItemClickToCoupons.bind(this)
    this.handleItemClickToPayment = this.handleItemClickToPayment.bind(this)
    this.handleItemClickToPaymentAdmins = this.handleItemClickToPaymentAdmins.bind(this)
    this.handleItemClickToInvoices = this.handleItemClickToInvoices.bind(this)
    this.handleItemClickToMarketing = this.handleItemClickToMarketing.bind(this)
    this.handleItemClickToTroubleshooting = this.handleItemClickToTroubleshooting.bind(this)
    this.handleItemClickToRedemption = this.handleItemClickToRedemption.bind(this)
    this.handleConfirmPwdChange = this.handleConfirmPwdChange.bind(this)
    this.handleNewPwdChange = this.handleNewPwdChange.bind(this)
    this.handleSubmitNewPwd = this.handleSubmitNewPwd.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.handleClickShowPasswordConfirm = this.handleClickShowPasswordConfirm.bind(this)
    this.handleItemReturntoApp = this.handleItemReturntoApp.bind(this)
    this.handleWarningDismiss = this.handleWarningDismiss.bind(this)
    this.handleItemClickToGolden = this.handleItemClickToGolden.bind(this)
  }

  handleLogout = async () => {
    this.setState({ loadingpage: true })
    try {
      await this.props.auth.logout()
      this.setState({ loadingpage: false, activeItem: 'Users' })
    } catch (error) {
      this.setState({loadingpage:false, warning:'login'})
    }    
  }

  async componentDidMount() {
    console.log('environment: ' + environment)
    const search = this.props.location.search
    const params = new URLSearchParams(search)
    let isLogged: any = 'false'
    let paramsAccessToken: any = 'none'
    let paramsIdToken: any = 'none'
    let storageAccessToken: any = null
    let storageIdToken: any = null
    let storageGoldenToken: any = null

    isLogged = localStorage.getItem('isLoggedIn')
    storageAccessToken = localStorage.getItem('accessToken')
    storageIdToken = localStorage.getItem('idToken')
    paramsAccessToken = localStorage.getItem('vvipAccess')
    paramsIdToken = localStorage.getItem('vvipId')
    storageGoldenToken = localStorage.getItem('goldenToken')
    if (params.has('accesstoken')) {
      if (params.get('accesstoken')) {
        paramsAccessToken = params.get('accesstoken')
      }
    }
    if (params.has('idtoken')) {
      if (params.get('idtoken')) {
        paramsIdToken = params.get('idtoken')
      }
    }
    if (paramsAccessToken !== 'none' && paramsIdToken !== 'none' && paramsAccessToken && paramsIdToken) {
this.props.auth.setSessionFromVvip({accessToken: JSON.parse(paramsAccessToken), idToken: JSON.parse(paramsIdToken)}, 'none')
    }
    if (!paramsAccessToken || !paramsIdToken) {
    if (isLogged) {
if (isLogged === 'true') {
if (storageAccessToken && storageIdToken) {
  console.log('storage access token: ' + storageAccessToken)
  console.log('storage id token ' + storageIdToken)
  console.log('storage access token object: ', JSON.parse(storageAccessToken))
  console.log('storage id token object ', JSON.parse(storageIdToken))
  this.props.auth.setSession({accessToken: JSON.parse(storageAccessToken), idToken: JSON.parse(storageIdToken)}, 'none')
}
}
    }
  }
  if (this.props.auth.isAuthenticated()) {
  if (storageGoldenToken && storageGoldenToken === 'yes') {
    localStorage.setItem('goldenToken', 'no')
    this.setState({ activeItem: 'atlanticoin' })
    this.props.history.replace('/golden')
  } else {
    this.setState({loading: true})
    try {
      const userid: string = this.props.auth.getIdToken().payload.sub
    const result = await getInfoUser(this.props.auth.getIdToken(), userid)
    if (result.usergroup && result.usergroup !== 'none') {
    if (result.usergroup !== 'admins' && result.usergroup !== 'goldenadmins') {
      this.setState({ activeItem: 'payment', loading: false })
      this.props.history.replace('/payment')
    } else {
      this.setState({loading: false})
    }
  } else {
    this.setState({loading: false})
    console.log('no group')
    this.props.history.replace(`/backendfailure`)
  }
    } catch (error) {
      this.setState({loading: false})
      console.log('no group')
    this.props.history.replace(`/backendfailure`)
    }
  }
}
  }

  handleClickShowPassword() {
    this.setState({ showpwd: !this.state.showpwd })
  }

  handleClickShowPasswordConfirm() {
    this.setState({ showpwdconfirm: !this.state.showpwdconfirm })
  }

  handleItemClickToGolden = () => {
    this.setState({ activeItem: 'atlanticoin' })
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    console.log('clicked login')
    this.setState({loading:true})
      if (!this.state.username || !this.state.password) {
        this.setState({loading:false, warning:'details'})
        return
      }      
try {
  await this.props.auth.login(this.state.username, this.state.password)
  console.log('login finished')
  this.setState({loading:false})
} catch (error) {
  this.setState({loading:false, warning:'login'})
}
    }

    handleSubmitNewPwd = async (event: React.SyntheticEvent) => {
      event.preventDefault()
      this.setState({loading:true})
        if (this.state.newpwd !== this.state.confirmpwd) {
          this.setState({loading:false, warning:'pwd'})
          return
        }
        
  try {
    await this.props.auth.completePwd(this.state.newpwd)
    this.setState({loading:false})
  } catch (error) {
    this.setState({loading:false, warning:'pwd'})
  }  
      }

  handleToggle = () => this.setState({ visible: !this.state.visible })

  handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value })
  }

handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ password: event.target.value })
}

handleNewPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ newpwd: event.target.value })
}

handleConfirmPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
this.setState({ confirmpwd: event.target.value })
}

  handlePusher = () => {
    const { visible } = this.state

    if (visible) this.setState({ visible: false })
  }

handleWarningDismiss = () => {
  this.setState({warning: 'none'})
}

  handleItemClickToUsers = () => {
    this.setState({ activeItem: 'users' })}

    handleItemClickToCoupons = () => {
      this.setState({ activeItem: 'coupons' })}

      handleItemClickToPaymentAdmins = () => {
        this.setState({ activeItem: 'payment' })
      }

      handleItemClickToInvoices = () => {
        this.setState({ activeItem: 'invoices' })
      }

      handleItemClickToMarketing = () => {
        this.setState({ activeItem: 'marketing' })
      }

      handleItemClickToRedemption = () => {
        this.setState({ activeItem: 'redemption' })
      }

      handleItemClickToTroubleshooting = () => {
        this.setState({ activeItem: 'troubleshooting' })
      }

      handleItemReturntoApp = () => {
        this.setState({          
      activeItem: 'Users',
      visible: false,
      loading: false,
      username: '',
      password: '',
      newpwd: '',
      confirmpwd: '',
      showpwd: false,
      showpwdconfirm: false,
      loadingpage: false
        })
      }

      handleItemClickToPayment = () => {
        this.setState({ activeItem: 'payment' })}

  handleItemClick = (e: any) => {
    console.log('item clicked, setting active item to: ' + e.name + ' with location in app: ' + this.props.location)
    this.setState({ activeItem: e.name })}

    componentWillUnmount(): void {
      window.onbeforeunload = null
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
      window.onbeforeunload = (event) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = 'Refreshing will redirect you to VirtualVip App, are you sure?'; // Legacy method for cross browser support
        }
        return 'Refreshing will redirect you to VirtualVip App, are you sure?'; // Legacy method for cross browser support
      }
if (prevState.updateCheck === this.state.updateCheck) {
try {
  if (this.props.auth.getIdToken()) {
    if (this.props.auth.getIdToken().payload) {
      if (this.props.auth.getIdToken().payload.sub) {
  const userid: string = this.props.auth.getIdToken().payload.sub
  const result = await getInfoUser(this.props.auth.getIdToken(), userid)
  if (result.usergroup && result.usergroup !== 'none') {
  if (this.state.usergroup !== result.usergroup) {
  this.setState((prevState: any) => ({usergroup: result.usergroup, exgroup: result.exgroup, update: true, updateCheck: !prevState.updateCheck}))
}
  } else {
    console.log('no group')
    this.props.history.replace(`/backendfailure`)
  }
}
}
}
} catch (error) {
  console.log('no group')
    this.props.history.replace(`/backendfailure`)
}
}
    }

  render() {
    const { visible } = this.state
    let usergroup
    let exgroup
    if (!this.state.update) {
      usergroup = 'admins'
    exgroup = 'none'
    let accesstoken = this.props.auth.getAccessToken()
    let idtoken = this.props.auth.getIdToken()
    if (idtoken && accesstoken) {
      console.log('id token: ' + idtoken.jwtToken + ' accesstoken: ' + accesstoken.jwtToken + ' group: ' + accesstoken.payload['cognito:groups'][0])
usergroup = accesstoken.payload['cognito:groups'][0]
exgroup = idtoken.payload['custom:exgroup']
    }
  } else {
usergroup = this.state.usergroup
exgroup = this.state.exgroup
  }
    if (exgroup !== 'none') {usergroup = exgroup}
    console.log('usergroup: ' + usergroup)
    if (usergroup === 'admins') {
      return (
        <PayPalScriptProvider
			options={options}
		>
        <div>
          <Router history={this.props.history}>
          <Sidebar.Pushable>
          <Sidebar
       as={Menu}
       animation="overlay"
       icon="labeled"
       width='wide'
       inverted
       vertical
       visible={visible}
       style={{ backgroundColor: '#6435c9', color: '#FFFFFF' }}
      >
        <Menu.Item name={'Users'} onClick={this.handleItemClickToUsers} as={Link} to={'/'}>
          Users
        </Menu.Item>
        <Menu.Item name={'Coupons'} onClick={this.handleItemClickToCoupons} as={Link} to={'/coupons'}>
          Coupons
        </Menu.Item>
        {paymentAdminsAvailable && <Menu.Item name={'Payment'} onClick={this.handleItemClickToPaymentAdmins} as={Link} to={'/paymentadmins'}>
          Payment
        </Menu.Item>}
        {invoiceAvailable && <Menu.Item name={'Invoices'} onClick={this.handleItemClickToInvoices} as={Link} to={'/invoices'}>
          Invoices
        </Menu.Item>}
        <Menu.Item name={'Marketing'} onClick={this.handleItemClickToMarketing} as={Link} to={'/campaignlist'}>
          Marketing
        </Menu.Item>
        <Menu.Item name={'Redemption'} onClick={this.handleItemClickToRedemption} as={Link} to={'/redemption'}>
          Redemption
        </Menu.Item>
        <Menu.Item name={'Troubleshooting'} onClick={this.handleItemClickToTroubleshooting} as={Link} to={'/ticketcreate'}>
          Troubleshooting
        </Menu.Item>
        <Menu.Item name={'Virtual Vip'} onClick={this.handleItemReturntoApp} as={Link} to={'/vvip'}>
        Home - Virtual Vip
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher
          dimmed={visible}
          onClick={this.handlePusher}
          style={{ minHeight: "100vh" }}
        >
          <Segment style={{ padding: '1em 0em'}} vertical>
            <Grid container  centered relaxed verticalAlign="middle" style={{marginBottom: '50px'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center" width={16}>
          
                    {this.generateMenu()}
  
                    {this.generateCurrentPage()}

                    {this.generateWarning()}

                </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Footer />
          </Segment>      
          </Sidebar.Pusher>
      </Sidebar.Pushable>
      </Router> 
        </div>
        </PayPalScriptProvider>
      )
    }
    if (usergroup === 'goldenadmins') {
      return (
        <PayPalScriptProvider
			options={options}
		>
        <div>
          <Router history={this.props.history}>
          <Sidebar.Pushable>
          <Sidebar
       as={Menu}
       animation="overlay"
       icon="labeled"
       width='wide'
       inverted
       vertical
       visible={visible}
       style={{ backgroundColor: '#6435c9', color: '#FFFFFF' }}
      >
        <Menu.Item name={'Users'} onClick={this.handleItemClickToUsers} as={Link} to={'/'}>
          Users
        </Menu.Item>
        {g_adminsCouponsAv && <Menu.Item name={'Coupons'} onClick={this.handleItemClickToCoupons} as={Link} to={'/coupons'}>
          Coupons
        </Menu.Item>}
        {invoiceAvailable && <Menu.Item name={'Invoices'} onClick={this.handleItemClickToInvoices} as={Link} to={'/invoices'}>
          Invoices
        </Menu.Item>}
        <Menu.Item name={'Marketing'} onClick={this.handleItemClickToMarketing} as={Link} to={'/campaignlist'}>
          Marketing
        </Menu.Item>
        <Menu.Item name={'Redemption'} onClick={this.handleItemClickToRedemption} as={Link} to={'/redemption'}>
          Redemption
        </Menu.Item>
        <Menu.Item name={'Troubleshooting'} onClick={this.handleItemClickToTroubleshooting} as={Link} to={'/ticketcreate'}>
          Troubleshooting
        </Menu.Item>
        <Menu.Item name={'Virtual Vip'} onClick={this.handleItemReturntoApp} as={Link} to={'/vvip'}>
        Home - Virtual Vip
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher
          dimmed={visible}
          onClick={this.handlePusher}
          style={{ minHeight: "100vh" }}
        >
          <Segment style={{ padding: '1em 0em'}} vertical>
            <Grid container  centered relaxed verticalAlign="middle" style={{marginBottom: '50px'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center" width={16}>
          
                    {this.generateMenu()}
  
                    {this.generateCurrentPage()}

                    {this.generateWarning()}

                </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Footer />
          </Segment>      
          </Sidebar.Pusher>
      </Sidebar.Pushable>
      </Router> 
        </div>
        </PayPalScriptProvider>
      )
    }
      return (
        <PayPalScriptProvider
			options={options}
		>
        <div>
          <Router history={this.props.history}>
          <Sidebar.Pushable>
          <Sidebar
       as={Menu}
       animation="overlay"
       icon="labeled"
       width='wide'
       inverted
       vertical
       visible={visible}
       style={{ backgroundColor: '#6435c9', color: '#FFFFFF' }}
      >
        <Menu.Item name={'Users'} onClick={this.handleItemClickToUsers} as={Link} to={'/'}>
          Users
        </Menu.Item>
        <Menu.Item name={'Payment'} onClick={this.handleItemClickToPayment} as={Link} to={'/payment'}>
          Payment
        </Menu.Item>
        {usergroup !== 'employees' && invoiceAvailable && <Menu.Item name={'Invoices'} onClick={this.handleItemClickToInvoices} as={Link} to={'/invoices'}>
          Invoices
        </Menu.Item>}
        {goldAvailable && (usergroup === 'employees' || usergroup === 'companies') && <Menu.Item name={'Atlanticoin'} onClick={this.handleItemClickToGolden} as={Link} to={'/golden'}>
          Atlanticoin
        </Menu.Item>}
        <Menu.Item name={'Troubleshooting'} onClick={this.handleItemClickToTroubleshooting} as={Link} to={'/ticketcreate'}>
          Troubleshooting
        </Menu.Item>
        <Menu.Item name={'Virtual Vip'} onClick={this.handleItemReturntoApp} as={Link} to={'/vvip'}>
          Home - Virtual Vip
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher
          dimmed={visible}
          onClick={this.handlePusher}
          style={{ minHeight: "100vh" }}
        >
          <Segment style={{ padding: '1em 0em'}} vertical>
            <Grid container  centered relaxed verticalAlign="middle" style={{marginBottom: '50px'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center" width={16}>
          
                    {this.generateMenu()}
  
                    {this.generateCurrentPage()}

                    {this.generateWarning()}

                </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Footer />
          </Segment>      
          </Sidebar.Pusher>
      </Sidebar.Pushable>
      </Router>
        </div>
        </PayPalScriptProvider>
      )      
  }

generateWarning() {
  switch (this.state.warning) {
    case 'login':
      return (
        <Message onDismiss={this.handleWarningDismiss} size='small' warning>
          <Message.Header>Attention!</Message.Header>
          <p>Login error</p>
          </Message>
          )
      case 'details':
        return (
          <Message onDismiss={this.handleWarningDismiss} size='small' warning>
          <Message.Header>Attention!</Message.Header>
          <p>Provide all details</p>
          </Message>
            )
        case 'pwd':
          return (
            <Message onDismiss={this.handleWarningDismiss} size='large' warning>
          <Message.Header>Attention!</Message.Header>
          <p>Password error! Remember that your password need minimum 8 characters, an Uppercase letter and a special character like * or ?</p>
          </Message>
              )
    default:
      break;
  } 
}

  generateMenu() {
    let activeItem = this.state.activeItem
    let usergroup
    let exgroup
    if (!this.state.update) {
      usergroup = 'admins'
    exgroup = 'none'
    let accesstoken = this.props.auth.getAccessToken()
    let idtoken = this.props.auth.getIdToken()
    if (idtoken && accesstoken) {
      console.log('id token: ' + idtoken.jwtToken + ' accesstoken: ' + accesstoken.jwtToken + ' group: ' + accesstoken.payload['cognito:groups'][0])
usergroup = accesstoken.payload['cognito:groups'][0]
exgroup = idtoken.payload['custom:exgroup']
    }
  } else {
usergroup = this.state.usergroup
exgroup = this.state.exgroup
  }
    if (exgroup !== 'none') {usergroup = exgroup}
    if (this.props.auth.isAuthenticated()) {
      if (usergroup === 'admins') {
        return (
          <div>
            <div>
          <Menu widths={3}>
          <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} onClick={this.handleToggle}>
              Navigation Menu
            </Menu.Item>
            <Menu.Item>
              <Grid stretched padded centered>
              <Grid.Row columns={1} centered>
<Grid.Column textAlign='center'>
<Image src={logoLarge} size='medium' centered/>
</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} centered>
                <Grid.Column textAlign='center'>
                <Header as='h3' color='violet'>
      Management System
  </Header>
</Grid.Column>
                </Grid.Row> 
              </Grid>
       </Menu.Item>
    {this.logInLogOutButton()}
            </Menu>
            </div>
            <div style={{overflow: 'auto', overflowY: 'hidden'}}>
            <Menu attached='top' tabular style={{ backgroundColor: '#E4DCDB'}}>
            <Menu.Item
              name='Users'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
              active={activeItem === 'Users' || activeItem === 'users'}
              onClick={this.handleItemClickToUsers}
              as={Link} 
              to='/'
            />
            <Menu.Item
              name='Marketing'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Marketing' || activeItem === 'marketing'}
              onClick={this.handleItemClickToMarketing}
              as={Link} 
              to='/campaignlist'
            />
            <Menu.Item
              name='Redemption'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Redemption' || activeItem === 'redemption'}
              onClick={this.handleItemClickToRedemption}
              as={Link} 
              to='/redemption'
            />
            {paymentAdminsAvailable && <Menu.Item
              name='Payment'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Payment' || activeItem === 'payment'}
              onClick={this.handleItemClickToPaymentAdmins}
              as={Link} 
              to='/paymentadmins'
            />}
            {invoiceAvailable && <Menu.Item
              name='Invoices'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Invoices' || activeItem === 'invoices'}
              onClick={this.handleItemClickToInvoices}
              as={Link} 
              to='/invoices'
            />}
            <Menu.Item
              name='Coupons'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Coupons' || activeItem === 'coupons'}
              onClick={this.handleItemClickToCoupons}
              as={Link} 
              to='/coupons'
            />
            <Menu.Item
              name='Troubleshooting'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Troubleshooting' || activeItem === 'troubleshooting'}
              onClick={this.handleItemClickToTroubleshooting}
              as={Link} 
              to='/ticketcreate'
            />
          </Menu>
          </div>
          </div>
        )
      }
      if (usergroup === 'goldenadmins') {
        return (
          <div>
          <Menu widths={3}>
          <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} onClick={this.handleToggle}>
              Navigation Menu
            </Menu.Item>
            <Menu.Item>
              <Grid stretched padded centered>
              <Grid.Row columns={1} centered>
<Grid.Column textAlign='center'>
<Image src={logoLarge} size='medium' centered/>
</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} centered>
                <Grid.Column textAlign='center'>
                <Header as='h3' color='violet'>
      Management System
  </Header>
</Grid.Column>
                </Grid.Row> 
              </Grid>
       </Menu.Item>
    {this.logInLogOutButton()}
            </Menu>
            <div style={{overflow: 'auto', overflowY: 'hidden'}}>
            <Menu attached='top' tabular style={{ backgroundColor: '#E4DCDB'}}>
            <Menu.Item
              name='Users'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Users' || activeItem === 'users'}
              onClick={this.handleItemClickToUsers}
              as={Link} 
              to='/'
            />
            <Menu.Item
              name='Marketing'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Marketing' || activeItem === 'marketing'}
              onClick={this.handleItemClickToMarketing}
              as={Link} 
              to='/campaignlist'
            />
            <Menu.Item
              name='Redemption'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Redemption' || activeItem === 'redemption'}
              onClick={this.handleItemClickToRedemption}
              as={Link} 
              to='/redemption'
            />
            {invoiceAvailable && <Menu.Item
              name='Invoices'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Invoices' || activeItem === 'invoices'}
              onClick={this.handleItemClickToInvoices}
              as={Link} 
              to='/invoices'
            />}
            {g_adminsCouponsAv && <Menu.Item
              name='Coupons'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Coupons' || activeItem === 'coupons'}
              onClick={this.handleItemClickToCoupons}
              as={Link} 
              to='/coupons'
            />}
            <Menu.Item
              name='Troubleshooting'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Troubleshooting' || activeItem === 'troubleshooting'}
              onClick={this.handleItemClickToTroubleshooting}
              as={Link} 
              to='/ticketcreate'
            />
          </Menu>
          </div>
          </div>
        )
      }
        return (
          <div>
          <Menu widths={3}>
          <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} onClick={this.handleToggle}>
              Navigation Menu
            </Menu.Item>
            <Menu.Item>
            <Grid stretched padded centered>
              <Grid.Row columns={1} centered>
<Grid.Column textAlign='center'>
<Image src={logoLarge} size='medium' centered/>
</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} centered>
                <Grid.Column textAlign='center'>
                <Header as='h3' color='violet'>
      Management System
  </Header>
</Grid.Column>
                </Grid.Row> 
              </Grid>
       </Menu.Item>
    {this.logInLogOutButton()}
            </Menu>
            <div style={{overflow: 'auto', overflowY: 'hidden'}}>
            <Menu attached='top' tabular style={{ backgroundColor: '#E4DCDB'}}>
            <Menu.Item
              name='Users'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
              active={activeItem === 'Users' || activeItem === 'users'}
              onClick={this.handleItemClickToUsers}
              as={Link} 
              to='/'
            />
            <Menu.Item
              name='Payment'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
              active={activeItem === 'Payment' || activeItem === 'payment'}
              onClick={this.handleItemClickToPayment}
              as={Link}
              to='/payment'
            />
            {usergroup !== 'employees' && invoiceAvailable && <Menu.Item
              name='Invoices'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem'}}
              active={activeItem === 'Invoices' || activeItem === 'invoices'}
              onClick={this.handleItemClickToInvoices}
              as={Link} 
              to='/invoices'
            />}
            {goldAvailable && (usergroup === 'employees' || usergroup === 'companies') && <Menu.Item
              name='Atlanticoin'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
              active={activeItem === 'Atlanticoin' || activeItem === 'atlanticoin'}
              onClick={this.handleItemClickToGolden}
              as={Link}
              to='/golden'
            />}
            <Menu.Item
              name='Troubleshooting'
              style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
              active={activeItem === 'Troubleshooting' || activeItem === 'troubleshooting'}
              onClick={this.handleItemClickToTroubleshooting}
              as={Link} 
              to='/ticketcreate'
            />          
          </Menu>
          </div>
          </div>
        )
    }
    else {
      return (
        <div>
        <Menu widths={3}>
        <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} as={Link} to='/'>
            Backoffice Access
          </Menu.Item>
          <Menu.Item>
          <Grid stretched padded centered>
              <Grid.Row columns={1} centered>
<Grid.Column textAlign='center'>
<Image src={logoLarge} size='medium' centered/>
</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} centered>
                <Grid.Column textAlign='center'>
                <Header as='h3' color='violet'>
      Management System
  </Header>
</Grid.Column>
                </Grid.Row> 
              </Grid>
     </Menu.Item>
  {this.logInLogOutButton()}
          </Menu>
          <div style={{overflow: 'auto', overflowY: 'hidden'}}>
            <Menu attached='top' tabular style={{ backgroundColor: '#E4DCDB'}}>
          <Menu.Item
            name='Home'
            style={{ backgroundColor: '#E4DCDB', fontWeight: 'bold', fontSize: '0.9rem' }}
            active={true}
          />
        </Menu>
        </div>
        </div>
      )
    }
}

logInLogOutButton() {
  if (this.props.auth.isAuthenticated()) {
    return (
      <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} name="logout" onClick={this.handleLogout}>
        Log Out
      </Menu.Item>
    )
  } else {
    return (
      <Menu.Item style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#7826A0'}} name="loggedOut">
       Login Required
      </Menu.Item>
    )
  }
}

renderLoginButton() {
  return(
    <Button color="violet" size="big" loading={this.state.loading} type="submit">
        Login
      </Button>
  )
}

renderLoading() {
  return (
    <Grid.Row>
      <Loader indeterminate active inline="centered">
        Loading Platform...
      </Loader>
    </Grid.Row>
  )
}

renderNewPwdButton() {
  return(
    <Button color="violet" size="big" loading={this.state.loading} type="submit">
        Set New Password
      </Button>
  )
}

  generateCurrentPage() {
    if (this.state.loadingpage) {
      return this.renderLoading()
    }
    if (this.props.auth.isForced()) {
      return (
        <div>
          <h1>Please change your Password</h1>
          <Form onSubmit={this.handleSubmitNewPwd}>
          <Form.Field>
              <label>New Password</label>
              <Input                
                placeholder="New Password"
                type={this.state.showpwd ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showpwd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                value={this.state.newpwd}
                onChange={this.handleNewPwdChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Confirm new Password</label>
              <Input
                placeholder="Confirm Password"
                type={this.state.showpwdconfirm ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPasswordConfirm}
                    >
                      {this.state.showpwdconfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                value={this.state.confirmpwd}
                onChange={this.handleConfirmPwdChange}
              />
            </Form.Field>
          {this.renderNewPwdButton()}
          </Form>
        </div>
      )
    }
    if (!this.props.auth.isAuthenticated()) {
      return (
        <div>
          <Card centered raised style={{ marginBottom: '0.1em', backgroundColor: '#dc6b33' }}><Card.Content style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
          Please log in to access Backoffice...
          </Card.Content></Card>
          <Form onSubmit={this.handleSubmit}>
          <Form.Field>
              <label>Email</label>
              <Input
                placeholder="Email"
                value={this.state.username}
                onChange={this.handleUsernameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <Input              
                placeholder="Password"
                type={this.state.showpwd ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showpwd ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
            </Form.Field>
          {this.renderLoginButton()}
          </Form>
        </div>
      )
    }
    return (
      <div style={{ padding: '1em 0em', fontSize: '0.7rem'}} >
      <Switch>
        
<Route
          path="/users/:idArray/delete"
          exact
          render={(props) => {
            return <DeleteUser {...props} auth={this.props.auth} />
          }}
        />

        <Route
          path="/users/:userIdArray/email"
          exact
          render={(props) => {
            return <EmailUser {...props} auth={this.props.auth} />
          }}
        />

        <Route
          path="/users/:userId/info"
          exact
          render={(props) => {
            return <InfoUser {...props} auth={this.props.auth} />
          }}
        />

        <Route
          path="/users/create"
          render={(props) => {
            return <CreateUser {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/payment/:groupandkey/confirmation"
          render={(props) => {
            return <Paymentconfirmation {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/payment/process/:upgrade"
          render={(props) => {
            return <PaymentProcess {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/payment/error"
          exact
          render={(props) => {
            return <Paymentfailure {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/gateway/:infoarray"
          exact
          render={(props) => {
            return <Paymentgateway {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/payment/save"
          exact
          render={(props) => {
            return <PaymentSave {...props} auth={this.props.auth} />
          }}
        />

        <Route
          path="/payment"
          exact
          render={(props) => {
            return <PaymentList {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/paymentadmins"
          exact
          render={(props) => {
            return <PaymentAdmins {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/invoices"
          exact
          render={(props) => {
            return <InvoiceList {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/golden/:mode/confirmation"
          exact
          render={(props) => {
            return <Goldenconfirmation {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/goldenboost/:infocompany"
          exact
          render={(props) => {
            return <GoldenBoostResNew {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/golden"
          exact
          render={(props) => {
            return <GoldenCoinResNew {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/ticketopen/:ticketArr"
          exact
          render={(props) => {
            return <OpenTroubleshooting {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/ticketprocess/:infoArray"
          exact
          render={(props) => {
            return <TroubleshootingProcess {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/ticketcreate"
          exact
          render={(props) => {
            return <CreateTroubleshooting {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/campaignlist"
          exact
          render={(props) => {
            return <CreateCampaign {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/campaignprocess"
          exact
          render={(props) => {
            return <CampaignProcess {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/campaigncreate"
          exact
          render={(props) => {
            return <Campaignconfirmation {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/campaignfailure"
          exact
          render={(props) => {
            return <Campaignfailure {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/backendfailure"
          exact
          render={(props) => {
            return <Backendfailure {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/couponconfirmation/:key"
          exact
          render={(props) => {
            return <Couponconfirmation {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/advancedcoupon"
          exact
          render={(props) => {
            return <CouponContainerNew {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/coupons"
          exact
          render={(props) => {
            return <CreateCoupon {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/redemption"
          exact
          render={(props) => {
            return <RedemptionList {...props} auth={this.props.auth} />
          }}
        />

<Route
          path="/vvip"
          exact
          component={() => {
            window.location.replace(vvipUrl);
            return null;
          }}          
        />

<Route
        path="/backoffice/index.html"
        exact
          render={(props) => {
            return <UsersList {...props} auth={this.props.auth} />
          }}
        />

        <Route
        path="/"
        exact
          render={(props) => {
            return <UsersList {...props} auth={this.props.auth} />
          }}
        />

      </Switch>
      </div>
    )
  }
}

export default App