import * as React from 'react'
import { Grid, Button, Form, Header, Card, Menu, Divider, Checkbox, Loader, Modal, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { getInfoUser, checkUpgrade } from '../api/users-api'
import { UserButton } from './UserButton'
import { UserModel } from '../types/UserModel'
import { InvoiceModel } from '../types/InvoiceModel'
const { stripeTestPlan, testPaymentActive, paypalAvailable, stripeplanmUA, stripeplanyUA, paypalplanmUA, paypalplanyUA, stripeplanmCA, stripeplanmCB, stripeplanmCC, stripeplanmCD, stripeplanmCE, stripeplanmCF, stripeplanmCG, stripeplanmCH, stripeplanyCA, stripeplanyCB, stripeplanyCC, stripeplanyCD, stripeplanyCE, stripeplanyCF, stripeplanyCG, stripeplanyCH, paypalplanmCA, paypalplanmCB, paypalplanmCC, paypalplanmCD, paypalplanmCE, paypalplanmCF, paypalplanmCG, paypalplanmCH, paypalplanyCA, paypalplanyCB, paypalplanyCC, paypalplanyCD, paypalplanyCE, paypalplanyCF, paypalplanyCG, paypalplanyCH, usersFirst, usersSecond, usersThird, usersFourth, usersFifth, usersSixth, usersSeventh, usersEighth, chargeFirstYear, chargeSecondYear, chargeThirdYear, chargeFourthYear, chargeFifthYear, chargeSixthYear, chargeSeventhYear, chargeEighthYear, chargeFirstMonth, chargeSecondMonth, chargeThirdMonth, chargeFourthMonth, chargeFifthMonth, chargeSixthMonth, chargeSeventhMonth, chargeEighthMonth, chargeUserMonth, chargeUserYear, nameplanmUA, nameplanyUA, nameplanmA, nameplanmB, nameplanmC, nameplanmD, nameplanmE, nameplanmF, nameplanmG, nameplanmH, nameplanyA, nameplanyB, nameplanyC, nameplanyD, nameplanyE, nameplanyF, nameplanyG, nameplanyH, currency } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

function fixRounding(value: number, precision: number) {
  let power = Math.pow(10, precision || 0)
  return Math.round(value * power) / power
}

const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

interface PaymentProcessProps {
  match: {
    params: {
      upgrade: string
    }
  },
  auth: Auth,
  history: any
}

interface PaymentProcessState {
activeItem: string,
usergroup: string,
exgroup: string,
checked: boolean,
timepayment: string,
charge: string,
paymentuser: string,
loading: boolean,
method: string,
numberofusers: string,
email: string,
upgradenumber: string,
upgradecharge: string,
duration: string,
loadingupgrade: boolean,
initialcharge: string,
modal: boolean,
companyName: string,
showContract: boolean
}

export class PaymentProcess extends React.PureComponent<
PaymentProcessProps,
PaymentProcessState
> {
  state: PaymentProcessState = {
activeItem: 'step1',
usergroup: 'members',
exgroup: 'none',
checked: true,
timepayment: 'month',
charge: '9.70',
paymentuser: '',
loading: true,
method: 'card',
numberofusers: '1',
email: '',
upgradenumber: '0',
upgradecharge: 'none',
duration: 'month',
loadingupgrade: false,
initialcharge: 'none',
modal: false,
companyName: 'none',
showContract: false
  }

  async componentDidMount() {
    try {
      const userid: string = this.props.auth.getIdToken().payload.sub
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
    let paymentuser = userPaymentInfo.payment
    let usergroup = userPaymentInfo.usergroup
    let renew = userPaymentInfo.renew
    let charge = userPaymentInfo.charge
    let initialcharge = userPaymentInfo.charge
    let exgroup = userPaymentInfo.exgroup
    let email = userPaymentInfo.email
    let duration = userPaymentInfo.timepayment
    let startusers = userPaymentInfo.numberofusers
    let companyName = userPaymentInfo.company_name || 'none'
    let numberuserVar = '1'
    let upgradecharge = 'none'
    let showContract = false
if (exgroup !== 'none') {usergroup = exgroup}
if (usergroup === 'companies') {
if (startusers === '1') {
showContract = true
}
} else {
  if (usergroup === 'guests' || usergroup === 'goldenemployees') {
showContract = true
  }
}
    if (usergroup) {
      if (usergroup === 'companies') {
        if (this.props.match.params.upgrade !== 'none') {
          if (parseInt(startusers) < parseInt(usersSecond)) {
            numberuserVar = usersSecond
            this.setState({numberofusers: usersSecond, email, initialcharge})
          } else {
            if (parseInt(startusers) < parseInt(usersThird)) {
              numberuserVar = usersThird
              this.setState({numberofusers: usersThird, email, initialcharge})
            } else {if (parseInt(startusers) < parseInt(usersFourth)) {
              numberuserVar = usersFourth
              this.setState({numberofusers: usersFourth, email, initialcharge})
            } else {if (parseInt(startusers) < parseInt(usersFifth)) {
              numberuserVar = usersFifth
              this.setState({numberofusers: usersFifth, email, initialcharge})
            } else {if (parseInt(startusers) < parseInt(usersSixth)) {
              numberuserVar = usersSixth
              this.setState({numberofusers: usersSixth, email, initialcharge})
            } else {if (parseInt(startusers) < parseInt(usersSeventh)) {
              numberuserVar = usersSeventh
              this.setState({numberofusers: usersSeventh, email, initialcharge})
            } else {if (parseInt(startusers) < parseInt(usersEighth)) {
              numberuserVar = usersEighth
              this.setState({numberofusers: usersEighth, email, initialcharge})
            }}}}}}
          }  
        } else {
        this.setState({numberofusers: usersFirst, email, initialcharge})
        numberuserVar = usersFirst
      }        
      }
      if (this.props.match.params.upgrade !== 'none') {
        let numberofusers = numberuserVar
        let charge = '0'
        if (duration === 'year') {
          switch (numberofusers) {
            case usersFirst:
              charge = chargeFirstYear
              break
              case usersSecond:
              charge = chargeSecondYear
              break
              case usersThird:
              charge = chargeThirdYear
              break
              case usersFourth:
                charge = chargeFourthYear
              break
              case usersFifth:
              charge = chargeFifthYear
              break
              case usersSixth:
              charge = chargeSixthYear
              break
              case usersSeventh:
              charge = chargeSeventhYear
              break
              case usersEighth:
                charge = chargeEighthYear
              break
            default:
              charge = chargeFirstYear
              break
          }
        } else {
          switch (numberofusers) {
            case usersFirst:
              charge = chargeFirstMonth
              break
              case usersSecond:
                charge = chargeSecondMonth
              break
              case usersThird:
                charge = chargeThirdMonth
              break
              case usersFourth:
                charge = chargeFourthMonth
              break
              case usersFifth:
              charge = chargeFifthMonth
              break
              case usersSixth:
              charge = chargeSixthMonth
              break
              case usersSeventh:
              charge = chargeSeventhMonth
              break
              case usersEighth:
                charge = chargeEighthMonth
              break
            default:
              charge = chargeFirstMonth
              break
          }}
          try {
            upgradecharge = await checkUpgrade(this.props.auth.getIdToken(), charge, email, initialcharge)
          } catch (error) {
            console.log('error getting users payment process ' + error);
              this.props.history.replace('/payment/error');
          }
      }
      this.setState({ companyName, usergroup, exgroup, paymentuser, charge, loading: false, email, upgradenumber: startusers, duration, upgradecharge, showContract })
    } else {
      console.log('error getting users payment process, renew value: ' + renew);
        this.setState({loading: false})
            this.props.history.replace('/payment/error');
    }
    } catch (error) {
      console.log('error getting users payment process ' + error);
        this.setState({loading: false})
            this.props.history.replace('/payment/error');
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.numberofusers !== this.state.numberofusers) {
      if (this.props.match.params.upgrade !== 'none' && this.state.activeItem === 'step1') {
        this.setState({loadingupgrade: true})
        let numberofusers = this.state.numberofusers
        let charge = '0'
        let upgradecharge = '0'
        if (this.state.duration === 'year') {
          switch (numberofusers) {
            case usersFirst:
              charge = chargeFirstYear
              break
              case usersSecond:
              charge = chargeSecondYear
              break
              case usersThird:
              charge = chargeThirdYear
              break
              case usersFourth:
                charge = chargeFourthYear
              break
              case usersFifth:
              charge = chargeFifthYear
              break
              case usersSixth:
              charge = chargeSixthYear
              break
              case usersSeventh:
              charge = chargeSeventhYear
              break
              case usersEighth:
                charge = chargeEighthYear
              break
            default:
              charge = chargeFirstYear
              break
          }
        } else {
          switch (numberofusers) {
            case usersFirst:
              charge = chargeFirstMonth
              break
              case usersSecond:
                charge = chargeSecondMonth
              break
              case usersThird:
                charge = chargeThirdMonth
              break
              case usersFourth:
                charge = chargeFourthMonth
              break
              case usersFifth:
              charge = chargeFifthMonth
              break
              case usersSixth:
              charge = chargeSixthMonth
              break
              case usersSeventh:
              charge = chargeSeventhMonth
              break
              case usersEighth:
                charge = chargeEighthMonth
              break
            default:
              charge = chargeFirstMonth
              break
          }}
          try {
            upgradecharge = await checkUpgrade(this.props.auth.getIdToken(), charge, this.state.email, this.state.initialcharge)
          } catch (error) {
            console.log('error getting users payment process ' + error);
              this.props.history.replace('/payment/error');
          }
          this.setState({ upgradecharge, loadingupgrade: false })
      }
    }
    
  }

  renewToggle = () => this.setState((prevState) => ({ checked: !prevState.checked }))

  handleUpgradeUser = async () => {
    let { numberofusers } = this.state
    let charge = '0'
    if (this.state.duration === 'year') {
      switch (numberofusers) {
        case usersFirst:
          charge = chargeFirstYear
          break
          case usersSecond:
          charge = chargeSecondYear
          break
          case usersThird:
          charge = chargeThirdYear
          break
          case usersFourth:
            charge = chargeFourthYear
          break
          case usersFifth:
          charge = chargeFifthYear
          break
          case usersSixth:
          charge = chargeSixthYear
          break
          case usersSeventh:
          charge = chargeSeventhYear
          break
          case usersEighth:
            charge = chargeEighthYear
          break
        default:
          charge = chargeFirstYear
          break
      }
    } else {
      switch (numberofusers) {
        case usersFirst:
          charge = chargeFirstMonth
          break
          case usersSecond:
            charge = chargeSecondMonth
          break
          case usersThird:
            charge = chargeThirdMonth
          break
          case usersFourth:
            charge = chargeFourthMonth
          break
          case usersFifth:
          charge = chargeFifthMonth
          break
          case usersSixth:
          charge = chargeSixthMonth
          break
          case usersSeventh:
          charge = chargeSeventhMonth
          break
          case usersEighth:
            charge = chargeEighthMonth
          break
        default:
          charge = chargeFirstMonth
          break
      }}
    this.setState({ activeItem: 'step2', timepayment: this.state.duration, charge })
  }

  handleMonthUser = async () => {
    let charge = chargeUserMonth
    this.setState({ activeItem: 'step2', timepayment: 'month', charge })
  }

  handleYearUser = async () => {
    let charge = chargeUserYear
    this.setState({ activeItem: 'step2', timepayment: 'year', charge })
  }

  handleCompanyYearUser = async () => {
    let { numberofusers } = this.state
    let charge = chargeFirstYear
    switch (numberofusers) {
      case usersFirst:
        charge = chargeFirstYear
        break
        case usersSecond:
        charge = chargeSecondYear
        break
        case usersThird:
        charge = chargeThirdYear
        break
        case usersFourth:
          charge = chargeFourthYear
        break
        case usersFifth:
        charge = chargeFifthYear
        break
        case usersSixth:
        charge = chargeSixthYear
        break
        case usersSeventh:
        charge = chargeSeventhYear
        break
        case usersEighth:
          charge = chargeEighthYear
        break
      default:
        charge = chargeFirstYear
        break
    }
    this.setState({ activeItem: 'step2', timepayment: 'year', charge })
  }

  handleCompanyMonthUser = async () => {
    let { numberofusers } = this.state
    let charge = chargeFirstMonth
    switch (numberofusers) {
      case usersFirst:
        charge = chargeFirstMonth
        break
        case usersSecond:
          charge = chargeSecondMonth
        break
        case usersThird:
          charge = chargeThirdMonth
        break
        case usersFourth:
          charge = chargeFourthMonth
        break
        case usersFifth:
        charge = chargeFifthMonth
        break
        case usersSixth:
        charge = chargeSixthMonth
        break
        case usersSeventh:
        charge = chargeSeventhMonth
        break
        case usersEighth:
          charge = chargeEighthMonth
        break
      default:
        charge = chargeFirstMonth
        break
    }
    this.setState({ activeItem: 'step2', timepayment: 'month', charge })
  }

  handleCreditcardUser = async () => {
    this.setState({ activeItem: 'step3', method: 'card' })
  }

  handlePaypalUser = async () => {
    if (paypalAvailable) {
      this.setState({ activeItem: 'step3', method: 'paypal' })
    } else {
this.setState({modal: true})
    }
  }

  handlePaynowUser = async (userInfo: string[]) => {
    let userinfoAr = userInfo.toString()
    this.setState({method: 'card', numberofusers: '1', upgradecharge: 'none', upgradenumber: '0', activeItem: 'step1'})
    this.props.history.replace(`/gateway/${userinfoAr}`)
  }

  handleUsersNumber = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ numberofusers: event.target.value })
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Payment process starting...
        </Loader>
      </Grid.Row>
    )
  }

  renderPaymentCompanystep1() {
    let { activeItem } = this.state
    let { numberofusers } = this.state
    let pricemonth = chargeFirstMonth + ' ' + currency
    let priceyear = chargeFirstYear + ' ' + currency
    switch (numberofusers) {
      case usersFirst:
        pricemonth = chargeFirstMonth + ' ' + currency
        priceyear = chargeFirstYear + ' ' + currency
        break
        case usersSecond:
          pricemonth = chargeSecondMonth + ' ' + currency
          priceyear = chargeSecondYear + ' ' + currency
        break
        case usersThird:
          pricemonth = chargeThirdMonth + ' ' + currency
          priceyear = chargeThirdYear + ' ' + currency
        break
        case usersFourth:
          pricemonth = chargeFourthMonth + ' ' + currency
          priceyear = chargeFourthYear + ' ' + currency
        break
        case usersFifth:
        pricemonth = chargeFifthMonth + ' ' + currency
        priceyear = chargeFifthYear + ' ' + currency
        break
        case usersSixth:
          pricemonth = chargeSixthMonth + ' ' + currency
          priceyear = chargeSixthYear + ' ' + currency
        break
        case usersSeventh:
          pricemonth = chargeSeventhMonth + ' ' + currency
          priceyear = chargeSeventhYear + ' ' + currency
        break
        case usersEighth:
          pricemonth = chargeEighthMonth + ' ' + currency
          priceyear = chargeEighthYear + ' ' + currency
        break
      default:
        pricemonth = chargeFirstMonth + ' ' + currency
        priceyear = chargeFirstYear + ' ' + currency
        break
    }
    return (
      <div>
        <HeaderCustom headerType="payment_1"/>
        <Grid centered relaxed stackable columns='equal' verticalAlign="middle">
          {this.props.match.params.upgrade === 'none' && <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Choose your Subscription Plan</Header></Grid.Column>
          </Grid.Row>}
          {this.props.match.params.upgrade !== 'none' && <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Upgrade your Subscription</Header></Grid.Column>
          </Grid.Row>}
          {this.props.match.params.upgrade === 'none' && <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              fluid
              color = "orange"
                onClick={this.handleCompanyMonthUser}
              >
                Standard
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handleCompanyMonthUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Subscription for one Month
          </Card.Header>
          <Card.Description>{pricemonth}</Card.Description>
        </Card.Content>
      </Card></Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
                fluid
                onClick={this.handleCompanyYearUser}
              >
                Full
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card fluid onClick={this.handleCompanyYearUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
          Upfront for one Year
          </Card.Header>
          <Card.Description>{priceyear}</Card.Description>
        </Card.Content>
      </Card>      
              </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
          </Grid.Row>}
          {this.props.match.params.upgrade !== 'none' && <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Button
              color = "orange"
              fluid
                onClick={this.handleUpgradeUser}
                loading={this.state.loadingupgrade}
              >
                Accept
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Price for Upgrade
          </Card.Header>
          <Card.Description>{this.state.upgradecharge + ' ' + currency}</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
            </Grid.Row>}
          <Grid.Row columns={5} centered>
          <Grid.Column />
            <Grid.Column />
            <Grid.Column textAlign="center" verticalAlign="middle" style={{minWidth: '14em'}}>
            <Card fluid>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Number of Employees
          </Card.Header>
          <Card.Description>
          <select
          style={{width: '100%'}}
              value={this.state.numberofusers}
              onChange={this.handleUsersNumber}
            >
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersFirst)) && <option value={usersFirst}>Up to {usersFirst}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersSecond)) && <option value={usersSecond}>Up to {usersSecond}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersThird)) && <option value={usersThird}>Up to {usersThird}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersFourth)) && <option value={usersFourth}>Up to {usersFourth}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersFifth)) && <option value={usersFifth}>Up to {usersFifth}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersSixth)) && <option value={usersSixth}>Up to {usersSixth}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersSeventh)) && <option value={usersSeventh}>Up to {usersSeventh}</option>}
              {(this.state.exgroup === 'none' && parseInt(this.state.upgradenumber) < parseInt(usersEighth)) && <option value={usersEighth}>Unlimited</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersFirst)) && <option value={usersFirst}>Up to {usersFirst}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersSecond)) && <option value={usersSecond}>Up to {usersSecond}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersThird)) && <option value={usersThird}>Up to {usersThird}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersFourth)) && <option value={usersFourth}>Up to {usersFourth}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersFifth)) && <option value={usersFifth}>Up to {usersFifth}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersSixth)) && <option value={usersSixth}>Up to {usersSixth}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersSeventh)) && <option value={usersSeventh}>Up to {usersSeventh}</option>}
              {(this.state.exgroup !== 'none' && parseInt(this.state.upgradenumber) <= parseInt(usersEighth)) && <option value={usersEighth}>Unlimited</option>}
            </select>
            </Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column />
      <Grid.Column />
          </Grid.Row>
          <Menu attached='bottom' pointing secondary widths={3} color='violet'>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

  renderPaymentCompanystep2() {
    let { activeItem } = this.state
    return (
      <>
      <div>
        <HeaderCustom headerType="payment_2"/>
        <Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Choose your Payment Method</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
                fluid
                onClick={this.handleCreditcardUser}
              >
                Card
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handleCreditcardUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Pay with your Card (Debit or Credit)
          </Card.Header>
          <Card.Description>The payment is processed by Stripe</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
              fluid
              onClick={this.handlePaypalUser}
              >
                Paypal
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handlePaypalUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Pay with your Paypal account
          </Card.Header>
          <Card.Description>The payment is processed by Paypal</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
          </Grid.Row>         
          <Menu attached='bottom' pointing secondary widths={3} color='violet'>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>
      <Modal
      onClose={() => {this.setState({modal:false})}}
      onOpen={() => {this.setState({modal:true})}}
      open={this.state.modal}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />Payment Method Suspended</Header>
      
      <Modal.Content>
          <p>
            At the moment we can't accept Paypal payments due to technical issues.
          </p>
          <p>
            Please use your Card to complete the payment. The process is secured by Stripe.
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={() => {this.setState({modal:false})}}>
         <Icon name='checkmark' /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
    </>
    )
  }

  renderPaymentCompanystep3() {
    let { activeItem } = this.state
    let valuePayment = this.state.charge
    let dateExp = new Date()
    let dateOldExp = new Date()
    let dateExpString: string = ''
    let startDate = 'none'
    let extend = 'no'
    let stringplan = null
    let invoicePeriod: string = 'none'
    let invoicePlan: string = 'none'
    let { numberofusers } = this.state
    if ((!this.state.duration.includes('days') || !this.state.duration.includes('hours')) && this.state.exgroup === 'none') {
      dateOldExp = new Date(parseInt(this.state.paymentuser))
    }
    if (this.state.method === 'card') {
      if (this.state.timepayment === 'month') {
        switch (numberofusers) {
          case usersFirst:
            stringplan = stripeplanmCA
            invoicePlan = nameplanmA
            break
            case usersSecond:
              stringplan = stripeplanmCB
              invoicePlan = nameplanmB
            break
            case usersThird:
              stringplan = stripeplanmCC
              invoicePlan = nameplanmC
            break
            case usersFourth:
              stringplan = stripeplanmCD
              invoicePlan = nameplanmD
            break
            case usersFifth:
            stringplan = stripeplanmCE
            invoicePlan = nameplanmE
            break
            case usersSixth:
              stringplan = stripeplanmCF
              invoicePlan = nameplanmF
            break
            case usersSeventh:
              stringplan = stripeplanmCG
              invoicePlan = nameplanmG
            break
            case usersEighth:
              stringplan = stripeplanmCH
              invoicePlan = nameplanmH
            break
          default:
            stringplan = stripeplanmCA
            invoicePlan = nameplanmA
            break
        }
      } else {
        switch (numberofusers) {
          case usersFirst:
            stringplan = stripeplanyCA
            invoicePlan = nameplanyA
            break
            case usersSecond:
              stringplan = stripeplanyCB
              invoicePlan = nameplanyB
            break
            case usersThird:
              stringplan = stripeplanyCC
              invoicePlan = nameplanyC
            break
            case usersFourth:
              stringplan = stripeplanyCD
              invoicePlan = nameplanyD
            break
            case usersFifth:
            stringplan = stripeplanyCE
            invoicePlan = nameplanyE
            break
            case usersSixth:
              stringplan = stripeplanyCF
              invoicePlan = nameplanyF
            break
            case usersSeventh:
              stringplan = stripeplanyCG
              invoicePlan = nameplanyG
            break
            case usersEighth:
              stringplan = stripeplanyCH
              invoicePlan = nameplanyH
            break
          default:
            stringplan = stripeplanyCA
            invoicePlan = nameplanyA
            break
        }
      }
    } else {
      if (this.state.timepayment === 'month') {
        switch (numberofusers) {
          case usersFirst:
            stringplan = paypalplanmCA
            invoicePlan = nameplanmA
            break
            case usersSecond:
              stringplan = paypalplanmCB
              invoicePlan = nameplanmB
            break
            case usersThird:
              stringplan = paypalplanmCC
              invoicePlan = nameplanmC
            break
            case usersFourth:
              stringplan = paypalplanmCD
              invoicePlan = nameplanmD
            break
            case usersFifth:
            stringplan = paypalplanmCE
            invoicePlan = nameplanmE
            break
            case usersSixth:
              stringplan = paypalplanmCF
              invoicePlan = nameplanmF
            break
            case usersSeventh:
              stringplan = paypalplanmCG
              invoicePlan = nameplanmG
            break
            case usersEighth:
              stringplan = paypalplanmCH
              invoicePlan = nameplanmH
            break
          default:
            stringplan = paypalplanmCA
            invoicePlan = nameplanmA
            break
        }
      } else {
        switch (numberofusers) {
          case usersFirst:
            stringplan = paypalplanyCA
            invoicePlan = nameplanyA
            break
            case usersSecond:
              stringplan = paypalplanyCB
              invoicePlan = nameplanyB
            break
            case usersThird:
              stringplan = paypalplanyCC
              invoicePlan = nameplanyC
            break
            case usersFourth:
              stringplan = paypalplanyCD
              invoicePlan = nameplanyD
            break
            case usersFifth:
            stringplan = paypalplanyCE
            invoicePlan = nameplanyE
            break
            case usersSixth:
              stringplan = paypalplanyCF
              invoicePlan = nameplanyF
            break
            case usersSeventh:
              stringplan = paypalplanyCG
              invoicePlan = nameplanyG
            break
            case usersEighth:
              stringplan = paypalplanyCH
              invoicePlan = nameplanyH
            break
          default:
            stringplan = paypalplanyCA
            invoicePlan = nameplanyA
            break
        }
      }
    }
    
      if (this.state.timepayment === 'month') {
  invoicePeriod = 'month'
        dateExp.setMonth(dateExp.getMonth() + 1)
        dateExpString = dateExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      }
    if (this.state.timepayment === 'year') {
      invoicePeriod = 'annual'
      dateExp.setFullYear(dateExp.getFullYear() + 1)
        dateExpString = dateExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    }
    
    let descriptionPayment = <div>Your account will be active until {dateExpString}</div>
    let renewPayment = 'The next charge will be of ' + valuePayment + ' (+IVA) ' + currency + ' on ' + dateExpString
    if (!this.state.checked) {
      renewPayment = 'Next charge would be of ' + valuePayment + ' (+IVA) ' + currency + ', on ' + dateExpString
    }
    if (this.props.match.params.upgrade !== 'none') {
      valuePayment = this.state.upgradecharge
      let chargedate = dateOldExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      descriptionPayment = <div>Your account will be active until {chargedate}</div>
      renewPayment = 'The automatic charge will be of ' + this.state.charge + ' (+IVA) ' + currency + ', on ' + chargedate
    if (!this.state.checked) {
      renewPayment = 'Next charge would be of ' + this.state.charge + ' (+IVA) ' + currency + ', on ' + chargedate
    }
    }
    if (this.state.checked) {
      descriptionPayment = <div>Your account will be automatically renewed every {this.state.timepayment}</div>
          }
    let planid: string
    if (stringplan) {
      planid = stringplan
    } else {
      planid = 'none'
    }
    let testCharge = this.state.charge
    if (testPaymentActive) {
planid = stripeTestPlan
testCharge = '9.70'
    }  
    const taxPerc = (22 * parseFloat(this.state.charge))/100
    const taxCharge = fixRounding(parseFloat(this.state.charge) + taxPerc, 2)
    const invoiceObj: InvoiceModel = {
      subscriptionType: invoicePlan,
      subscriptionPrice: testCharge,
      type: 'business',
      period: invoicePeriod,
      companyName: this.state.companyName
    }
    console.log('invoice_object', {invoiceObj})
    const invoiceString: string = JSON.stringify(invoiceObj)
    console.log('invoice_object', {"invoice": invoiceString})
    let infoarray: string[] = [dateExp.getTime().toString(), this.state.usergroup, taxCharge.toString(), String(this.state.checked), this.state.timepayment, this.state.numberofusers, dateOldExp.getTime().toString(), this.state.method, this.state.email, startDate, extend, planid, 'no', this.state.upgradecharge, invoiceObj.subscriptionType, invoiceObj.subscriptionPrice, invoiceObj.type, invoiceObj.period, this.state.companyName, String(this.state.showContract)]
    return (
      <div>
        <HeaderCustom headerType="payment_3"/>
        <Grid centered stackable relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">View your Subscription Recap</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={6} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Type
          </Card.Header>
          <Card.Description>Company</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Duration
          </Card.Header>
          <Card.Description>{this.state.timepayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Number of Employees
          </Card.Header>
          <Card.Description>{this.state.numberofusers}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Method
          </Card.Header>
          <Card.Description>{this.state.method}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
          Amount Charged
          </Card.Header>
          <Card.Description style={{fontWeight: "bold"}}>{valuePayment + ' (+IVA) ' + currency}</Card.Description>
          <Card.Description>{descriptionPayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header>
          <Checkbox
          toggle
          onChange={this.renewToggle}
          checked={this.state.checked}
        />
          </Card.Header>
          <Card.Description style={{fontWeight: "bold", color: '#6435c9'}}>Auto-renew your Subscription.</Card.Description>
          <Card.Description>{renewPayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
          <div>
                        <Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Amount to Pay (+22%IVA)</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{taxCharge + ' ' + currency}</strong>
              </div>
              </div>      
    </Form>
    </Grid.Column>
              </Grid.Row>
                    </Grid>
                    </div>
            </Grid.Column>
          </Grid.Row>
          <Divider clearing />
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
          <UserButton
                cssName="paynow-button"
                onClick={this.handlePaynowUser}
                idArray={infoarray}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Menu attached='bottom' pointing secondary widths={3} color='violet'>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

  renderPaymentstep1() {
    let { activeItem } = this.state
    return (
      <div>
        <HeaderCustom headerType="payment_1"/>
        <Grid centered stackable relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Choose your Subscription Plan</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
              fluid
                onClick={this.handleMonthUser}
              >
                Standard
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handleMonthUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Subscription for one Month
          </Card.Header>
          <Card.Description>{chargeUserMonth + ' ' + currency}</Card.Description>
          <Card.Description>Account for one user</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
              fluid
                onClick={this.handleYearUser}
              >
                Full
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handleYearUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Upfront for one Year
          </Card.Header>
          <Card.Description>{chargeUserYear + ' ' + currency}</Card.Description>
          <Card.Description>Account for one user</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
          </Grid.Row>       
          <Menu attached='bottom' pointing secondary widths={3} color='violet'>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

  renderPaymentstep2() {
    let { activeItem } = this.state
    return (
      <Modal
      onClose={() => {this.setState({modal:false})}}
      onOpen={() => {this.setState({modal:true})}}
      open={this.state.modal}
      size='small'
      trigger={<div>
        <HeaderCustom headerType="payment_2"/>
        <Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Choose your Payment Method</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
                fluid
                onClick={this.handleCreditcardUser}
              >
                Card
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handleCreditcardUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Pay with your Card (Debit or Credit)
          </Card.Header>
          <Card.Description>The payment is processed by Stripe</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button
              color = "orange"
              fluid
              onClick={this.handlePaypalUser}
              >
                Paypal
              </Button>
              <Grid centered columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Card fluid onClick={this.handlePaypalUser}>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Pay with your Paypal account
          </Card.Header>
          <Card.Description>The payment is processed by Paypal</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
          </Grid.Row>
          </Grid>
            </Grid.Column>
          </Grid.Row>         
          <Menu attached='bottom' pointing secondary widths={3} color='violet'>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>}
    >
      <Header icon><Icon color='violet' name='attention' />Payment Method Suspended</Header>
      
      <Modal.Content>
          <p>
            At the moment we can't accept Paypal payments due to technical issues.
          </p>
          <p>
            Please use your Card to complete the payment. The process is secured by Stripe.
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={() => {this.setState({modal:false})}}>
         <Icon name='checkmark' /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
    )
  }

  renderPaymentstep3() {
    let { activeItem } = this.state
    let valuePayment = this.state.charge
    let dateExp = new Date()
    let dateOldExp = new Date()
    let dateExpString: string = ''
    let invoicePeriod: string = 'none'
    let invoicePlan: string = 'none'
    let startDate = 'none'
    let extend = 'no'
    let stringplan = null
    if ((!this.state.duration.includes('days') || !this.state.duration.includes('hours')) && this.state.exgroup === 'none') {
      dateOldExp = new Date(parseInt(this.state.paymentuser))
    }
    if (this.state.method === 'card') {
      if (this.state.timepayment === 'month') {
        stringplan = stripeplanmUA
        invoicePlan = nameplanmUA
  } else {
        stringplan = stripeplanyUA
        invoicePlan = nameplanyUA
  }
    } else {
      if (this.state.timepayment === 'month') {
        stringplan = paypalplanmUA
        invoicePlan = nameplanmUA
  } else {
        stringplan = paypalplanyUA
        invoicePlan = nameplanyUA
  }
    }    
      if (this.state.timepayment === 'month') {
        invoicePeriod = 'month'
        dateExp.setMonth(dateExp.getMonth() + 1)
        dateExpString = dateExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      }
    if (this.state.timepayment === 'year') {
      invoicePeriod = 'annual'
      dateExp.setFullYear(dateExp.getFullYear() + 1)
        dateExpString = dateExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    }
    
    let descriptionPayment = <div>Your account will be active until {dateExpString}</div>
    let renewPayment = 'The next charge will be of ' + valuePayment + ' ' + currency + ' on ' + dateExpString
    if (!this.state.checked) {
      renewPayment = 'Next charge would be of ' + valuePayment + ' ' + currency + ', on ' + dateExpString
    }
    if (this.state.checked) {
      descriptionPayment = <div>Your account will be automatically renewed every {this.state.timepayment}</div>
          }
    let planid: string
    if (stringplan) {
      planid = stringplan
    } else {
      planid = 'none'
    }
    let testCharge = this.state.charge
    if (testPaymentActive) {
planid = stripeTestPlan
testCharge = '9.70'
    }    
    const taxPerc = (22 * parseFloat(this.state.charge))/100
    const taxCharge = fixRounding(parseFloat(this.state.charge) + taxPerc, 2) 
    const invoiceObj: InvoiceModel = {
      subscriptionType: invoicePlan,
      subscriptionPrice: testCharge,
      type: 'consumer',
      period: invoicePeriod
    }
    let infoarray: string[] = [dateExp.getTime().toString(), this.state.usergroup, taxCharge.toString(), String(this.state.checked), this.state.timepayment, this.state.numberofusers, dateOldExp.getTime().toString(), this.state.method, this.state.email, startDate, extend, planid, 'no', this.state.upgradecharge, invoiceObj.subscriptionType, invoiceObj.subscriptionPrice, invoiceObj.type, invoiceObj.period, String(this.state.showContract)]
    return (
      <div>
        <HeaderCustom headerType="payment_3"/>
        <Grid centered relaxed stackable columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">View your Subscription Recap</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={6} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Type
          </Card.Header>
          <Card.Description>Single user</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Duration
          </Card.Header>
          <Card.Description>{this.state.timepayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Number of Users
          </Card.Header>
          <Card.Description>{this.state.numberofusers}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Method
          </Card.Header>
          <Card.Description>{this.state.method}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Amount Charged
          </Card.Header>
          <Card.Description style={{fontWeight: "bold"}}>{valuePayment + ' ' + currency}</Card.Description>
          <Card.Description>{descriptionPayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header>
          <Checkbox
          toggle
          onChange={this.renewToggle}
          checked={this.state.checked}
        />
          </Card.Header>
          <Card.Description style={{fontWeight: "bold"}}>Auto-renew your Subscription.</Card.Description>
          <Card.Description>{renewPayment}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
          <div>
                        <Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Amount to Pay (+22%IVA)</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{taxCharge + ' ' + currency}</strong>
              </div>
              </div>      
    </Form>
    </Grid.Column>
              </Grid.Row>
                    </Grid>
                    </div>
                    </Grid.Column>
          </Grid.Row>    
          <Divider clearing />
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
          <UserButton
                cssName="paynow-button"
                onClick={this.handlePaynowUser}
                idArray={infoarray}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>          
          <Menu attached='bottom' secondary pointing widths={3}>
          <Menu.Item
            name='Subscription'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

  renderPaymentList() {
    let { activeItem } = this.state
    let { usergroup } = this.state

    if (usergroup === 'companies') {
      switch (activeItem) {
        case 'step1':
          return this.renderPaymentCompanystep1()
        case 'step2':
          return this.renderPaymentCompanystep2()
        case 'step3':
          return this.renderPaymentCompanystep3()
        default:
          return this.renderPaymentCompanystep1() 
      }
    } else {
        switch (activeItem) {
          case 'step1':
        return this.renderPaymentstep1()
          case 'step2':
            return this.renderPaymentstep2()
          case 'step3':
            return this.renderPaymentstep3()
            default:
              return this.renderPaymentstep1() 
          }
    }
    
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderPaymentList()
  }
}
