import * as React from 'react'
import { Card,Grid } from 'semantic-ui-react'

interface CardElementProps {
    title: string,
    onClose: any
  }
  
  interface CardElementState {
  }
  
  export class CardElement extends React.PureComponent<CardElementProps, CardElementState> {
    state: CardElementState = {
      }
    handleClose = () => {this.props.onClose(this.props.title)}
    render() {
      return (
        <Grid.Column>
        <Card centered raised style={{fontWeight: "bold", backgroundColor: '#6435c9' }}>
        <Card.Content>
          <Card.Header style={{fontWeight: "bold", color: '#FFFFFF', cursor: 'initial' }}>
          {this.props.title}
          </Card.Header>
          <Card.Description>
          <div style={{fontWeight: "bold", color: '#FFFFFF', fontSize: '0.6rem', cursor: 'pointer' }} onClick={this.handleClose}>
    X
      </div>
            </Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
      )
    }
  }