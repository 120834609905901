import * as React from 'react'
import { Grid, Button, Header, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { ClipboardCopy } from './ClipboardCopy'

interface PaymentconfirmationProps {
  auth: Auth,
  history: any,
  match: {
    params: {
      key: string
    }
  }
}

interface PaymentconfirmationState {
  keycode: string
}

export class Couponconfirmation extends React.PureComponent<
PaymentconfirmationProps,
PaymentconfirmationState
> {
  state: PaymentconfirmationState = {
    keycode: ''
  }

  handleHome = () => {
    this.props.history.replace(`/coupons`)
  }

  render() {
    if (this.props.match.params.key === 'success') {
      return (
        <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                <Header size="large" icon color='violet'>
        <Icon name='sign language' />
        Coupons successfully created!
      </Header>
      </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                  <Button
                  color = "orange"
                    size="large"
                    className="action-button"
                    onClick={this.handleHome}
                    style={{margin: '0.1em'}}
                  >
                    OK
                  </Button>
                </Grid.Column>
              </Grid.Row>
              </Grid>
              )
    } else {
      return (
        <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                <Header size="large" icon color='violet'>
        <Icon name='sign language' />
        Coupon successfully created!
      </Header>
      </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                  <ClipboardCopy copyText={this.props.match.params.key} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                  <Button
                  color = "orange"
                    size="large"
                    className="action-button"
                    onClick={this.handleHome}
                    style={{margin: '0.1em'}}
                  >
                    OK
                  </Button>
                </Grid.Column>
              </Grid.Row>
              </Grid>
              )
    }
}
}
