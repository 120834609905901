import * as React from 'react'
import { Grid, Button, Header } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'

interface CampaignfailureProps {
  auth: Auth,
  history: any
}

interface CampaignfailureState {
  loading: boolean
}

export class Campaignfailure extends React.PureComponent<
CampaignfailureProps,
CampaignfailureState
> {
  state: CampaignfailureState = {
    loading: true
  }

  async componentDidMount() {
setTimeout(() => {
  this.setState({loading:false})
}, 1000);
  }

  handleHome = () => {
    this.props.history.replace(`/campaignlist`)
  }

  render() {
    return (
      <div>
        <HeaderCustom headerType="campaign_failure"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center"><Header size="large">Campaign creation Failed!</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "violet"
                size="large"
                className="action-button"
                onClick={this.handleHome}
                loading={this.state.loading}
              >
                Return to Home and Try Again
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
      </div>
    )
  }
}

