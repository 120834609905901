/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import * as React from 'react'
import { UserModel } from '../types/UserModel'
import { getUsers } from '../api/users-api'
import { Grid, Button, Loader } from 'semantic-ui-react'
import { UserButton } from './UserButton'
import { CheckElement } from './CheckElement'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { getInfoUser } from '../api/users-api'
import { CSVLink } from 'react-csv'

const { deleteAvailable } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface UsersListProps {
  history: any,
  auth: Auth
}

interface UsersListState {
  users: UserModel[],
  idCheck: string[],
  emailCheck: string[],
  idView: string[],
  emailView: string[],
  formInput: string,
  selectCheck: boolean,
  selectClear: boolean,
  loading: boolean,
  usergroup: string,
  csvOptions: any
}

export class UsersList extends React.PureComponent<
  UsersListProps,
  UsersListState
> {
  state: UsersListState = {
    users: [],
    idCheck: [],
    emailCheck: [],
    idView: [],
    emailView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    loading: true,
    usergroup: 'members',
    csvOptions: {}
  }
  csvLink = React.createRef<HTMLDivElement>()

  handleCreateUser = () => {
    this.props.history.push(`/users/create`)
  }

  handleDownload = () => {
this.csvLink.current ? this.csvLink.current.click() : alert('Unkwnown error!')
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ formInput: event.target.value })
  }

  handleDeleteUser = (userId: string[]) => {
    this.props.history.push(`/users/${userId.toString()}/delete`)
  }

  handleEmailUser = (userId: string[]) => {
    this.props.history.push(`/users/${userId.toString()}/email`)
  }

  handleInfoUser = (userId: string[]) => {
    this.props.history.push(`/users/${userId[0]}/info`)
  }

  handleSelectAll = () => {
    let idAll = this.state.idView
    let emailAll = this.state.emailView
    if (this.state.selectCheck) {
      this.setState({ idCheck: idAll, emailCheck: emailAll, selectCheck: false })
    } else {
    this.setState({ idCheck: idAll, emailCheck: emailAll, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ idCheck: [], emailCheck: [], selectClear: false })
    } else {
      this.setState({ idCheck: [], emailCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userIdarray: string[], userEmailarray: string[]) => {
    let idCheckNew = userIdarray
    let emailCheckNew = userEmailarray
      this.setState({ idView: idCheckNew, emailView: emailCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ idView: [], emailView: [] })
  }

  handleIdCheckPush = (userId: string, userEmail: string) => {
    let idCheckNew = this.state.idCheck.concat([userId])
    let emailCheckNew = this.state.emailCheck.concat([userEmail])
      this.setState({ idCheck: idCheckNew, emailCheck: emailCheckNew })
      console.log('checkpush: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  handleIdCheckDelete = (userId: string, userEmail: string) => {
    let idCheckNew = this.state.idCheck.filter(
      id => id !== userId
    )
    let emailCheckNew = this.state.emailCheck.filter(
      id => id !== userEmail
    )
      this.setState({ idCheck: idCheckNew, emailCheck: emailCheckNew })
      console.log('checkdelete: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  shouldComponentUpdate(nextProps: UsersListProps, nextState: UsersListState) {
    if (nextState.idView !== this.state.idView) {return false}
    if (nextState.emailView !== this.state.emailView) {return false}
    return true
  }

  async componentDidMount() {
    try {
      const userid: string = this.props.auth.getIdToken().payload.sub
      const users = await getUsers(this.props.auth.getIdToken())
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
      let usergroup = userPaymentInfo.usergroup
      let exgroup = userPaymentInfo.exgroup
      const headersCsv = [
        {label: 'ID', key: 'id'},
        {label: 'NAME', key: 'name'},
        {label: 'EMAIL', key: 'email'},
        {label: 'EXPIRATION', key: 'payment'},
        {label: 'GROUP', key: 'usergroup'},
        {label: 'AUTO-RENEW', key: 'renew'},
        {label: 'SUBSCRIPTION CHARGE', key: 'charge'},
        {label: 'ACCOUNTS AVAILABLE', key: 'numberofusers'},
        {label: 'ACCOUNTS ACTIVATED', key: 'usersactivated'},
        {label: 'SUBSCRIPTION DURATION', key: 'timepayment'},
        {label: 'KEYCODE/COUPON', key: 'coupon'},
        {label: 'PREVIOUS GROUP', key: 'exgroup'},
        {label: 'TYPE OF ACCOUNT', key: 'type'},
        {label: 'COMPANY NAME', key: 'company_name'}
      ]
      const usersFormat = users.filter((user) => {return user.type !== 'admin'})
if (exgroup !== 'none') {usergroup = exgroup}
      this.setState({
        users: users,
        loading: false,
        usergroup,
        csvOptions: {
          data: usersFormat,
      headers: headersCsv,
      filename: 'vvip-users.csv'
        }
      })
    } catch (e) {
      this.setState({loading: false})
      console.log(`Failed to fetch user info, error: ` + e)
    }
      
  }

  renderGroupScope() {
    let usergroup = this.state.usergroup
    switch (usergroup) {
      case 'guests':
        return this.renderMembersUsersList()
      case 'members':
        return this.renderMembersUsersList()
      case 'admins':
        return this.renderAdminUsersList()
      case 'companies':
        return this.renderMembersUsersList()
      case 'employees':
        return this.renderMembersUsersList()
      case 'goldenemployees':
        return this.renderMembersUsersList()
      case 'goldenadmins':
       return this.renderGoldenUsersList()
    }
  }

  renderGoldenUsersList() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZeroGolden()
      case 'many':
        return this.renderUsersManyGolden()
      case 'one':
        return this.renderUsersOneGolden()
    }
  }

  renderAdminUsersList() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZero()
      case 'many':
        return this.renderUsersMany()
      case 'one':
        return this.renderUsersOne()
    }
  }

  renderMembersUsersList() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZeroMembers()
      case 'many':
        return this.renderUsersManyMembers()
      case 'one':
        return this.renderUsersOneMembers()
    }
  }

  renderUsersZeroGolden() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={true}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {                        
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                idArray.push(user.id)
                emailArray.push(user.email)
                if (user.id === this.state.users[this.state.users.length - 1].id) {
                  this.handleCreateAllUserId(idArray, emailArray)
               }
                return (
                    <CheckElement
                      user={user}
                      onCheck={this.handleIdCheckPush}
                      onUnCheck={this.handleIdCheckDelete}
                      userId={user.id}
                      userEmail={user.email}
                      onSelectCheck={this.state.selectCheck}
                      onSelectClear={this.state.selectClear}
                    />
                )
              } else {
                if (user.id === this.state.users[this.state.users.length - 1].id) {
                  this.handleCreateAllUserId(idArray, emailArray)
               }
              }
              }              
            }                        
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersZero() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered relaxed stackable columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              <Button
                color="violet"
                size="medium"
                className="add-button"
                onClick={this.handleCreateUser}
              >
                Create user
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={true}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {                        
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }                        
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersZeroMembers() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered relaxed stackable columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={1} centered>            
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
          <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {                        
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }                        
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{ overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersManyGolden() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {            
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersMany() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              <Button
                color="violet"
                size="medium"
                className="add-button"
                onClick={this.handleCreateUser}
              >
                Create user
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              {!deleteAvailable && <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />}
              {deleteAvailable && <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.idCheck}
                disableCheck={false}
              />}
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {        
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersManyMembers() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {            
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOneGolden() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOne() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              <Button
              color="violet"
                size="medium"
                className="add-button"
                onClick={this.handleCreateUser}
              >
                Create user
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            {!deleteAvailable && <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.idCheck}
                disableCheck={true}
              />}
              {deleteAvailable && <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.idCheck}
                disableCheck={false}
              />}
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
          <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOneMembers() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="users"/>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="info-button"
                onClick={this.handleInfoUser}
                idArray={this.state.idCheck}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={7} style={{minWidth: '72em'}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {
            if (!this.state.formInput) {
              idArray.push(user.id)
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(idArray, emailArray)
             }
              return (
                  <CheckElement
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(user.id)
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                  return (
                      <CheckElement
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(idArray, emailArray)
                 }
                }
              }              
            }            
          })}
          {this.state.users.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Users
        </Loader>
      </Grid.Row>
    )
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderGroupScope()
  }
}
