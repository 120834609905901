/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import * as React from 'react'
import { UserModel } from '../types/UserModel'
import { getCoupons, getRedemptionUsers, getUsers } from '../api/users-api'
import { Grid, Button, Loader } from 'semantic-ui-react'
import { UserButton } from './UserButton'
import { CheckElementRedemption } from './CheckElementRedemption'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { CouponModel } from '../types/CouponModel'

interface RedemptionListProps {
  history: any,
  auth: Auth
}

interface RedemptionListState {
  emailCheck: string[],
  emailView: string[],
  formInput: string,
  selectCheck: boolean,
  selectClear: boolean,
  loading: boolean,
  redemptionUsers: any[]
}

export class RedemptionList extends React.PureComponent<
RedemptionListProps,
RedemptionListState
> {
  state: RedemptionListState = {
redemptionUsers: [],
    emailCheck: [],
    emailView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    loading: true
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ formInput: event.target.value })
  }

  handleDeleteUser = (userId: string[]) => {
    let arr = userId.push('redemption')
    this.props.history.push(`/users/${arr.toString()}/delete`)
  }

  handleEmailUser = (userId: string[]) => {
    let arr = userId.push('redemption')
    this.props.history.push(`/users/${arr.toString()}/email`)
  }

  handleSelectAll = () => {
    let emailAll = this.state.emailView
    if (this.state.selectCheck) {
      this.setState({ emailCheck: emailAll, selectCheck: false })
    } else {
    this.setState({ emailCheck: emailAll, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ emailCheck: [], selectClear: false })
    } else {
      this.setState({ emailCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userEmailarray: string[]) => {
    let emailCheckNew = userEmailarray
      this.setState({ emailView: emailCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ emailView: [] })
  }

  handleIdCheckPush = (userEmail: string) => {
    let emailCheckNew = this.state.emailCheck.concat([userEmail])
      this.setState({ emailCheck: emailCheckNew })
  }

  handleIdCheckDelete = (userEmail: string) => {
    let emailCheckNew = this.state.emailCheck.filter(
      id => id !== userEmail
    )
      this.setState({ emailCheck: emailCheckNew })
  }

  shouldComponentUpdate(nextProps: RedemptionListProps, nextState: RedemptionListState) {
    if (nextState.emailView !== this.state.emailView) {return false}
    return true
  }

  async componentDidMount() {
    try {
      const idtoken = this.props.auth.getIdToken()
      const group = idtoken.payload['cognito:groups'][0]
      const users: UserModel[] = await getUsers(idtoken)
      const redemptionUsers = await getRedemptionUsers(idtoken)
      const coupons: CouponModel[] = await getCoupons(idtoken)
      let arrRedemption: any[] = []

  
  for (let index = 0; index < redemptionUsers.length; index++) {
    let redemptionObj = {
      email: '',
      origin: '',
      contact: '',
      subscription: '',
      duration: '',
      type: '',
      companyname: '',
      method: ''
    }
    const redElement = redemptionUsers[index];
    let email: string = redElement.email.S
    let origin: string = redElement.origin.S
    let contact: string = redElement.firstcontact.S
    let method: string = redElement.method.S
    let status: string = redElement.status.S
    let subscription: string = 'none'
    let duration: string = 'none'
    let type: string = 'none'
    let companyname: string = 'none'
    for (let k = 0; k < users.length; k++) {
      const element = users[k];
      if (group === 'goldenadmins') {
      if (email === element.email && status === 'normal') {
        if (element.type !== 'admin') {
          element.company_name ? companyname = element.company_name : companyname = 'none'
          if (element.payment !== 'unconfirmed') {
if (parseInt(element.payment) > new Date().getTime()) {
if (element.timepayment.includes('days') || element.timepayment.includes('hours')) {
subscription = 'free'
duration = element.timepayment
} else {
subscription = 'paying'
duration = element.timepayment
}
} else {
if (element.renew === 'yes') {
subscription = 'paying'
duration = element.timepayment
} else {
subscription = 'expired'
}
}
for (let y = 0; y < coupons.length; y++) {
  const coupElement = coupons[y];
  if (coupElement.Code.S === element.coupon) {
if (element.type === 'company') {
type = 'company'
} else {
type = 'member'
}
  }
}
if (type === 'none') {
if (element.type === 'company') {
type = 'company'
} else {
type = 'employee'
}
}
        } else {
          if (element.type === 'company') {
type = 'company'
          } else {
            type = 'guest'
          }
          subscription = 'unconfirmed'
        }
      }
      }
    } else {
      if (email === element.email) {
        if (element.type !== 'admin') {
          element.company_name ? companyname = element.company_name : companyname = 'none'
          if (element.payment !== 'unconfirmed') {
if (parseInt(element.payment) > new Date().getTime()) {
if (element.timepayment.includes('days') || element.timepayment.includes('hours')) {
subscription = 'free'
duration = element.timepayment
} else {
subscription = 'paying'
duration = element.timepayment
}
} else {
if (element.renew === 'yes') {
subscription = 'paying'
duration = element.timepayment
} else {
subscription = 'expired'
}
}
for (let y = 0; y < coupons.length; y++) {
  const coupElement = coupons[y];
  if (coupElement.Code.S === element.coupon) {
if (element.type === 'company') {
type = 'company'
} else {
type = 'member'
}
  }
}
if (type === 'none') {
if (element.type === 'company') {
type = 'company'
} else {
type = 'employee'
}
}
        } else {
          if (element.type === 'company') {
type = 'company'
          } else {
            type = 'guest'
          }
          subscription = 'unconfirmed'
        }
      }
      }
    }
  }
  if (status === 'deleted') {
subscription = status
  }
  if (origin === 'digit') {
origin = 'manually added'
  } else {
    if (origin !== 'seller') {
origin = 'uploaded from file'
    }
  }
  redemptionObj.type = type
  redemptionObj.email = email
  redemptionObj.companyname = companyname
  redemptionObj.origin = origin
  redemptionObj.contact = contact
  redemptionObj.duration = duration
  redemptionObj.subscription = subscription
  redemptionObj.method = method
  arrRedemption.push(redemptionObj)
  }

      this.setState({
        redemptionUsers: arrRedemption,
        loading: false
      })
    } catch (e) {
      this.setState({loading: false})
      alert(`Failed to fetch user info, error: ` + e)
    }
      
  }

  renderGoldenUsersList() {
    let usersState = 'zero'
    if (this.state.emailCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.emailCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZeroGolden()
      case 'many':
        return this.renderUsersManyGolden()
      case 'one':
        return this.renderUsersOneGolden()
    }
  }

  renderUsersZeroGolden() {
    this.handleDeleteAllUserId()
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="redemptionusers"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={true}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.emailCheck}
                disableCheck={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-20px'}}>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '44vh', overflowY: 'auto'}}>
        <Grid.Row columns={9}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Target</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Origin</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Method</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Subscription Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Account duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.redemptionUsers.map((user) => {                      
            if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                this.handleCreateAllUserId(emailArray)
             }
              return (
                  <CheckElementRedemption
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.email}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                emailArray.push(user.email)
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
                return (
                    <CheckElementRedemption
                      user={user}
                      onCheck={this.handleIdCheckPush}
                      onUnCheck={this.handleIdCheckDelete}
                      userId={user.email}
                      userEmail={user.email}
                      onSelectCheck={this.state.selectCheck}
                      onSelectClear={this.state.selectClear}
                    />
                )
              } else {
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
              }
            }                        
          })}
          {this.state.redemptionUsers.length === 0 && <Grid.Row columns={9}>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersManyGolden() {
    this.handleDeleteAllUserId()
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="redemptionusers"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-20px'}}>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '44vh', overflowY: 'auto'}}>
        <Grid.Row columns={9}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Target</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Origin</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Method</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Subscription Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Account duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.redemptionUsers.map((user) => {            
            if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                this.handleCreateAllUserId(emailArray)
             }
              return (
                  <CheckElementRedemption
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.email}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                emailArray.push(user.email)
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
                return (
                    <CheckElementRedemption
                      user={user}
                      onCheck={this.handleIdCheckPush}
                      onUnCheck={this.handleIdCheckDelete}
                      userId={user.email}
                      userEmail={user.email}
                      onSelectCheck={this.state.selectCheck}
                      onSelectClear={this.state.selectClear}
                    />
                )
              } else {
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
              }
            }            
          })}
          {this.state.redemptionUsers.length === 0 && <Grid.Row columns={9}>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOneGolden() {
    this.handleDeleteAllUserId()
    let emailArray: string[] = []
    return (
      <div>
        <HeaderCustom headerType="redemptionusers"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="email-button"
                onClick={this.handleEmailUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <UserButton
                cssName="delete-button"
                onClick={this.handleDeleteUser}
                idArray={this.state.emailCheck}
                disableCheck={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-20px'}}>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '44vh', overflowY: 'auto'}}>
        <Grid.Row columns={9}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Target</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Origin</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Contact Method</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Subscription Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Account duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.redemptionUsers.map((user) => {
            if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                this.handleCreateAllUserId(emailArray)
             }
              return (
                  <CheckElementRedemption
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.email}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {                
                emailArray.push(user.email)
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
                return (
                    <CheckElementRedemption
                      user={user}
                      onCheck={this.handleIdCheckPush}
                      onUnCheck={this.handleIdCheckDelete}
                      userId={user.email}
                      userEmail={user.email}
                      onSelectCheck={this.state.selectCheck}
                      onSelectClear={this.state.selectClear}
                    />
                )
              } else {
                if (user.email === this.state.redemptionUsers[this.state.redemptionUsers.length - 1].email) {
                  this.handleCreateAllUserId(emailArray)
               }
              }
            }            
          })}
          {this.state.redemptionUsers.length === 0 && <Grid.Row columns={9}>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                  <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None available</Grid.Column>
                </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Users
        </Loader>
      </Grid.Row>
    )
  }

  render() {
    console.log('rendering userslist...')
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderGoldenUsersList()
  }
}
