import React from 'react'
import { Auth } from './auth/Auth'
import { Router, Route } from 'react-router-dom'
import Callback from './components/Callback'
import App from './App';
import { Amplify } from 'aws-amplify'
import { Auth as authAmp } from 'aws-amplify';

const {authConfig} = require(`./config-${process.env.REACT_APP_AWS_ACCOUNT}`)

authAmp.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH'
})

Amplify.configure({
  Auth: {      
      // REQUIRED - Amazon Cognito Region
      region: authConfig.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: authConfig.poolId,

      userPoolWebClientId: authConfig.clientId
  }
})

const history = require('history').createBrowserHistory()

const auth = new Auth(history)

const handleAuthentication = (props: any) => {
  console.log('handling authentication...')
  const location = props.location
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication('none')
  }
}

export const makeAuthRouting = () => {
  return (
    <Router history={history}>
      <div>
        <Route
          path="/callback"
          render={props => {
            handleAuthentication(props)
            return <Callback />
          }}
        />
        <Route
          render={props => {
            return <App auth={auth} {...props} />
          }}
        />
      </div>
    </Router>
  )
}
