import * as React from 'react'
import { Loader, Grid, Button, Checkbox, Icon, Divider } from 'semantic-ui-react'
import { getCampaigns, updateCampaign, restartCampaign, deleteCampaign, pauseCampaign } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { CampaignModel } from '../types/CampaignModel'
import { HeaderCustom } from './HeaderCustom'

interface CreateCampaignProps {
  auth: Auth,
  history: any
}

interface CreateCampaignState {
  loading: boolean,
  campaigns: any[],
  checked: boolean,
  idcheck: string,
  update: boolean,
  loadingButton: boolean,
  campaignStatus: string
}

export class CreateCampaign extends React.PureComponent<
  CreateCampaignProps,
  CreateCampaignState
> {
  state: CreateCampaignState = {
    loading: true,
    campaigns: [],
    checked: false,
    idcheck: 'none',
    update: false,
    loadingButton: false,
    campaignStatus: 'none'
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.update === this.state.update) {
      let campaignArrFormatted: any[] = []
      try {
  const campaigns: CampaignModel[] = await getCampaigns(this.props.auth.getIdToken())
  const dateYear = new Date()
  const thisYear = dateYear.getFullYear().toString()
  for (let index = 0; index < campaigns.length; index++) {
    let campaignFormatted: any = {
      start: '',
      end: '',
      name: '',
      description: '',
  targets: '',
  status: '',
  message: '',
  schedule: '',
  progress: '',
  id: '',
  scheduledescription: ''
    }
    let progress = ''
    console.log('id:', campaigns[index].id.S)
    let startFormatted = new Date(parseInt(campaigns[index].start.S))
    let endFormatted = new Date(parseInt(campaigns[index].end.S))
    let cron = campaigns[index].cron.S
    let cronArr = cron.split(' ')
    let scheduleDescription = ''
    if (cronArr[4] === '*') {
      scheduleDescription = 'Every Day of'
    } else {
      let dayArr = cronArr[4].split(',')
      for (let index = 0; index < dayArr.length; index++) {
        const element = dayArr[index];
        let dayElement = 'none'
        switch (element) {
          case 'MON':
            dayElement = 'Monday'
            break;
            case 'TUE':
              dayElement = 'Tuesday'
              break;
              case 'WED':
                dayElement = 'Wednesday'
            break;
            case 'THU':
              dayElement = 'Thursday'
            break;
            case 'FRI':
              dayElement = 'Friday'
            break;
            case 'SAT':
              dayElement = 'Saturday'
            break;
            case 'SUN':
              dayElement = 'Sunday'
            break;
          default:
            break;
        }
        if (index === 0) {
          if (index === (dayArr.length - 1)) {            
            scheduleDescription = 'Every ' + dayElement + ' of '
          } else {
            scheduleDescription = 'Every ' + dayElement
          }  
        } else {
          if (index === (dayArr.length - 1)) {
            scheduleDescription += ' and ' + dayElement + ' of '
          } else {
            scheduleDescription += ', ' + dayElement
          }  
        }       
      }
    }
    if (cronArr[3] === '*') {
      scheduleDescription += 'every Month'
    } else {
      let monthArr = cronArr[3].split(',')
      for (let index = 0; index < monthArr.length; index++) {
        const element = monthArr[index];
        let monthElement = 'none'
        switch (element) {
          case '1':
            monthElement = 'January'
            break;
            case '2':
              monthElement = 'February'
              break;
              case '3':
                monthElement = 'March'
            break;
            case '4':
              monthElement = 'April'
            break;
            case '5':
              monthElement = 'May'
            break;
            case '6':
              monthElement = 'June'
            break;
            case '7':
              monthElement = 'July'
            break;
            case '8':
              monthElement = 'August'
            break;
            case '9':
              monthElement = 'September'
            break;
            case '10':
              monthElement = 'October'
            break;
            case '11':
              monthElement = 'November'
            break;
            case '12':
              monthElement = 'December'
            break;
          default:
            break;
        }
        if (index === 0) {
            scheduleDescription += monthElement  
        } else {
          if (index === (monthArr.length - 1)) {
            scheduleDescription += ' and ' + monthElement
          } else {
            scheduleDescription += ', ' + monthElement
          }  
        }       
      }
    }
    if (cronArr[5] === '*') {
  scheduleDescription += ', every year'
    } else {
      scheduleDescription += ' ' + thisYear
    }
    scheduleDescription += ' at ' + cronArr[1] + ':' + cronArr[0]
    campaignFormatted.scheduledescription = scheduleDescription
    campaignFormatted.name = campaigns[index].name.S
    campaignFormatted.id = campaigns[index].id.S
    campaignFormatted.description = campaigns[index].description.S
    campaignFormatted.start = startFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    campaignFormatted.end = endFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    campaignFormatted.targets = campaigns[index].targets.S
    campaignFormatted.status = campaigns[index].status.S
    campaignFormatted.message = campaigns[index].email.S
  if (campaignFormatted.status === 'ACTIVE') {
  let timeStart = parseInt(campaigns[index].start.S)
  let timeEnd = parseInt(campaigns[index].end.S)
  let timeNow = new Date().getTime()
  if (timeNow > timeStart) {
    if (timeNow < timeEnd) {
      progress = 'Delivering messages...'
    } else {
      //UPGRADE CAMPAIGN STATUS TO EXPIRED
  try {
    await updateCampaign(this.props.auth.getIdToken(), 'EXPIRED', campaignFormatted.id)
  } catch (error) {
    this.setState({loading: false})
      console.log(error)
      alert(`Failed to fetch campaigns info ` + error)
  }
  campaignFormatted.status = 'EXPIRED'
      progress = 'Already delivered all messages'
    }
  } else {
    progress = 'Waiting for schedule to start...'
  }
  } else {
    if (campaignFormatted.status === 'EXPIRED') {
      progress = 'Already delivered all messages'
    } else {
  let timeEnd = parseInt(campaigns[index].end.S)
  let timeNow = new Date().getTime()
      if (timeNow < timeEnd) {
      progress = 'Waiting to be restarted...'} else {
        try {
          await updateCampaign(this.props.auth.getIdToken(), 'EXPIRED', campaignFormatted.id)
        } catch (error) {
          this.setState({loading: false})
            console.log(error)
            alert(`Failed to fetch campaigns info ` + error)
        }
        progress = 'Already delivered all messages'
      }
    }
  }
  campaignFormatted.progress = progress
    campaignArrFormatted.push(campaignFormatted)
  }
  this.setState({ campaigns: campaignArrFormatted, loading: false, update: !prevState.update })
    } catch (e) {
      this.setState({loading: false})
      console.log(e)
      alert(`Failed to fetch campaigns info ` + e)
    }
    }
  }

  async componentDidMount() {
    
    let campaignArrFormatted: any[] = []
    try {
const campaigns: CampaignModel[] = await getCampaigns(this.props.auth.getIdToken())
const dateYear = new Date()
const thisYear = dateYear.getFullYear().toString()
for (let index = 0; index < campaigns.length; index++) {
  let campaignFormatted: any = {
    start: '',
    end: '',
    name: '',
    description: '',
targets: '',
status: '',
message: '',
schedule: '',
progress: '',
id: '',
scheduledescription: ''
  }
  let progress = ''
  console.log('id:', campaigns[index].id.S)
  let startFormatted = new Date(parseInt(campaigns[index].start.S))
  let endFormatted = new Date(parseInt(campaigns[index].end.S))
  let cron = campaigns[index].cron.S
  let cronArr = cron.split(' ')
  let scheduleDescription = ''
    if (cronArr[4] === '*') {
      scheduleDescription = 'Every Day of'
    } else {
      let dayArr = cronArr[4].split(',')
      for (let index = 0; index < dayArr.length; index++) {
        const element = dayArr[index];
        let dayElement = 'none'
        switch (element) {
          case 'MON':
            dayElement = 'Monday'
            break;
            case 'TUE':
              dayElement = 'Tuesday'
              break;
              case 'WED':
                dayElement = 'Wednesday'
            break;
            case 'THU':
              dayElement = 'Thursday'
            break;
            case 'FRI':
              dayElement = 'Friday'
            break;
            case 'SAT':
              dayElement = 'Saturday'
            break;
            case 'SUN':
              dayElement = 'Sunday'
            break;
          default:
            break;
        }
        if (index === 0) {
          if (index === (dayArr.length - 1)) {            
            scheduleDescription = 'Every ' + dayElement + ' of '
          } else {
            scheduleDescription = 'Every ' + dayElement
          }  
        } else {
          if (index === (dayArr.length - 1)) {
            scheduleDescription += ' and ' + dayElement + ' of '
          } else {
            scheduleDescription += ', ' + dayElement
          }  
        }       
      }
    }
    if (cronArr[3] === '*') {
      scheduleDescription += 'every Month'
    } else {
      let monthArr = cronArr[3].split(',')
      for (let index = 0; index < monthArr.length; index++) {
        const element = monthArr[index];
        let monthElement = 'none'
        switch (element) {
          case '1':
            monthElement = 'January'
            break;
            case '2':
              monthElement = 'February'
              break;
              case '3':
                monthElement = 'March'
            break;
            case '4':
              monthElement = 'April'
            break;
            case '5':
              monthElement = 'May'
            break;
            case '6':
              monthElement = 'June'
            break;
            case '7':
              monthElement = 'July'
            break;
            case '8':
              monthElement = 'August'
            break;
            case '9':
              monthElement = 'September'
            break;
            case '10':
              monthElement = 'October'
            break;
            case '11':
              monthElement = 'November'
            break;
            case '12':
              monthElement = 'December'
            break;
          default:
            break;
        }
        if (index === 0) {
            scheduleDescription += monthElement  
        } else {
          if (index === (monthArr.length - 1)) {
            scheduleDescription += ' and ' + monthElement
          } else {
            scheduleDescription += ', ' + monthElement
          }  
        }       
      }
    }
    if (cronArr[5] === '*') {
  scheduleDescription += ', every year'
    } else {
      scheduleDescription += ' ' + thisYear
    }
    scheduleDescription += ' at ' + cronArr[1] + ':' + cronArr[0]
  campaignFormatted.scheduledescription = scheduleDescription
  campaignFormatted.name = campaigns[index].name.S
  campaignFormatted.id = campaigns[index].id.S
  campaignFormatted.description = campaigns[index].description.S
  campaignFormatted.start = startFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  campaignFormatted.end = endFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  campaignFormatted.targets = campaigns[index].targets.S
  campaignFormatted.status = campaigns[index].status.S
  campaignFormatted.message = campaigns[index].email.S
if (campaignFormatted.status === 'ACTIVE') {
let timeStart = parseInt(campaigns[index].start.S)
let timeEnd = parseInt(campaigns[index].end.S)
let timeNow = new Date().getTime()
if (timeNow > timeStart) {
  if (timeNow < timeEnd) {
    progress = 'Delivering messages...'
  } else {
    //UPDATE CAMPAIGN STATUS TO EXPIRED
try {
  await updateCampaign(this.props.auth.getIdToken(), 'EXPIRED', campaignFormatted.id)
} catch (error) {
  this.setState({loading: false})
    console.log(error)
    alert(`Failed to fetch campaigns info ` + error)
}
campaignFormatted.status = 'EXPIRED'
    progress = 'Already delivered all messages'
  }
} else {
  progress = 'Waiting for schedule to start...'
}
} else {
  if (campaignFormatted.status === 'EXPIRED') {
    progress = 'Already delivered all messages'
  } else {
let timeEnd = parseInt(campaigns[index].end.S)
let timeNow = new Date().getTime()
    if (timeNow < timeEnd) { 
    progress = 'Waiting to be restarted...'} else {
      try {
        await updateCampaign(this.props.auth.getIdToken(), 'EXPIRED', campaignFormatted.id)
      } catch (error) {
        this.setState({loading: false})
          console.log(error)
          alert(`Failed to fetch campaigns info ` + error)
      }
      progress = 'Already delivered all messages'
    }
  }
}
campaignFormatted.progress = progress
  campaignArrFormatted.push(campaignFormatted)
}
this.setState({ campaigns: campaignArrFormatted, loading: false })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
    alert(`Failed to fetch campaigns info ` + e)
  }
  }

handleRestart = async () => {
  this.setState({loadingButton: true})
try {
  await restartCampaign(this.props.auth.getIdToken(), this.state.idcheck)
  this.setState({ checked: false, idcheck: 'none', campaignStatus: 'none', loadingButton: false })
} catch (error) {
  this.setState({loadingButton: false})
    console.log(error)
    alert(`Failed to fetch campaigns info ` + error)
}
}

handlePause = async () => {
  this.setState({loadingButton: true})
try {
  await pauseCampaign(this.props.auth.getIdToken(), this.state.idcheck)
  this.setState({ checked: false, idcheck: 'none', campaignStatus: 'none', loadingButton: false })
} catch (error) {
  this.setState({loadingButton: false})
    console.log(error)
    alert(`Failed to fetch campaigns info ` + error)
}
}

handleDelete = async () => {
  this.setState({loadingButton: true})
try {
  await deleteCampaign(this.props.auth.getIdToken(), this.state.idcheck)
  this.setState({ checked: false, idcheck: 'none', campaignStatus: 'none', loadingButton: false })
} catch (error) {
  this.setState({loadingButton: false})
    console.log(error)
    alert(`Failed to fetch campaigns info ` + error)
}
}

  handleCreate = async () => {
    this.setState({ checked: false, idcheck: 'none', campaignStatus: 'none' })
    this.props.history.push('/campaignprocess')
  }

  toggle = (id: string, status: string) => {
    let idch = 'none'
    let statusch = 'none'
    if (!this.state.checked) {
idch = id
statusch = status
    }
  this.setState((prevState: any) => ({ checked: !prevState.checked, idcheck: idch, campaignStatus: statusch }))
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Campaigns...
        </Loader>
      </Grid.Row>
    )
  }

  renderCampaignList() {
    return (
      <div>
          <HeaderCustom headerType="campaign_status"/>
          <Divider style={{margin: '8px'}} />
          <Grid container  verticalAlign="middle" relaxed columns="equal" style={{ backgroundColor: "white"}}>        
        <Grid.Row columns={3} style={{margin: "2px 0px 1px"}}>
        <Grid.Column style={{padding: '6px'}}>
          {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handlePause}>
        <Icon name='pause' />
      </Button>}
      {(this.state.idcheck !== 'none' && this.state.campaignStatus === 'ACTIVE') && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handlePause}>
        <Icon name='pause' />
      </Button>}
      {(this.state.idcheck !== 'none' && this.state.campaignStatus !== 'ACTIVE') && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handlePause}>
        <Icon name='pause' />
      </Button>}
      </Grid.Column>
                <Grid.Column style={{padding: '6px'}}>
                {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleRestart}>
        <Icon name='play' />
      </Button>}
      {(this.state.idcheck !== 'none' && this.state.campaignStatus === 'PAUSED') && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleRestart}>
        <Icon name='play' />
      </Button>}
      {(this.state.idcheck !== 'none' && this.state.campaignStatus !== 'PAUSED') && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleRestart}>
        <Icon name='play' />
      </Button>}
                </Grid.Column>
                <Grid.Column style={{padding: '6px'}}>
                {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDelete}>
        <Icon name='trash alternate' />
      </Button>}
      {this.state.idcheck !== 'none' && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDelete}>
        <Icon name='trash alternate' />
      </Button>}
      </Grid.Column>
              </Grid.Row>
              </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '35vh', overflowY: 'auto'}}>
              <Grid.Row columns={1}>
                <Grid.Column style={{fontWeight: "bold", fontSize: '13pt', padding: '6px'}}>Campaigns activated</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={9}>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Name</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Description</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Targets</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Schedule description</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Start date</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Expiration date</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Status</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Progress</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '11pt', overflow: 'auto', padding: '6px'}}>Select</Grid.Column>
              </Grid.Row>
              {this.state.campaigns.map((campaign) => {
                return (
                <Grid.Row columns={9} style={{overflow: 'auto'}}>
                  <Grid.Column style={{fontSize: '9pt', padding: '6px'}}>{campaign.name}</Grid.Column>
                  <Grid.Column style={{fontSize: '9pt', overflow: 'auto', padding: '6px'}}>{campaign.description}</Grid.Column>
                  <Grid.Column style={{fontSize: '9pt', fontWeight: "bold", overflow: 'auto', padding: '6px'}}>{campaign.targets}</Grid.Column>
                  <Grid.Column style={{fontSize: '9pt', overflow: 'auto', padding: '6px'}}>{campaign.scheduledescription}</Grid.Column>
                  <Grid.Column style={{fontSize: '9pt', padding: '6px'}}>{campaign.start}</Grid.Column>
                  <Grid.Column style={{fontSize: '9pt', padding: '6px'}}>{campaign.end}</Grid.Column>
                  {!this.state.loadingButton && <Grid.Column style={{fontSize: '9pt', fontWeight: "bold", padding: '6px'}}>{campaign.status}</Grid.Column>}
                  {!this.state.loadingButton && <Grid.Column style={{fontSize: '9pt', fontWeight: "bold", padding: '6px'}}>{campaign.progress}</Grid.Column>}
                  <Grid.Column style={{padding: '6px'}}>
                {(this.state.idcheck === campaign.id) && <Checkbox
                checked={this.state.checked}
          onClick={() => {this.toggle(campaign.id, campaign.status)}}
        />}
        {(this.state.idcheck !== campaign.id) && <Checkbox
                checked={false}
          onClick={() => {this.toggle(campaign.id, campaign.status)}}
        />}
        </Grid.Column>
                </Grid.Row>
                )
              })}
              {this.state.campaigns.length === 0 && <Grid.Row columns={9}>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                </Grid.Row>}             
              </Grid>
              <Grid centered relaxed columns='equal'>
                    <Grid.Row columns={1} centered>
                      <Grid.Column textAlign="center">
                      <Button
                          color="orange"
                          size="huge"
                          className="action-button"
                          onClick={this.handleCreate}
                        >
                          Create a Campaign
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                    </Grid> 
              </div>
    )
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return this.renderCampaignList()
}

}