import { Auth as authAmp } from 'aws-amplify';

authAmp.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH'
})

export class Auth {
  accessToken;
  idToken;
expiresAt;
status;
user;

  constructor(history) {
    this.history = history
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.completePwd = this.completePwd.bind(this);
    this.isForced = this.isForced.bind(this);
    this.setSession = this.setSession.bind(this);
    this.setSessionFromVvip = this.setSessionFromVvip.bind(this);
  }

  handleAuthentication(user) {
    if (user === 'none') {
    authAmp.currentSession().then(authResult => {
        console.log('Access token: ', authResult.accessToken)
        console.log('id token: ', authResult.idToken)
          this.setSession(authResult, 'none');
    }).catch(error => {
      this.history.replace('/');
        console.log(error);
    });
  } else {
    let status = 'none';
    authAmp.currentSession().then(authResult => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        status = 'force_change';
          }
          this.user = user;
          this.setSession(authResult, status);
  }).catch(error => {
    this.history.replace('/');
      console.log(error);
  });
  }
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult, status) {
    // Set isLoggedIn flag in localStorage
    let storageExp = null;
    let expiresAt = 0;
    localStorage.setItem('isLoggedIn', 'true');
    console.log('setting local storage items: accesstoken: ' + JSON.stringify(authResult.accessToken) + ' idtoken: ' + JSON.stringify(authResult.idToken) + ', not stringified: accesstoken: ', authResult.accessToken + ' idtoken: ', authResult.idToken)
    localStorage.setItem('accessToken', JSON.stringify(authResult.accessToken));
    localStorage.setItem('idToken', JSON.stringify(authResult.idToken));
    storageExp = localStorage.getItem('tokenExp')
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    console.log('set session Id token: ', this.idToken);
    if (storageExp) {
expiresAt = parseInt(storageExp)
    } else {
      expiresAt = (authResult.accessToken.payload.exp * 1000) + new Date().getTime();
    }    
    localStorage.setItem('tokenExp', expiresAt.toString());
    this.expiresAt = expiresAt;
    this.status = status;
    // navigate to the home route
    this.history.replace('/');
  }

  setSessionFromVvip(authResult, status) {
    // Set isLoggedIn flag in localStorage
    localStorage.removeItem('vvipAccess');
    localStorage.removeItem('vvipId');
    localStorage.setItem('isLoggedIn', 'true');
    console.log('setting local storage items: accesstoken: ' + JSON.stringify(authResult.accessToken) + ' idtoken: ' + JSON.stringify(authResult.idToken) + ', not stringified: accesstoken: ', authResult.accessToken + ' idtoken: ', authResult.idToken)
    localStorage.setItem('accessToken', JSON.stringify(authResult.accessToken));
    localStorage.setItem('idToken', JSON.stringify(authResult.idToken));
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    console.log('set session Id token: ', this.idToken);
    let expiresAt = (authResult.accessToken.payload.exp * 1000) + (authResult.accessToken.payload.iat * 1000);
    localStorage.setItem('tokenExp', expiresAt.toString());
    this.expiresAt = expiresAt;
    this.status = status;
    // navigate to the home route
    this.history.replace('/');
  }

  renewSession() {
    authAmp.currentSession().then(authResult => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult, 'none');
       } else {
         let err = 'token not present';
         this.logout();
         console.log(err);
       }
    }).catch(error => {
      console.log(error);
      this.logout();
    });
  }

  async logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    this.status = 'none';
    // Remove isLoggedIn flag from localStorage    
    try {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('tokenExp');
      localStorage.removeItem('vvipAccess');
      localStorage.removeItem('vvipId');
      localStorage.removeItem('goldenToken');
    } catch (error) {
      console.log(error);
    }
try {
  await authAmp.signOut();
} catch (error) {
  console.log(error);
}
    // navigate to the home route
    this.history.replace('/');
    return
  }

  async login(username, password) {
    let user;
    console.log('login process started');
    try {
     user = await authAmp.signIn(username, password);
     console.log('login process finished');
     localStorage.setItem('isLoggedIn', 'true');
     let status = 'none';
     console.log('challenge name: ' + user.challengeName);
     if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      status = 'force_change';
        }
        this.user = user;
        if (status === 'force_change') {
          this.status = status;
          this.history.replace('/');
        } else {
          console.log('getting current session...')
        let authResult = await authAmp.currentSession();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    localStorage.setItem('accessToken', JSON.stringify(this.accessToken));
    localStorage.setItem('idToken', JSON.stringify(this.idToken));
    console.log('set session Id token: ', this.idToken);
    console.log('exp : ' + authResult.accessToken.payload.exp.toString())
    let expiresAt = (authResult.accessToken.payload.exp * 1000) + new Date().getTime();
    localStorage.setItem('tokenExp', expiresAt.toString());
    this.expiresAt = expiresAt;
    this.status = status;
    this.history.replace('/');
        }
  } catch (error) {
    console.log(error);
    await this.logout();
    throw new Error('Incorrect username or password!');
  }
  }

  async completePwd(newpassword) {
    let user;
    try {
     user = await authAmp.completeNewPassword(
      this.user,
      newpassword
  );
  console.log('user object: ' + user);
  let status = 'none';
  this.user = user;
  let authResult = await authAmp.currentSession();
  this.accessToken = authResult.accessToken;
  this.idToken = authResult.idToken;
  localStorage.setItem('accessToken', JSON.stringify(this.accessToken));
    localStorage.setItem('idToken', JSON.stringify(this.idToken));
  console.log('set session Id token: ', this.idToken);
  let expiresAt = (authResult.accessToken.payload.exp * 1000) + new Date().getTime();
  localStorage.setItem('tokenExp', expiresAt.toString());
  this.expiresAt = expiresAt;
  this.status = status;
    } catch (error) {
      console.log(error);
    await this.logout();
    throw new Error('Login error!');
    }
  }

  isForced() {
    // Check whether the current time is past the
    // access token's expiry time
    let status = this.status;
    return status === 'force_change';
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }
}
