import * as React from 'react'
import { Grid, Button, Header, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'

interface PaymentsaveProps {
  auth: Auth,
  history: any
}

interface PaymentsaveState {
}

export class PaymentSave extends React.PureComponent<
PaymentsaveProps,
PaymentsaveState
> {

  handleHome = () => {
    this.props.history.replace(`/payment`)
  }

  render() {
return (
    <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
            <Header size="large" icon color='violet'>
    <Icon name='sign language' />
    Payment settings successfully updated!
  </Header>
  </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "orange"
                size="large"
                className="action-button"
                onClick={this.handleHome}
                style={{margin: '0.1em'}}
              >
                OK
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
          )
}
}
