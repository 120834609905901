import * as React from 'react'
import { Form, Button, Divider, Grid } from 'semantic-ui-react'
import { createUser } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'

interface CreateUserProps {
  auth: Auth,
  history: any
}

interface CreateUserState {
  name: string,
  email: string,
  usergroup: string,
  timepayment: string,
  uploadingUser: boolean,
  numberofusers: string,
  company_name: string,
  nickname: string,
  family_name: string
}

export class CreateUser extends React.PureComponent<
  CreateUserProps,
  CreateUserState
> {
  state: CreateUserState = {
    name: '',
    email: '',
    usergroup: 'guest',
    timepayment: 'month',
    uploadingUser: false,
    numberofusers: '1',
    company_name: '',
    nickname: '',
    family_name: ''
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = ''
            let value = event.target.value
            if (value) {
              if (typeof value === 'string') {                     
                let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
              }
            }
    this.setState({ name: message })
  }

  handleFamilyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = ''
            let value = event.target.value
            if (value) {
              if (typeof value === 'string') {                     
                let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
              }
            }
    this.setState({ family_name: message })
  }

  handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = ''
            let value = event.target.value
            if (value) {
              if (typeof value === 'string') {                     
                let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
              }
            }
    this.setState({ company_name: message })
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value })
  }

  handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ nickname: event.target.value })
  }

  handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ usergroup: event.target.value })
  }

  handleHome = () => {
    this.props.history.replace(`/`)
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    try {
      if (!this.state.name || !this.state.email) {
        alert('All details should be provided')
        return
      }

      this.setUploadState(true)
      console.log('companyName = ' + this.state.company_name)
      await createUser(this.props.auth.getIdToken(), {
        id: this.state.email,
        name: this.state.name,
        email: this.state.email,
        payment: 'none',
        usergroup: this.state.usergroup,
        renew: 'no',
        charge: 'none',
        numberofusers: this.state.numberofusers,
        usersactivated: 'none',
        timepayment: this.state.timepayment,
        coupon: 'none',
        exgroup: 'none',
        company_name: this.state.company_name,
        nickname: this.state.nickname,
        family_name: this.state.family_name
      })

      alert('User was created!')
    } catch (e) {
      alert('Could not create the user')
    } finally {
      this.setUploadState(false)
    }
  }

  setUploadState(uploadingUser: boolean) {
    this.setState({
      uploadingUser
    })
  }

  handleReturn = () => {
    this.props.history.replace(`/`)
  }

  render() {
    console.log('rendering create user component, company_name: ' + this.state.company_name)
    if (this.state.usergroup === 'admin') {
      return (
        <div>
          <HeaderCustom headerType="create"/>
  
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="User name"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Family Name</label>
              <input
                placeholder="Family Name"
                value={this.state.family_name}
                onChange={this.handleFamilyNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Nickname</label>
              <input
                placeholder="Nickname"
                value={this.state.nickname}
                onChange={this.handleNicknameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="User email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <select
                value={this.state.usergroup}
                onChange={this.handleGroupChange}
              >
                <option value="guest">Guest</option>
                <option value="company">Company</option>
                <option value="admin">Admin</option>
                <option value="goldenadmin">GoldenAdmin</option>
              </select>
            </Form.Field>
            {this.renderButton()}
          </Form>
          <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    }
    if (this.state.usergroup === 'goldenadmin') {
      return (
        <div>
          <HeaderCustom headerType="create"/>
  
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="User name"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Family Name</label>
              <input
                placeholder="Family Name"
                value={this.state.family_name}
                onChange={this.handleFamilyNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Nickname</label>
              <input
                placeholder="Nickname"
                value={this.state.nickname}
                onChange={this.handleNicknameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="User email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <select
                value={this.state.usergroup}
                onChange={this.handleGroupChange}
              >
                <option value="guest">Guest</option>
                <option value="company">Company</option>
                <option value="admin">Admin</option>
                <option value="goldenadmin">GoldenAdmin</option>
              </select>
            </Form.Field>
            {this.renderButton()}
          </Form>
          <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    }
    if (this.state.usergroup !== 'company') {
      return (
        <div>
          <HeaderCustom headerType="create"/>
  
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="User name"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Family Name</label>
              <input
                placeholder="Family Name"
                value={this.state.family_name}
                onChange={this.handleFamilyNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Nickname</label>
              <input
                placeholder="Nickname"
                value={this.state.nickname}
                onChange={this.handleNicknameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="User email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <select
                value={this.state.usergroup}
                onChange={this.handleGroupChange}
              >
                <option value="guest">Guest</option>
                <option value="company">Company</option>
                <option value="admin">Admin</option>
                <option value="goldenadmin">GoldenAdmin</option>
              </select>
            </Form.Field>
            {this.renderButton()}
          </Form>
          <Divider clearing />
          <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    } else {
      return (
        <div>
          <HeaderCustom headerType="create"/>
  
          <Form onSubmit={this.handleSubmit}>
          <Form.Field>
              <label>Company Name</label>
              <input
                placeholder="Company name"
                value={this.state.company_name}
                onChange={this.handleCompanyNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="User name"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Family Name</label>
              <input
                placeholder="Family Name"
                value={this.state.family_name}
                onChange={this.handleFamilyNameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Nickname</label>
              <input
                placeholder="Nickname"
                value={this.state.nickname}
                onChange={this.handleNicknameChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="User email"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <select
                value={this.state.usergroup}
                onChange={this.handleGroupChange}
              >
                <option value="guest">Guest</option>
                <option value="company">Company</option>
                <option value="admin">Admin</option>
                <option value="goldenadmin">GoldenAdmin</option>
              </select>
            </Form.Field>
            {this.renderButton()}
          </Form>
          <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    }    
  }

  renderButton() {
    return (
      <Button color="violet" size="big" loading={this.state.uploadingUser} type="submit">
        Create
      </Button>
    )
  }
}
