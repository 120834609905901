/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Icon } from 'semantic-ui-react'

const {footerConfig, adminEmail} = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

export const Footer = () => {
  return <footer><span>
  <Icon name='copyright outline' /> {footerConfig.Copyrights}
</span>
<span>
  {footerConfig.Suggestions}:{' '}
  <a className="image-link" href={footerConfig.hrefFooter} rel="noreferrer">
    {adminEmail}
  </a>{' '}
</span>
<span>{footerConfig.AllRightsReserved}</span></footer>;
};
