import * as React from 'react'
import { Button } from 'semantic-ui-react'

interface ButtonProps {
    idArray: string[],
    cssName: string,
    onClick: any,
    disableCheck: boolean
  }

  interface ButtonState {
  }

  export class UserButton extends React.PureComponent<ButtonProps, ButtonState> {

handleButton = () => {this.props.onClick(this.props.idArray)}

    render() {
      let text = this.props.cssName
      if (this.props.disableCheck) {
      switch (text) {
        case "delete-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
            disabled
          >
            Delete user
            </Button>)
        case "email-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
            disabled
          >
            Email user
            </Button>)
        case "info-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
            disabled
          >
            User info
            </Button>)
            case "golden-button":
              return (<Button
                size="large"
                color="orange"
                className="action-button"
                onClick={this.handleButton}
                disabled
              >
                Confirm
                </Button>)
            case "paynow-button":
              return (<Button
                size="medium"
                color="violet"
                className="action-button"
                onClick={this.handleButton}
                disabled
              >
                Pay Now
                </Button>)
        default:
          return (<Button
            size="medium"
            color="violet"
            className="button"
            onClick={this.handleButton}
            disabled
          >
            Button
            </Button>)
      }
    } else {
      switch (text) {
        case "golden-button":
              return (<Button
                size="large"
                color="orange"
                className="action-button"
                onClick={this.handleButton}
              >
                Confirm
                </Button>)
        case "delete-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
          >
            Delete user
            </Button>)
        case "email-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
          >
            Email user
            </Button>)
        case "info-button":
          return (<Button
            size="medium"
            color="violet"
            className="action-button"
            onClick={this.handleButton}
          >
            User info
            </Button>)
            case "paynow-button":
              return (<Button
                size="medium"
                color="violet"
                className="action-button"
                onClick={this.handleButton}
              >
                Pay Now
                </Button>)
        default:
          return (<Button
            size="medium"
            color="violet"
            className="button"
            onClick={this.handleButton}
          >
            Button
            </Button>)
      }
    }
    }
  }
