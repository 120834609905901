import * as React from 'react'
import { Card } from 'semantic-ui-react'

interface UserInfoCardProps {
  attributeKey: string,
  attributeValue: string  
}

interface UserInfoCardState {
}

export class UserInfoCard extends React.PureComponent<UserInfoCardProps, UserInfoCardState> {

  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header style={{color: '#6435c9'}}>
            {this.props.attributeKey}
          </Card.Header>
          <Card.Description style={{overflow: 'auto', overflowY: 'hidden'}}>{this.props.attributeValue}</Card.Description>
        </Card.Content>
      </Card>
    )
  }
}