import React, {useState} from "react";
import { Button, Popup, Checkbox, Form, Grid, Label, Message, Modal, Header, Icon } from 'semantic-ui-react'
import { createCoupon } from '../api/users-api'
import { FileUploader } from './FileUploader'

const inputStyle = {
  fontSize: '0.8rem',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#b6b6b3',
  },
  '::placeholder': {
    color: '#b6b6b3',
  },
  textAlign: 'center',
  border: '0em',
  fontWeight: "bold"
}

const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

export const CouponContainerNew = (props) => {
  const [expirationtrial, setExpirationtrial] = useState('hours');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('normal');
  const [count, setCount] = useState(1);
  const [countexp, setCountexp] = useState(1);
  const [countnumber, setCountnumber] = useState(1);
  const [couponexpiration, setCouponexpiration] = useState('year');
  const [tag, setTag] = useState('')
  const [sellers, setSellers] = useState('')
  const [mode, setMode] = useState('none')
  const [specialname, setSpecial] = useState('')
  const [status, setStatus] = useState('not uploaded yet!')
  const [checked, setChecked] = useState(false)
  const [open, setOpen] = useState(false)

  const handleInsert = () => {
    setMode('insert')
      }

const handleExpTrialChange = (event) => {
  setExpirationtrial(event.target.value)
}

      const handleToggle = () => {
        setChecked(!checked)
          }

      const handleUpload = () => {
        setMode('upload')
          }

  const handleClickAdd = () => {
let max = 23
if (type === 'golden' || type === 'special') {
max = 1000000
}
if (count < max) {
setCount(count + 1)
}
}

  const handleClickSubtract = () => {
if (count > 1) {
  setCount(count - 1)
}
  }

  const handleReturn = () => {
    props.history.replace(`/coupons`)
  }

  const handleClick = (event) => {
setCount(event.target.valueAsNumber)
  }

  const handleClickAddExp = () => {
let max = 99
if (countexp < max) {
setCountexp(countexp + 1)
}
}

  const handleClickSubtractExp = () => {
if (countexp > 1) {
  setCountexp(countexp - 1)
}
  }

  const handleClickExp = (event) => {
setCountexp(event.target.valueAsNumber)
  }

  const handleYes = async () => {
    setOpen(false)
let dateExp = new Date()
    let toDate = new Date()
    let trialTime = expirationtrial
    let countTrialVar = count
    if (expirationtrial === 'months') {
countTrialVar = count * 30
trialTime = 'days'
    }
    const trial = countTrialVar + ' ' + trialTime
    switch (couponexpiration) {
      case 'year':
        dateExp.setFullYear(dateExp.getFullYear() + countexp)
        break;
    case 'month':
      dateExp.setMonth(dateExp.getMonth() + countexp)
        break;
    case 'day':
      dateExp.setDate(dateExp.getDate() + countexp)
        break;
      default:
        break;
    }
    const couponObj = {
  exp: dateExp.getTime().toString(),
  type,
  trial,
  sellers,
  creation: toDate.getTime().toString(),
  tag: "[ " + tag + " ]",
  number: countnumber.toString(),
  emailcheck: checked,
  specialname
}
if (!couponObj.specialname) {
  couponObj.specialname = 'none'
}
try {
  const keycode = await createCoupon(props.auth.getIdToken(), couponObj)
  console.log(keycode)
  setLoading(false)
    props.history.replace(`/couponconfirmation/${keycode}`)
} catch (error) {
  setLoading(false)
  alert('Could not create the Coupon!')
  return
}    
  }

  const handleNo = () => {
    setOpen(false)
    setLoading(false)
  }

  const handleClickAddNumber = () => {
let max = 999
if (specialname) {
max = 1
}
if (countnumber < max) {
setCountnumber(countnumber + 1)
}
}

  const handleClickSubtractNumber = () => {
if (countnumber > 1) {
  setCountnumber(countnumber - 1)
}
  }

  const handleClickNumber = (event) => {
setCountnumber(event.target.valueAsNumber)
  }

  const handleTypeChange = (event) => {
    setType(event.target.value)
  }

  const handleSpecialChange = (event) => {
    const value = event.target.value.toUpperCase()
    if (countnumber !== 1) {
      setCountnumber(1)
    }
    setSpecial(value)
  }

  const handleExpChange = (event) => {
    setCouponexpiration(event.target.value)
  }

  const handleTagChange = (event) => {
    const tag_1 = event.target.value.replace("[ ","")
    const tag_2 = tag_1.replace(" ]","")
    if (tag_2.includes(']') || tag_2.includes('[') || tag_2.includes(' ')) {
setTag('')
      alert('Do not put ], [ or empty spaces in your tag!')
return
    }
    setTag(tag_2)
  }

  const handleSellerChange = (event) => {
    setSellers(event.target.value)
  }

  const handleStartProcess = () => {
    setStatus('uploading...')
    }
    const handleErrorProcess = (error) => {
      alert(error)
      setStatus('not uploaded yet!')      
    }
    const handleReadSuccess = (result) => {
      try {
        const emailArray = result.split(',')
        if (emailArray.length === 0) {
          throw new Error('No content in the file!')
        }
        setStatus('upload successful!')
        setSellers(result)
      } catch (error) {
        alert('Reading file error! Check correct format and extension.')
        setStatus('not uploaded yet!')
      }
    }


  const handleSubmit = async (event) => {
    setLoading(true)
    let dateExp = new Date()
    let toDate = new Date()
    let trialTime = expirationtrial
    let countTrialVar = count
    if (expirationtrial === 'months') {
countTrialVar = count * 30
trialTime = 'days'
    }
    const trial = countTrialVar + ' ' + trialTime   
    switch (couponexpiration) {
      case 'year':
        dateExp.setFullYear(dateExp.getFullYear() + countexp)
        break;
    case 'month':
      dateExp.setMonth(dateExp.getMonth() + countexp)
        break;
    case 'day':
      dateExp.setDate(dateExp.getDate() + countexp)
        break;
      default:
        break;
    }
    let sellersParam = sellers
if (!sellers) {sellersParam = 'none'}
    const arrSellers = sellersParam.split(',')
    const couponObj = {
  exp: dateExp.getTime().toString(),
  type,
  trial,
  sellers,
  creation: toDate.getTime().toString(),
  tag: "[ " + tag + " ]",
  number: countnumber.toString(),
  emailcheck: checked,
  specialname
}
if (!tag) {
  setLoading(false)
  alert('Please describe your coupon with a Tag!')
  return
}
if (couponObj.tag.length <= 1) {
  setLoading(false)
  alert('Please describe your coupon with a Tag!')
  return
}
if (!couponObj.sellers) {
  couponObj.sellers = 'none'
if (couponObj.type !== 'golden') {
  setSellers('none')
  setOpen(true)
  return
}
}
if (countnumber < arrSellers.length) {
  setLoading(false)
  alert('Do not put more Sellers than Coupons!')
  return
}
if (!couponObj.specialname) {
  couponObj.specialname = 'none'
}
if (couponObj.specialname !== 'none' && specialname.length < 10) {
  alert('Your Code must be of minimum 10 characters!')
return
}
if (countnumber > arrSellers.length && couponObj.type !== 'golden') {
  setOpen(true)
return
}
if (couponObj.sellers === 'none' && couponObj.type !== 'golden') {
  setOpen(true)
return
}
try {
  const keycode = await createCoupon(props.auth.getIdToken(), couponObj)
  console.log(keycode)
  setLoading(false)
    props.history.replace(`/couponconfirmation/${keycode}`)
} catch (error) {
  setLoading(false)
  alert('Could not create the Coupon!')
  return
}
  }

  return (
    <div>
<Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em'}}>
              <Grid.Row columns={1} centered >
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divBadgeStyle}>
      <label style={{fontSize: '1.1rem', fontWeight: "bold", color: '#6435c9'}}>Coupon Advanced Creation</label>
      </div>   
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Type of Coupon</label>
      <select value={type} onChange={handleTypeChange}
           style={{width: '100%', margin: 'auto'}} >
<option style={inputStyle} value="normal">Normal</option>
<option style={inputStyle} value="special">Special</option>
<option style={inputStyle} value="golden">Golden</option>
            </select>
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Set a Tag</label>
        <input
        className='inputform'
        style={inputStyle}
                  value={"[ " + tag + " ]"}
                  onChange={handleTagChange}
                /> 
        </div>  
      <div style={divStyle}>
      {(type === 'special' || type === 'golden') && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Free Trial Duration</label>}
      {type === 'normal' && <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Guest Duration (no Free Trial)</label>}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickSubtract}
          >
            -
            </Button>
            <input
              className='inputform'
              style={inputStyle}
              disabled
                value={count}
                onChange={handleClick}
              />
            <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickAdd}
          >
            +
            </Button>
              </div>
              <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em',
  marginTop: '0.3em'
}}>
<select value={expirationtrial} onChange={handleExpTrialChange}
           style={{width: '100%', margin: 'auto'}} >
{type === 'normal' && <option style={inputStyle} value="hours">Hours</option>}
{(type === 'golden' || type === 'special') && <option style={inputStyle} value="days">Days</option>}
{(type === 'golden' || type === 'special') && <option style={inputStyle} value="months">Months</option>}
            </select>
</div>
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Coupon Expiration</label>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickSubtractExp}
          >
            -
            </Button>
            <input
              className='inputform'
              disabled
              style={inputStyle}
                value={countexp}
                onChange={handleClickExp}
              />
            <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickAddExp}
          >
            +
            </Button>
            </div>
            <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em',
  marginTop: '0.3em'
}}>
<select value={couponexpiration} onChange={handleExpChange}
           style={{width: '100%', margin: 'auto'}} >
<option style={inputStyle} value="day">Days</option>
<option style={inputStyle} value="month">Months</option>
<option style={inputStyle} value="year">Years</option>
            </select>
</div>             
      </div>
      {type !== 'golden' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Number of Coupons</label>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickSubtractNumber}
          >
            -
            </Button>
            <input
              className='inputform'
              style={inputStyle}
              disabled
                value={countnumber}
                onChange={handleClickNumber}
              />
            <Button
      size="small"
            color="orange"
            loading={loading}
            onClick={handleClickAddNumber}
          >
            +
            </Button>
            </div>     
      </div>}
      {type !== 'golden' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Link Sellers to Coupons</label>
        {mode === 'insert' && <input
        className='inputform'
        style={inputStyle}
        placeholder='none'
                  value={sellers}
                  onChange={handleSellerChange}
                />}
                {mode === 'upload' && <div style={{justifyContent: 'center'}}><FileUploader color={'violet'} onStartProcess={handleStartProcess} onError={handleErrorProcess} onReadSuccess={handleReadSuccess} />                
                <Message size='mini' compact color='violet'><Message.Header>Status</Message.Header><p>{status}</p></Message>
                </div>
                }
                {mode === 'none' &&
                 <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                 <Popup content='Insert seller emails separated by ","' trigger={<Button
                 size="small"
                       color="violet"
                       loading={loading}
                       onClick={handleInsert}
                     >
                       Insert
                       </Button>} />
                       <Popup content='Upload a file .txt with email_1,email_2,...' trigger={<Button
                 size="small"
                       color="violet"
                       loading={loading}
                       onClick={handleUpload}
                     >
                       Upload
                       </Button>} />
                       </div>   
                }
        </div>}
        {type !== 'golden' && <Popup content='Send coupons to sellers' trigger={<div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Notify Sellers</label>
      <Checkbox
                    toggle
                    onChange={handleToggle}
                    checked={checked}
                  />
        </div>
        } />}
        {type === 'golden' && <Popup content='Set a descriptive code instead of an alphanumeric one' trigger={<div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Golden Coupon</label>
      <input
        className='inputform'
        style={inputStyle}
        placeholder='none'
                  value={specialname}
                  onChange={handleSpecialChange}
                />
        </div>
        } />}
      <Button
            fluid
            color="orange"
            onClick={handleSubmit}
            loading={loading}
            style={{marginTop: '0.3em'}}
          >
            Confirm
            </Button>
    </Form>
    {!loading && <Label size="large" pointing='above' basic color='violet'>Create your new Coupon!</Label>}
    {loading && <Label size="large" pointing='above' basic color='violet'>Do not Refresh or Close the page! Creating Coupon...</Label>}
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Grid container columns="equal" centered relaxed verticalAlign="middle" style={{ margin: '0.5em'}}>
    <Grid.Row columns={1} centered style={{margin: '0.5em'}}>
                  <Grid.Column textAlign="center">
                  <Button
                          loading={loading}
                          color="violet"
                          fluid
                          className="action-button"      
                          onClick={handleReturn}                    
                        >
                          Cancel
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            <Modal
            basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />Attention!</Header>
      
      <Modal.Content>
          <p>
            Sellers and Coupons number of coupons are not equal!
          </p>
          <p>
          You will have some coupons without a seller linked to it or viceversa.
          </p>
          <p>
          <strong>Do you still want to proceed?</strong>
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={handleNo}>
         <Icon name='remove' /> No
        </Button>
        <Button basic color='violet' onClick={handleYes}>
         <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
    </div>
  );
};