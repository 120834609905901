import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { Grid } from 'semantic-ui-react';

const { stripePublicKey } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)
const PUBLIC_KEY = stripePublicKey;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Stripe = (props) => {
  console.log('mounting stripe container');
  return (
    <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm infoPay={props.infoObj} history={props.history} billing={props.billing} token={props.token} upgrade={props.upgrade} contract={props.contract} />
    </Elements>
    </Grid.Column>
              </Grid.Row>            
            </Grid>
  );
};

export default Stripe;