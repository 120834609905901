import * as React from 'react'
import { Checkbox,Grid } from 'semantic-ui-react'

interface CheckElementRedemptionProps {
    userId: string,
    onUnCheck: any,
    onCheck: any,
    onSelectCheck: boolean,
    onSelectClear: boolean,
    user: any,
    userEmail: string
  }
  
  interface CheckElementRedemptionState {
      checked: boolean,
      checkselect: boolean
  }
  
  export class CheckElementRedemption extends React.PureComponent<CheckElementRedemptionProps, CheckElementRedemptionState> {
    state: CheckElementRedemptionState = {
        checked: false,
        checkselect: false
      }
    toggle = () => {this.setState((prevState: any) => ({ checked: !prevState.checked }))}
    handleCheck = () => {this.props.onCheck(this.props.userEmail)}
    handleUnCheck = () => {this.props.onUnCheck(this.props.userEmail)}
    shouldComponentUpdate(nextProps: CheckElementRedemptionProps, nextState: CheckElementRedemptionState) {
      if (this.props.onSelectCheck !== nextProps.onSelectCheck) {
          this.setState({ checked: true, checkselect: !this.state.checkselect })
          return false
      }
    if (this.props.onSelectClear !== nextProps.onSelectClear) {
        this.setState({ checked: false, checkselect: !this.state.checkselect })
        return false
    }    
    if (this.state.checkselect === nextState.checkselect) {
      if (this.state.checked !== nextState.checked) {
        if (nextState.checked) {
    this.handleCheck()
    } else {
      this.handleUnCheck()
      }
    }
  }
return true  
}
    render() {
      return (
        <Grid.Row column={9}>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.email}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.type}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.origin}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{new Date(parseInt(this.props.user.contact)).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.method}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.subscription}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.duration}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.companyname}</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>
                <Checkbox
                checked={this.state.checked}
          onClick={this.toggle}
        />
        </Grid.Column>
        </Grid.Row>
      )
    }
  }