import React, {useRef} from 'react'
import { Button } from 'semantic-ui-react'

export const FileUploader = (props) => {
    const fileInput = useRef(null)

    const handleClick = event => {
      console.log(event)
if (fileInput.current) {
      fileInput.current.click()
    }
    }

    const handleFileInput = (e) => {
        props.onStartProcess()
      const file = e.target.files[0]
      const filePop = file
      const extension = filePop.name.split('.').pop()
if (extension !== 'txt') {
props.onError('Wrong extension!')
return
}
      const reader = new FileReader()
      let result = ''
      reader.onload = (e) => {
        const text = e.target.result
        console.log(text)
        result = text
      };
      reader.readAsText(file)
      if (!result) {
        props.onError('No content in the file!')
      return
      } else {
        props.onReadSuccess(result)
        return
      }
      
    }
    return (
        <div className="file-uploader">            
            <Button color={props.color} size='large' onClick={handleClick}>
Upload your File
            </Button>
            <input ref={fileInput} style={{display: 'none'}} type="file" onChange={handleFileInput} />
        </div>
    )
}