import * as React from 'react'
import { Button } from 'semantic-ui-react'

interface ClipboardCopyProps {
  copyText: string
}

interface ClipboardCopyState {
  isCopied: boolean
}

async function copyTextToClipboard(text : string) {
  return await navigator.clipboard.writeText(text)
}

export class ClipboardCopy extends React.PureComponent<
ClipboardCopyProps,
ClipboardCopyState
> {
  state: ClipboardCopyState = {
    isCopied: false
  }  

  // onClick handler function for the copy button
  handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(this.props.copyText)
      .then(() => {
        // If successful, update the isCopied state value
        this.setState({ isCopied: true })
        setTimeout(() => {
          this.setState({ isCopied: false })
        }, 1500)
      })
      .catch((err) => {
        console.log(err);
      });
  }
render() {
  return (
    <div>
      <input size={40} type="text" style={{ fontSize: '0.7rem', fontWeight: 'bold', color: 'EE82EE', border: '0em', textAlign: 'center' }} value={this.props.copyText} readOnly />
      {/* Bind our handler function to the onClick button property */}
      <Button size='tiny' color='violet' style={{margin: "0.2em"}} onClick={this.handleCopyClick}>
        <span>{this.state.isCopied ? 'Copied!' : 'Copy'}</span>
      </Button>
    </div>
  )
}
}