import * as React from 'react'
import { Grid, Button, Header, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'

interface CampaignconfirmationProps {
  auth: Auth,
  history: any
}

interface CampaignconfirmationState {
}

export class Campaignconfirmation extends React.PureComponent<
CampaignconfirmationProps,
CampaignconfirmationState
> {
  state: CampaignconfirmationState = {
  }

  handleHome = () => {
    this.props.history.replace(`/campaignlist`)
  }

  render() {
return (
    <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
            <Header size="large" icon color='violet'>
    <Icon name='sign language' />
    Campaign successfully Created!
  </Header>
  </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "orange"
                size="large"
                className="action-button"
                onClick={this.handleHome}
                style={{margin: '3px'}}
              >
                OK
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
          )
    }
    
}
