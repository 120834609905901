import * as React from 'react'
import { Form, Button, Divider, Grid, TextArea } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { sendEmail } from '../api/users-api'

interface EmailUserProps {
  match: {
    params: {
      userIdArray: string
    }
  },
  history: any,
  auth: Auth
}

interface EmailUserState {
  message: any,
  sendingEmail: boolean,
  subject: string
}

export class EmailUser extends React.PureComponent<
  EmailUserProps,
  EmailUserState
> {
  state: EmailUserState = {
    message: '',
    sendingEmail: false,
    subject: ''
  }

  handleReturn = () => {
    let arr: any = this.props.match.params.userIdArray.split(',')
    if (arr[arr.length - 1] === 'redemption') {
      this.props.history.replace(`/redemption`)
    } else {
      this.props.history.replace(`/`)
    }    
  }
  handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let message = ''
    let value = event.target.value
    if (value) {
      if (typeof value === 'string') {                      
        let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
      }
    }
    this.setState({ subject: message })
  }
  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    let arr: any = this.props.match.params.userIdArray.split(',')
    if (arr[arr.length - 1] === 'redemption') {
      arr = arr.slice(0, -1)
    }
    arr = arr.toString()
    try {
      if (!this.state.message || !this.state.subject) {
        alert('Email text should be provided')
        return
      }

      this.setUploadState(true)
      await sendEmail(this.props.auth.getIdToken(), 
      arr,
      this.state.message,
      this.state.subject
      )

      alert('Email sent!')
    } catch (e) {
      alert('Could not send the email: ' + e)
    } finally {
      this.setUploadState(false)
      this.handleReturn()
    }
  }

  setUploadState(sendingEmail: boolean) {
    this.setState({
      sendingEmail
    })
  }

  render() {
    return (
      <div>
        <HeaderCustom headerType="email"/>

        <Form onSubmit={this.handleSubmit}>
        <Form.Field>
            <label>Subject</label>
            <input
              placeholder="Subject"
              value={this.state.subject}
              onChange={this.handleSubjectChange}
            />
          </Form.Field>
          <Form.Field>
          <label>Email Text</label>
            <TextArea
              placeholder="Write your message..."
              value={this.state.message}
              onChange={
                (e, { value }) => {
                  console.log(e)
                  let message = ''
                  if (value) {
                    if (typeof value === 'string') {                      
                      let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
                    }
                  }
                  this.setState({ message})
                }
              }
            />
          </Form.Field>
          {this.renderButton()}
        </Form>
        <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
      </div>
    )
  }
  renderButton() {
    return (
      <Button color="violet" size="big" loading={this.state.sendingEmail} type="submit">
        Send Email
      </Button>
    )
  }

}
