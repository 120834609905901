const vvipDomain = process.env.REACT_APP_VVIP_DOMAIN || 'app.virtualvip.club'
const backofficeUrl = `https://${vvipDomain}`
export const environment = process.env.REACT_APP_AWS_ACCOUNT || 'none'
export const currency = 'EUR'
export const couponExpiration = 'YEAR'
export const maxPointsMonth = '16000'
export const maxPointsYear = '96000'
export const freeTrialDays = '15'
export const stripeTestPlan = 'price_1LhHDzAI0ZCvTanHeE9BwRcZ'
export const invoiceAvailable = true
export const g_adminsCouponsAv = true
export const paymentAdminsAvailable = false
export const testPaymentActive = false
export const upgradeAvailable = false
export const goldAvailable = false
export const paypalAvailable = false
export const deleteAvailable = true
export const usersFirst = '5'
export const usersSecond = '10'
export const usersThird = '25'
export const usersFourth = '50'
export const usersFifth = '100'
export const usersSixth = '250'
export const usersSeventh = '500'
export const usersEighth = '1000000000'
export const chargeFirstYear = '69.70'
export const chargeSecondYear = '137.70'
export const chargeThirdYear = '233.70'
export const chargeFourthYear = '469.70'
export const chargeFifthYear = '777.70'
export const chargeSixthYear = '1333.70'
export const chargeSeventhYear = '2597.70'
export const chargeEighthYear = '4997.70'
export const chargeFirstMonth = '9.70'
export const chargeSecondMonth = '17.70'
export const chargeThirdMonth = '33.70'
export const chargeFourthMonth = '69.70'
export const chargeFifthMonth = '117.70'
export const chargeSixthMonth = '233.70'
export const chargeSeventhMonth = '397.70'
export const chargeEighthMonth = '777.70'
export const chargeUserMonth = '9.70'
export const chargeUserYear = '69.70'
export const vvipUrl = `https://${vvipDomain}`
export const digitalGoldUrl = 'https://marketplace.digitalgoldsa.com'
export const goldenBadgeSrc = `${vvipUrl}/assets/img/golden_coin_token.png`
export const newGoldenBadgeSrc = `${vvipUrl}/assets/img/atlantic_coin_token.png`
export const newGoldenBadgeLargeSrc = `${vvipUrl}/assets/img/atlantic_coin_large.png`
export const adminEmail = 'support@virtualvip.club'
export const logoLarge = `${vvipUrl}/assets/img/logo_large.jpg`
export const adminImage = `${vvipUrl}/assets/img/admin_image.png`
export const userImage = `${vvipUrl}/assets/img/user_image.png`
export const stripeBadgeSrc = `${vvipUrl}/assets/img/badge_stripe.svg`
export const stripeLogoSrc = `${vvipUrl}/assets/img/logo_stripe.png`
export const paypalBadgeSrc = `${vvipUrl}/assets/img/badge_paypal.png`
export const paypalLogoSrc = `${vvipUrl}/assets/img/logo_paypal.png`
export const apiEndpoint = `https://${vvipDomain}/api`
export const stripePublicKey = 'pk_live_51Lg64BAI0ZCvTanHMSJyoHRdCWO2mw4k7060ohsymgekbtukfWoXxO30N3usTOkepabu8s1ZD99sgBHZhobZKbey00Sh5LiRiV'
export const nameplanyUA = 'VirtualVIP app usage: Annual (1 user)'
export const nameplanmUA = 'VirtualVIP app usage: Monthly (1 user)'
export const nameplanyH = 'VirtualVIP app usage: Annual (Unlimited users)'
export const nameplanyG = 'VirtualVIP app usage: Annual (1-500 users)'
export const nameplanyF = 'VirtualVIP app usage: Annual (1-250 users)'
export const nameplanyE = 'VirtualVIP app usage: Annual (1-100 users)'
export const nameplanyD = 'VirtualVIP app usage: Annual (1-50 users)'
export const nameplanyC = 'VirtualVIP app usage: Annual (1-25 users)'
export const nameplanyB = 'VirtualVIP app usage: Annual (1-10 users)'
export const nameplanyA = 'VirtualVIP app usage: Annual (1-5 users)'
export const nameplanmH = 'VirtualVIP app usage: Monthly (Unlimited users)'
export const nameplanmG = 'VirtualVIP app usage: Monthly (1-500 users)'
export const nameplanmF = 'VirtualVIP app usage: Monthly (1-250 users)'
export const nameplanmE = 'VirtualVIP app usage: Monthly (1-100 users)'
export const nameplanmD = 'VirtualVIP app usage: Monthly (1-50 users)'
export const nameplanmC = 'VirtualVIP app usage: Monthly (1-25 users)'
export const nameplanmB = 'VirtualVIP app usage: Monthly (1-10 users)'
export const nameplanmA = 'VirtualVIP app usage: Monthly (1-5 users)'
export const stripeplanmCA='price_1MGgRWAI0ZCvTanHx6RijAiP'
export const stripeplanmCB='price_1MGgSaAI0ZCvTanHyyiK19PM'
export const stripeplanmCC='price_1MGgU3AI0ZCvTanHAZ2gNWNO'
export const stripeplanmCD='price_1MGgUlAI0ZCvTanHEcDXs52B'
export const stripeplanmCE='price_1MGgVXAI0ZCvTanHUChjApcV'
export const stripeplanmCF='price_1MGgW4AI0ZCvTanHs4cjG8dc'
export const stripeplanmCG='price_1MGgWvAI0ZCvTanHThVBFXoA'
export const stripeplanmCH='price_1MGgXLAI0ZCvTanHwcpumkX8'
export const stripeplanyCA='price_1MGgaDAI0ZCvTanH2P0ZlSuv'
export const stripeplanyCB='price_1MGgb6AI0ZCvTanHTu7eOi4K'
export const stripeplanyCC='price_1MGgdWAI0ZCvTanHSQKTThbX'
export const stripeplanyCD='price_1MGhAWAI0ZCvTanH8Xwo6njf'
export const stripeplanyCE='price_1MGhBKAI0ZCvTanHhlOZbOsg'
export const stripeplanyCF='price_1MGhBzAI0ZCvTanH9gLoLSiM'
export const stripeplanyCG='price_1MGhDDAI0ZCvTanHBouzGDnQ'
export const stripeplanyCH='price_1MGhELAI0ZCvTanHUKSy1ISY'
export const stripeplanyUA='price_1MGhFqAI0ZCvTanHvTriyheI'
export const stripeplanmUA='price_1MGhF8AI0ZCvTanHvudY7Xk0'
export const paypalplanmCA = 'P-8TY199114G108530TMM6XOLQ'//DA CAMBIARE
export const paypalplanmCB = 'P-70K00155WK965191VMM6XQUQ'//DA CAMBIARE
export const paypalplanmCC = 'P-34D27120S6429153LMM6XRSI'//DA CAMBIARE
export const paypalplanmCD = 'P-70G11135Y2251333HMM6XS3I'//DA CAMBIARE
export const paypalplanmCE = 'P-8TY199114G108530TMM6XOLQ'//DA CAMBIARE
export const paypalplanmCF = 'P-70K00155WK965191VMM6XQUQ'//DA CAMBIARE
export const paypalplanmCG = 'P-34D27120S6429153LMM6XRSI'//DA CAMBIARE
export const paypalplanmCH = 'P-70G11135Y2251333HMM6XS3I'//DA CAMBIARE
export const paypalplanyCA = 'P-7JF04637DN958992UMM6X3GI'//DA CAMBIARE
export const paypalplanyCB = 'P-7EB259601U8367707MM6YE2A'//DA CAMBIARE
export const paypalplanyCC = 'P-7HM46762GX5175308MM6YD2A'//DA CAMBIARE
export const paypalplanyCD = 'P-8KV780839F668923AMM6YGTA'//DA CAMBIARE
export const paypalplanyCE = 'P-7JF04637DN958992UMM6X3GI'//DA CAMBIARE
export const paypalplanyCF = 'P-7EB259601U8367707MM6YE2A'//DA CAMBIARE
export const paypalplanyCG = 'P-7HM46762GX5175308MM6YD2A'//DA CAMBIARE
export const paypalplanyCH = 'P-8KV780839F668923AMM6YGTA'//DA CAMBIARE
export const paypalplanyUA = 'P-1SE32710RF771194RMM6YIIY'//DA CAMBIARE
export const paypalplanmUA = 'P-0WD99317HM440410BMM6XZFA'//DA CAMBIARE
export const paypalClientId = 'AXC07RJmGiDM3AYXRBj4ZHmmM6yDMNO5nO14-2AvR_WkuxuZUtVFPzrd0gBOxJsQo6wBpB6Mrbu1rH6G'

export const footerConfig = {
  Social: 'Connect with Us on:',
  Website: 'https://www.coach4charity.com/',//DA CAMBIARE
  Facebook: 'https://www.facebook.com/Coach4Charity',//DA CAMBIARE
  Linkedin: 'https://www.linkedin.com/company/coach-4-charity/',//DA CAMBIARE
  Instagram: 'https://www.instagram.com/coach4charity',//DA CAMBIARE
  Copyrights: 'VirtualVip 2022',
  AllRightsReserved: ' | All rights reserved',
  Suggestions: '| For suggestions and improvements',
  hrefFooter: `mailto:${adminEmail}`,
  imageUrl: `${vvipUrl}/assets/img/logo_small_compact.jpg`,

}

export const authConfig = {
  region: process.env.REACT_APP_REGION || 'eu-west-1',
  logoutUrl: backofficeUrl,
  poolId: process.env.REACT_APP_COGNITO_POOL_ID || 'eu-west-1_C16ChymBI',
  callbackUrl: `${backofficeUrl}/callback`,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '5j2ud20g3tv340ugdejnhuv42o'
}