import * as React from 'react'
import { Grid, Button, Message, Header, Loader, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { ClipboardCopy } from './ClipboardCopy'
const {vvipUrl} = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface PaymentconfirmationProps {
  auth: Auth,
  history: any,
  match: {
    params: {
      groupandkey: string
    }
  }
}

interface PaymentconfirmationState {
  keycode: string,
  loading: boolean,
  usergroup: string,
  useredit: boolean
}

export class Paymentconfirmation extends React.PureComponent<
PaymentconfirmationProps,
PaymentconfirmationState
> {
  state: PaymentconfirmationState = {
    keycode: '',
    loading: true,
    usergroup: 'none',
    useredit: false
  }

  handleHome = () => {
    this.props.history.replace(`/payment`)
  }

  handleHomeApp = () => {
    window.location.replace(vvipUrl)
  }

  async componentDidMount() {
    let propArr = this.props.match.params.groupandkey.split(',')
    let usergroup = propArr[0]
    let key = propArr[1]
    if (usergroup === 'none' && key === 'none') {
this.setState({loading: false, useredit: true})
    } else {
      if (usergroup === 'none' || key === 'none') {
        this.setState({loading: false, usergroup: 'members'})
      } else {
    if (usergroup === 'companies') {
    const keycode = key
  //AWAIT UPDATE USER WITH THE CODE
  this.setState({loading: false, keycode, usergroup})
   } else {
    this.setState({loading: false, usergroup})
   }
  }
  }
  }

   renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Verifying Payment...
        </Loader>
      </Grid.Row>
    )
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    if (this.state.useredit) {
return (
    <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
            <Header size="large" icon color='violet'>
    <Icon name='sign language' />
    Account settings successfully updated!
  </Header>
  </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "orange"
                size="large"
                className="action-button"
                onClick={this.handleHome}
                style={{margin: '0.1em'}}
              >
                OK
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
          )
    }
    let usergroup = this.state.usergroup
    if (usergroup !== 'companies') {
      return (      
        <div>
          <HeaderCustom headerType="paymentsuccesfull"/>
          <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
                <Button
                  color='orange'
                  size="large"
                  className="action-button"
                  onClick={this.handleHomeApp}
                  style={{margin: '0.1em'}}
                >
                  Let's Train
                </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    } else {
    return (      
      <div>
        <HeaderCustom headerType="paymentconfirmation"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <ClipboardCopy copyText={this.state.keycode} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign='center'>
          <Message size='large' color='violet'>
          <Message.Header>Attention!</Message.Header>
          <p>Keep the code safe and send it to your employees to let them register on VirtualVip.</p>
          <p>The code was sent to you by email but you can check it in your user info on USERS section.</p>
          </Message>
</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "orange"
                size="large"
                className="action-button"
                onClick={this.handleHomeApp}
                style={{margin: '0.1em'}}
              >
                Let's Train
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
      </div>
    )
  }
}
}
