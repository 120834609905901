import * as React from 'react'
import { Grid, Button, Checkbox, Loader, Message } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import PaypalContainer from './PaypalContainer'
import StripeContainer from './StripeContainer'
import { UserModel } from '../types/UserModel'
import { getInfoUser, saveUserEdit, createStripeSubscription, getPaypalPlan } from '../api/users-api'
import { loadStripe } from "@stripe/stripe-js"
import { checkPayment } from '../api/users-api'

const { stripePublicKey, usersEighth, currency, upgradeAvailable } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)
const PUBLIC_KEY = stripePublicKey

interface PaymentlistProps {
  auth: Auth,
  history: any
}

interface PaymentlistState {
  email: string,
usergroup: string,
paymentuser: string,
checkedRenew: boolean,
saveEdit: boolean,
charge: string,
loading: boolean,
timepayment: string,
numberofusers: string,
usersactivated: string,
initialcheck: boolean,
loadingbutton: boolean,
method: string,
planid: string,
stripe: any,
declined: boolean,
save: boolean,
customer_id: string,
updatecheck: boolean,
type: any
}

export class PaymentList extends React.PureComponent<
PaymentlistProps,
PaymentlistState
> {
  state: PaymentlistState = {
    email: 'none',
usergroup: 'members',
paymentuser: 'none',
checkedRenew: false,
saveEdit: false,
charge: 'none',
loading: true,
timepayment: 'month',
numberofusers: '1',
usersactivated: '1',
initialcheck: false,
loadingbutton: false,
method: 'none',
planid: 'none',
stripe: null,
declined: false,
save: false,
customer_id: 'none',
updatecheck: false,
type: null
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.updateCheck === this.state.updatecheck) {
    if (this.state.method === 'card') {
      let result: any = null
      try {
        result = await checkPayment(this.props.auth.getIdToken(), this.state.email)
        console.log('update check payment: ' + result)
      } catch (error) {
        console.log('error checkpayment payment list');
        }
        if (result) {
          if (result === 'none') {
            this.setState((prevState: any) => ({declined: true, updatecheck: !prevState.updateCheck}))
                  } else {
                    this.setState((prevState: any) => ({customer_id: result, declined: false, updatecheck: !prevState.updateCheck}))
                  }
        }
    }
  }
  }

  async componentDidMount() {
    console.log('paylist mounted')     
    try {
      const stripeTestPromise = loadStripe(PUBLIC_KEY)
      let method = 'none'
      let type: any = 'none'
      const userid: string = this.props.auth.getIdToken().payload.sub
      console.log('IDtoken: ', this.props.auth.getIdToken().jwtToken)
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)      
    let paymentuser = userPaymentInfo.payment
    let usergroup = userPaymentInfo.usergroup
    let renew = userPaymentInfo.renew
    let charge = userPaymentInfo.charge
    let email = userPaymentInfo.email
    type = userPaymentInfo.type
    if (userPaymentInfo.method) {
      method = userPaymentInfo.method
    }
    if (method === 'card') {
      try {
        let result = await checkPayment(this.props.auth.getIdToken(), email)
        console.log('check payment: ' + result)
        if (result === 'none') {
this.setState({declined: true})
        } else {
          this.setState({declined: false})
        }
        } catch (error) {
          console.log('error check payment payment list');
        }
    }
    let timepayment = userPaymentInfo.timepayment
    let numberofusers = userPaymentInfo.numberofusers
    let usersactivated = userPaymentInfo.usersactivated
    let exgroup = userPaymentInfo.exgroup
if (exgroup !== 'none') {usergroup = exgroup}
    if (usergroup) {
      if (renew === 'yes') {
        this.setState({ checkedRenew: true, initialcheck: true })
            } else {
        this.setState({ checkedRenew: false, initialcheck: false })
            }
      this.setState({ usergroup, paymentuser, charge, loading: false, timepayment, numberofusers, usersactivated, method, email, stripe: stripeTestPromise, type })
    } else {
      console.log('error mounting component payment list');
        this.setState({loading: false})
    }
    } catch (error) {
      console.log('error mounting component payment list');
        this.setState({loading: false})
    }
  }

  renewStatusToggle = async () => {
    let planid : string = 'none'    
    if (this.state.initialcheck !== !this.state.checkedRenew) {
      this.setState((prevState) => ({ checkedRenew: !prevState.checkedRenew}))
      if (this.state.method !== 'card') {
        this.setState({ loadingbutton: true })
      try {
        planid = await getPaypalPlan(this.props.auth.getIdToken(), this.state.email)
        this.setState({ saveEdit: true, planid, loadingbutton: false })
      } catch (error) {
        console.log(error);
        this.setState((prevState) => ({ checkedRenew: !prevState.checkedRenew, loadingbutton: false, saveEdit: false}))
            return
      }
      } else {
        this.setState({saveEdit: true })
      }  
    } else {
      this.setState((prevState) => ({ checkedRenew: !prevState.checkedRenew, saveEdit: false}))
    }    
  }

  handleSaveEdit = async() => {
    this.setState( {loadingbutton: true} )
      let renew = 'no'
      if (this.state.checkedRenew) {
        renew = 'yes'
        let response: any
        try {
        response = await createStripeSubscription(this.props.auth.getIdToken(), {useredit: 'yes', email: this.state.email, stripe: this.state.stripe})
        await saveUserEdit(this.props.auth.getIdToken(), renew, this.state.usergroup, this.state.method, this.state.email, response.subscription.result.id, this.state.charge)
        this.setState( {loadingbutton: false, saveEdit: false, initialcheck: this.state.checkedRenew } )
      } catch (error) {
        this.setState( {loadingbutton: false, saveEdit: false, checkedRenew: !this.state.checkedRenew } )
        }
      } else {
try {
  await saveUserEdit(this.props.auth.getIdToken(), renew, this.state.usergroup, this.state.method, this.state.email, 'none', this.state.charge)
        this.setState( {loadingbutton: false, saveEdit: false, initialcheck: this.state.checkedRenew } )
} catch (error) {
  this.setState( {loadingbutton: false, saveEdit: false, checkedRenew: !this.state.checkedRenew } )
}
      }
  }

  handlePaymentprocess = () => {
    let data = 'none'
    this.setState({saveEdit: false, planid: 'none'})
    this.props.history.push(`/payment/process/${data}`)
  }

  handlePaymentprocessUpgrade = () => {
    let data = 'yes'//UPGRADECHECK
    this.setState({saveEdit: false, planid: 'none'})
    this.props.history.push(`/payment/process/${data}`)
  }

  handlePaymentprocessSave = () => {
    this.setState({saveEdit: false, planid: 'none', save: true})
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading payment details...
        </Loader>
      </Grid.Row>
    )
  }

  renderBaseTop() {
    let renew = ''
    let expirationBar = ''
    let expVar = ''
    let statusVar = ''
    let typeVar = ''
    let chargeVar = this.state.charge
    let durationVar = this.state.timepayment
    this.state.checkedRenew ? renew = 'yes' : renew = 'no'
    let startvar =  new Date(parseInt(this.state.paymentuser))
    const infoObj = {
      planid: this.state.planid,
      start_date: startvar.toISOString(),
      useredit: 'yes',
      renew: renew,
      usergroup: this.state.usergroup,
      method: this.state.method,
      email: this.state.email,
      charge: chargeVar
    }
    if (new Date().getTime() > parseInt(this.state.paymentuser)) {
      statusVar = 'Disabled'
      expVar = 'Expired'
    } else {
      statusVar = 'Active'
      expVar = new Date(parseInt(this.state.paymentuser)).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    }
    if (this.state.usergroup === 'companies') {
typeVar = 'Business'
    } else {
      typeVar = 'Consumer'
    }
    if (this.state.initialcheck) {
      expirationBar = 'Expiration Date/Next Charge'
    } else {
      expirationBar = 'Expiration Date'
      chargeVar = '0'
    }
    console.log('expvar: ' + expVar + ', declined: ' + this.state.declined + ', method: ' + this.state.method + ', group' + this.state.usergroup + ', numberofusers: ' + this.state.numberofusers)
    chargeVar = chargeVar + ' ' + currency
    if (this.state.usergroup === 'employees' || this.state.usergroup === 'guests') {
      return (
        <div>
          <HeaderCustom headerType="payment_status"/>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
              <Grid.Row columns={1}>
                <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={4} style={{minWidth: "60em"}}>
                <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Expiration</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={4} style={{minWidth: "60em"}}>
                <Grid.Column>{typeVar}</Grid.Column>
                <Grid.Column>{durationVar}</Grid.Column>
                <Grid.Column>{statusVar}</Grid.Column>
                <Grid.Column>{expVar}</Grid.Column>
              </Grid.Row>
              </Grid>
              </div>
        )
    } else {
      if (this.state.usergroup === 'companies') {
if (this.state.numberofusers === '1') {
  return (
    <div>
      <HeaderCustom headerType="payment_status"/>
    <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
          <Grid.Row columns={1}>
            <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} style={{minWidth: "60em"}}>
            <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
            <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
            <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
            <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Expiration</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} style={{minWidth: "60em"}}>
            <Grid.Column>{typeVar}</Grid.Column>
            <Grid.Column>{durationVar}</Grid.Column>
            <Grid.Column>{statusVar}</Grid.Column>
            <Grid.Column>{expVar}</Grid.Column>
          </Grid.Row>
          </Grid>
          </div>
    )
}
      }
      if (this.state.method === 'card') {
          if (this.state.usergroup === 'companies') {
            return (
              <div>
                <HeaderCustom headerType="payment_status"/>
              <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                    <Grid.Row columns={1}>
                      <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
                    </Grid.Row>
                    {(this.state.numberofusers === '1' || expVar === 'Expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={7} style={{minWidth: "60em"}}>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Available</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Activated</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                    </Grid.Row>}
                    {(this.state.numberofusers !== '1' && expVar !== 'Expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={8} style={{minWidth: "60em"}}>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Available</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Activated</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                      <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Auto-Renew</Grid.Column>
                    </Grid.Row>}
                    {(this.state.numberofusers === '1' || expVar === 'Expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={7} style={{minWidth: "60em"}}>
                      <Grid.Column>{typeVar}</Grid.Column>
                      <Grid.Column>{durationVar}</Grid.Column>
                      <Grid.Column>{statusVar}</Grid.Column>
                      <Grid.Column>{this.state.numberofusers}</Grid.Column>
                      <Grid.Column>{this.state.usersactivated}</Grid.Column>
                      <Grid.Column>{expVar}</Grid.Column>
                      <Grid.Column>{chargeVar}</Grid.Column>
                    </Grid.Row>}
                    {(this.state.numberofusers !== '1' && expVar !== 'Expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={8} style={{minWidth: "60em"}}>
                      <Grid.Column>{typeVar}</Grid.Column>
                      <Grid.Column>{durationVar}</Grid.Column>
                      <Grid.Column>{statusVar}</Grid.Column>
                      <Grid.Column>{this.state.numberofusers}</Grid.Column>
                      <Grid.Column>{this.state.usersactivated}</Grid.Column>
                      <Grid.Column>{expVar}</Grid.Column>
                      <Grid.Column>{chargeVar}</Grid.Column>
                      <Grid.Column>
                        <Checkbox
                    toggle
                    onChange={this.renewStatusToggle}
                    checked={this.state.checkedRenew}
                  />
                  </Grid.Column>
                    </Grid.Row>}
                    </Grid>
                    <Grid centered relaxed columns='equal'>
                    <Grid.Row columns={1} centered>
                      <Grid.Column textAlign="center">
                      {this.state.saveEdit && <Button
                          loading={this.state.loadingbutton}
                          color="violet"
                          size="medium"
                          className="action-button"
                          onClick={this.handleSaveEdit}
                        >
                          Confirm
                        </Button>}
                      {(this.state.numberofusers !== '1' && expVar !== 'Expired' && !this.state.declined && this.state.charge !== 'none' && !this.state.saveEdit) && <Button
                          loading={this.state.loadingbutton}
                          color="violet"
                          size="medium"
                          className="action-button"
                          onClick={this.handleSaveEdit}
                          disabled
                        >
                          Confirm
                        </Button>}
                      </Grid.Column>
                    </Grid.Row>
                    {this.state.saveEdit && <Grid.Row columns={1} centered>
<Grid.Column textAlign="center">
<Message size='tiny' warning><Message.Header>Attention!</Message.Header><p>Confirming this will change your Subscription settings</p></Message>
</Grid.Column>
                    </Grid.Row>}
                    </Grid>
                    </div>
              )
          } else {
            return (
          <div>
            <HeaderCustom headerType="payment_status"/>
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1}>
                  <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
                </Grid.Row>
                {(expVar !== 'expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={6} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Auto-Renew</Grid.Column>
                </Grid.Row>}
                {(expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={5} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                </Grid.Row>}
                {(expVar !== 'expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={6} style={{minWidth: "60em"}}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                  <Grid.Column>
                    <Checkbox
                toggle
                onChange={this.renewStatusToggle}
                checked={this.state.checkedRenew}
              />
              </Grid.Column>
                </Grid.Row>}
                {(expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={5} style={{minWidth: "60em"}}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                </Grid.Row>}
                </Grid>
                <Grid centered relaxed columns='equal'>
                <Grid.Row columns={1} centered>
                  <Grid.Column textAlign="center">
                        {this.state.saveEdit && <Button
                          loading={this.state.loadingbutton}
                          color="violet"
                          size="medium"
                          className="action-button"
                          onClick={this.handleSaveEdit}
                        >
                          Confirm
                        </Button>}
                      {(expVar !== 'expired' && !this.state.declined && !this.state.saveEdit && this.state.charge !== 'none') && <Button
                          loading={this.state.loadingbutton}
                          color="violet"
                          size="medium"
                          className="action-button"
                          onClick={this.handleSaveEdit}
                          disabled
                        >
                          Confirm
                        </Button>}
                  </Grid.Column>
                </Grid.Row>
                {this.state.saveEdit && <Grid.Row columns={1} centered>
<Grid.Column textAlign="center">
<Message size='tiny' warning><Message.Header>Attention!</Message.Header><p>Confirming this will change your Subscription settings</p></Message>
</Grid.Column>
                    </Grid.Row>}
                </Grid>
                </div>
          )
        }
    } else {
      if (this.state.usergroup === 'companies') {
        return (
          <div>
            <HeaderCustom headerType="payment_status"/>
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1}>
                  <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
                </Grid.Row>
                {(this.state.numberofusers !== '1' && expVar !== 'expired' && !this.state.declined &&  this.state.charge !== 'none') && <Grid.Row columns={8} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Available</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Activated</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Auto-Renew</Grid.Column>
                </Grid.Row>}
                {(this.state.numberofusers === '1' || expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={7} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Available</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Employees Activated</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                </Grid.Row>}
                {(this.state.numberofusers !== '1' && expVar !== 'expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={8} style={{minWidth: "60em"}}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{this.state.numberofusers}</Grid.Column>
                  <Grid.Column>{this.state.usersactivated}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                  <Grid.Column>
                    <Checkbox
                toggle
                onChange={this.renewStatusToggle}
                checked={this.state.checkedRenew}
              />
              </Grid.Column>
                </Grid.Row>}
                {(this.state.numberofusers === '1' || expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={7} style={{minWidth: "60em"}}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{this.state.numberofusers}</Grid.Column>
                  <Grid.Column>{this.state.usersactivated}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                </Grid.Row>}
                </Grid>
                <Grid centered relaxed columns='equal'>
                <Grid.Row columns={1} centered>
                  <Grid.Column textAlign="center">
                  {this.state.saveEdit && this.state.checkedRenew && <div>
<PaypalContainer infoObj={infoObj} history={this.props.history} type={'subscription'} vault={true} token={this.props.auth.getIdToken()} upgrade={'none'} contract={'false'}/>
        </div>}
                    {this.state.saveEdit && !this.state.checkedRenew && <Button
                      loading={this.state.loadingbutton}
                      color="violet"
                      size="medium"
                      className="action-button"
                      onClick={this.handleSaveEdit}
                    >
                      Confirm
                    </Button>}
                  {(this.state.numberofusers !== '1' && expVar !== 'expired' && !this.state.declined && !this.state.saveEdit && this.state.charge !== 'none') && <Button
                      loading={this.state.loadingbutton}
                      color="violet"
                      size="medium"
                      className="action-button"
                      onClick={this.handleSaveEdit}
                      disabled
                    >
                      Confirm
                    </Button>}
                  </Grid.Column>
                </Grid.Row>
                {this.state.saveEdit && !this.state.checkedRenew && <Grid.Row columns={1} centered>
<Grid.Column textAlign="center">
<Message size='tiny' warning><Message.Header>Attention!</Message.Header><p>Confirming this will change your Subscription settings</p></Message>
</Grid.Column>
                    </Grid.Row>}
                </Grid>
                </div>
          )
      } else {
        return (
      <div>
        <HeaderCustom headerType="payment_status"/>
      <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
            <Grid.Row columns={1}>
              <Grid.Column style={{fontWeight: "bold"}}>Subscription Plan</Grid.Column>
            </Grid.Row>
            {(expVar !== 'expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={6} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Auto-Renew</Grid.Column>
                </Grid.Row>}
                {(expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={5} style={{minWidth: "60em"}}>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Type</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Duration</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Status</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>{expirationBar}</Grid.Column>
                  <Grid.Column style={{ fontWeight: "bold", color: '#6435c9'}}>Next Charge</Grid.Column>
                </Grid.Row>}
                {(expVar !== 'expired' && !this.state.declined && this.state.charge !== 'none') && <Grid.Row columns={6}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                  <Grid.Column>
                    <Checkbox
                toggle
                onChange={this.renewStatusToggle}
                checked={this.state.checkedRenew}
              />
              </Grid.Column>
                </Grid.Row>}
                {(expVar === 'expired' || this.state.declined || this.state.charge === 'none') && <Grid.Row columns={5} style={{minWidth: "60em"}}>
                  <Grid.Column>{typeVar}</Grid.Column>
                  <Grid.Column>{durationVar}</Grid.Column>
                  <Grid.Column>{statusVar}</Grid.Column>
                  <Grid.Column>{expVar}</Grid.Column>
                  <Grid.Column>{chargeVar}</Grid.Column>
                </Grid.Row>}
            </Grid>
            <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
              {this.state.saveEdit && this.state.checkedRenew && <div>
<PaypalContainer infoObj={infoObj} history={this.props.history} type={'subscription'} vault={true} token={this.props.auth.getIdToken()} upgrade={'none'} contract={'false'}/>
        </div>}
              {this.state.saveEdit && !this.state.checkedRenew && <Button
                      loading={this.state.loadingbutton}
                      color="violet"
                      size="medium"
                      className="action-button"
                      onClick={this.handleSaveEdit}
                    >
                      Confirm
                    </Button>}
                  {(expVar !== 'expired' && !this.state.declined && !this.state.saveEdit && this.state.charge !== 'none') && <Button
                      loading={this.state.loadingbutton}
                      color="violet"
                      size="medium"
                      className="action-button"
                      onClick={this.handleSaveEdit}
                      disabled
                    >
                      Confirm
                    </Button>}
              </Grid.Column>
            </Grid.Row>
            {this.state.saveEdit && !this.state.checkedRenew && <Grid.Row columns={1} centered>
<Grid.Column textAlign="center">
<Message size='tiny' warning><Message.Header>Attention!</Message.Header><p>Confirming this will change your Subscription settings</p></Message>
</Grid.Column>
                    </Grid.Row>}
            </Grid>
            </div>
      )
    }
    }
  }
  }

  renderBottomSaveCard() {
    return (
      <Grid centered relaxed columns='equal' style={{marginTop: '-0.9em'}}>
                <Grid.Row columns={1} centered>
                  <Grid.Column textAlign="center">
                  <Button
                      color="violet"
                      size="tiny"
                      className="action-button"
                      onClick={this.handlePaymentprocessSave}
                    >
                      Replace your Card with Stripe
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
    )
  }

  renderBottomEmployer() {
    return (
      <Grid centered relaxed columns='equal'>
                    <Grid.Row columns={1} centered>
                      <Grid.Column textAlign="center">
                        <div>
                      <h1>Payment reserved to Company account</h1>
                      </div>
                      </Grid.Column>
                    </Grid.Row>
                    </Grid>
    )
      }

  renderBottomEnable() {
return (
  <Grid centered relaxed columns='equal'>
                <Grid.Row columns={1} centered>
                  <Grid.Column textAlign="center">
                  <Button
                      color="orange"
                      size="huge"
                      className="action-button"
                      onClick={this.handlePaymentprocess}
                    >
                      Renew your Subscription
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
)
  }

  renderBottomActivate() {
    return (
      <Grid centered relaxed columns='equal'>
                    <Grid.Row columns={1} centered>
                      <Grid.Column textAlign="center">
                      <Button
                          color="orange"
                          size="huge"
                          className="action-button"
                          onClick={this.handlePaymentprocess}
                        >
                          Activate your Subscription
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                    </Grid>
    )
      }

      renderBottomUpgrade() {
        return (
          <Grid centered relaxed columns='equal'>
                        <Grid.Row columns={1} centered>
                          <Grid.Column textAlign="center">
                          <Button
                              color="orange"
                              size="huge"
                              className="action-button"
                              onClick={this.handlePaymentprocessUpgrade}
                            >
                              Upgrade your Subscription
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                        </Grid>
        )
          }

          renderStripe() {
            let infoObj = {
              newexpiration: 'none',
              renew: 'none',
              email: 'none',
              method: 'none',
              extend: 'none',
              usergroup: 'none',
              charge: 'none',
              oldexpiration: 'none',
              planid: 'none',
              duration: 'none',
              numberofusers: 'none',
              start_date: 'none',
              useredit: 'none'
            }
            return (
               <div>
            <HeaderCustom headerType="payment_storage"/>
                <Grid container  centered relaxed verticalAlign="middle">
                      <Grid.Row columns={1} centered>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                <StripeContainer infoObj={infoObj} history={this.props.history} billing={this.state.customer_id} token={this.props.auth.getIdToken()} upgrade={'save'} contract={'false'}/>
                </Grid.Column>
                      </Grid.Row>         
                    </Grid>
              </div>
            )
          }

      renderBase() {
        if (this.state.type === 'admin') {
          return (
            <div>
        {this.renderBaseTop()}
            </div>
          )
        }
        if (this.state.usergroup === 'goldenemployees') {
          return (
            <div>
        {this.renderBaseTop()}
            </div>
          ) 
        }
if (this.state.usergroup === 'employees') {
  return (
    <div>
{this.renderBaseTop()}

{this.renderBottomEmployer()}
    </div>
  ) 
}
if (new Date().getTime() > parseInt(this.state.paymentuser)) {
  return (
    <div>
{this.renderBaseTop()}

{this.renderBottomEnable()}
    </div>
  ) 
} else {
  if (this.state.usergroup === 'companies') {
if (this.state.initialcheck) {
  if (this.state.numberofusers !== usersEighth) {
    return (
      <div>
  {this.renderBaseTop()}
  
  {upgradeAvailable && this.renderBottomUpgrade()}

  {this.state.customer_id !== 'none' && upgradeAvailable && this.renderBottomSaveCard()}
      </div>
    )
  } else {
    return (
      <div>
  {this.renderBaseTop()}

  {this.state.customer_id !== 'none' && upgradeAvailable && this.renderBottomSaveCard()}
      </div>
    )
  }
} else {
  if (this.state.numberofusers === '1') {
    return (
      <div>
  {this.renderBaseTop()}
  
  {this.renderBottomActivate()}
      </div>
    )
  } else {
    if (this.state.numberofusers !== usersEighth) {
      return (
        <div>
    {this.renderBaseTop()}
    
    {upgradeAvailable && this.renderBottomUpgrade()}

    {this.state.customer_id !== 'none' && upgradeAvailable && this.renderBottomSaveCard()}
        </div>
      )
    } else {
      return (
        <div>
    {this.renderBaseTop()}

    {this.state.customer_id !== 'none' && upgradeAvailable && this.renderBottomSaveCard()}
        </div>
      )
    }
  }
  
}
  } else {
    if (this.state.usergroup === 'guests') {
      return (
        <div>
    {this.renderBaseTop()}

    {this.renderBottomActivate()}
        </div>
      )
    } else {
      return (
        <div>
    {this.renderBaseTop()}
        </div>
      )
    }      
}
}        
      }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    if (this.state.save) {
return this.renderStripe()
    }
    return this.renderBase() 
  }
}
