import React, {useEffect, useState} from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { Button, Form, Grid, Divider, Image, Label, Icon, Modal, Header } from 'semantic-ui-react'
import { createStripeOrder, refundStripe, saveCard } from '../api/users-api'
import { createStripeSubscription } from '../api/users-api'
import { deletePayment } from '../api/users-api'
import { cancelPayment } from '../api/users-api'
import { paymentConfirmation } from '../api/users-api'
const { stripeBadgeSrc, stripeLogoSrc } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)
const inputStyleStripe = {
  fontSize: '19px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#b6b6b3',
  },
  '::placeholder': {
    color: '#b6b6b3',
  },
  textAlign: 'center'
}

const inputStyle = {
  fontSize: '19px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#b6b6b3',
  },
  '::placeholder': {
    color: '#b6b6b3',
  },
  textAlign: 'center',
  border: '0em'
}

const divStyle = {
  border: '0.13em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

export const CheckoutForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('IT');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [vatcode, setVatcode] = useState('');
  const [sdicode, setSdicode] = useState('');
  const [peccode, setPeccode] = useState('');
  const [fiscalcode, setFiscalcode] = useState('');
  const [idcode, setID] = useState('');
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [processMessage, setProcessMsg] = useState('Do not Refresh or Close the page! Processing Payment data')

useEffect(() => {
const interval = setInterval(() => {
if (processMessage === 'Do not Refresh or Close the page! Processing Payment data...') {
setProcessMsg('Do not Refresh or Close the page! Processing Payment data')
} else {
  let Msg = processMessage + '.'
setProcessMsg(Msg)
}
}, 1000);
return () => clearInterval(interval);
}, [])

const handleCityChange = (event) => {
  setCity(event.target.value);
}

const handleIDChange = (event) => {
  setID(event.target.value);
}

const handleVatChange = (event) => {
  setVatcode(event.target.value);
}

const handlePecChange = (event) => {
  setPeccode(event.target.value);
}

const handleSdiChange = (event) => {
  setSdicode(event.target.value);
}

const handleFiscalChange = (event) => {
  setFiscalcode(event.target.value);
}

const handleCountryChange = (event) => {
  setCountry(event.target.value);
}

const handleAddress1Change = (event) => {
  setAddress1(event.target.value)
}

const handleAddress2Change = (event) => {
 setAddress2(event.target.value)
}

const handlePostalcodeChange = (event) => {
  setPostalcode(event.target.value)
}

const handleNameChange = (event) => {
  setName(event.target.value)
}

const handleSurnameChange = (event) => {
  setSurname(event.target.value)
}

const handleClick = async () => {
  if (!stripe) {
alert('Wait a minute and try again! Stripe client is not loaded yet')
return
  }
  setLoading(true);
  if (props.upgrade !== 'none') {
    if (props.infoPay.renew === 'yes') {
      let resultKey = null
      let resultOrder = null
      try {
        resultOrder = await createStripeOrder(props.token, {amount: props.upgrade, customerid: props.billing, invoice_data: props.infoPay.invoice_data})
      } catch (error) {
        console.log(error.message);
          setLoading(false);
        props.history.replace('/payment/error');
      }
      if (resultOrder) {
        const result = await stripe.confirmCardPayment(resultOrder.result.client_secret, {payment_method: resultOrder.result.payment_method, expand: ['payment_intent.payment_method']});
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error.message);
          setLoading(false);
        props.history.replace('/payment/error');
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
    //PAYUSER BACKEND
    try {
      resultKey = await createStripeSubscription(props.token, {priceId: props.infoPay.planid, stripe, customerid: props.billing, upgradeExp: props.infoPay.oldexpiration, invoice_data: props.infoPay.invoice_data})
    } catch (error) {
      console.log(error.message);
        setLoading(false);
      props.history.replace('/payment/error');
    }
    if (resultKey) {
  //PAYUSER BACKEND
  try {
    let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.subscription.result.id, customerid: props.billing, upgrade: props.upgrade, upgradeid: resultOrder.result.id})
    let data = props.infoPay.usergroup + ',' + key;
    localStorage.setItem('showContract', props.contract);
    props.history.replace(`/payment/${data}/confirmation`);
  } catch (error) {
    console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
  }
    } else {
      try {
        await refundStripe(props.token, resultOrder.result.id)
      } catch (error) {
        console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
      return
      }
      console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
      return
    }
          } else {
    if (result.paymentIntent.status !== 'processing') {
    try {
      await deletePayment(props.token, result.paymentIntent.payment_method.id);
    } catch (error) {
      console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
        return
    } 
          } else {
    try {
      await cancelPayment(props.token, result.paymentIntent.id);
      await deletePayment(props.token, result.paymentIntent.payment_method.id);
    } catch (error) {
      console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
        return
    }
          }
          console.log('Payment not succeded');
          setLoading(false);
          props.history.replace('/payment/error');     
          }
        }
      } else {
        console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
      }      
    } else {
      let resultKey = null
      try {
        resultKey = await createStripeOrder(props.token, {amount: props.upgrade, customerid: props.billing, invoice_data: props.infoPay.invoice_data})
      } catch (error) {
        console.log(error.message);
          setLoading(false);
        props.history.replace('/payment/error');
      }
      if (resultKey) {
        const result = await stripe.confirmCardPayment(resultKey.result.client_secret, {payment_method: resultKey.result.payment_method, expand: ['payment_intent.payment_method']});
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error.message);
          setLoading(false);
        props.history.replace('/payment/error');
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
    //PAYUSER BACKEND
    try {
      let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.result.id, customerid: props.billing, upgrade: props.upgrade, upgradeid: resultKey.result.id});
      let data = props.infoPay.usergroup + ',' + key;
      localStorage.setItem('showContract', props.contract);
      props.history.replace(`/payment/${data}/confirmation`);
    } catch (error) {
      console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
    }
          } else {
    if (result.paymentIntent.status !== 'processing') {
    try {
      await deletePayment(props.token, result.paymentIntent.payment_method.id);
    } catch (error) {
      console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
        return
    } 
          } else {
    try {
      await cancelPayment(props.token, result.paymentIntent.id);
      await deletePayment(props.token, result.paymentIntent.payment_method.id);
    } catch (error) {
      console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
        return
    }
          }
          console.log('Payment not succeded');
          setLoading(false);
          props.history.replace('/payment/error');     
          }
        }
      } else {
        console.log('stripe error');
        setLoading(false);
        props.history.replace('/payment/error');
      }
    }
  } else {
  if (props.infoPay.renew === 'yes') {
    let resultKey = null
    try {
      resultKey = await createStripeSubscription(props.token, {priceId: props.infoPay.planid, stripe, customerid: props.billing, invoice_data: props.infoPay.invoice_data})
    } catch (error) {
      console.log(error.message);
        setLoading(false);
      props.history.replace('/payment/error');
    }
    if (resultKey) {
  //PAYUSER BACKEND
  try {
    let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.subscription.result.id, customerid: resultKey.customerid, upgrade: 'none'});
    let data = props.infoPay.usergroup + ',' + key;
    localStorage.setItem('showContract', props.contract);
      props.history.replace(`/payment/${data}/confirmation`);
  } catch (error) {
    console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
  }
    } else {
      console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
    }
  } else {
    let resultKey = null
    try {
      resultKey = await createStripeOrder(props.token, {amount: props.infoPay.charge, customerid: props.billing, invoice_data: props.infoPay.invoice_data})
    } catch (error) {
      console.log(error.message);
        setLoading(false);
      props.history.replace('/payment/error');
    }
    if (resultKey) {
      const result = await stripe.confirmCardPayment(resultKey.result.client_secret, {payment_method: resultKey.result.payment_method, expand: ['payment_intent.payment_method']});
      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        console.log(result.error.message);
        setLoading(false);
      props.history.replace('/payment/error');
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
  //PAYUSER BACKEND
  try {
    let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.result.id, customerid: resultKey.customerid, upgrade: 'none'})
    let data = props.infoPay.usergroup + ',' + key;
    localStorage.setItem('showContract', props.contract);
    props.history.replace(`/payment/${data}/confirmation`);
  } catch (error) {
    console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
  }
        } else {
  if (result.paymentIntent.status !== 'processing') {
  try {
    await deletePayment(props.token, result.paymentIntent.payment_method.id);
  } catch (error) {
    console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
      return
  } 
        } else {
  try {
    await cancelPayment(props.token, result.paymentIntent.id);
    await deletePayment(props.token, result.paymentIntent.payment_method.id);
  } catch (error) {
    console.log('stripe error');
      setLoading(false);
      props.history.replace('/payment/error');
      return
  }
        }
        console.log('Payment not succeded');
        setLoading(false);
        props.history.replace('/payment/error');     
        }
      }
    } else {
      console.log('stripe error');
      setLoading(false);
      console.log('replaced!');
      props.history.replace('/payment/error');
    }
  }
}
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe) {
      alert('Wait a minute and try again! Stripe client is not loaded yet')
      return
        }
        let invoiceObj = null
        let IDcode = null
        if (fiscalcode) {
          IDcode = fiscalcode
        } else {
IDcode = idcode
        }        
        props.infoPay.usergroup === 'companies' ? invoiceObj = {
          subscriptionType: props.infoPay.invoice_data.subscriptionType,
          subscriptionPrice: props.infoPay.invoice_data.subscriptionPrice,
          period: props.infoPay.invoice_data.period,
          type: props.infoPay.invoice_data.type,
          vatCode: vatcode,
          sdi: sdicode,
          pec: peccode,
          companyName: props.infoPay.invoice_data.companyName,
          country
        } : invoiceObj = {
          subscriptionType: props.infoPay.invoice_data.subscriptionType,
          subscriptionPrice: props.infoPay.invoice_data.subscriptionPrice,
          period: props.infoPay.invoice_data.period,
          type: props.infoPay.invoice_data.type,
          fiscalCode: IDcode,
          consumerName: name + ' ' + surname,
          country
        }
        console.log(invoiceObj)
        if (props.infoPay.usergroup === 'companies') {
          if (!name || !surname || !address1 || !city || !country || !postalcode || !vatcode) {
            alert('All fields are Required!')
            return
              }
              if (country === 'IT') {
if (!sdicode || !peccode) {
  alert('All fields are Required!')
  return
}
if (sdicode.length !== 7) {
  alert('Il codice SDI non è corretto!')
  return
}
if (vatcode.length !== 11) {
  alert('Il numero della Partita IVA non è corretto!')
  return
}
              } else {
                if (vatcode.length <= 5) {
                  alert('VAT number is wrong!')
                  return
                }
              }
              
            } else {
          if (!name || !surname || !address1 || !city || !country || !postalcode) {
            alert('All fields are Required!')
            return
              }
              if (!fiscalcode && !idcode) {
                alert('All fields are Required!')
                return
              }
              if (fiscalcode) {
              if (fiscalcode.length !== 16) {
                alert('Il codice fiscale non è corretto!')
                return
              }
            } else {
              if (idcode.length < 4) {
                alert('ID number is wrong!')
                return
              }
            }
        }
    setLoading(true);
    try {
      let { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city,
            country,
            "line1": address1,
            "line2": address2,
            "postal_code": postalcode
          },
          "email": props.infoPay.email,
          "name": name + ' ' + surname
        }
      });
paymentMethod = paymentMethod.id
      if (!error) {
        console.log("Stripe 23 | token generated!", paymentMethod);
        //send token to backend here  
        if (props.upgrade !== 'none') {
          if (props.infoPay.renew === 'yes') {
            let resultKey = null
            let resultOrder = null
            try {
              resultOrder = await createStripeOrder(props.token, {paymentMethodId: paymentMethod, amount: props.upgrade, email: props.infoPay.email, invoice_data: invoiceObj})
            } catch (error) {
              console.log(error.message);
                setLoading(false);
              props.history.replace('/payment/error');
            }
            if (resultOrder) {
              const result =  await stripe.confirmCardPayment(resultOrder.result.client_secret, {payment_method: paymentMethod});
            if (result.error) {
              // Show error to your customer (for example, insufficient funds)
              console.log(result.error.message);
              setLoading(false);
            props.history.replace('/payment/error');
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === 'succeeded') {
        //PAYUSER BACKEND
        try {
          resultKey = await createStripeSubscription(props.token, {paymentMethodId: paymentMethod, priceId: props.infoPay.planid, stripe, email: props.infoPay.email, upgradeExp: props.infoPay.oldexpiration, invoice_data: invoiceObj})
        } catch (error) {
          console.log(error.message);
            setLoading(false);
          props.history.replace('/payment/error');
        }
        if (resultKey) {
      //PAYUSER BACKEND
      try {
        let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.subscription.result.id, customerid: resultKey.subscription.customerid, upgrade: props.upgrade, upgradeid: resultOrder.result.id})
        let data = props.infoPay.usergroup + ',' + key;
        localStorage.setItem('showContract', props.contract);
        props.history.replace(`/payment/${data}/confirmation`);
      } catch (error) {
        console.log('stripe error');
          setLoading(false);
          props.history.replace('/payment/error');
      }
        } else {
          try {
            await refundStripe(props.token, resultOrder.result.id)
          } catch (error) {
            console.log('stripe error');
          setLoading(false);
          console.log('replaced!');
          props.history.replace('/payment/error');
          return
          }
          console.log('stripe error' + resultOrder.result.id);
          setLoading(false);
          console.log('replaced!');
          props.history.replace('/payment/error');
        }
              } else {
        if (result.paymentIntent.status !== 'processing') {
        try {
          await deletePayment(props.token, paymentMethod);
        } catch (error) {
          console.log('stripe error');
            setLoading(false);
            console.log('replaced!');
            props.history.replace('/payment/error');
            return
        } 
              } else {
        try {
          await cancelPayment(props.token, result.paymentIntent.id);
          await deletePayment(props.token, paymentMethod);
        } catch (error) {
          console.log('stripe error');
            setLoading(false);
            console.log('replaced!');
            props.history.replace('/payment/error');
            return
        }
              }
              console.log('Payment not succeded');
              setLoading(false);
              props.history.replace('/payment/error');     
              }
            }
          } else {
            console.log('stripe error');
            setLoading(false);
            console.log('replaced!');
            props.history.replace('/payment/error');
          }          
          } else {
            let resultKey = null
            try {
              resultKey = await createStripeOrder(props.token, {paymentMethodId: paymentMethod, amount: props.upgrade, email: props.infoPay.email, invoice_data: invoiceObj})
            } catch (error) {
              console.log(error.message);
                setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
            }
            if (resultKey) {
              const result = await stripe.confirmCardPayment(resultKey.result.client_secret, {payment_method: paymentMethod});
              if (result.error) {
                // Show error to your customer (for example, insufficient funds)
                console.log(result.error.message);
                setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
          //PAYUSER BACKEND
          try {
            let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.result.id, customerid: resultKey.customerid, upgrade: props.upgrade, upgradeid: resultKey.result.id})
            let data = props.infoPay.usergroup + ',' + key;
            localStorage.setItem('showContract', props.contract);
            props.history.replace(`/payment/${data}/confirmation`);
          } catch (error) {
            console.log('stripe error');
              setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
          }
                } else {
          if (result.paymentIntent.status !== 'processing') {
          try {
            await deletePayment(props.token, paymentMethod);
          } catch (error) {
            console.log('stripe error');
              setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
              return
          } 
                } else {
          try {
            await cancelPayment(props.token, result.paymentIntent.id);
            await deletePayment(props.token, paymentMethod);
          } catch (error) {
            console.log('stripe error');
              setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
              return
          }
                }
                console.log('Payment not succeded');
                setLoading(false);
                props.history.replace('/payment/error');     
                }
              }
            } else {
              console.log('stripe error');
              setLoading(false);
              console.log('replaced!');
              props.history.replace('/payment/error');
            }
          }
        } else {     
if (props.infoPay.renew === 'yes') {
  let resultKey = null
  try {
    resultKey = await createStripeSubscription(props.token, {paymentMethodId: paymentMethod, priceId: props.infoPay.planid, stripe, email: props.infoPay.email, invoice_data: invoiceObj})
  } catch (error) {
    console.log(error.message);
      setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
  }
  if (resultKey) {
//PAYUSER BACKEND
try {
  let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.subscription.result.id, customerid: resultKey.subscription.customerid, upgrade: 'none'})
  let data = props.infoPay.usergroup + ',' + key;
  localStorage.setItem('showContract', props.contract);
  props.history.replace(`/payment/${data}/confirmation`);
} catch (error) {
  console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
}
  } else {
    console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
  }
} else {
  let resultKey = null
  try {
    resultKey = await createStripeOrder(props.token, {paymentMethodId: paymentMethod, amount: props.infoPay.charge, email: props.infoPay.email, invoice_data: invoiceObj})
  } catch (error) {
    console.log(error.message);
      setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
  }
  if (resultKey) {
    const result = await stripe.confirmCardPayment(resultKey.result.client_secret, {payment_method: paymentMethod});
    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
//PAYUSER BACKEND
try {
  let key = await paymentConfirmation(props.token, {...props.infoPay, orderid: resultKey.result.id, customerid: resultKey.customerid, upgrade: 'none'})
  let data = props.infoPay.usergroup + ',' + key;
  localStorage.setItem('showContract', props.contract);
  props.history.replace(`/payment/${data}/confirmation`);
} catch (error) {
  console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
}
      } else {
if (result.paymentIntent.status !== 'processing') {
try {
  await deletePayment(props.token, paymentMethod);
} catch (error) {
  console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
    return
} 
      } else {
try {
  await cancelPayment(props.token, result.paymentIntent.id);
  await deletePayment(props.token, paymentMethod);
} catch (error) {
  console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
    return
}
      }
      console.log('Payment not succeded');
      setLoading(false);
      props.history.replace('/payment/error');     
      }
    }
  } else {
    console.log('stripe error');
    setLoading(false);
    console.log('replaced!');
    props.history.replace('/payment/error');
  }
}
      }
      } else {
        console.log(error.message);
        setLoading(false);
        props.history.replace('/payment/error');
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      props.history.replace('/payment/error');  
    }   
  };
  const handleSubmitSave = async (event) => {
    event.preventDefault();
    if (!stripe) {
      alert('Wait a minute and try again! Stripe client is not loaded yet')
      return
        }
        let invoiceObj = null
        let IDcode = null
        if (fiscalcode) {
          IDcode = fiscalcode
        } else {
IDcode = idcode
        }        
        props.infoPay.usergroup === 'companies' ? invoiceObj = {
          subscriptionType: props.infoPay.invoice_data.subscriptionType,
          subscriptionPrice: props.infoPay.invoice_data.subscriptionPrice,
          period: props.infoPay.invoice_data.period,
          type: props.infoPay.invoice_data.type,
          vatCode: vatcode,
          sdi: sdicode,
          pec: peccode,
          companyName: props.infoPay.invoice_data.companyName,
          country
        } : invoiceObj = {
          subscriptionType: props.infoPay.invoice_data.subscriptionType,
          subscriptionPrice: props.infoPay.invoice_data.subscriptionPrice,
          period: props.infoPay.invoice_data.period,
          type: props.infoPay.invoice_data.type,
          fiscalCode: IDcode,
          consumerName: name + ' ' + surname,
          country
        }
        console.log(invoiceObj)
        if (props.infoPay.usergroup === 'companies') {
          if (!name || !surname || !address1 || !city || !country || !postalcode || !vatcode) {
            alert('All fields are Required!')
            return
              }
              if (country === 'IT') {
if (!sdicode || !peccode) {
  alert('All fields are Required!')
  return
}
if (sdicode.length !== 7) {
  alert('Il codice SDI non è corretto!')
  return
}
if (vatcode.length !== 11) {
  alert('Il numero di Partita IVA non è corretto!')
  return
}
              } else {
                if (vatcode.length <= 5) {
                  alert('VAT number is wrong!')
                  return
                }
              }
            } else {
          if (!name || !surname || !address1 || !city || !country || !postalcode) {
            alert('All fields are Required!')
            return
              }
              if (!fiscalcode && !idcode) {
                alert('All fields are Required!')
                return
              }
              if (fiscalcode) {
              if (fiscalcode.length !== 16) {
                alert('Il codice fiscale non è corretto!')
                return
              }
            } else {
              if (idcode.length < 4) {
                alert('ID number is wrong!')
                return
              }
            }
        }  
    setLoading(true);
    try {
      let { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city,
            country,
            "line1": address1,
            "line2": address2,
            "postal_code": postalcode
          },
          "email": props.infoPay.email,
          "name": name + ' ' + surname
        }
      });
paymentMethod = paymentMethod.id
      if (!error) {
        try {
         await saveCard(props.token, paymentMethod, props.billing)
          props.history.replace('/payment/save');
        } catch (error) {
          console.log(error.message);
        setLoading(false);
        props.history.replace('/payment/error');
        }
      } else {
        console.log(error.message);
        setLoading(false);
        props.history.replace('/payment/error'); 
      }
    } catch(error) {
      console.log(error.message);
      setLoading(false);
      props.history.replace('/payment/error'); 
    }
  }


  if (props.upgrade === 'save') {
    return (
      <div>
  <Grid container  centered relaxed verticalAlign="middle">
                <Grid.Row columns={1} centered>
                  <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
      <Form onSubmit={handleSubmitSave} style={{ margin: '0 auto' }}>
                  <div style={divBadgeStyle}>
                  <Image style={{width: '100%'}} size='large' src={stripeBadgeSrc} />
                  </div>
        <div style={divStyle}>
        <label htmlFor="cardNumber" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Card Number</label>
              <CardNumberElement
                id="cardNumber"
                options={{
                  style: {
                    base: inputStyleStripe,
                  },
                }}
              />
        </div>
        <div style={divStyle}>
        <label htmlFor="expiry" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Card Expiration</label>
              <CardExpiryElement
                id="expiry"
                options={{
                  style: {
                    base: inputStyleStripe,
                  },
                }}
              />
        </div>
        <div style={divStyle}>
        <label htmlFor="cvc" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>CVC</label>
              <CardCvcElement
                id="cvc"
                options={{
                  style: {
                    base: inputStyleStripe,
                  },
                }}
              />
        </div>      
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Full Name of the card's owner</label>
        <input
        className='inputform'
        style={inputStyle}
                  placeholder="Name (Required)"
                  value={name}
                  onChange={handleNameChange}
                />
                <input
                className='inputform'
                style={inputStyle}
                  placeholder="Surname (Required)"
                  value={surname}
                  onChange={handleSurnameChange}
                />
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Address Details</label>
        <input
        className='inputform'
        style={inputStyle}
                  placeholder="City (Required)"
                  value={city}
                  onChange={handleCityChange}
                />              
                <input
                className='inputform'
                style={inputStyle}
                  placeholder="Address line 1 (Required)"
                  value={address1}
                  onChange={handleAddress1Change}
                />
                <input
                className='inputform'
                style={inputStyle}
                  placeholder="Address line 2"
                  value={address2}
                  onChange={handleAddress2Change}
                />
                <input
                className='inputform'
                style={inputStyle}
                  placeholder="Postal code (Required)"
                  value={postalcode}
                  onChange={handlePostalcodeChange}
                />
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Country</label>
        <select value={country} onChange={handleCountryChange}
             style={{width: '100%', margin: 'auto'}} >
                <option style={inputStyle} value="AF">Afghanistan</option>
  <option style={inputStyle} value="AL">Albania</option>
  <option style={inputStyle} value="DZ">Algeria</option>
  <option style={inputStyle} value="AS">American Samoa</option>
  <option style={inputStyle} value="AD">Andorra</option>
  <option style={inputStyle} value="AG">Angola</option>
  <option style={inputStyle} value="AI">Anguilla</option>
  <option style={inputStyle} value="AG">Antigua &amp; Barbuda</option>
  <option style={inputStyle} value="AR">Argentina</option>
  <option style={inputStyle} value="AA">Armenia</option>
  <option style={inputStyle} value="AW">Aruba</option>
  <option style={inputStyle} value="AU">Australia</option>
  <option style={inputStyle} value="AT">Austria</option>
  <option style={inputStyle} value="AZ">Azerbaijan</option>
  <option style={inputStyle} value="BS">Bahamas</option>
  <option style={inputStyle} value="BH">Bahrain</option>
  <option style={inputStyle} value="BD">Bangladesh</option>
  <option style={inputStyle} value="BB">Barbados</option>
  <option style={inputStyle} value="BY">Belarus</option>
  <option style={inputStyle} value="BE">Belgium</option>
  <option style={inputStyle} value="BZ">Belize</option>
  <option style={inputStyle} value="BJ">Benin</option>
  <option style={inputStyle} value="BM">Bermuda</option>
  <option style={inputStyle} value="BT">Bhutan</option>
  <option style={inputStyle} value="BO">Bolivia</option>
  <option style={inputStyle} value="BL">Bonaire</option>
  <option style={inputStyle} value="BA">Bosnia &amp; Herzegovina</option>
  <option style={inputStyle} value="BW">Botswana</option>
  <option style={inputStyle} value="BR">Brazil</option>
  <option style={inputStyle} value="BC">British Indian Ocean Ter</option>
  <option style={inputStyle} value="BN">Brunei</option>
  <option style={inputStyle} value="BG">Bulgaria</option>
  <option style={inputStyle} value="BF">Burkina Faso</option>
  <option style={inputStyle} value="BI">Burundi</option>
  <option style={inputStyle} value="KH">Cambodia</option>
  <option style={inputStyle} value="CM">Cameroon</option>
  <option style={inputStyle} value="CA">Canada</option>
  <option style={inputStyle} value="IC">Canary Islands</option>
  <option style={inputStyle} value="CV">Cape Verde</option>
  <option style={inputStyle} value="KY">Cayman Islands</option>
  <option style={inputStyle} value="CF">Central African Republic</option>
  <option style={inputStyle} value="TD">Chad</option>
  <option style={inputStyle} value="CD">Channel Islands</option>
  <option style={inputStyle} value="CL">Chile</option>
  <option style={inputStyle} value="CN">China</option>
  <option style={inputStyle} value="CI">Christmas Island</option>
  <option style={inputStyle} value="CS">Cocos Island</option>
  <option style={inputStyle} value="CO">Colombia</option>
  <option style={inputStyle} value="CC">Comoros</option>
  <option style={inputStyle} value="CG">Congo</option>
  <option style={inputStyle} value="CK">Cook Islands</option>
  <option style={inputStyle} value="CR">Costa Rica</option>
  <option style={inputStyle} value="CT">Cote D'Ivoire</option>
  <option style={inputStyle} value="HR">Croatia</option>
  <option style={inputStyle} value="CU">Cuba</option>
  <option style={inputStyle} value="CB">Curacao</option>
  <option style={inputStyle} value="CY">Cyprus</option>
  <option style={inputStyle} value="CZ">Czech Republic</option>
  <option style={inputStyle} value="DK">Denmark</option>
  <option style={inputStyle} value="DJ">Djibouti</option>
  <option style={inputStyle} value="DM">Dominica</option>
  <option style={inputStyle} value="DO">Dominican Republic</option>
  <option style={inputStyle} value="TM">East Timor</option>
  <option style={inputStyle} value="EC">Ecuador</option>
  <option style={inputStyle} value="EG">Egypt</option>
  <option style={inputStyle} value="SV">El Salvador</option>
  <option style={inputStyle} value="GQ">Equatorial Guinea</option>
  <option style={inputStyle} value="ER">Eritrea</option>
  <option style={inputStyle} value="EE">Estonia</option>
  <option style={inputStyle} value="ET">Ethiopia</option>
  <option style={inputStyle} value="FA">Falkland Islands</option>
  <option style={inputStyle} value="FO">Faroe Islands</option>
  <option style={inputStyle} value="FJ">Fiji</option>
  <option style={inputStyle} value="FI">Finland</option>
  <option style={inputStyle} value="FR">France</option>
  <option style={inputStyle} value="GF">French Guiana</option>
  <option style={inputStyle} value="PF">French Polynesia</option>
  <option style={inputStyle} value="FS">French Southern Ter</option>
  <option style={inputStyle} value="GA">Gabon</option>
  <option style={inputStyle} value="GM">Gambia</option>
  <option style={inputStyle} value="GE">Georgia</option>
  <option style={inputStyle} value="DE">Germany</option>
  <option style={inputStyle} value="GH">Ghana</option>
  <option style={inputStyle} value="GI">Gibraltar</option>
  <option style={inputStyle} value="GB">Great Britain</option>
  <option style={inputStyle} value="GR">Greece</option>
  <option style={inputStyle} value="GL">Greenland</option>
  <option style={inputStyle} value="GD">Grenada</option>
  <option style={inputStyle} value="GP">Guadeloupe</option>
  <option style={inputStyle} value="GU">Guam</option>
  <option style={inputStyle} value="GT">Guatemala</option>
  <option style={inputStyle} value="GN">Guinea</option>
  <option style={inputStyle} value="GY">Guyana</option>
  <option style={inputStyle} value="HT">Haiti</option>
  <option style={inputStyle} value="HW">Hawaii</option>
  <option style={inputStyle} value="HN">Honduras</option>
  <option style={inputStyle} value="HK">Hong Kong</option>
  <option style={inputStyle} value="HU">Hungary</option>
  <option style={inputStyle} value="IS">Iceland</option>
  <option style={inputStyle} value="IN">India</option>
  <option style={inputStyle} value="ID">Indonesia</option>
  <option style={inputStyle} value="IA">Iran</option>
  <option style={inputStyle} value="IQ">Iraq</option>
  <option style={inputStyle} value="IR">Ireland</option>
  <option style={inputStyle} value="IM">Isle of Man</option>
  <option style={inputStyle} value="IL">Israel</option>
  <option style={inputStyle} value="IT">Italy</option>
  <option style={inputStyle} value="JM">Jamaica</option>
  <option style={inputStyle} value="JP">Japan</option>
  <option style={inputStyle} value="JO">Jordan</option>
  <option style={inputStyle} value="KZ">Kazakhstan</option>
  <option style={inputStyle} value="KE">Kenya</option>
  <option style={inputStyle} value="KI">Kiribati</option>
  <option style={inputStyle} value="NK">Korea North</option>
  <option style={inputStyle} value="KS">Korea South</option>
  <option style={inputStyle} value="KW">Kuwait</option>
  <option style={inputStyle} value="KG">Kyrgyzstan</option>
  <option style={inputStyle} value="LA">Laos</option>
  <option style={inputStyle} value="LV">Latvia</option>
  <option style={inputStyle} value="LB">Lebanon</option>
  <option style={inputStyle} value="LS">Lesotho</option>
  <option style={inputStyle} value="LR">Liberia</option>
  <option style={inputStyle} value="LY">Libya</option>
  <option style={inputStyle} value="LI">Liechtenstein</option>
  <option style={inputStyle} value="LT">Lithuania</option>
  <option style={inputStyle} value="LU">Luxembourg</option>
  <option style={inputStyle} value="MO">Macau</option>
  <option style={inputStyle} value="MK">Macedonia</option>
  <option style={inputStyle} value="MG">Madagascar</option>
  <option style={inputStyle} value="MY">Malaysia</option>
  <option style={inputStyle} value="MW">Malawi</option>
  <option style={inputStyle} value="MV">Maldives</option>
  <option style={inputStyle} value="ML">Mali</option>
  <option style={inputStyle} value="MT">Malta</option>
  <option style={inputStyle} value="MH">Marshall Islands</option>
  <option style={inputStyle} value="MQ">Martinique</option>
  <option style={inputStyle} value="MR">Mauritania</option>
  <option style={inputStyle} value="MU">Mauritius</option>
  <option style={inputStyle} value="ME">Mayotte</option>
  <option style={inputStyle} value="MX">Mexico</option>
  <option style={inputStyle} value="MI">Midway Islands</option>
  <option style={inputStyle} value="MD">Moldova</option>
  <option style={inputStyle} value="MC">Monaco</option>
  <option style={inputStyle} value="MN">Mongolia</option>
  <option style={inputStyle} value="MS">Montserrat</option>
  <option style={inputStyle} value="MA">Morocco</option>
  <option style={inputStyle} value="MZ">Mozambique</option>
  <option style={inputStyle} value="MM">Myanmar</option>
  <option style={inputStyle} value="NA">Nambia</option>
  <option style={inputStyle} value="NU">Nauru</option>
  <option style={inputStyle} value="NP">Nepal</option>
  <option style={inputStyle} value="AN">Netherland Antilles</option>
  <option style={inputStyle} value="NL">Netherlands (Holland, Europe)</option>
  <option style={inputStyle} value="NV">Nevis</option>
  <option style={inputStyle} value="NC">New Caledonia</option>
  <option style={inputStyle} value="NZ">New Zealand</option>
  <option style={inputStyle} value="NI">Nicaragua</option>
  <option style={inputStyle} value="NE">Niger</option>
  <option style={inputStyle} value="NG">Nigeria</option>
  <option style={inputStyle} value="NW">Niue</option>
  <option style={inputStyle} value="NF">Norfolk Island</option>
  <option style={inputStyle} value="NO">Norway</option>
  <option style={inputStyle} value="OM">Oman</option>
  <option style={inputStyle} value="PK">Pakistan</option>
  <option style={inputStyle} value="PW">Palau Island</option>
  <option style={inputStyle} value="PS">Palestine</option>
  <option style={inputStyle} value="PA">Panama</option>
  <option style={inputStyle} value="PG">Papua New Guinea</option>
  <option style={inputStyle} value="PY">Paraguay</option>
  <option style={inputStyle} value="PE">Peru</option>
  <option style={inputStyle} value="PH">Philippines</option>
  <option style={inputStyle} value="PO">Pitcairn Island</option>
  <option style={inputStyle} value="PL">Poland</option>
  <option style={inputStyle} value="PT">Portugal</option>
  <option style={inputStyle} value="PR">Puerto Rico</option>
  <option style={inputStyle} value="QA">Qatar</option>
  <option style={inputStyle} value="ME">Republic of Montenegro</option>
  <option style={inputStyle} value="RS">Republic of Serbia</option>
  <option style={inputStyle} value="RE">Reunion</option>
  <option style={inputStyle} value="RO">Romania</option>
  <option style={inputStyle} value="RU">Russia</option>
  <option style={inputStyle} value="RW">Rwanda</option>
  <option style={inputStyle} value="NT">St Barthelemy</option>
  <option style={inputStyle} value="EU">St Eustatius</option>
  <option style={inputStyle} value="HE">St Helena</option>
  <option style={inputStyle} value="KN">St Kitts-Nevis</option>
  <option style={inputStyle} value="LC">St Lucia</option>
  <option style={inputStyle} value="MB">St Maarten</option>
  <option style={inputStyle} value="PM">St Pierre &amp; Miquelon</option>
  <option style={inputStyle} value="VC">St Vincent &amp; Grenadines</option>
  <option style={inputStyle} value="SP">Saipan</option>
  <option style={inputStyle} value="SO">Samoa</option>
  <option style={inputStyle} value="AS">Samoa American</option>
  <option style={inputStyle} value="SM">San Marino</option>
  <option style={inputStyle} value="ST">Sao Tome &amp; Principe</option>
  <option style={inputStyle} value="SA">Saudi Arabia</option>
  <option style={inputStyle} value="SN">Senegal</option>
  <option style={inputStyle} value="RS">Serbia</option>
  <option style={inputStyle} value="SC">Seychelles</option>
  <option style={inputStyle} value="SL">Sierra Leone</option>
  <option style={inputStyle} value="SG">Singapore</option>
  <option style={inputStyle} value="SK">Slovakia</option>
  <option style={inputStyle} value="SI">Slovenia</option>
  <option style={inputStyle} value="SB">Solomon Islands</option>
  <option style={inputStyle} value="OI">Somalia</option>
  <option style={inputStyle} value="ZA">South Africa</option>
  <option style={inputStyle} value="ES">Spain</option>
  <option style={inputStyle} value="LK">Sri Lanka</option>
  <option style={inputStyle} value="SD">Sudan</option>
  <option style={inputStyle} value="SR">Suriname</option>
  <option style={inputStyle} value="SZ">Swaziland</option>
  <option style={inputStyle} value="SE">Sweden</option>
  <option style={inputStyle} value="CH">Switzerland</option>
  <option style={inputStyle} value="SY">Syria</option>
  <option style={inputStyle} value="TA">Tahiti</option>
  <option style={inputStyle} value="TW">Taiwan</option>
  <option style={inputStyle} value="TJ">Tajikistan</option>
  <option style={inputStyle} value="TZ">Tanzania</option>
  <option style={inputStyle} value="TH">Thailand</option>
  <option style={inputStyle} value="TG">Togo</option>
  <option style={inputStyle} value="TK">Tokelau</option>
  <option style={inputStyle} value="TO">Tonga</option>
  <option style={inputStyle} value="TT">Trinidad &amp; Tobago</option>
  <option style={inputStyle} value="TN">Tunisia</option>
  <option style={inputStyle} value="TR">Turkey</option>
  <option style={inputStyle} value="TU">Turkmenistan</option>
  <option style={inputStyle} value="TC">Turks &amp; Caicos Is</option>
  <option style={inputStyle} value="TV">Tuvalu</option>
  <option style={inputStyle} value="UG">Uganda</option>
  <option style={inputStyle} value="UA">Ukraine</option>
  <option style={inputStyle} value="AE">United Arab Emirates</option>
  <option style={inputStyle} value="GB">United Kingdom</option>
  <option style={inputStyle} value="US">United States of America</option>
  <option style={inputStyle} value="UY">Uruguay</option>
  <option style={inputStyle} value="UZ">Uzbekistan</option>
  <option style={inputStyle} value="VU">Vanuatu</option>
  <option style={inputStyle} value="VS">Vatican City State</option>
  <option style={inputStyle} value="VE">Venezuela</option>
  <option style={inputStyle} value="VN">Vietnam</option>
  <option style={inputStyle} value="VB">Virgin Islands (Brit)</option>
  <option style={inputStyle} value="VA">Virgin Islands (USA)</option>
  <option style={inputStyle} value="WK">Wake Island</option>
  <option style={inputStyle} value="WF">Wallis &amp; Futana Is</option>
  <option style={inputStyle} value="YE">Yemen</option>
  <option style={inputStyle} value="ZR">Zaire</option>
  <option style={inputStyle} value="ZM">Zambia</option>
  <option style={inputStyle} value="ZW">Zimbabwe</option>
              </select>
        </div>
        {props.infoPay.usergroup !== 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Codice Fiscale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Fiscal code (Required)"
                value={fiscalcode}
                onChange={handleFiscalChange}
              />
      </div>}
      {props.infoPay.usergroup !== 'companies' && country !== 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>ID Card Number</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="ID number (Required)"
                value={idcode}
                onChange={handleIDChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Pec Email Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Pec email (Required)"
                value={peccode}
                onChange={handlePecChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Codice SDI Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="SDI code (Required)"
                value={sdicode}
                onChange={handleSdiChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Partita IVA Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="P. IVA (Required)"
                value={vatcode}
                onChange={handleVatChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country !== 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>VAT Number</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="VAT number (Required)"
                value={vatcode}
                onChange={handleVatChange}
              />
      </div>}
        <Button
              fluid
              color="orange"
              type="submit"
              disabled={loading}
              style={{marginTop: '0.3em'}}
            >
              {!loading && <Image style={{margin: 'auto'}} size='tiny' src={stripeLogoSrc} />}
              {loading && <Icon style={{margin: 'auto'}} size='small' loading name="spinner" />}
              </Button>
      </Form>
      {!loading && <Label size="large" pointing='above' basic color='violet'>Save the new Payment Data</Label>}
              {loading && <Label size="large" pointing='above' basic color='violet'>{'Do not Refresh or Close the page! Processing Payment data...'}</Label>}
      </Grid.Column>
                </Grid.Row>            
              </Grid>
              <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />Attention!</Header>
      
      <Modal.Content>
      <p>
            If you want to use this application please get in touch with us at: <a className="image-link" href="mailto:virtualvip@formulacoach.it" rel="noreferrer">
    virtualvip@formulacoach.it
  </a>
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={
          () => {
            setCountry('IT')
            setOpen(false)
            props.history.replace('/vvip');
          }        
          }>
         <Icon name='checkmark' /> Return to Home
        </Button>
      </Modal.Actions>
    </Modal>
      </div>
    );
  }
if (props.billing !== 'none') {
return (
  <div>
    <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                <div style={divBadgeStyle}>
                <Image style={{width: '100%'}} size='large' src={stripeBadgeSrc} />
                </div>
<Divider />
  <Button
            size="medium"
            color="violet"
            onClick={handleClick}
            loading={loading}
          >
            Pay with your card
            </Button>
            {!loading && <Label size="large" pointing='above' basic color='violet'>Your card details were securely saved by Stripe</Label>}
            {loading && <Label size="large" pointing='above' basic color='violet'>Processing payment... do not refresh the page!</Label>}
            </Grid.Column>
              </Grid.Row>            
            </Grid>
            </div>
)
} else {
  return (
    <div>
<Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered >
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form onSubmit={handleSubmit} style={{ margin: '0 auto' }}>
                <div style={divBadgeStyle}>
                <Image style={{width: '100%'}} size='large' src={stripeBadgeSrc} />
                </div>
      <div style={divStyle}>
      <label htmlFor="cardNumber" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Card Number</label>
            <CardNumberElement
              id="cardNumber"
              options={{
                style: {
                  base: inputStyleStripe,
                },
              }}
            />
      </div>
      <div style={divStyle}>
      <label htmlFor="expiry" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Card Expiration</label>
            <CardExpiryElement
              id="expiry"
              options={{
                style: {
                  base: inputStyleStripe,
                },
              }}
            />
      </div>
      <div style={divStyle}>
      <label htmlFor="cvc" style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>CVC</label>
            <CardCvcElement
              id="cvc"
              options={{
                style: {
                  base: inputStyleStripe,
                },
              }}
            />
      </div>      
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Full Name of the card's owner</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Name (Required)"
                value={name}
                onChange={handleNameChange}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Surname (Required)"
                value={surname}
                onChange={handleSurnameChange}
              />
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{props.infoPay.usergroup === 'companies' && "Company"} Address Details</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="City (Required)"
                value={city}
                onChange={handleCityChange}
              />              
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Address line 1 (Required)"
                value={address1}
                onChange={handleAddress1Change}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Address line 2"
                value={address2}
                onChange={handleAddress2Change}
              />
              <input
              className='inputform'
              style={inputStyle}
                placeholder="Postal code (Required)"
                value={postalcode}
                onChange={handlePostalcodeChange}
              />
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Country</label>
      <select value={country} onChange={handleCountryChange}
           style={{width: '100%', margin: 'auto'}} >
              <option style={inputStyle} value="AF">Afghanistan</option>
<option style={inputStyle} value="AL">Albania</option>
<option style={inputStyle} value="DZ">Algeria</option>
<option style={inputStyle} value="AS">American Samoa</option>
<option style={inputStyle} value="AD">Andorra</option>
<option style={inputStyle} value="AG">Angola</option>
<option style={inputStyle} value="AI">Anguilla</option>
<option style={inputStyle} value="AG">Antigua &amp; Barbuda</option>
<option style={inputStyle} value="AR">Argentina</option>
<option style={inputStyle} value="AA">Armenia</option>
<option style={inputStyle} value="AW">Aruba</option>
<option style={inputStyle} value="AU">Australia</option>
<option style={inputStyle} value="AT">Austria</option>
<option style={inputStyle} value="AZ">Azerbaijan</option>
<option style={inputStyle} value="BS">Bahamas</option>
<option style={inputStyle} value="BH">Bahrain</option>
<option style={inputStyle} value="BD">Bangladesh</option>
<option style={inputStyle} value="BB">Barbados</option>
<option style={inputStyle} value="BY">Belarus</option>
<option style={inputStyle} value="BE">Belgium</option>
<option style={inputStyle} value="BZ">Belize</option>
<option style={inputStyle} value="BJ">Benin</option>
<option style={inputStyle} value="BM">Bermuda</option>
<option style={inputStyle} value="BT">Bhutan</option>
<option style={inputStyle} value="BO">Bolivia</option>
<option style={inputStyle} value="BL">Bonaire</option>
<option style={inputStyle} value="BA">Bosnia &amp; Herzegovina</option>
<option style={inputStyle} value="BW">Botswana</option>
<option style={inputStyle} value="BR">Brazil</option>
<option style={inputStyle} value="BC">British Indian Ocean Ter</option>
<option style={inputStyle} value="BN">Brunei</option>
<option style={inputStyle} value="BG">Bulgaria</option>
<option style={inputStyle} value="BF">Burkina Faso</option>
<option style={inputStyle} value="BI">Burundi</option>
<option style={inputStyle} value="KH">Cambodia</option>
<option style={inputStyle} value="CM">Cameroon</option>
<option style={inputStyle} value="CA">Canada</option>
<option style={inputStyle} value="IC">Canary Islands</option>
<option style={inputStyle} value="CV">Cape Verde</option>
<option style={inputStyle} value="KY">Cayman Islands</option>
<option style={inputStyle} value="CF">Central African Republic</option>
<option style={inputStyle} value="TD">Chad</option>
<option style={inputStyle} value="CD">Channel Islands</option>
<option style={inputStyle} value="CL">Chile</option>
<option style={inputStyle} value="CN">China</option>
<option style={inputStyle} value="CI">Christmas Island</option>
<option style={inputStyle} value="CS">Cocos Island</option>
<option style={inputStyle} value="CO">Colombia</option>
<option style={inputStyle} value="CC">Comoros</option>
<option style={inputStyle} value="CG">Congo</option>
<option style={inputStyle} value="CK">Cook Islands</option>
<option style={inputStyle} value="CR">Costa Rica</option>
<option style={inputStyle} value="CT">Cote D'Ivoire</option>
<option style={inputStyle} value="HR">Croatia</option>
<option style={inputStyle} value="CU">Cuba</option>
<option style={inputStyle} value="CB">Curacao</option>
<option style={inputStyle} value="CY">Cyprus</option>
<option style={inputStyle} value="CZ">Czech Republic</option>
<option style={inputStyle} value="DK">Denmark</option>
<option style={inputStyle} value="DJ">Djibouti</option>
<option style={inputStyle} value="DM">Dominica</option>
<option style={inputStyle} value="DO">Dominican Republic</option>
<option style={inputStyle} value="TM">East Timor</option>
<option style={inputStyle} value="EC">Ecuador</option>
<option style={inputStyle} value="EG">Egypt</option>
<option style={inputStyle} value="SV">El Salvador</option>
<option style={inputStyle} value="GQ">Equatorial Guinea</option>
<option style={inputStyle} value="ER">Eritrea</option>
<option style={inputStyle} value="EE">Estonia</option>
<option style={inputStyle} value="ET">Ethiopia</option>
<option style={inputStyle} value="FA">Falkland Islands</option>
<option style={inputStyle} value="FO">Faroe Islands</option>
<option style={inputStyle} value="FJ">Fiji</option>
<option style={inputStyle} value="FI">Finland</option>
<option style={inputStyle} value="FR">France</option>
<option style={inputStyle} value="GF">French Guiana</option>
<option style={inputStyle} value="PF">French Polynesia</option>
<option style={inputStyle} value="FS">French Southern Ter</option>
<option style={inputStyle} value="GA">Gabon</option>
<option style={inputStyle} value="GM">Gambia</option>
<option style={inputStyle} value="GE">Georgia</option>
<option style={inputStyle} value="DE">Germany</option>
<option style={inputStyle} value="GH">Ghana</option>
<option style={inputStyle} value="GI">Gibraltar</option>
<option style={inputStyle} value="GB">Great Britain</option>
<option style={inputStyle} value="GR">Greece</option>
<option style={inputStyle} value="GL">Greenland</option>
<option style={inputStyle} value="GD">Grenada</option>
<option style={inputStyle} value="GP">Guadeloupe</option>
<option style={inputStyle} value="GU">Guam</option>
<option style={inputStyle} value="GT">Guatemala</option>
<option style={inputStyle} value="GN">Guinea</option>
<option style={inputStyle} value="GY">Guyana</option>
<option style={inputStyle} value="HT">Haiti</option>
<option style={inputStyle} value="HW">Hawaii</option>
<option style={inputStyle} value="HN">Honduras</option>
<option style={inputStyle} value="HK">Hong Kong</option>
<option style={inputStyle} value="HU">Hungary</option>
<option style={inputStyle} value="IS">Iceland</option>
<option style={inputStyle} value="IN">India</option>
<option style={inputStyle} value="ID">Indonesia</option>
<option style={inputStyle} value="IA">Iran</option>
<option style={inputStyle} value="IQ">Iraq</option>
<option style={inputStyle} value="IR">Ireland</option>
<option style={inputStyle} value="IM">Isle of Man</option>
<option style={inputStyle} value="IL">Israel</option>
<option style={inputStyle} value="IT">Italy</option>
<option style={inputStyle} value="JM">Jamaica</option>
<option style={inputStyle} value="JP">Japan</option>
<option style={inputStyle} value="JO">Jordan</option>
<option style={inputStyle} value="KZ">Kazakhstan</option>
<option style={inputStyle} value="KE">Kenya</option>
<option style={inputStyle} value="KI">Kiribati</option>
<option style={inputStyle} value="NK">Korea North</option>
<option style={inputStyle} value="KS">Korea South</option>
<option style={inputStyle} value="KW">Kuwait</option>
<option style={inputStyle} value="KG">Kyrgyzstan</option>
<option style={inputStyle} value="LA">Laos</option>
<option style={inputStyle} value="LV">Latvia</option>
<option style={inputStyle} value="LB">Lebanon</option>
<option style={inputStyle} value="LS">Lesotho</option>
<option style={inputStyle} value="LR">Liberia</option>
<option style={inputStyle} value="LY">Libya</option>
<option style={inputStyle} value="LI">Liechtenstein</option>
<option style={inputStyle} value="LT">Lithuania</option>
<option style={inputStyle} value="LU">Luxembourg</option>
<option style={inputStyle} value="MO">Macau</option>
<option style={inputStyle} value="MK">Macedonia</option>
<option style={inputStyle} value="MG">Madagascar</option>
<option style={inputStyle} value="MY">Malaysia</option>
<option style={inputStyle} value="MW">Malawi</option>
<option style={inputStyle} value="MV">Maldives</option>
<option style={inputStyle} value="ML">Mali</option>
<option style={inputStyle} value="MT">Malta</option>
<option style={inputStyle} value="MH">Marshall Islands</option>
<option style={inputStyle} value="MQ">Martinique</option>
<option style={inputStyle} value="MR">Mauritania</option>
<option style={inputStyle} value="MU">Mauritius</option>
<option style={inputStyle} value="ME">Mayotte</option>
<option style={inputStyle} value="MX">Mexico</option>
<option style={inputStyle} value="MI">Midway Islands</option>
<option style={inputStyle} value="MD">Moldova</option>
<option style={inputStyle} value="MC">Monaco</option>
<option style={inputStyle} value="MN">Mongolia</option>
<option style={inputStyle} value="MS">Montserrat</option>
<option style={inputStyle} value="MA">Morocco</option>
<option style={inputStyle} value="MZ">Mozambique</option>
<option style={inputStyle} value="MM">Myanmar</option>
<option style={inputStyle} value="NA">Nambia</option>
<option style={inputStyle} value="NU">Nauru</option>
<option style={inputStyle} value="NP">Nepal</option>
<option style={inputStyle} value="AN">Netherland Antilles</option>
<option style={inputStyle} value="NL">Netherlands (Holland, Europe)</option>
<option style={inputStyle} value="NV">Nevis</option>
<option style={inputStyle} value="NC">New Caledonia</option>
<option style={inputStyle} value="NZ">New Zealand</option>
<option style={inputStyle} value="NI">Nicaragua</option>
<option style={inputStyle} value="NE">Niger</option>
<option style={inputStyle} value="NG">Nigeria</option>
<option style={inputStyle} value="NW">Niue</option>
<option style={inputStyle} value="NF">Norfolk Island</option>
<option style={inputStyle} value="NO">Norway</option>
<option style={inputStyle} value="OM">Oman</option>
<option style={inputStyle} value="PK">Pakistan</option>
<option style={inputStyle} value="PW">Palau Island</option>
<option style={inputStyle} value="PS">Palestine</option>
<option style={inputStyle} value="PA">Panama</option>
<option style={inputStyle} value="PG">Papua New Guinea</option>
<option style={inputStyle} value="PY">Paraguay</option>
<option style={inputStyle} value="PE">Peru</option>
<option style={inputStyle} value="PH">Philippines</option>
<option style={inputStyle} value="PO">Pitcairn Island</option>
<option style={inputStyle} value="PL">Poland</option>
<option style={inputStyle} value="PT">Portugal</option>
<option style={inputStyle} value="PR">Puerto Rico</option>
<option style={inputStyle} value="QA">Qatar</option>
<option style={inputStyle} value="ME">Republic of Montenegro</option>
<option style={inputStyle} value="RS">Republic of Serbia</option>
<option style={inputStyle} value="RE">Reunion</option>
<option style={inputStyle} value="RO">Romania</option>
<option style={inputStyle} value="RU">Russia</option>
<option style={inputStyle} value="RW">Rwanda</option>
<option style={inputStyle} value="NT">St Barthelemy</option>
<option style={inputStyle} value="EU">St Eustatius</option>
<option style={inputStyle} value="HE">St Helena</option>
<option style={inputStyle} value="KN">St Kitts-Nevis</option>
<option style={inputStyle} value="LC">St Lucia</option>
<option style={inputStyle} value="MB">St Maarten</option>
<option style={inputStyle} value="PM">St Pierre &amp; Miquelon</option>
<option style={inputStyle} value="VC">St Vincent &amp; Grenadines</option>
<option style={inputStyle} value="SP">Saipan</option>
<option style={inputStyle} value="SO">Samoa</option>
<option style={inputStyle} value="AS">Samoa American</option>
<option style={inputStyle} value="SM">San Marino</option>
<option style={inputStyle} value="ST">Sao Tome &amp; Principe</option>
<option style={inputStyle} value="SA">Saudi Arabia</option>
<option style={inputStyle} value="SN">Senegal</option>
<option style={inputStyle} value="RS">Serbia</option>
<option style={inputStyle} value="SC">Seychelles</option>
<option style={inputStyle} value="SL">Sierra Leone</option>
<option style={inputStyle} value="SG">Singapore</option>
<option style={inputStyle} value="SK">Slovakia</option>
<option style={inputStyle} value="SI">Slovenia</option>
<option style={inputStyle} value="SB">Solomon Islands</option>
<option style={inputStyle} value="OI">Somalia</option>
<option style={inputStyle} value="ZA">South Africa</option>
<option style={inputStyle} value="ES">Spain</option>
<option style={inputStyle} value="LK">Sri Lanka</option>
<option style={inputStyle} value="SD">Sudan</option>
<option style={inputStyle} value="SR">Suriname</option>
<option style={inputStyle} value="SZ">Swaziland</option>
<option style={inputStyle} value="SE">Sweden</option>
<option style={inputStyle} value="CH">Switzerland</option>
<option style={inputStyle} value="SY">Syria</option>
<option style={inputStyle} value="TA">Tahiti</option>
<option style={inputStyle} value="TW">Taiwan</option>
<option style={inputStyle} value="TJ">Tajikistan</option>
<option style={inputStyle} value="TZ">Tanzania</option>
<option style={inputStyle} value="TH">Thailand</option>
<option style={inputStyle} value="TG">Togo</option>
<option style={inputStyle} value="TK">Tokelau</option>
<option style={inputStyle} value="TO">Tonga</option>
<option style={inputStyle} value="TT">Trinidad &amp; Tobago</option>
<option style={inputStyle} value="TN">Tunisia</option>
<option style={inputStyle} value="TR">Turkey</option>
<option style={inputStyle} value="TU">Turkmenistan</option>
<option style={inputStyle} value="TC">Turks &amp; Caicos Is</option>
<option style={inputStyle} value="TV">Tuvalu</option>
<option style={inputStyle} value="UG">Uganda</option>
<option style={inputStyle} value="UA">Ukraine</option>
<option style={inputStyle} value="AE">United Arab Emirates</option>
<option style={inputStyle} value="GB">United Kingdom</option>
<option style={inputStyle} value="US">United States of America</option>
<option style={inputStyle} value="UY">Uruguay</option>
<option style={inputStyle} value="UZ">Uzbekistan</option>
<option style={inputStyle} value="VU">Vanuatu</option>
<option style={inputStyle} value="VS">Vatican City State</option>
<option style={inputStyle} value="VE">Venezuela</option>
<option style={inputStyle} value="VN">Vietnam</option>
<option style={inputStyle} value="VB">Virgin Islands (Brit)</option>
<option style={inputStyle} value="VA">Virgin Islands (USA)</option>
<option style={inputStyle} value="WK">Wake Island</option>
<option style={inputStyle} value="WF">Wallis &amp; Futana Is</option>
<option style={inputStyle} value="YE">Yemen</option>
<option style={inputStyle} value="ZR">Zaire</option>
<option style={inputStyle} value="ZM">Zambia</option>
<option style={inputStyle} value="ZW">Zimbabwe</option>
            </select>
      </div>
      {props.infoPay.usergroup !== 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Codice Fiscale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Fiscal code (Required)"
                value={fiscalcode}
                onChange={handleFiscalChange}
              />
      </div>}
      {props.infoPay.usergroup !== 'companies' && country !== 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>ID Card Number</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="ID number (Required)"
                value={idcode}
                onChange={handleIDChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Pec Email Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="Pec email (Required)"
                value={peccode}
                onChange={handlePecChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Codice SDI Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="SDI code (Required)"
                value={sdicode}
                onChange={handleSdiChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country === 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Partita IVA Aziendale</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="P. IVA (Required)"
                value={vatcode}
                onChange={handleVatChange}
              />
      </div>}
      {props.infoPay.usergroup === 'companies' && country !== 'IT' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>VAT Number</label>
      <input
      className='inputform'
      style={inputStyle}
                placeholder="VAT number (Required)"
                value={vatcode}
                onChange={handleVatChange}
              />
      </div>}
      <Button
            fluid
            color="orange"
            type="submit"
            disabled={loading}
            style={{marginTop: '0.3em'}}
          >
            {!loading && <Image style={{margin: 'auto'}} size='tiny' src={stripeLogoSrc} />}
              {loading && <Icon style={{margin: 'auto'}} size='small' loading name="spinner" />}
            </Button>
    </Form>
    {!loading && <Label size="large" pointing='above' basic color='violet'>Pay with your card</Label>}
            {loading && <Label size="large" pointing='above' basic color='violet'>{'Do not Refresh or Close the page! Processing Payment data...'}</Label>}
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />Attention!</Header>
      
      <Modal.Content>
      <p>
            If you want to use this application please get in touch with us at: <a className="image-link" href="mailto:virtualvip@formulacoach.it" rel="noreferrer">
    virtualvip@formulacoach.it
  </a>
          </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={
          () => {
            setCountry('IT')
            setOpen(false)
            props.history.replace('/vvip');
          }        
          }>
         <Icon name='checkmark' /> Return to Home
        </Button>
      </Modal.Actions>
    </Modal>
    </div>
  );
}
};