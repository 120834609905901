/* eslint-disable array-callback-return */
import * as React from 'react'
import { Loader, Grid, Button, Checkbox, Icon, Divider } from 'semantic-ui-react'
import { getTickets, getInfoUser, closeTicket, deleteTicket } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { UserModel } from '../types/UserModel'
import { TicketMessageModel } from '../types/TicketMessageModel'
import { HeaderCustom } from './HeaderCustom'
const {adminEmail} = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface CreateTroubleshootingProps {
  auth: Auth,
  history: any
}

interface CreateTroubleshootingState {
  loading: boolean,
  tickets: any[],
  checked: boolean,
  idcheck: string,
  update: boolean,
  loadingButton: boolean,
  ticketStatus: string,
  group: string,
  name: string,
  email: string,
  exgroup: string
}

export class CreateTroubleshooting extends React.PureComponent<
  CreateTroubleshootingProps,
  CreateTroubleshootingState
> {
  state: CreateTroubleshootingState = {
    loading: true,
    tickets: [],
    checked: false,
    idcheck: 'none',
    update: false,
    loadingButton: false,
    ticketStatus: 'none',
    group: 'admins',
    name: 'none',
    email: 'none',
    exgroup: 'none'
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.update === this.state.update) {
      let ticketArrFormatted: any[] = []
      try {
  const tickets: TicketMessageModel[] = await getTickets(this.props.auth.getIdToken())
  for (let index = 0; index < tickets.length; index++) {
    let ticketFormatted: any = {
      subject: '',
      timestamp: '',
      name: '',
      id: '',
      status: '',
      progress: '',
      email: ''
    }
    if (tickets[index].type.S === 'ticket') {
    let progress = ''
    let timeFormatted = new Date(parseInt(tickets[index].timeStamp.S))
    ticketFormatted.name = tickets[index].name.S
    ticketFormatted.id = tickets[index].ticket.S
    ticketFormatted.email = tickets[index].email.S
    ticketFormatted.subject = tickets[index].subject.S
    ticketFormatted.timestamp = timeFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    ticketFormatted.status = tickets[index].status.S
  if (ticketFormatted.status === 'OPEN') {
    let timestamp = 0
  for (let k = 0; k < tickets.length; k++) {
    const element = tickets[k];    
    if (element.ticket.S === ticketFormatted.id) {
if (parseInt(element.timeStamp.S) > timestamp) {
timestamp = parseInt(element.timeStamp.S)
if (element.group.S === 'admins') {
progress = 'Waiting for User Response...'
} else {
  progress = 'Waiting for Support Response...'
}
}
    }
  }
  } else {
    progress = 'Problem Solved!'
  }
  ticketFormatted.progress = progress
    ticketArrFormatted.push(ticketFormatted)
}
  }
  this.setState({ tickets: ticketArrFormatted, update: !prevState.update })
    } catch (e) {
      console.log(e)
    }
    }
  }

  async componentDidMount() {
    let ticketArrFormatted: any[] = []
    try {
      const userid: string = this.props.auth.getIdToken().payload.sub
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
      let usergroup = userPaymentInfo.usergroup
      let exgroup = userPaymentInfo.exgroup
      let nameUser = userPaymentInfo.name
      let emailUser = userPaymentInfo.email
if (exgroup !== 'none') {usergroup = exgroup}
if (usergroup !== 'admins') {
usergroup = 'users'
}
const tickets: TicketMessageModel[] = await getTickets(this.props.auth.getIdToken())
for (let index = 0; index < tickets.length; index++) {
  let ticketFormatted: any = {
    subject: '',
    timestamp: '',
    name: '',
    id: '',
    status: '',
    progress: '',
    email: ''
  }
  if (tickets[index].type.S === 'ticket') {
  let progress = ''
  console.log('id:', tickets[index].id.S)
  let timeFormatted = new Date(parseInt(tickets[index].timeStamp.S))
  ticketFormatted.name = tickets[index].name.S
  ticketFormatted.id = tickets[index].ticket.S
  ticketFormatted.email = tickets[index].email.S
  ticketFormatted.subject = tickets[index].subject.S
  ticketFormatted.timestamp = timeFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  ticketFormatted.status = tickets[index].status.S
if (ticketFormatted.status === 'OPEN') {
  let timestamp = 0
for (let k = 0; k < tickets.length; k++) {
  const element = tickets[k];
  if (element.ticket.S === ticketFormatted.id) {
if (parseInt(element.timeStamp.S) > timestamp) {
timestamp = parseInt(element.timeStamp.S)
if (element.group.S === 'admins') {
progress = 'Waiting for User Response...'
} else {
progress = 'Waiting for Support Response...'
}
}
  }
}
} else {
  progress = 'Problem Solved!'
}
ticketFormatted.progress = progress
  ticketArrFormatted.push(ticketFormatted)
}
}
this.setState({ tickets: ticketArrFormatted, loading: false, group: usergroup, name: nameUser, email: emailUser, exgroup })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
  }
  }

handleOpen = async () => {
  let namecheck = ''
  if (this.state.group === 'admins') {
    namecheck = this.props.auth.getIdToken().payload['given_name']
  } else {
    namecheck = this.state.name
  }
  let ticketArr = namecheck + ',' + this.state.group + ',' + this.state.idcheck
  this.setState({idcheck: 'none', checked: false})
  this.props.history.push(`/ticketopen/${ticketArr}`)
}

handleClose = async () => {
  this.setState({loadingButton: true})
try {
  await closeTicket(this.props.auth.getIdToken(), this.state.idcheck)
  this.setState({ checked: false, idcheck: 'none', ticketStatus: 'none', loadingButton: false })
} catch (error) {
  this.setState({loadingButton: false})
    console.log(error)
}
}

handleDelete = async () => {
  this.setState({loadingButton: true})
try {
  await deleteTicket(this.props.auth.getIdToken(), this.state.idcheck)
  this.setState({ checked: false, idcheck: 'none', ticketStatus: 'none', loadingButton: false })
} catch (error) {
  this.setState({loadingButton: false})
    console.log(error)
}
}

  handleCreate = async () => {
    let infoArr = this.state.name + ',' + this.state.email
    this.setState({idcheck: 'none', checked: false})
    this.props.history.push(`/ticketprocess/${infoArr}`)
  }

  toggle = (id: string, status: string) => {
    let idch = 'none'
    let statusch = 'none'
    if (!this.state.checked) {
idch = id
statusch = status
    }
  this.setState((prevState: any) => ({ checked: !prevState.checked, idcheck: idch, ticketStatus: statusch }))
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Tickets
        </Loader>
      </Grid.Row>
    )
  }

  renderTicketList() {
    if (this.state.group === 'admins') {
      return (
        <div>
            <HeaderCustom headerType="ticket_status"/>
            <Divider style={{margin: '0.3em'}} />
            <Grid container  verticalAlign="middle" relaxed columns="equal" style={{ backgroundColor: "white"}}>        
          <Grid.Row columns={3} style={{margin: "0em 0em 0.1em", backgroundColor: '#E4DCDB'}}>
          <Grid.Column style={{padding:'0.2em'}}>
            {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleClose}>
          <Icon name='lock' />
        </Button>}
        {(this.state.idcheck !== 'none' && this.state.ticketStatus === 'OPEN') && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleClose}>
          <Icon name='lock' />
        </Button>}
        {(this.state.idcheck !== 'none' && this.state.ticketStatus !== 'OPEN') && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleClose}>
          <Icon name='lock' />
        </Button>}
        </Grid.Column>
                  <Grid.Column style={{padding:'0.2em'}}>
                  {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleOpen}>
          <Icon name='folder open' />
        </Button>}
        {this.state.idcheck !== 'none' && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleOpen}>
          <Icon name='folder open' />
        </Button>}
                  </Grid.Column>
                  <Grid.Column style={{padding:'0.2em'}}>
                  {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDelete}>
          <Icon name='trash alternate' />
        </Button>}
        {this.state.idcheck !== 'none' && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleDelete}>
          <Icon name='trash alternate' />
        </Button>}
        </Grid.Column>
                </Grid.Row>
                </Grid>
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1}>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '0.7rem', padding: '0.2em'}}>Tickets Created</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={7} style={{minWidth: '60em'}}>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Author</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Subject</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Creation Date</Grid.Column>            
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Status</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Progress</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Contact</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Select</Grid.Column>
                </Grid.Row>
                {this.state.tickets.map((ticket) => {
                  return (
                  <Grid.Row columns={7}  style={{minWidth: '60em'}}>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{ticket.name}</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{ticket.subject}</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>{ticket.timestamp}</Grid.Column>
                    {!this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', fontWeight: "bold",  padding: '0.2em'}}>{ticket.status}</Grid.Column>}
                    {this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
            Loading...</Grid.Column>}
                    {!this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', fontWeight: "bold",  padding: '0.2em'}}>{ticket.progress}</Grid.Column>}
                    {this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
            Loading...</Grid.Column>}
            <Grid.Column style={{fontSize: '0.7rem', fontWeight: "bold", overflow: 'auto'}}>{ticket.email}</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>
                  {(this.state.idcheck === ticket.id) && <Checkbox
                  checked={this.state.checked}
            onClick={() => {this.toggle(ticket.id, ticket.status)}}
          />}
          {(this.state.idcheck !== ticket.id) && <Checkbox
                  checked={false}
            onClick={() => {this.toggle(ticket.id, ticket.status)}}
          />}
          </Grid.Column>
                  </Grid.Row>
                  )
                })}
                {this.state.tickets.length === 0 && <Grid.Row columns={7} style={{minWidth: '60em'}}>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{fontSize: '0.7rem',  padding: '0.2em'}}>None available</Grid.Column>
                  </Grid.Row>}
                </Grid>
                </div>
      )
    } else {
      let checkTicket = false
      return (
        <div>
            <HeaderCustom headerType="ticket_status"/>
            <Divider style={{margin: '0.3em'}} />
            <Grid container  verticalAlign="middle" relaxed columns="equal" style={{ backgroundColor: "white"}}>        
          <Grid.Row centered columns={1} style={{margin: "0em 0em 0.1em", backgroundColor: '#E4DCDB'}}>
                  <Grid.Column style={{padding: '0.2em'}}>
                  {this.state.idcheck === 'none' && <Button disabled icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleOpen}>
          <Icon name='folder open' />
        </Button>}
        {this.state.idcheck !== 'none' && <Button icon color="orange" fluid loading={this.state.loadingButton} onClick={this.handleOpen}>
          <Icon name='folder open' />
        </Button>}
                  </Grid.Column>
                </Grid.Row>
                </Grid>
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1}>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '0.7rem', padding: '0.2em'}}>Tickets Created</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={7} style={{minWidth: '60em'}}>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Author</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Subject</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Creation Date</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Status</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Progress</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Contact</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.7rem',  padding: '0.2em'}}>Select</Grid.Column>
                </Grid.Row>
                {this.state.tickets.map((ticket) => {                  
                  if (ticket.email === this.state.email) {
                    checkTicket = true
                    return (
                      <Grid.Row columns={7} style={{minWidth: '60em'}} >
                        <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em'}}>{ticket.name}</Grid.Column>
                        <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em'}}>{ticket.subject}</Grid.Column>
                        <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em'}}>{ticket.timestamp}</Grid.Column>
                        {!this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em',  fontWeight: "bold"}}>{ticket.status}</Grid.Column>}
                        {this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em'}}>
                Loading...</Grid.Column>}
                        {!this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', fontWeight: "bold",  padding: '0.2em'}}>{ticket.progress}</Grid.Column>}
                        {this.state.loadingButton && <Grid.Column style={{fontSize: '0.7rem', padding: '0.2em'}}>
                Loading...</Grid.Column>}
                <Grid.Column style={{fontSize: '0.7rem', fontWeight: "bold",  padding: '0.2em', overflow: 'auto'}}>{adminEmail}</Grid.Column>
                        <Grid.Column style={{padding: '0.2em'}}>
                      {(this.state.idcheck === ticket.id) && <Checkbox
                      checked={this.state.checked}
                onClick={() => {this.toggle(ticket.id, ticket.status)}}
              />}
              {(this.state.idcheck !== ticket.id) && <Checkbox
                      checked={false}
                onClick={() => {this.toggle(ticket.id, ticket.status)}}
              />}
              </Grid.Column>
                      </Grid.Row>
                      )
                  }})}
                  {!checkTicket && this.state.tickets.length !== 0 && <Grid.Row columns={7} style={{minWidth: '60em'}}>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                  </Grid.Row>}
                {this.state.tickets.length === 0 && <Grid.Row columns={7} style={{minWidth: '60em'}}>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                    <Grid.Column style={{ padding: '0.2em'}}>None available</Grid.Column>
                  </Grid.Row>}
                </Grid>
                <Grid centered relaxed columns='equal'>
                      <Grid.Row columns={1} centered>
                        <Grid.Column textAlign="center">
                        <Button
                            color="orange"
                            size="huge"
                            className="action-button"
                            onClick={this.handleCreate}
                          >
                            Create a Ticket
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                      </Grid> 
                </div>
      )
    }
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return this.renderTicketList()
}

}