/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Modal, Header, Popup, Form, Image, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { boostGolden } from '../api/users-api'
import { SingleRangeSlider } from 'neo-react-semantic-ui-range'
import 'neo-react-semantic-ui-range/dist/styles.min.css'
import { GoldenBoostModel } from '../types/GoldenCoinModel'
import { UserButton } from './UserButton'
const { newGoldenBadgeLargeSrc } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

interface GoldenBoostProps {
  auth: Auth,
  history: any,
  match: {
    params: {
      infocompany: string
    }
  }
}

interface GoldenBoostState {
  loadingbutton: boolean,
  sliderValue: number,
  confirmState: boolean,
  startModal: boolean,
  endModal: boolean,
  base_conversion_rate: string,
  new_conversion_rate: string
}

function percentage(num: number, per: number) {
  return (num/100)*per
}

function fixRounding(value: number, precision: number) {
  let power = Math.pow(10, precision || 0)
  return Math.round(value * power) / power
}

export class GoldenBoostResNew extends React.PureComponent<
GoldenBoostProps,
GoldenBoostState
> {
  state: GoldenBoostState = {
    loadingbutton: false,
    sliderValue: 0,
    confirmState: false,
    startModal: true,
    endModal: false,
    base_conversion_rate: '0.0001',
    new_conversion_rate: '0.0001'
  }

handleConfirm = async (userInfo: string[]) => {
  let userinfo = userInfo[0]
this.setState({confirmState: true, endModal: true, new_conversion_rate: userinfo})
}

handleBase = () => {
  this.setState({base_conversion_rate: '0.00005'})
  }

  handleTop = () => {
    this.setState({base_conversion_rate: '0.0001'})
    }

    handleMax = () => {
      this.setState({base_conversion_rate: '0.0002'})
      }

       componentDidMount() {
        const propArr = this.props.match.params.infocompany.split(',')
this.setState({
  base_conversion_rate: propArr[5]
})
      }

handleYes = async () => {
  const propArr = this.props.match.params.infocompany.split(',')
  const initialReserved = parseFloat(propArr[2])
  const companyBalance = parseFloat(propArr[1])
const reservedBalance = fixRounding(percentage(companyBalance, this.state.sliderValue), 2)
const sumBalance = fixRounding(initialReserved + reservedBalance, 2)
  const requestObj: GoldenBoostModel = {
    reserved_coins: sumBalance.toString(),
    max_points: propArr[3],
    conversion: this.state.new_conversion_rate + ',' + this.state.base_conversion_rate
  }
try {
  this.setState({loadingbutton: true})
  await boostGolden(this.props.auth.getIdToken(), requestObj)
  this.setState({loadingbutton: false})
} catch (error) {
  this.setState({loadingbutton: false})
  console.log(error)
}
  this.props.history.replace(`/golden/${'allocation'}/confirmation`)
}

handleNo = () => {
  this.setState({confirmState: false})
}

handleChangeSlider = (value: number) => {
  if (!this.state.confirmState) {
    if (value >= 90) {
      this.setState({sliderValue: 90})
    } else {
      this.setState({sliderValue: value})
    }
  }

}

handleReturn = () => {
  this.props.history.replace(`/golden`)
}

renderCancel() {
  return (
    <Grid container columns="equal" centered relaxed verticalAlign="middle" style={{ margin: '0.5em'}}>
    <Grid.Row columns={1} centered style={{margin: '0.5em'}}>
                  <Grid.Column textAlign="center">
                  <Button
                          loading={this.state.loadingbutton}
                          color="violet"
                          fluid
                          className="action-button"      
                          onClick={this.handleReturn}                    
                        >
                          Cancel
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
  )
}

          renderGolden() {
            let checkUpgrade = false            
            const propArr = this.props.match.params.infocompany.split(',')
            const minimum = parseFloat(propArr[3])
            const oldNewConv = propArr[4]
            const baseConv = parseFloat(this.state.base_conversion_rate)
            const companyBalance = parseFloat(propArr[1])
            const initialReserved = parseFloat(propArr[2])
            const reservedBalance = fixRounding(percentage(companyBalance, this.state.sliderValue), 2)
            const sumBalance = fixRounding(initialReserved + reservedBalance, 2)
            let newConv = this.state.base_conversion_rate
            let percResult = 0
              percResult = fixRounding((sumBalance * baseConv)/minimum, 8)            
newConv = percResult.toString()
            if (percResult < 0.00005) {
newConv = '0.00005'
}
  if (newConv !== oldNewConv && sumBalance > 0) {
checkUpgrade = true
  }
  let convTax = 0
if (sumBalance > 0) {
  convTax = fixRounding((1000 * parseFloat(newConv)), 2)
}
  
            return (
               <div>
            <HeaderCustom headerType="golden_boost"/>
                <Grid container columns="equal" centered relaxed verticalAlign="middle">
                <Grid.Row columns={1} centered>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                        <div>
                        <Grid container  centered relaxed verticalAlign="middle" style={{ marginTop: '-0.1em'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divBadgeStyle}>
      <Image size='medium' centered src={newGoldenBadgeLargeSrc} />
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Balance Available for Allocation</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{companyBalance}</strong> Golden Coins
              </div>
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.8rem', fontWeight: "bold"}}>Max. 90%</label>
              <div style={{textAlign: 'center', margin: '0.4em'}}>
              <SingleRangeSlider value={this.state.sliderValue} color="violet" defaultMinValue={0} defaultMaxValue={100} step={1} onChange={this.handleChangeSlider} />
              </div>
              <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>{this.state.sliderValue.toString() + ' %'}</label>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <div style={{
        width: "100%",
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Current Allocation</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{initialReserved}</strong> Golden Coins
              </div>
      </div>
      <div style={{
        width: "100%",
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>New Allocation</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{reservedBalance}</strong> Golden Coins
              </div>
      </div>
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Total Allocation</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              <strong>{sumBalance}</strong> Golden Coins
              </div>
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Boost Mode</label>
      <div style={{fontSize: '0.8rem', fontWeight: "bold"}}>Choose your Conversion Rate:</div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      {this.state.base_conversion_rate === '0.00005' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Minimum Level`} trigger={<Button
                    color = "orange"
                      size="small"
                      className="yes-button"
                      onClick={this.handleBase}
                    >
                      Base
                    </Button>}/>}
                    {this.state.base_conversion_rate !== '0.00005' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Minimum Level`} trigger={<Button
                    style={{backgroundColor: '#882501', color: '#767676'}}
                      size="small"
                      className="yes-button"
                      onClick={this.handleBase}
                    >
                      Base
                    </Button>}/>}
                    {this.state.base_conversion_rate === '0.0001' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Medium Level`} trigger={<Button
                    color = "orange"
                      size="small"
                      className="yes-button"
                      onClick={this.handleTop}
                    >
                      Top
                    </Button>}/>}
                    {this.state.base_conversion_rate !== '0.0001' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Medium Level`} trigger={<Button
                    style={{backgroundColor: '#882501', color: '#767676'}}
                      size="small"
                      className="yes-button"
                      onClick={this.handleTop}
                    >
                      Top
                    </Button>}/>}
                    {this.state.base_conversion_rate === '0.0002' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Maximum Level`} trigger={<Button
                    color = "orange"
                      size="small"
                      className="yes-button"
                      onClick={this.handleMax}
                    >
                      Max
                    </Button>}/>}                    
                    {this.state.base_conversion_rate !== '0.0002' && <Popup content={`This option sets the Vip/Golden Conversion Rate to the Maximum Level`} trigger={<Button
                    style={{backgroundColor: '#882501', color: '#767676'}}
                      size="small"
                      className="yes-button"
                      onClick={this.handleMax}
                    >
                      Max
                    </Button>}/>}
      </div>
      <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em'
}}>
             <strong>1000</strong> - Max VVIP Points for Session = <strong>{convTax}</strong> Golden Coins
              </div>
      </div>      
      {!this.state.confirmState && reservedBalance >= minimum && 
      <UserButton
      cssName="golden-button"
      onClick={this.handleConfirm}
      idArray={[newConv]}
      disableCheck={false}
    />}
                        {!this.state.confirmState && reservedBalance < minimum && !checkUpgrade && <Popup content={`You need to allocate a minimum of ${minimum} Golden Coins`} trigger={<UserButton
      cssName="golden-button"
      onClick={this.handleConfirm}
      idArray={[newConv]}
      disableCheck={true}
    />} />}
    {!this.state.confirmState && reservedBalance < minimum && checkUpgrade && initialReserved >= minimum && <UserButton
      cssName="golden-button"
      onClick={this.handleConfirm}
      idArray={[newConv]}
      disableCheck={false}
    />}
    {!this.state.confirmState && reservedBalance < minimum && checkUpgrade && initialReserved < minimum && <Popup content={`You need to allocate a minimum Total of ${minimum} Golden Coins or change your Boost Mode to Confirm`} trigger={<UserButton
      cssName="golden-button"
      onClick={this.handleConfirm}
      idArray={[newConv]}
      disableCheck={true}
    />} />}
                        {this.state.confirmState && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Are you sure?</label>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <Button
                    color = "orange"
                      size="medium"
                      loading={this.state.loadingbutton}
                      className="yes-button"
                      onClick={this.handleYes}
                    >
                      Yes
                    </Button>
                    <Button
                    color = "orange"
                      size="medium"
                      loading={this.state.loadingbutton}
                      className="no-button"
                      onClick={this.handleNo}
                    >
                      No
                    </Button>
      </div>
      </div>}      
    </Form>
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            <Modal
      onClose={() => {this.setState({startModal:false})}}
      onOpen={() => {this.setState({startModal:true})}}
      open={this.state.startModal}
      size='small'
    >
      <Header icon><Icon color='violet' name='bolt' />Power Up your Training!</Header>
      
      <Modal.Content>
      <p>With this Feature you will be able to reward your <strong>employees</strong> with <strong>Golden Coins</strong>!<br /> Choose <strong>how much balance you want to reserve as reward</strong> for your employees and confirm. <br /> From now on all <strong>Virtual Vip points</strong> gained by your employees will be converted to <strong>Golden Coins</strong> and your employees will <strong>Climb the Rankings</strong>!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={() => {this.setState({startModal:false})}}>
         <Icon name='checkmark' /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
    <Modal
      onClose={() => {this.setState({endModal:false})}}
      onOpen={() => {this.setState({endModal:true})}}
      open={this.state.endModal}
      size='small'
    >
      <Header icon><Icon color='violet' name='attention' />Attention!</Header>
      
      <Modal.Content>
      <p><strong>The Total Allocated Sum won't be immediately deducted from your Balance</strong> but it will be distributed to your <strong>employees</strong> as they keep training with Virtual Vip.<br />You are <strong>free to use your Reserved Balance on Atlanticoin Platform</strong>, but the rewards will be reduced too.</p><p>Remember that <strong>you can always Allocate more Tokens</strong> to boost the training of your employees!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='violet' onClick={() => {this.setState({endModal:false})}}>
         <Icon name='checkmark' /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
    </div>
    </Grid.Column>
              </Grid.Row>            
            </Grid>
            </div>
            )
          }

  render() {
return(
  <div>
{this.renderGolden()}

{this.renderCancel()}
  </div>  
)
  }
}
