import * as React from 'react'
import { Grid, Button, Header, Message, Checkbox } from 'semantic-ui-react'
import { deleteUser } from '../api/users-api'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'

interface DeleteUserProps {
  match: {
    params: {
      idArray: string
    }
  },
  auth: Auth,
  history: any
}

interface DeleteUserState {
  deletingUser: boolean,
  modeSelection: boolean,
  awsStatus: boolean,
  gameStatus: boolean,
  userAlert: boolean
}

export class DeleteUser extends React.PureComponent<
  DeleteUserProps,
  DeleteUserState
> {
  state: DeleteUserState = {
    deletingUser: false,
    modeSelection: true,
    awsStatus: false,
    gameStatus: false,
    userAlert: false
  }

  componentDidMount() {
    let idtoken = this.props.auth.getIdToken()
    let username = idtoken.payload.sub
    let arr: any = this.props.match.params.idArray.split(',')
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element === username) {
        this.setState({userAlert: true})
      }
    }
  }

  handleNo = () => {
    let arr: any = this.props.match.params.idArray.split(',')
    if (arr[arr.length - 1] === 'redemption') {
      this.props.history.replace(`/redemption`)
    } else {
      this.props.history.replace(`/`)
    }
  }

  handleConfirm = () => {
    if (!this.state.awsStatus && !this.state.gameStatus) {
      alert('Choose one mode at least!')
          } else {
            this.setState({modeSelection: false})
          }
  }

  gameToggle = () => {
    this.setState((prevState) => ({ gameStatus: !prevState.gameStatus}))
  }

  awsToggle = () => {
    this.setState((prevState) => ({ awsStatus: !prevState.awsStatus}))
  }

  handleYes = async () => {
    let idArr = this.props.match.params.idArray
    this.state.gameStatus ? idArr += ',game' : idArr += ''
    this.state.awsStatus ? idArr += ',aws' : idArr += ''
    if (!this.state.awsStatus && !this.state.gameStatus) {
alert('Choose one mode at least!')
    }
    try {
      this.setDeleteState(true)
      await deleteUser(
        this.props.auth.getIdToken(),
        idArr
      )
      alert('User was deleted!')
    } catch (e) {
      alert('Could not delete the user: ')
    } finally {
      this.setDeleteState(false)
      this.props.history.replace(`/`)
    }
  }

  setDeleteState(deletingUser: boolean) {
    this.setState({
      deletingUser
    })
  }

  renderMode() {
    return (
      <div>
        <HeaderCustom headerType="delete"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
          <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white"}}>
          <Grid.Row columns={1}>
            <Grid.Column style={{fontWeight: "bold", fontSize: '0.7rem', color: "#6435c9"}}>Deletion Mode</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{margin: "0.6em"}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9'}}>AWS</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9'}}>GAME</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column><Checkbox
                    toggle
                    onChange={this.awsToggle}
                    checked={this.state.awsStatus}
                  /></Grid.Column>
            <Grid.Column><Checkbox
                    toggle
                    onChange={this.gameToggle}
                    checked={this.state.gameStatus}
                  /></Grid.Column>
          </Grid.Row>
          </Grid>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "orange"
                size="large"
                loading={this.state.deletingUser}
                className="yes-button"
                onClick={this.handleConfirm}
              >
                Confirm
              </Button>
            </Grid.Column>
          </Grid.Row>
          {this.state.awsStatus && <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center"><Message color='violet' size="large"><Message.Header>Warning!</Message.Header><p>If you selected COMPANY accounts with AWS option activated, all their EMPLOYEES will be deleted too!</p></Message></Grid.Column>
          </Grid.Row>}
          </Grid>
      </div>
    )
  }
  
  renderDelete() {
    return (
      <div>
        <HeaderCustom headerType="delete"/>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center"><Header size="large">Are you sure?</Header></Grid.Column>
          </Grid.Row>          
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <Button
              color = "violet"
                size="large"
                loading={this.state.deletingUser}
                className="yes-button"
                onClick={this.handleYes}
              >
                Yes
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color = "violet"
                size="large"
                className="no-button"
                onClick={this.handleNo}
              >
                No
              </Button>
            </Grid.Column>
          </Grid.Row>
          {this.state.userAlert && <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center"><Message warning size="large"><Message.Header>Attention!</Message.Header><p>You selected your PERSONAL ACCOUNT. If you proceed, your account will be removed from Virtual Vip.</p></Message></Grid.Column>
          </Grid.Row>}
          </Grid>
      </div>
    )
  }

  render() {
    let arr: any = this.props.match.params.idArray.split(',')
    if (arr[arr.length - 1] === 'redemption') {
      return this.renderDelete()
    } else {
      if (this.state.modeSelection) {
return this.renderMode()
      } else {
return this.renderDelete()
      }
    }    
  }
}
