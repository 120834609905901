import * as React from 'react'
import { Checkbox,Grid } from 'semantic-ui-react'
import { UserModel } from '../types/UserModel'

interface CheckElementCampaignProps {
    userId: string,
    onUnCheck: any,
    onCheck: any,
    onSelectCheck: boolean,
    onSelectClear: boolean,
    user: UserModel,
    userEmail: string
  }
  
  interface CheckElementCampaignState {
      checked: boolean,
      checkselect: boolean
  }
  
  export class CheckElementCampaign extends React.PureComponent<CheckElementCampaignProps, CheckElementCampaignState> {
    state: CheckElementCampaignState = {
        checked: false,
        checkselect: false
      }
    toggle = () => {this.setState((prevState: any) => ({ checked: !prevState.checked }))}
    handleCheck = () => {this.props.onCheck(this.props.userEmail)}
    handleUnCheck = () => {this.props.onUnCheck(this.props.userEmail)}
    shouldComponentUpdate(nextProps: CheckElementCampaignProps, nextState: CheckElementCampaignState) {
      if (this.props.onSelectCheck !== nextProps.onSelectCheck) {
          this.setState({ checked: true, checkselect: !this.state.checkselect })
          return false
      }
    if (this.props.onSelectClear !== nextProps.onSelectClear) {
        this.setState({ checked: false, checkselect: !this.state.checkselect })
        return false
    }    
    if (this.state.checkselect === nextState.checkselect) {
      if (this.state.checked !== nextState.checked) {
        if (nextState.checked) {
    this.handleCheck()
    } else {
      this.handleUnCheck()
      }
    }
  }
return true  
}
    render() {
      return (
        <Grid.Row column={7}>
              <Grid.Column style={{padding: "6px", overflow: "auto"}}>{!this.props.user.type && 'unconfirmed'}{this.props.user.type === 'user' && 'consumer'}{this.props.user.type === 'company' && 'business'}{this.props.user.type !== 'user' && this.props.user.type !== 'company' && this.props.user.type}</Grid.Column><Grid.Column style={{padding: "6px", overflow: "auto"}}>{this.props.user.name}</Grid.Column><Grid.Column style={{padding: '6px', overflow: 'auto'}}>{this.props.user.company_name}</Grid.Column><Grid.Column style={{padding: '6px', overflow: 'auto'}}>{this.props.user.email}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.timepayment}</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '6px'}}>{this.props.user.timepayment === 'unconfirmed' && 'unconfirmed'}{(this.props.user.type !== 'admin' && this.props.user.timepayment !== 'unconfirmed') && new Date(parseInt(this.props.user.payment)).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})}{this.props.user.type === 'admin' && 'none'}</Grid.Column>
              <Grid.Column style={{padding: "6px", overflow: "auto"}}>
                <Checkbox
                checked={this.state.checked}
          onClick={this.toggle}
        />
        </Grid.Column>
        </Grid.Row>
      )
    }
  }