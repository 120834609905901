import { UserModel } from '../types/UserModel'
import { CreateUserModel } from '../types/CreateUserModel'
import { CouponModel, CouponReq, CouponReqBody } from '../types/CouponModel'
import { subscriptionModel } from '../types/subscriptionModel'
import { PayConfirmModel } from '../types/PayConfirmModel'
import { CampaignModelApi, CampaignModel } from '../types/CampaignModel'
import { TicketMessageModel, TicketMessageModelApi } from '../types/TicketMessageModel'
import { SaveCardModel } from '../types/SaveCardModel'
import { GoldenBoostModel, GoldenCreateModel, GoldenUserModel } from '../types/GoldenCoinModel'
import { InvoiceListModel, InvoiceRequest } from '../types/InvoiceModel'

const { apiEndpoint } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)
const headers: Record<string, string> = process.env.REACT_APP_AWS_ACCOUNT === 'main' ? {'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': ''} : {'Access-Control-Allow-Origin': 'true', 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': ''}

export async function getUsers(Token: any): Promise<UserModel[]> {
  console.log('Fetching users')
  let response: any
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  try {
    response = await fetch(`${apiEndpoint}/vvirtuals/all`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user error')
    throw new Error('getting users error: ')
  }
  console.log('Fetching user done')
  if (!response.ok) {
    console.log('Fetching user error')
    throw new Error('getting users error: status')
  }
  console.log('response',{response})
  const result = await response.json()
  return result.items
}

export async function getInfoUser(
  Token: any,
  userId: string
): Promise<UserModel> {
  let idToken = Token.jwtToken
  console.log('Fetching user info')
  console.log('Bearer ' + idToken)
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/vvirtuals/info`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        objectid: userId
      })
    })
  } catch (error) {
    console.log('Fetching user info error ' + error)
    throw new Error('getting user info error: ' + error)
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  console.log('response',{response})
  const result = await response.json()
  return result.infoItem
}

export async function createUser(
  Token: any,
  User: CreateUserModel
): Promise<UserModel> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Creating user')
  let reply: any
  try {
    reply = await fetch(`${apiEndpoint}/vvirtuals/create`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: User.id,
        name: User.name,
        email: User.email,
        payment: User.payment,
        usergroup: User.usergroup,
        renew: User.renew,
        charge: User.charge,
        numberofusers: User.numberofusers,
        usersactivated: User.usersactivated,
        timepayment: User.timepayment,
        coupon: User.coupon,
        exgroup: User.exgroup,
        company_name: User.company_name,
        nickname: User.nickname,
        family_name: User.family_name
      })
    })
  } catch (error) {
    console.log('Creating user error')
    throw new Error('creating user error: ')
  }
  console.log('Creating user done')
  if (!reply.ok) {
    console.log('Creating user error')
    throw new Error('creating user error: status')
  }
  const result = await reply.json()
  return result.newItem
}

export async function deleteUser(
  Token: any,
  userIdArray: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Deleting user')
    reply = await fetch(`${apiEndpoint}/vvirtuals/${userIdArray}/delete`, {
      method: 'DELETE',
      headers: headers
    })
    console.log('Deleting user done')
  } catch (e) {
    console.log('Deleting user error: ')
    throw new Error('deleting item error: ')
  }
  if (!reply.ok) {
    console.log('Deleting user error')
    throw new Error('deleting item error: status')
  } else {
    console.log('Deleting user done')
    return 
  }
}

export async function saveUserEdit(
  Token: any,
  renew: string,
  group: string,
  method: string,
  email: string,
  orderid: string,
  charge: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Updating user edit', {renew, group, email, charge, orderid, method})
    reply = await fetch(`${apiEndpoint}/vvirtuals/saveuseredit`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        renew,
        group,
        method,
        email,
        orderid,
        charge
      })
    })
    console.log('Updating user edit done')
  } catch (e) {
    console.log('Updating user edit error: ')
    throw new Error('Updating user edit error: ')
  }
  if (!reply.ok) {
    console.log('Updating user edit error')
    throw new Error('Updating user edit error: status')
  } else {
    console.log('Updating user edit done')
    return 
  }
}

export async function sendEmail(
  Token: any,
  userIdArray: string,
  message: string,
  subject: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Sending email')
    reply = await fetch(`${apiEndpoint}/vvirtuals/message`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        message,
        subject,
        usernames: userIdArray
      })
    })
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!reply.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  } else {
    console.log('Sending email done')
    return 
  }
}

export async function refundStripe(
  Token: any,
  order_id: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Sending email')
    reply = await fetch(`${apiEndpoint}/payment/refund`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        order_id
      })
    })
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!reply.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  } else {
    console.log('Sending email success!')
    return
  }
}

export async function deleteCoupon(
  Token: any,
  Id: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  const couponarray = Id.split(',')
  const reqBody: CouponReqBody = {
    couponarray
  }
  try {
    console.log('Deleting user ' + Id)
    reply = await fetch(`${apiEndpoint}/coupons/delete`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(reqBody)
    })
    console.log('Deleting coupon done')
  } catch (e) {
    console.log('Deleting coupon error: ')
    throw new Error('deleting coupon error: ')
  }
  if (!reply.ok) {
    console.log('Deleting coupon error')
    throw new Error('deleting coupon error: status')
  } else {
    console.log('Deleting coupon done')
    return 
  }
}

export async function createCoupon(
  Token: any,
  reqObj: CouponReq
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let result: any
  let response: any
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/coupons/create`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(reqObj)
    })
    result = await response.json()
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  }
  return result.keycode
}

export async function getCoupons(
  Token: any
): Promise<CouponModel[]> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/coupons`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.coupons
}

export async function updateCoupons(
  Token: any
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/coupons/update`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  return 'success'
}

export async function getRedemptionUsers(
  Token: any
): Promise<any> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/redemption`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.users
}

export async function saveCard(
  Token: any,
  paymentId: string,
  customer: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  let result: any
  const obj: SaveCardModel = {
    paymentId,
    customer
  }
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/payment/save`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
    })
    result = await response.json()
    console.log('Sending email done', {result})
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  } else {
    return
  }  
}

function onSubscriptionComplete(result: subscriptionModel) {
  // Payment was successful.
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.
  return result
}

 function handlePaymentThatRequiresCustomerAction({
  subscription,
  stripe,
  priceId,
  customerid,
  paymentMethodId,
  token
}: any) {
  if (subscription.result && (subscription.result.status === 'active' || subscription.result.status === 'trialing')) {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId, customerid };
  }
  console.log('subscriptionObj', {subscription})
  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = subscription.result.latest_invoice.payment_intent;
  console.log('intent', {paymentIntent})
  if (
    paymentIntent.status === 'requires_action') {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result: any) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (that is, insufficient funds, card has expired, etc).
          throw new Error(result.error)
        } else {
          console.log('resultconfirm: ', {confirmResult: result})
          if (result.paymentIntent.status === 'succeeded') {
          
            return { subscription, priceId, paymentMethodId, customerid, token };
          } else {
            throw new Error('Confirm Payment Failed')
          }
        }
      })
      .catch((error: any) => {
        console.log('require customer error: ' + error.message)
throw new Error(error)
      });
  } else {
    // No customer action needed.
    return { subscription, priceId, paymentMethodId, customerid, token };
  }
}

async function handleRequiresPaymentMethod({
  subscription,
  paymentMethodId,
  priceId,
  customerid,
  token
}: any) {
  if (subscription.result.status === 'active' || subscription.result.status === 'trialing') {
    // subscription is active, no customer actions required.
    return { customerid, subscription, priceId, paymentMethodId };
  } else if (
    subscription.result.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    headers.Authorization = `Bearer ${token}`
    await fetch(`${apiEndpoint}/payment/delete`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        paymentMethodId: paymentMethodId
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // The card had an error when trying to attach it to a customer.
          throw new Error('Invalid Card! Payment declined')
        }
        console.log('response requires payment: ', {responseRequire: response})
        return response;
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        console.log(result.json());
      })
      throw new Error('Invalid card! Payment declined')    
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
  } else {
    return { subscription, priceId, paymentMethodId, customerid };
  }
}

export async function createStripeSubscription(Token:any, object: subscriptionModel) {
  let paymentMethodId = 'none'
  let priceId = 'none'
  let customerid = 'none'
  let email = 'none'
  let useredit = 'no'
  let upgradeExp = null
  let invoice_data = null
  let idToken = Token.jwtToken
  if (object.paymentMethodId) {
    paymentMethodId = object.paymentMethodId
  }
  if (object.priceId) {
    priceId = object.priceId
  }
  if (object.customerid) {
    customerid = object.customerid
  }
  if (object.email) {
    email = object.email
  }
  if (object.useredit) {
    useredit = object.useredit
  }
  if (object.upgradeExp) {
    upgradeExp = object.upgradeExp
  }
  if (object.invoice_data) {
    invoice_data = object.invoice_data
  }
  headers.Authorization = `Bearer ${idToken}`
  return (
    await fetch(`${apiEndpoint}/payment/createsubscription`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        paymentMethodId,
        priceId,
        customerid,
        email,
        useredit,
        amount: 'none',
        upgradeExp,
        invoice_data
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // The card had an error when trying to attach it to a customer.
          throw new Error('error')
        }
        return response
      })
      // If the card is declined, display an error to the user.
      .then((result) => {        
        return result.json();
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        console.log('resultObj', result)
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
          customerid: result.customerid,
          stripe: object.stripe,
          token: idToken
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        console.log('final result error: ' + error.message)
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        throw new Error(error)
      })
  );
}

export async function createStripeOrder(Token: any, object: subscriptionModel) {
  let paymentMethodId = 'none'
  let amount = 'none'
  let customerid = 'none'
  let email = 'none'
  let invoice_data = null
  let idToken = Token.jwtToken
  if (object.paymentMethodId) {
    paymentMethodId = object.paymentMethodId
  }
  if (object.amount) {
    amount = object.amount
  }
  if (object.customerid) {
    customerid = object.customerid
  }
  if (object.email) {
    email = object.email
  }
  if (object.invoice_data) {
    invoice_data = object.invoice_data
  }
  headers.Authorization = `Bearer ${idToken}`
  return (
    await fetch(`${apiEndpoint}/payment/createorder`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        paymentMethodId,
        amount,
        customerid,
        email,
        invoice_data
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // The card had an error when trying to attach it to a customer.
          throw new Error('error')
        }
        return response.json()
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        return result
      })      
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        throw new Error(error)
      })
  );
}

export async function deletePayment(
  Token: any, id: string
): Promise<void> {
  let idToken = Token.jwtToken
  try {
    console.log('Sending email')
    headers.Authorization = `Bearer ${idToken}`
    await fetch(`${apiEndpoint}/payment/delete`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        paymentMethodId: id
      }),
    })
    .then((response) => {
      if (!response.ok) {
        // The card had an error when trying to attach it to a customer.
        throw new Error('error')
      }
      return response.json()
    })
      // If the card is declined, display an error to the user.
      .then((result) => {
        console.log('Sending email done: ', result)
      })
    return
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
}

export async function cancelPayment(
  Token: any,
  id: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  try {
    console.log('Sending email')
    await fetch(`${apiEndpoint}/payment/cancel`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        paymentIntentId: id
      }),
    })
    .then((response) => {
      if (!response.ok) {
        // The card had an error when trying to attach it to a customer.
        throw new Error('error')
      }
      return response.json()
    })
      // If the card is declined, display an error to the user.
      .then((result) => {
        console.log('Sending email done: ', result)
      })
    return
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
}

export async function checkPayment(
  Token: any, email: string
): Promise<any> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let result_id = 'none'
  try {
    console.log('Sending email')
    await fetch(`${apiEndpoint}/payment/check`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        email
      }),
    })
    .then((response) => {
      if (!response.ok) {
        // The card had an error when trying to attach it to a customer.
        throw new Error('error')
      }
      return response.json()
    })
      // If the card is declined, display an error to the user.
      .then((result) => {
          console.log('Sending email done, customerid: ' + result.customer_id)
          result_id = result.customer_id
      })
      return result_id
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
}

export async function getPaypalPlan(
  Token: any, email: string
): Promise<string> {
  let resultFinal: string = 'none'
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  try {
    console.log('Sending email')
    await fetch(`${apiEndpoint}/payment/paypal`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        email
      }),
    })
    .then((response) => {
      if (!response.ok) {
        // The card had an error when trying to attach it to a customer.
        throw new Error('error')
      }
      return response.json()
    })
      // If the card is declined, display an error to the user.
      .then((result) => {
        resultFinal = result.planid
      })
    console.log('Sending email done')
    if (resultFinal === 'none') {
      console.log('Sending email error: ')
      throw new Error('sending email error: ')
    }
    return resultFinal
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
}

export async function paymentConfirmation(
  Token: any,
  object: PayConfirmModel
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/payment/confirm`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        object
      )
    })
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  }
  const result = await response.json()
  return result.keycode
}

export async function getSubscriptions(
  Token: any
): Promise<any[]> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/payment/subscriptions`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.subscriptions
}

export async function checkUpgrade(
  Token: any,
  charge: string,
  email: string,
  initialcharge: string
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/payment/upgrade`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        charge,
        email,
        initialcharge
      })
    })
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  } else {
    const result = await response.json()
    return result.charge
  }
}

export async function paypalSubscription(
  Token: any,
  start: string,
  plan: string,
  id: string
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/payment/paypalsub`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        start,
        plan,
        id
      })
    })
    console.log('Sending email done')
  } catch (e) {
    console.log('Sending email error: ')
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  } else {
    const result = await response.json()
    return result
  }
}

export async function createCampaign(
  Token: any,
  Campaign: CampaignModelApi
): Promise<string> {
  let idToken = Token.jwtToken
  let error: any = null
  headers.Authorization = `Bearer ${idToken}`
  let response: any
  try {
    console.log('Creating coupon')
    response = await fetch(`${apiEndpoint}/campaigns/create`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        Campaign
      )
    })
    console.log('Sending email done')
  } catch (e) {
    error = e
    console.log('Sending email error', {error})
    throw new Error('sending email error: ')
  }
  if (!response.ok) {
    console.log('Sending email error')
    throw new Error('sending email error: status')
  }
  return 'success'
}

export async function getCampaigns(
  Token: any
): Promise<CampaignModel[]> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/campaigns`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.campaigns
}

export async function updateCampaign(
  Token: any,
  Status: string,
  Id: string
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/campaigns/update`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        Status,
        Id
      })
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  return 'success'
}

export async function deleteCampaign(
  Token: any,
  ruleId: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Deleting user')
    reply = await fetch(`${apiEndpoint}/campaigns/${ruleId}/delete`, {
      method: 'DELETE',
      headers: headers
    })
    console.log('Deleting user done')
  } catch (e) {
    console.log('Deleting user error: ')
    throw new Error('deleting item error: ')
  }
  if (!reply.ok) {
    console.log('Deleting user error')
    throw new Error('deleting item error: status')
  } else {
    console.log('Deleting user done')
    return 
  }
}

export async function restartCampaign(
  Token: any,
  ruleId: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Deleting user')
    reply = await fetch(`${apiEndpoint}/campaigns/${ruleId}/restart`, {
      method: 'GET',
      headers: headers
    })
    console.log('Deleting user done')
  } catch (e) {
    console.log('Deleting user error: ')
    throw new Error('deleting item error: ')
  }
  if (!reply.ok) {
    console.log('Deleting user error')
    throw new Error('deleting item error: status')
  } else {
    console.log('Deleting user done')
    return 
  }
}

export async function pauseCampaign(
  Token: any,
  ruleId: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Deleting user')
    reply = await fetch(`${apiEndpoint}/campaigns/${ruleId}/pause`, {
      method: 'GET',
      headers: headers
    })
    console.log('Deleting user done')
  } catch (e) {
    console.log('Deleting user error: ')
    throw new Error('deleting item error: ')
  }
  if (!reply.ok) {
    console.log('Deleting user error')
    throw new Error('deleting item error: status')
  } else {
    console.log('Deleting user done')
    return 
  }
}

export async function getTickets(
  Token: any
): Promise<TicketMessageModel[]> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/tickets`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.tickets
}

export async function getInvoices(
  Token: any
): Promise<InvoiceListModel[]> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching invoice info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/payment/invoices`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.items
}

export async function downloadInvoice(
  Token: any,
  requestObj: InvoiceRequest
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching invoice info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/payment/invoices`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        requestObj
      )
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.url
}

export async function closeTicket(
  Token: any,
  Id: string
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/tickets/close`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        Id
      })
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  return 'success'
}

export async function deleteTicket(
  Token: any,
  Id: string
): Promise<void> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  let reply: any
  try {
    console.log('Deleting user')
    reply = await fetch(`${apiEndpoint}/tickets/${Id}/delete`, {
      method: 'DELETE',
      headers: headers
    })
    console.log('Deleting user done')
  } catch (e) {
    console.log('Deleting user error: ')
    throw new Error('deleting item error: ')
  }
  if (!reply.ok) {
    console.log('Deleting user error')
    throw new Error('deleting item error: status')
  } else {
    console.log('Deleting user done')
    return 
  }
}

export async function updateTicket(
  Token: any,
  TicketObj: TicketMessageModelApi
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/tickets/update`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        TicketObj
      )
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  return 'success'
}

export async function createTicket(
  Token: any,
  TicketObj: TicketMessageModelApi
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Fetching user info')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/tickets/create`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        TicketObj
      )
    })
  } catch (error) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: ')
  }
  console.log('Fetching user info done')
  if (!response.ok) {
    console.log('Fetching user info error')
    throw new Error('getting user info error: status')
  }
  const result = await response.json()
  return result.id
}

export async function createGolden(
  Token: any,
  GoldenObj: GoldenCreateModel
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Creating golden coin account')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/golden/create`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        GoldenObj
      )
    })
  } catch (error) {
    console.log('Creating golden coin account error')
    throw new Error('Creating golden coin account error: ')
  }
  console.log('Creating golden coin account done')
  if (!response.ok) {
    console.log('Creating golden coin account error')
    throw new Error('Creating golden coin account error')
  }
  const result = await response.json()
  return result
}

export async function getGolden(
  Token: any,
  id: string
): Promise<GoldenUserModel> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Getting golden coin account')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/golden/${id}/info`, {
      method: 'GET',
      headers: headers
    })
  } catch (error) {
    console.log('Getting golden coin account error')
    throw new Error('Getting golden coin account error: ')
  }
  console.log('Getting golden coin account done')
  if (!response.ok) {
    console.log('Getting golden coin account error')
    throw new Error('Getting golden coin account error')
  }
  const result = await response.json()
  return result
}

export async function boostGolden(
  Token: any,
  GoldenObj: GoldenBoostModel
): Promise<string> {
  let idToken = Token.jwtToken
  headers.Authorization = `Bearer ${idToken}`
  console.log('Boosting golden coin account')
  let response: any
  try {
    response = await fetch(`${apiEndpoint}/golden/boost`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        GoldenObj
      )
    })
  } catch (error) {
    console.log('Boosting golden coin account error')
    throw new Error('Boosting golden coin account error: ')
  }
  console.log('Boosting golden coin account done')
  if (!response.ok) {
    console.log('Boosting golden coin account error')
    throw new Error('Boosting golden coin account error')
  }
  const result = await response.json()
  return result
}