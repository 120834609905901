import * as React from 'react'
import { Grid, Divider, Header, Loader, Icon } from 'semantic-ui-react'
import { getSubscriptions, getUsers } from '../api/users-api'
import  { Auth } from '../auth/Auth'
const { currency } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface PaymentAdminsProps {
  auth: Auth,
  history: any
}

interface PaymentAdminsState {
  subscriptions: any[],
  loading: boolean,
  year: string
}

export class PaymentAdmins extends React.PureComponent<
  PaymentAdminsProps,
  PaymentAdminsState
> {
  state: PaymentAdminsState = {
    loading: true,
    subscriptions: [],
    year: new Date().getFullYear().toString()
  }

  async componentDidMount() {    
    let subscriptionArrFormatted: any[] = []
    try {
      const users = await getUsers(this.props.auth.getIdToken())
const subscriptions = await getSubscriptions(this.props.auth.getIdToken())
for (let index = 0; index < subscriptions.length; index++) {
  let subscriptionFormatted = {
    id: '',
    type: '',
    user: '',
    expiration: '',
    amount: '',
    email: '',
    renew: '',
    method: '',
    customerid: '',
    planId: '',
    start: '',
    numberofusers: '',
    duration: '',
    stamp: '',
    name: ''
  }
  console.log('id : ' + subscriptions[index].id.S)
  console.log('customer_id : ' + subscriptions[index].customerid.S)
  let dateFormattedExp = new Date(parseInt(subscriptions[index].expiration.S))
  let dateFormattedStart = new Date(parseInt(subscriptions[index].start.S))
  subscriptionFormatted.start = dateFormattedStart.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  subscriptionFormatted.expiration = dateFormattedExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  subscriptionFormatted.id = subscriptions[index].id.S
  subscriptionFormatted.user = subscriptions[index].user.S
  subscriptionFormatted.amount = subscriptions[index].amount.S
  subscriptionFormatted.email = subscriptions[index].email.S
  subscriptionFormatted.renew = subscriptions[index].renew.S
  subscriptionFormatted.method = subscriptions[index].method.S
  subscriptionFormatted.customerid = subscriptions[index].customerid.S
  subscriptionFormatted.planId = subscriptions[index].planId.S
  subscriptionFormatted.numberofusers = subscriptions[index].numberofusers.S
  subscriptionFormatted.type = subscriptions[index].type.S
  subscriptionFormatted.duration = subscriptions[index].duration.S
  subscriptionFormatted.stamp = subscriptions[index].stamp.S
  for (let i = 0; i < users.length; i++) {
    const element = users[i];
    if (element.email === subscriptionFormatted.email) {
      if (element.type === 'company') {
        element.company_name ? subscriptionFormatted.name = element.company_name : subscriptionFormatted.name = 'unconfirmed'
      } else {
        subscriptionFormatted.name = element.name
      }      
    }
  }
  if (!subscriptionFormatted.name) {
    subscriptionFormatted.name = 'Account Deleted'
  }
  console.log('subscriptionobj: ', subscriptionFormatted)
  subscriptionArrFormatted.push(subscriptionFormatted)
}
this.setState({ subscriptions: subscriptionArrFormatted, loading: false, year: new Date().getFullYear().toString() })
  } catch (e) {
    console.log(e);
        this.setState({loading: false})
            alert('Getting subscriptions error');
  }
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Subscriptions
        </Loader>
      </Grid.Row>
    )
  }

  handleThisYear = async (event: React.ChangeEvent<HTMLSelectElement>) => {
this.setState({year: event.target.value})
  }

  renderSubscriptionList() {
    return (
      <div>
          <Header style={{marginBottom: "-20px"}} size='small' icon color='violet'>
    <Icon name='payment' size='small' />
    Cashflow
    <Header.Subheader>
    Billings Details
    </Header.Subheader>
  </Header>
        <Grid container  celled verticalAlign="middle" columns={7} style={{ backgroundColor: "white", maxHeight: '20vh', overflowY: 'auto'}}>
              <Grid.Row columns={1}>
                <Grid.Column style={{fontWeight: "bold", fontSize: '12pt', padding: '6px'}}>Subscriptions activated</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={8}>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Name</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Type</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Email</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Account</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Amount</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Start</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>End</Grid.Column>
                <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', fontSize: '11pt', padding: '6px'}}>Auto-Renew</Grid.Column>
              </Grid.Row>
              {this.state.subscriptions.map((subscription) => {
                return (
                <Grid.Row columns={8} style={{overflow: 'auto'}}>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.name}</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.type}</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.email}</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.user}</Grid.Column>
                  <Grid.Column style={{fontWeight: "bold", fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.amount} {currency}</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.start}</Grid.Column>
                  <Grid.Column style={{fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.expiration}</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', overflow: 'auto', padding: '6px'}}>{subscription.renew}</Grid.Column>
                </Grid.Row>
                )
              })}
              {this.state.subscriptions.length === 0 && <Grid.Row columns={8}>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                  <Grid.Column style={{ fontSize: '10pt', padding: '6px', overflow: 'auto'}}>None available</Grid.Column>
                </Grid.Row>}            
              </Grid>
              </div>
    )
  }

  renderCompletePage() {
    return (
      <div>
  {this.renderSubscriptionList()}
  <Divider />
  {this.renderEstimated()}
      </div>
    )
  }

  renderEstimated() {
    let yearExpand = {
      'January': 0,
      'February': 0,
      'March': 0,
      'April': 0,
      'May': 0,
      'June': 0,
      'July': 0,
      'August': 0,
      'September': 0,
      'October': 0,
      'November': 0,
      'December': 0
    }
    let yearob = {
      'January': 0,
      'February': 0,
      'March': 0,
      'April': 0,
      'May': 0,
      'June': 0,
      'July': 0,
      'August': 0,
      'September': 0,
      'October': 0,
      'November': 0,
      'December': 0
    }
      let earningsYear = 0
      let earningsTotal = 0
      let earningsYearEx = 0
      let earningsTotalEx = 0
      let thisYear = new Date().getFullYear().toString()
      let thisYearState = this.state.year
      let monthtoday = new Date().toLocaleString('en-US', { month: 'long' })
    for (let index = 0; index < this.state.subscriptions.length; index++) {
      const element = this.state.subscriptions[index]      
      let yearsub = new Date(element.start).getFullYear().toString()
      let monthsub = new Date(element.start).toLocaleString('en-US', { month: 'long' })
      let expyear = new Date(element.expiration).getFullYear().toString()
      let monthstamp = new Date(parseInt(element.stamp)).toLocaleString('en-US', { month: 'long' })      
      let yearstamp = new Date(parseInt(element.stamp)).getFullYear().toString()
      let typesub = element.type
      let methodsub = element.method
      let renewsub = element.renew
      let amountsub = parseInt(element.amount)
      let durationsub = element.duration
      console.log('all values of subs: ' + yearsub + monthsub + expyear + monthstamp + yearstamp + typesub + methodsub + renewsub + amountsub + durationsub)
      if (typesub === 'subscription' && renewsub === 'no') {
earningsTotal += amountsub
if (thisYear === yearstamp) {
  earningsYear += amountsub
  let monthAmount = 0
  switch (monthstamp) {
    case 'January':
      monthAmount = yearExpand.January
      monthAmount += amountsub
      yearExpand.January = monthAmount
      break;
    case 'February':
      monthAmount = yearExpand.February
      monthAmount += amountsub
      yearExpand.February = monthAmount
      break;
    case 'March':
      monthAmount = yearExpand.March
      monthAmount += amountsub
      yearExpand.March = monthAmount
      break;
    case 'April':
      monthAmount = yearExpand.April
      monthAmount += amountsub
      yearExpand.April = monthAmount
      break;
    case 'May':
      monthAmount = yearExpand.May
      monthAmount += amountsub
      yearExpand.May = monthAmount
      break;
    case 'June':
      monthAmount = yearExpand.June
      monthAmount += amountsub
      yearExpand.June = monthAmount
      break;
    case 'July':
      monthAmount = yearExpand.July
      monthAmount += amountsub
      yearExpand.July = monthAmount
      break;
    case 'August':
      monthAmount = yearExpand.August
      monthAmount += amountsub
      yearExpand.August = monthAmount
      break;
    case 'September':
      monthAmount = yearExpand.September
      monthAmount += amountsub
      yearExpand.September = monthAmount
      break;
    case 'October':
      monthAmount = yearExpand.October
      monthAmount += amountsub
      yearExpand.October = monthAmount
      break;
    case 'November':
      monthAmount = yearExpand.November
      monthAmount += amountsub
      yearExpand.November = monthAmount
      break;
    case 'December':
      monthAmount = yearExpand.December
      monthAmount += amountsub
      yearExpand.December = monthAmount
      break;  
    default:
      break;
  }
}
      }
      if (typesub === 'subscription' && renewsub === 'yes' && methodsub === 'card' && durationsub === 'month') {
        earningsTotal += amountsub        
        if (parseInt(yearstamp) < parseInt(thisYear) && parseInt(yearsub) < parseInt(thisYear)) {
          let amounth = 0
          amounth = yearExpand.January
          amounth += amountsub
              yearExpand.January = amounth
              amounth = yearExpand.February
              amounth += amountsub
              yearExpand.February = amounth
              amounth = yearExpand.March
              amounth += amountsub
              yearExpand.March = amounth
              amounth = yearExpand.April
              amounth += amountsub
              yearExpand.April = amounth
              amounth = yearExpand.May
              amounth += amountsub
              yearExpand.May = amounth
              amounth = yearExpand.June
              amounth += amountsub
              yearExpand.June = amounth
              amounth = yearExpand.July
              amounth += amountsub
              yearExpand.July = amounth
              amounth = yearExpand.August
              amounth += amountsub
              yearExpand.August = amounth
              amounth = yearExpand.September
              amounth += amountsub
              yearExpand.September = amounth
              amounth = yearExpand.October
              amounth += amountsub
              yearExpand.October = amounth
              amounth = yearExpand.November
              amounth += amountsub
              yearExpand.November = amounth
              amounth = yearExpand.December
              amounth += amountsub
              yearExpand.December = amounth
              let countersub = parseInt(thisYear) - parseInt(yearsub)
              switch (monthsub) {
                case 'January':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 7
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 8
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 9
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 10
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 10
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 11
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 11
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'February':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 7
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 8
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 9
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 10
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 10
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'March':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 7
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 8
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 9
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'April':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 7
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 8
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'May':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 7
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'June':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 6
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'July':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)                      
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 5
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'August':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 7
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub                      
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 3
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 4
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'September':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 8
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 7
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2                   
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'October':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 9
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 8
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 7
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3                  
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub * 2
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'November':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += (amountsub * 2)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 10
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 9
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 8
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 7
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4                
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                      earningsTotal += amountsub
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'December':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYear += amountsub
                      earningsTotal += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 11
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * 2)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 2
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 10
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 3
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 9
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 4
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 8
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 5
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 7
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 6
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 6
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 7
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 5               
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 8
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 4
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 9
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 3
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 10
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub * 2
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 11
                      earningsTotal += (amountsub * multy)
                      earningsTotal -= amountsub
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYear += amountsub * 12
                      earningsTotal += (amountsub * multy)
                    }
                    break;
                  default:
                    break;
                }
                  break;
                default:
                  break;
              }
        }
        if (thisYear === yearstamp) {
          earningsYear += amountsub
          let monthAmount = 0
          switch (monthstamp) {
            case 'January':
              monthAmount = yearExpand.January
              monthAmount += amountsub
              yearExpand.January = monthAmount              
              break;
            case 'February':
              monthAmount = yearExpand.February
              monthAmount += amountsub
              yearExpand.February = monthAmount    
              break;
            case 'March':
              monthAmount = yearExpand.March
              monthAmount += amountsub
              yearExpand.March = monthAmount    
              break;
            case 'April':
              monthAmount = yearExpand.April
              monthAmount += amountsub
              yearExpand.April = monthAmount              
              break;
            case 'May':
              monthAmount = yearExpand.May
              monthAmount += amountsub
              yearExpand.May = monthAmount              
              break;
            case 'June':
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount    
              break;
            case 'July':
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount    
              break;
            case 'August':
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount    
              break;
            case 'September':
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount     
              break;
            case 'October':
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount     
              break;
            case 'November':
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              break;
            case 'December':
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              break;  
            default:
              break;
          }
          if (yearsub === thisYear) {
          switch (monthsub) {
            case 'January':
              monthAmount = yearExpand.February
              monthAmount += amountsub
              yearExpand.February = monthAmount
              monthAmount = yearExpand.March
              monthAmount += amountsub
              yearExpand.March = monthAmount
              monthAmount = yearExpand.April
              monthAmount += amountsub
              yearExpand.April = monthAmount
              monthAmount = yearExpand.May
              monthAmount += amountsub
              yearExpand.May = monthAmount
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'February':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'March':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'April':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'May':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'June':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'July':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                case 'August':
                  earningsYear += (amountsub * 7)
                  earningsTotal += (amountsub * 7)
                  break;
                case 'September':
                  earningsYear += (amountsub * 8)
                  earningsTotal += (amountsub * 8)
                  break;
                case 'October':
                  earningsYear += (amountsub * 9)
                  earningsTotal += (amountsub * 9)
                  break;
                case 'November':
                  earningsYear += (amountsub * 10)
                  earningsTotal += (amountsub * 10)
                  break;
                case 'December':
                  earningsYear += (amountsub * 11)
                  earningsTotal += (amountsub * 11)
                  break;
                default:
                  break;
              }
              break;
            case 'February':
              monthAmount = yearExpand.March
              monthAmount += amountsub
              yearExpand.March = monthAmount
              monthAmount = yearExpand.April
              monthAmount += amountsub
              yearExpand.April = monthAmount
              monthAmount = yearExpand.May
              monthAmount += amountsub
              yearExpand.May = monthAmount
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'March':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'April':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'May':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'June':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'July':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'August':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                case 'September':
                  earningsYear += (amountsub * 7)
                  earningsTotal += (amountsub * 7)
                  break;
                case 'October':
                  earningsYear += (amountsub * 8)
                  earningsTotal += (amountsub * 8)
                  break;
                case 'November':
                  earningsYear += (amountsub * 9)
                  earningsTotal += (amountsub * 9)
                  break;
                case 'December':
                  earningsYear += (amountsub * 10)
                  earningsTotal += (amountsub * 10)
                  break;
                default:
                  break;
              }
              break;
            case 'March':
              monthAmount = yearExpand.April
              monthAmount += amountsub
              yearExpand.April = monthAmount
              monthAmount = yearExpand.May
              monthAmount += amountsub
              yearExpand.May = monthAmount
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'April':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'May':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'June':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'July':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'August':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'September':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                case 'October':
                  earningsYear += (amountsub * 7)
                  earningsTotal += (amountsub * 7)
                  break;
                case 'November':
                  earningsYear += (amountsub * 8)
                  earningsTotal += (amountsub * 8)
                  break;
                case 'December':
                  earningsYear += (amountsub * 9)
                  earningsTotal += (amountsub * 9)
                  break;
                default:
                  break;
              }
              break;
            case 'April':
              monthAmount = yearExpand.May
              monthAmount += amountsub
              yearExpand.May = monthAmount
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'May':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'June':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'July':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'August':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'September':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'October':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                case 'November':
                  earningsYear += (amountsub * 7)
                  earningsTotal += (amountsub * 7)
                  break;
                case 'December':
                  earningsYear += (amountsub * 8)
                  earningsTotal += (amountsub * 8)
                  break;
                default:
                  break;
              }
              break;
            case 'May':
              monthAmount = yearExpand.June
              monthAmount += amountsub
              yearExpand.June = monthAmount
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'June':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'July':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'August':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'September':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'October':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'November':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                case 'December':
                  earningsYear += (amountsub * 7)
                  earningsTotal += (amountsub * 7)
                  break;
                default:
                  break;
              }
              break;
            case 'June':
              monthAmount = yearExpand.July
              monthAmount += amountsub
              yearExpand.July = monthAmount
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'July':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'August':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'September':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'October':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'November':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                case 'December':
                  earningsYear += (amountsub * 6)
                  earningsTotal += (amountsub * 6)
                  break;
                default:
                  break;
              }
              break;
            case 'July':
              monthAmount = yearExpand.August
              monthAmount += amountsub
              yearExpand.August = monthAmount
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'August':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'September':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'October':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'November':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                case 'December':
                  earningsYear += (amountsub * 5)
                  earningsTotal += (amountsub * 5)
                  break;
                default:
                  break;
              }
              break;
            case 'August':
              monthAmount = yearExpand.September
              monthAmount += amountsub
              yearExpand.September = monthAmount
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'September':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'October':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'November':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                case 'December':
                  earningsYear += (amountsub * 4)
                  earningsTotal += (amountsub * 4)
                  break;
                default:
                  break;
              }
              break;
            case 'September':
              monthAmount = yearExpand.October
              monthAmount += amountsub
              yearExpand.October = monthAmount
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'October':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'November':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                case 'December':
                  earningsYear += (amountsub * 3)
                  earningsTotal += (amountsub * 3)
                  break;
                default:
                  break;
              }
              break;
            case 'October':
              monthAmount = yearExpand.November
              monthAmount += amountsub
              yearExpand.November = monthAmount
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              switch (monthtoday) {
                case 'November':
                  earningsYear += amountsub
                  earningsTotal += amountsub
                  break;
                case 'December':
                  earningsYear += (amountsub * 2)
                  earningsTotal += (amountsub * 2)
                  break;
                default:
                  break;
              }
              break;
            case 'November':
              monthAmount = yearExpand.December
              monthAmount += amountsub
              yearExpand.December = monthAmount
              if (monthtoday === 'December') {
                earningsYear += amountsub
                earningsTotal += amountsub
              }
              break;
            default:
              break;
          }
        }
        }
        if (parseInt(thisYear) > parseInt(yearstamp) && yearsub === thisYear) {
              let amont = 0
              switch (monthsub) {
                case 'January':
                        amont = yearExpand.January
                        amont += amountsub
                        yearExpand.January = amont
                        amont = yearExpand.February
                        amont += amountsub
                        yearExpand.February = amont
                        amont = yearExpand.March
                        amont += amountsub
                        yearExpand.March = amont
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        let amountyear = 0
              let amounttotal = 0
              switch (monthtoday) {
                case 'January':
                  earningsYear += amountsub
                  break;
                case 'February':
                  amountyear = amountsub * 2
                  amounttotal = amountsub
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'March':
                  amountyear = amountsub * 3
                  amounttotal = amountsub * 2
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'April':
                  amountyear = amountsub * 4
                  amounttotal = amountsub * 3
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'May':
                  amountyear = amountsub * 5
                  amounttotal = amountsub * 4
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'June':
                  amountyear = amountsub * 6
                  amounttotal = amountsub * 5
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'July':
                  amountyear = amountsub * 7
                  amounttotal = amountsub * 6
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'August':
                  amountyear = amountsub * 8
                  amounttotal = amountsub * 7
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'September':
                  amountyear = amountsub * 9
                  amounttotal = amountsub * 8
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'October':
                  amountyear = amountsub * 10
                  amounttotal = amountsub * 9
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'November':
                  amountyear = amountsub * 11
                  amounttotal = amountsub * 10
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                case 'December':
                  amountyear = amountsub * 12
                  amounttotal = amountsub * 11
                  earningsYear += amountyear
                  earningsTotal += amounttotal
                  break;
                default:
                  break;}
                        break;
                      case 'February':
                        amont = yearExpand.February
                        amont += amountsub
                        yearExpand.February = amont
                        amont = yearExpand.March
                        amont += amountsub
                        yearExpand.March = amont
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'February':
                        earningsYear += amountsub
                        break;
                      case 'March':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'April':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'May':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 10
                        amounttotal = amountsub * 9
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 11
                        amounttotal = amountsub * 10
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'March':
                        amont = yearExpand.March
                        amont += amountsub
                        yearExpand.March = amont
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'March':
                        earningsYear += amountsub                    
                        break;
                      case 'April':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'May':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 10
                        amounttotal = amountsub * 9
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'April':
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'April':
                        earningsYear += amountsub
                        break;
                      case 'May':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'May':
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                      case 'May':
                        earningsYear += amountsub
                        break;
                      case 'June':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYear += amountyear
                        earningsTotal += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'June':
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'June':
                            earningsYear += amountsub
                            break;
                          case 'July':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'August':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'September':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 6
                            amounttotal = amountsub * 5
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 7
                            amounttotal = amountsub * 6
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'July':
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'July':
                            earningsYear += amountsub
                            break;
                          case 'August':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'September':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 6
                            amounttotal = amountsub * 5
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'August':
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'August':
                            earningsYear += amountsub
                            break;
                          case 'September':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'September':
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'September':
                            earningsYear += amountsub
                            break;
                          case 'October':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'October':
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'October':
                            earningsYear += amountsub
                            break;
                          case 'November':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'November':
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        switch (monthtoday) {
                          case 'November':
                            earningsYear += amountsub
                            break;
                          case 'December':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYear += amountyear
                            earningsTotal += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'December':
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        if (monthtoday === 'December') {
                          earningsYear += amountsub
                        }                       
                        break;  
                      default:
                        break;
              }
        }
              }
              if (typesub === 'subscription' && renewsub === 'yes' && methodsub === 'card' && durationsub === 'year') {
                earningsTotal += amountsub
                if (thisYear === yearstamp) {
                  earningsYear += amountsub
                  let amont = 0
                    switch (monthstamp) {
                      case 'January':
                        amont = yearExpand.January
                        amont += amountsub
                        yearExpand.January = amont
                        break;
                      case 'February':
                        amont = yearExpand.February
                        amont += amountsub
                        yearExpand.February = amont
                        break;
                      case 'March':
                        amont = yearExpand.March
                        amont += amountsub
                        yearExpand.March = amont
                        break;
                      case 'April':
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        break;
                      case 'May':
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        break;
                      case 'June':
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        break;
                      case 'July':
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        break;
                      case 'August':
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        break;
                      case 'September':
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        break;
                      case 'October':
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        break;
                      case 'November':
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        break;
                      case 'December':
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        break;  
                      default:
                        break;
                    }
                }
                  if (parseInt(yearstamp) < parseInt(thisYear) && parseInt(yearsub) < parseInt(thisYear)) {                    
                    let amont = 0
                    let countersub = parseInt(thisYear) - parseInt(yearstamp)
                    switch (monthsub) {
                      case 'January':
                        amont = yearExpand.January
                        amont += amountsub
                        yearExpand.January = amont
                        if (countersub === 1) {
                          earningsYear += amountsub
                          earningsTotal += amountsub
                        } else {
                          earningsYear += amountsub * countersub
                          earningsTotal += amountsub * countersub
                        }                        
                        break;
                      case 'February':
                        amont = yearExpand.February
                        amont += amountsub
                        yearExpand.February = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January') {
                          earningsYear += amountsub
                          earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'March':
                        amont = yearExpand.March
                        amont += amountsub
                        yearExpand.March = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'April':
                        amont = yearExpand.April
                        amont += amountsub
                        yearExpand.April = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'May':
                        amont = yearExpand.May
                        amont += amountsub
                        yearExpand.May = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'June':
                        amont = yearExpand.June
                        amont += amountsub
                        yearExpand.June = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'July':
                        amont = yearExpand.July
                        amont += amountsub
                        yearExpand.July = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'August':
                        amont = yearExpand.August
                        amont += amountsub
                        yearExpand.August = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'September':
                        amont = yearExpand.September
                        amont += amountsub
                        yearExpand.September = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'October':
                        amont = yearExpand.October
                        amont += amountsub
                        yearExpand.October = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'November':
                        amont = yearExpand.November
                        amont += amountsub
                        yearExpand.November = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;
                      case 'December':
                        amont = yearExpand.December
                        amont += amountsub
                        yearExpand.December = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October' && monthtoday !== 'November') {
                          earningsYear += amountsub
                        earningsTotal += amountsub
                        }
                      } else {
                        earningsYear += amountsub * countersub
                        earningsTotal += amountsub * countersub
                      }
                        break;  
                      default:
                        break;
                    }
                  }
                      }
                      if (typesub === 'subscription' && renewsub === 'yes' && methodsub !== 'card' && durationsub === 'year') {
                        earningsTotal += amountsub
                        if (thisYear === yearsub) {
                          earningsYear += amountsub
                          let amont = 0
                            switch (monthsub) {
                              case 'January':
                                amont = yearExpand.January
                                amont += amountsub
                                yearExpand.January = amont
                                break;
                              case 'February':
                                amont = yearExpand.February
                                amont += amountsub
                                yearExpand.February = amont
                                break;
                              case 'March':
                                amont = yearExpand.March
                                amont += amountsub
                                yearExpand.March = amont
                                break;
                              case 'April':
                                amont = yearExpand.April
                                amont += amountsub
                                yearExpand.April = amont
                                break;
                              case 'May':
                                amont = yearExpand.May
                                amont += amountsub
                                yearExpand.May = amont
                                break;
                              case 'June':
                                amont = yearExpand.June
                                amont += amountsub
                                yearExpand.June = amont
                                break;
                              case 'July':
                                amont = yearExpand.July
                                amont += amountsub
                                yearExpand.July = amont
                                break;
                              case 'August':
                                amont = yearExpand.August
                                amont += amountsub
                                yearExpand.August = amont
                                break;
                              case 'September':
                                amont = yearExpand.September
                                amont += amountsub
                                yearExpand.September = amont
                                break;
                              case 'October':
                                amont = yearExpand.October
                                amont += amountsub
                                yearExpand.October = amont
                                break;
                              case 'November':
                                amont = yearExpand.November
                                amont += amountsub
                                yearExpand.November = amont
                                break;
                              case 'December':
                                amont = yearExpand.December
                                amont += amountsub
                                yearExpand.December = amont
                                break;  
                              default:
                                break;
                            }
                        }
                        if (parseInt(yearsub) < parseInt(thisYear) && parseInt(expyear) === parseInt(thisYear)) {
                          let amont = 0
                          let countersub = parseInt(thisYear) - parseInt(yearsub)
                          switch (monthsub) {
                            case 'January':
                              amont = yearExpand.January
                              amont += amountsub
                              yearExpand.January = amont
                              if (countersub === 1) {
                                earningsYear += amountsub
                                earningsTotal += amountsub
                              } else {
                                earningsYear += amountsub * countersub
                                earningsTotal += amountsub * countersub
                              }                        
                              break;
                            case 'February':
                              amont = yearExpand.February
                              amont += amountsub
                              yearExpand.February = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January') {
                                earningsYear += amountsub
                                earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'March':
                              amont = yearExpand.March
                              amont += amountsub
                              yearExpand.March = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'April':
                              amont = yearExpand.April
                              amont += amountsub
                              yearExpand.April = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'May':
                              amont = yearExpand.May
                              amont += amountsub
                              yearExpand.May = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'June':
                              amont = yearExpand.June
                              amont += amountsub
                              yearExpand.June = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'July':
                              amont = yearExpand.July
                              amont += amountsub
                              yearExpand.July = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'August':
                              amont = yearExpand.August
                              amont += amountsub
                              yearExpand.August = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'September':
                              amont = yearExpand.September
                              amont += amountsub
                              yearExpand.September = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'October':
                              amont = yearExpand.October
                              amont += amountsub
                              yearExpand.October = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'November':
                              amont = yearExpand.November
                              amont += amountsub
                              yearExpand.November = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;
                            case 'December':
                              amont = yearExpand.December
                              amont += amountsub
                              yearExpand.December = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October' && monthtoday !== 'November') {
                                earningsYear += amountsub
                              earningsTotal += amountsub
                              }
                            } else {
                              earningsYear += amountsub * countersub
                              earningsTotal += amountsub * countersub
                            }
                              break;  
                            default:
                              break;
                          }
                      }
                      }
                      if (typesub === 'subscription' && renewsub === 'yes' && methodsub !== 'card' && durationsub === 'month') {
                        earningsTotal += amountsub
                        if (yearsub === thisYear) {
                          let monthAmount = 0
                          switch (monthsub) {//GUARDARE
                            case 'January':
                              monthAmount = yearExpand.January
                              monthAmount += amountsub
                              yearExpand.January = monthAmount  
                            monthAmount = yearExpand.February
                              monthAmount += amountsub
                              yearExpand.February = monthAmount
                              monthAmount = yearExpand.March
                              monthAmount += amountsub
                              yearExpand.March = monthAmount
                              monthAmount = yearExpand.April
                              monthAmount += amountsub
                              yearExpand.April = monthAmount
                              monthAmount = yearExpand.May
                              monthAmount += amountsub
                              yearExpand.May = monthAmount
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'January':
                                  earningsYear += amountsub
                                  break;
                                case 'February':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'March':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'April':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'May':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'June':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 8)
                                  earningsTotal += (amountsub * 7)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 9)
                                  earningsTotal += (amountsub * 8)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 10)
                                  earningsTotal += (amountsub * 9)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 11)
                                  earningsTotal += (amountsub * 10)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 12)
                                  earningsTotal += (amountsub * 11)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'February':
                              monthAmount = yearExpand.February
                              monthAmount += amountsub
                              yearExpand.February = monthAmount
                              monthAmount = yearExpand.March
                              monthAmount += amountsub
                              yearExpand.March = monthAmount
                              monthAmount = yearExpand.April
                              monthAmount += amountsub
                              yearExpand.April = monthAmount
                              monthAmount = yearExpand.May
                              monthAmount += amountsub
                              yearExpand.May = monthAmount
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'February':
                                  earningsYear += amountsub                                  
                                  break;
                                case 'March':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'April':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'May':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'June':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 8)
                                  earningsTotal += (amountsub * 7)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 9)
                                  earningsTotal += (amountsub * 8)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 10)
                                  earningsTotal += (amountsub * 9)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 11)
                                  earningsTotal += (amountsub * 10)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'March':
                              monthAmount = yearExpand.March
                              monthAmount += amountsub
                              yearExpand.March = monthAmount
                              monthAmount = yearExpand.April
                              monthAmount += amountsub
                              yearExpand.April = monthAmount
                              monthAmount = yearExpand.May
                              monthAmount += amountsub
                              yearExpand.May = monthAmount
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'March':
                                  earningsYear += amountsub
                                  break;
                                case 'April':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'May':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'June':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 8)
                                  earningsTotal += (amountsub * 7)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 9)
                                  earningsTotal += (amountsub * 8)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 10)
                                  earningsTotal += (amountsub * 9)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'April':
                              monthAmount = yearExpand.April
                              monthAmount += amountsub
                              yearExpand.April = monthAmount
                              monthAmount = yearExpand.May
                              monthAmount += amountsub
                              yearExpand.May = monthAmount
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'April':
                                  earningsYear += amountsub
                                  break;
                                case 'May':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'June':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 8)
                                  earningsTotal += (amountsub * 7)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 9)
                                  earningsTotal += (amountsub * 8)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'May':
                              monthAmount = yearExpand.May
                              monthAmount += amountsub
                              yearExpand.May = monthAmount
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'May':
                                  earningsYear += amountsub
                                  break;
                                case 'June':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 8)
                                  earningsTotal += (amountsub * 7)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'June':
                              monthAmount = yearExpand.June
                              monthAmount += amountsub
                              yearExpand.June = monthAmount
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'June':
                                  earningsYear += amountsub
                                  break;
                                case 'July':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 7)
                                  earningsTotal += (amountsub * 6)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'July':
                              monthAmount = yearExpand.July
                              monthAmount += amountsub
                              yearExpand.July = monthAmount
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'July':
                                  earningsYear += amountsub
                                  break;
                                case 'August':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'September':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 6)
                                  earningsTotal += (amountsub * 5)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'August':
                              monthAmount = yearExpand.August
                              monthAmount += amountsub
                              yearExpand.August = monthAmount
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'August':
                                  earningsYear += amountsub
                                break;
                                case 'September':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 5)
                                  earningsTotal += (amountsub * 4)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'September':
                              monthAmount = yearExpand.September
                              monthAmount += amountsub
                              yearExpand.September = monthAmount
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'September':
                                  earningsYear += amountsub
                                  break;
                                case 'October':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 4)
                                  earningsTotal += (amountsub * 3)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'October':
                              monthAmount = yearExpand.October
                              monthAmount += amountsub
                              yearExpand.October = monthAmount
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              switch (monthtoday) {
                                case 'October':
                                  earningsYear += amountsub
                                  break;
                                case 'November':
                                  earningsYear += (amountsub * 2)
                                  earningsTotal += amountsub
                                  break;
                                case 'December':
                                  earningsYear += (amountsub * 3)
                                  earningsTotal += (amountsub * 2)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'November':
                              monthAmount = yearExpand.November
                              monthAmount += amountsub
                              yearExpand.November = monthAmount
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              if (monthtoday === 'November') {
                                earningsYear += amountsub
                              }
                              if (monthtoday === 'December') {
                                earningsYear += (amountsub * 2)
                                earningsTotal += amountsub
                              }
                              break;
                            case 'December':
                              monthAmount = yearExpand.December
                              monthAmount += amountsub
                              yearExpand.December = monthAmount
                              if (monthtoday === 'December') {
                                earningsYear += amountsub
                              }
                              break;
                            default:
                              break;
                          }
                        } else {
                          let amounth = 0
                          amounth = yearExpand.January
                          amounth += amountsub
                              yearExpand.January = amounth
                              amounth = yearExpand.February
                              amounth += amountsub
                              yearExpand.February = amounth
                              amounth = yearExpand.March
                              amounth += amountsub
                              yearExpand.March = amounth
                              amounth = yearExpand.April
                              amounth += amountsub
                              yearExpand.April = amounth
                              amounth = yearExpand.May
                              amounth += amountsub
                              yearExpand.May = amounth
                              amounth = yearExpand.June
                              amounth += amountsub
                              yearExpand.June = amounth
                              amounth = yearExpand.July
                              amounth += amountsub
                              yearExpand.July = amounth
                              amounth = yearExpand.August
                              amounth += amountsub
                              yearExpand.August = amounth
                              amounth = yearExpand.September
                              amounth += amountsub
                              yearExpand.September = amounth
                              amounth = yearExpand.October
                              amounth += amountsub
                              yearExpand.October = amounth
                              amounth = yearExpand.November
                              amounth += amountsub
                              yearExpand.November = amounth
                              amounth = yearExpand.December
                              amounth += amountsub
                              yearExpand.December = amounth
                              let countersub = parseInt(thisYear) - parseInt(yearsub)
                              switch (monthsub) {
                                case 'January':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 7
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 8
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 9
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 10
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 10
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 11
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 11
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'February':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                      earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 7
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 8
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 9
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 10
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 10
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'March':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 7
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 8
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 9
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'April':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 7
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 8
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'May':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 7
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'June':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 6
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'July':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)                      
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 5
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'August':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 7
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub                      
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 3
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 4
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'September':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 8
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 7
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2                   
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'October':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 9
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 8
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 7
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3                  
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub * 2
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'November':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * 2)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 10
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 9
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 8
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 7
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4                
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal += amountsub
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'December':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYear += amountsub
                                      earningsTotal += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 11
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * 2)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 2
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 10
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 3
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 9
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 4
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 8
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 5
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 7
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 6
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 6
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 7
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 5               
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 8
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 4
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 9
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 3
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 10
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub * 2
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 11
                                      earningsTotal += (amountsub * multy)
                                      earningsTotal -= amountsub
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYear += amountsub * 12
                                      earningsTotal += (amountsub * multy)
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                default:
                                  break;
                              }
                        }
                      }
      if (typesub === 'upgrade') {
        earningsTotal += amountsub
        if (thisYear === yearstamp) {
          earningsYear += amountsub
          let monthAmount = 0
  switch (monthstamp) {
    case 'January':
      monthAmount = yearExpand.January
      monthAmount += amountsub
      yearExpand.January = monthAmount
      break;
    case 'February':
      monthAmount = yearExpand.February
      monthAmount += amountsub
      yearExpand.February = monthAmount
      break;
    case 'March':
      monthAmount = yearExpand.March
      monthAmount += amountsub
      yearExpand.March = monthAmount
      break;
    case 'April':
      monthAmount = yearExpand.April
      monthAmount += amountsub
      yearExpand.April = monthAmount
      break;
    case 'May':
      monthAmount = yearExpand.May
      monthAmount += amountsub
      yearExpand.May = monthAmount
      break;
    case 'June':
      monthAmount = yearExpand.June
      monthAmount += amountsub
      yearExpand.June = monthAmount
      break;
    case 'July':
      monthAmount = yearExpand.July
      monthAmount += amountsub
      yearExpand.July = monthAmount
      break;
    case 'August':
      monthAmount = yearExpand.August
      monthAmount += amountsub
      yearExpand.August = monthAmount
      break;
    case 'September':
      monthAmount = yearExpand.September
      monthAmount += amountsub
      yearExpand.September = monthAmount
      break;
    case 'October':
      monthAmount = yearExpand.October
      monthAmount += amountsub
      yearExpand.October = monthAmount
      break;
    case 'November':
      monthAmount = yearExpand.November
      monthAmount += amountsub
      yearExpand.November = monthAmount
      break;
    case 'December':
      monthAmount = yearExpand.December
      monthAmount += amountsub
      yearExpand.December = monthAmount
      break;  
    default:
      break;
  }
        }
              }
    }
    for (let index = 0; index < this.state.subscriptions.length; index++) {
      const element = this.state.subscriptions[index]      
      let yearsub = new Date(element.start).getFullYear().toString()
      let monthsub = new Date(element.start).toLocaleString('en-US', { month: 'long' })
      let expyear = new Date(element.expiration).getFullYear().toString()
      let monthstamp = new Date(parseInt(element.stamp)).toLocaleString('en-US', { month: 'long' })      
      let yearstamp = new Date(parseInt(element.stamp)).getFullYear().toString()
      let typesub = element.type
      let methodsub = element.method
      let renewsub = element.renew
      let amountsub = parseInt(element.amount)
      let durationsub = element.duration
if (typesub === 'subscription' && renewsub === 'no') {
earningsTotalEx += amountsub
if (thisYearState === yearstamp) {
  earningsYearEx += amountsub
  let monthAmount = 0
  switch (monthstamp) {
    case 'January':
      monthAmount = yearob.January
      monthAmount += amountsub
      yearob.January = monthAmount
      break;
    case 'February':
      monthAmount = yearob.February
      monthAmount += amountsub
      yearob.February = monthAmount
      break;
    case 'March':
      monthAmount = yearob.March
      monthAmount += amountsub
      yearob.March = monthAmount
      break;
    case 'April':
      monthAmount = yearob.April
      monthAmount += amountsub
      yearob.April = monthAmount
      break;
    case 'May':
      monthAmount = yearob.May
      monthAmount += amountsub
      yearob.May = monthAmount
      break;
    case 'June':
      monthAmount = yearob.June
      monthAmount += amountsub
      yearob.June = monthAmount
      break;
    case 'July':
      monthAmount = yearob.July
      monthAmount += amountsub
      yearob.July = monthAmount
      break;
    case 'August':
      monthAmount = yearob.August
      monthAmount += amountsub
      yearob.August = monthAmount
      break;
    case 'September':
      monthAmount = yearob.September
      monthAmount += amountsub
      yearob.September = monthAmount
      break;
    case 'October':
      monthAmount = yearob.October
      monthAmount += amountsub
      yearob.October = monthAmount
      break;
    case 'November':
      monthAmount = yearob.November
      monthAmount += amountsub
      yearob.November = monthAmount
      break;
    case 'December':
      monthAmount = yearob.December
      monthAmount += amountsub
      yearob.December = monthAmount
      break;  
    default:
      break;
  }
}
      }
      if (typesub === 'subscription' && renewsub === 'yes' && methodsub === 'card' && durationsub === 'month') {
        earningsTotalEx += amountsub        
        if (parseInt(yearstamp) < parseInt(thisYearState) && parseInt(yearsub) < parseInt(thisYearState)) {
          let amounth = 0
          amounth = yearob.January
          amounth += amountsub
              yearob.January = amounth
              amounth = yearob.February
              amounth += amountsub
              yearob.February = amounth
              amounth = yearob.March
              amounth += amountsub
              yearob.March = amounth
              amounth = yearob.April
              amounth += amountsub
              yearob.April = amounth
              amounth = yearob.May
              amounth += amountsub
              yearob.May = amounth
              amounth = yearob.June
              amounth += amountsub
              yearob.June = amounth
              amounth = yearob.July
              amounth += amountsub
              yearob.July = amounth
              amounth = yearob.August
              amounth += amountsub
              yearob.August = amounth
              amounth = yearob.September
              amounth += amountsub
              yearob.September = amounth
              amounth = yearob.October
              amounth += amountsub
              yearob.October = amounth
              amounth = yearob.November
              amounth += amountsub
              yearob.November = amounth
              amounth = yearob.December
              amounth += amountsub
              yearob.December = amounth
              let countersub = parseInt(thisYearState) - parseInt(yearsub)
              switch (monthsub) {
                case 'January':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 7
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 8
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 9
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 10
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 10
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 11
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 11
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'February':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 7
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 8
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 9
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 10
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 10
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'March':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 7
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 8
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 9
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 9
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'April':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 7
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 8
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 8
                      }
                      break;
                    default:
                      break;
                  }
                  break;
                case 'May':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 7
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 7
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'June':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 6
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 6
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'July':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)                      
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 5
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 5
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'August':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 7
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub                      
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 3
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 3
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 4
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 4
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'September':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 8
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 7
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2                   
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'October':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 9
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 8
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 7
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3                  
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub * 2
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub * 2
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'November':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * 2)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 10
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 9
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 8
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 7
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4                
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx += amountsub
                    }
                    break;
                  default:
                    break;
                }
                  break;
                case 'December':
                  switch (monthtoday) {
                    case 'January':
                      if (countersub === 1) {
                        earningsYearEx += amountsub
                      earningsTotalEx += amountsub
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 11
                    }
                      break;
                    case 'February':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * 2)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 2
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 10
                      }
                      break;
                    case 'March':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * 3)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 3
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 9
                      }
                      break;
                    case 'April':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * 4)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 4
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 8
                      }
                      break;
                    case 'May':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * 5)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 5
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 7
                      }
                      break;
                    case 'June':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * 6)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 6
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 6
                      }
                      break;
                    case 'July':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * 7)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 7
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 5               
                      }
                      break;
                    case 'August':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * 8)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 8
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 4
                      }
                      break;
                    case 'September':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * 9)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 9
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 3
                      }
                      break;
                    case 'October':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * 10)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 10
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub * 2
                      }
                      break;
                    case 'November':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * 11)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 11
                      earningsTotalEx += (amountsub * multy)
                      earningsTotalEx -= amountsub
                      }
                      break;
                    case 'December':
                      if (countersub === 1) {
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * 12)
                      } else {
                        let multy = countersub * 12
                        earningsYearEx += amountsub * 12
                      earningsTotalEx += (amountsub * multy)
                    }
                    break;
                  default:
                    break;
                }
                  break;
                default:
                  break;
              }
        }
        if (thisYearState === yearstamp) {
          earningsYearEx += amountsub
          let monthAmount = 0
          switch (monthstamp) {
            case 'January':
              monthAmount = yearob.January
              monthAmount += amountsub
              yearob.January = monthAmount              
              break;
            case 'February':
              monthAmount = yearob.February
              monthAmount += amountsub
              yearob.February = monthAmount    
              break;
            case 'March':
              monthAmount = yearob.March
              monthAmount += amountsub
              yearob.March = monthAmount    
              break;
            case 'April':
              monthAmount = yearob.April
              monthAmount += amountsub
              yearob.April = monthAmount              
              break;
            case 'May':
              monthAmount = yearob.May
              monthAmount += amountsub
              yearob.May = monthAmount              
              break;
            case 'June':
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount    
              break;
            case 'July':
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount    
              break;
            case 'August':
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount    
              break;
            case 'September':
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount     
              break;
            case 'October':
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount     
              break;
            case 'November':
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              break;
            case 'December':
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              break;  
            default:
              break;
          }
          if (yearsub === thisYearState) {
          switch (monthsub) {
            case 'January':
              monthAmount = yearob.February
              monthAmount += amountsub
              yearob.February = monthAmount
              monthAmount = yearob.March
              monthAmount += amountsub
              yearob.March = monthAmount
              monthAmount = yearob.April
              monthAmount += amountsub
              yearob.April = monthAmount
              monthAmount = yearob.May
              monthAmount += amountsub
              yearob.May = monthAmount
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'February':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'March':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'April':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'May':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'June':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'July':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 7)
                  earningsTotalEx += (amountsub * 7)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 8)
                  earningsTotalEx += (amountsub * 8)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 9)
                  earningsTotalEx += (amountsub * 9)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 10)
                  earningsTotalEx += (amountsub * 10)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 11)
                  earningsTotalEx += (amountsub * 11)
                  break;
                default:
                  break;
              }
              break;
            case 'February':
              monthAmount = yearob.March
              monthAmount += amountsub
              yearob.March = monthAmount
              monthAmount = yearob.April
              monthAmount += amountsub
              yearob.April = monthAmount
              monthAmount = yearob.May
              monthAmount += amountsub
              yearob.May = monthAmount
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'March':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'April':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'May':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'June':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'July':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 7)
                  earningsTotalEx += (amountsub * 7)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 8)
                  earningsTotalEx += (amountsub * 8)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 9)
                  earningsTotalEx += (amountsub * 9)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 10)
                  earningsTotalEx += (amountsub * 10)
                  break;
                default:
                  break;
              }
              break;
            case 'March':
              monthAmount = yearob.April
              monthAmount += amountsub
              yearob.April = monthAmount
              monthAmount = yearob.May
              monthAmount += amountsub
              yearob.May = monthAmount
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'April':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'May':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'June':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'July':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 7)
                  earningsTotalEx += (amountsub * 7)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 8)
                  earningsTotalEx += (amountsub * 8)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 9)
                  earningsTotalEx += (amountsub * 9)
                  break;
                default:
                  break;
              }
              break;
            case 'April':
              monthAmount = yearob.May
              monthAmount += amountsub
              yearob.May = monthAmount
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'May':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'June':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'July':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 7)
                  earningsTotalEx += (amountsub * 7)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 8)
                  earningsTotalEx += (amountsub * 8)
                  break;
                default:
                  break;
              }
              break;
            case 'May':
              monthAmount = yearob.June
              monthAmount += amountsub
              yearob.June = monthAmount
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'June':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'July':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 7)
                  earningsTotalEx += (amountsub * 7)
                  break;
                default:
                  break;
              }
              break;
            case 'June':
              monthAmount = yearob.July
              monthAmount += amountsub
              yearob.July = monthAmount
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'July':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'August':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 6)
                  earningsTotalEx += (amountsub * 6)
                  break;
                default:
                  break;
              }
              break;
            case 'July':
              monthAmount = yearob.August
              monthAmount += amountsub
              yearob.August = monthAmount
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'August':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'September':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 5)
                  earningsTotalEx += (amountsub * 5)
                  break;
                default:
                  break;
              }
              break;
            case 'August':
              monthAmount = yearob.September
              monthAmount += amountsub
              yearob.September = monthAmount
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'September':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'October':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 4)
                  earningsTotalEx += (amountsub * 4)
                  break;
                default:
                  break;
              }
              break;
            case 'September':
              monthAmount = yearob.October
              monthAmount += amountsub
              yearob.October = monthAmount
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'October':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'November':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 3)
                  earningsTotalEx += (amountsub * 3)
                  break;
                default:
                  break;
              }
              break;
            case 'October':
              monthAmount = yearob.November
              monthAmount += amountsub
              yearob.November = monthAmount
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              switch (monthtoday) {
                case 'November':
                  earningsYearEx += amountsub
                  earningsTotalEx += amountsub
                  break;
                case 'December':
                  earningsYearEx += (amountsub * 2)
                  earningsTotalEx += (amountsub * 2)
                  break;
                default:
                  break;
              }
              break;
            case 'November':
              monthAmount = yearob.December
              monthAmount += amountsub
              yearob.December = monthAmount
              if (monthtoday === 'December') {
                earningsYearEx += amountsub
                earningsTotalEx += amountsub
              }
              break;
            default:
              break;
          }
        }
        }
        if (parseInt(thisYearState) > parseInt(yearstamp) && yearsub === thisYearState) {
              let amont = 0
              switch (monthsub) {
                case 'January':
                        amont = yearob.January
                        amont += amountsub
                        yearob.January = amont
                        amont = yearob.February
                        amont += amountsub
                        yearob.February = amont
                        amont = yearob.March
                        amont += amountsub
                        yearob.March = amont
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        let amountyear = 0
              let amounttotal = 0
              switch (monthtoday) {
                case 'January':
                  earningsYearEx += amountsub
                  break;
                case 'February':
                  amountyear = amountsub * 2
                  amounttotal = amountsub
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'March':
                  amountyear = amountsub * 3
                  amounttotal = amountsub * 2
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'April':
                  amountyear = amountsub * 4
                  amounttotal = amountsub * 3
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'May':
                  amountyear = amountsub * 5
                  amounttotal = amountsub * 4
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'June':
                  amountyear = amountsub * 6
                  amounttotal = amountsub * 5
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'July':
                  amountyear = amountsub * 7
                  amounttotal = amountsub * 6
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'August':
                  amountyear = amountsub * 8
                  amounttotal = amountsub * 7
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'September':
                  amountyear = amountsub * 9
                  amounttotal = amountsub * 8
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'October':
                  amountyear = amountsub * 10
                  amounttotal = amountsub * 9
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'November':
                  amountyear = amountsub * 11
                  amounttotal = amountsub * 10
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                case 'December':
                  amountyear = amountsub * 12
                  amounttotal = amountsub * 11
                  earningsYearEx += amountyear
                  earningsTotalEx += amounttotal
                  break;
                default:
                  break;}
                        break;
                      case 'February':
                        amont = yearob.February
                        amont += amountsub
                        yearob.February = amont
                        amont = yearob.March
                        amont += amountsub
                        yearob.March = amont
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'February':
                        earningsYearEx += amountsub
                        break;
                      case 'March':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'April':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'May':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 10
                        amounttotal = amountsub * 9
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 11
                        amounttotal = amountsub * 10
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'March':
                        amont = yearob.March
                        amont += amountsub
                        yearob.March = amont
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'March':
                        earningsYearEx += amountsub                    
                        break;
                      case 'April':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'May':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 10
                        amounttotal = amountsub * 9
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'April':
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'April':
                        earningsYearEx += amountsub
                        break;
                      case 'May':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'June':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 9
                        amounttotal = amountsub * 8
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'May':
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                      case 'May':
                        earningsYearEx += amountsub
                        break;
                      case 'June':
                        amountyear = amountsub * 2
                        amounttotal = amountsub
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'July':
                        amountyear = amountsub * 3
                        amounttotal = amountsub * 2
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'August':
                        amountyear = amountsub * 4
                        amounttotal = amountsub * 3
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'September':
                        amountyear = amountsub * 5
                        amounttotal = amountsub * 4
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'October':
                        amountyear = amountsub * 6
                        amounttotal = amountsub * 5
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'November':
                        amountyear = amountsub * 7
                        amounttotal = amountsub * 6
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      case 'December':
                        amountyear = amountsub * 8
                        amounttotal = amountsub * 7
                        earningsYearEx += amountyear
                        earningsTotalEx += amounttotal
                        break;
                      default:
                        break;}
                        break;
                      case 'June':
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'June':
                            earningsYearEx += amountsub
                            break;
                          case 'July':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'August':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'September':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 6
                            amounttotal = amountsub * 5
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 7
                            amounttotal = amountsub * 6
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'July':
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'July':
                            earningsYearEx += amountsub
                            break;
                          case 'August':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'September':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 6
                            amounttotal = amountsub * 5
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'August':
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'August':
                            earningsYearEx += amountsub
                            break;
                          case 'September':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'October':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 5
                            amounttotal = amountsub * 4
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'September':
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'September':
                            earningsYearEx += amountsub
                            break;
                          case 'October':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'November':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 4
                            amounttotal = amountsub * 3
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'October':
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'October':
                            earningsYearEx += amountsub
                            break;
                          case 'November':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          case 'December':
                            amountyear = amountsub * 3
                            amounttotal = amountsub * 2
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'November':
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        switch (monthtoday) {
                          case 'November':
                            earningsYearEx += amountsub
                            break;
                          case 'December':
                            amountyear = amountsub * 2
                            amounttotal = amountsub
                            earningsYearEx += amountyear
                            earningsTotalEx += amounttotal
                            break;
                          default:
                            break;}
                        break;
                      case 'December':
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        if (monthtoday === 'December') {
                          earningsYearEx += amountsub
                        }                       
                        break;  
                      default:
                        break;
              }
        }
              }
              if (typesub === 'subscription' && renewsub === 'yes' && methodsub === 'card' && durationsub === 'year') {
                earningsTotalEx += amountsub
                if (thisYearState === yearstamp) {
                  earningsYearEx += amountsub
                  let amont = 0
                    switch (monthstamp) {
                      case 'January':
                        amont = yearob.January
                        amont += amountsub
                        yearob.January = amont
                        break;
                      case 'February':
                        amont = yearob.February
                        amont += amountsub
                        yearob.February = amont
                        break;
                      case 'March':
                        amont = yearob.March
                        amont += amountsub
                        yearob.March = amont
                        break;
                      case 'April':
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        break;
                      case 'May':
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        break;
                      case 'June':
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        break;
                      case 'July':
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        break;
                      case 'August':
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        break;
                      case 'September':
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        break;
                      case 'October':
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        break;
                      case 'November':
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        break;
                      case 'December':
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        break;  
                      default:
                        break;
                    }
                }
                  if (parseInt(yearstamp) < parseInt(thisYearState) && parseInt(yearsub) < parseInt(thisYearState)) {                    
                    let amont = 0
                    let countersub = parseInt(thisYearState) - parseInt(yearstamp)
                    switch (monthsub) {
                      case 'January':
                        amont = yearob.January
                        amont += amountsub
                        yearob.January = amont
                        if (countersub === 1) {
                          earningsYearEx += amountsub
                          earningsTotalEx += amountsub
                        } else {
                          earningsYearEx += amountsub * countersub
                          earningsTotalEx += amountsub * countersub
                        }                        
                        break;
                      case 'February':
                        amont = yearob.February
                        amont += amountsub
                        yearob.February = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January') {
                          earningsYearEx += amountsub
                          earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'March':
                        amont = yearob.March
                        amont += amountsub
                        yearob.March = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'April':
                        amont = yearob.April
                        amont += amountsub
                        yearob.April = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'May':
                        amont = yearob.May
                        amont += amountsub
                        yearob.May = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'June':
                        amont = yearob.June
                        amont += amountsub
                        yearob.June = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'July':
                        amont = yearob.July
                        amont += amountsub
                        yearob.July = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'August':
                        amont = yearob.August
                        amont += amountsub
                        yearob.August = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'September':
                        amont = yearob.September
                        amont += amountsub
                        yearob.September = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'October':
                        amont = yearob.October
                        amont += amountsub
                        yearob.October = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'November':
                        amont = yearob.November
                        amont += amountsub
                        yearob.November = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;
                      case 'December':
                        amont = yearob.December
                        amont += amountsub
                        yearob.December = amont
                        if (countersub === 1) {
                        if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October' && monthtoday !== 'November') {
                          earningsYearEx += amountsub
                        earningsTotalEx += amountsub
                        }
                      } else {
                        earningsYearEx += amountsub * countersub
                        earningsTotalEx += amountsub * countersub
                      }
                        break;  
                      default:
                        break;
                    }
                  }
                      }
                      if (typesub === 'subscription' && renewsub === 'yes' && methodsub !== 'card' && durationsub === 'year') {
                        earningsTotalEx += amountsub
                        if (thisYearState === yearsub) {
                          earningsYearEx += amountsub
                          let amont = 0
                            switch (monthsub) {
                              case 'January':
                                amont = yearob.January
                                amont += amountsub
                                yearob.January = amont
                                break;
                              case 'February':
                                amont = yearob.February
                                amont += amountsub
                                yearob.February = amont
                                break;
                              case 'March':
                                amont = yearob.March
                                amont += amountsub
                                yearob.March = amont
                                break;
                              case 'April':
                                amont = yearob.April
                                amont += amountsub
                                yearob.April = amont
                                break;
                              case 'May':
                                amont = yearob.May
                                amont += amountsub
                                yearob.May = amont
                                break;
                              case 'June':
                                amont = yearob.June
                                amont += amountsub
                                yearob.June = amont
                                break;
                              case 'July':
                                amont = yearob.July
                                amont += amountsub
                                yearob.July = amont
                                break;
                              case 'August':
                                amont = yearob.August
                                amont += amountsub
                                yearob.August = amont
                                break;
                              case 'September':
                                amont = yearob.September
                                amont += amountsub
                                yearob.September = amont
                                break;
                              case 'October':
                                amont = yearob.October
                                amont += amountsub
                                yearob.October = amont
                                break;
                              case 'November':
                                amont = yearob.November
                                amont += amountsub
                                yearob.November = amont
                                break;
                              case 'December':
                                amont = yearob.December
                                amont += amountsub
                                yearob.December = amont
                                break;  
                              default:
                                break;
                            }
                        }
                        if (parseInt(yearsub) < parseInt(thisYearState) && parseInt(expyear) === parseInt(thisYearState)) {
                          let amont = 0
                          let countersub = parseInt(thisYearState) - parseInt(yearsub)
                          switch (monthsub) {
                            case 'January':
                              amont = yearob.January
                              amont += amountsub
                              yearob.January = amont
                              if (countersub === 1) {
                                earningsYearEx += amountsub
                                earningsTotalEx += amountsub
                              } else {
                                earningsYearEx += amountsub * countersub
                                earningsTotalEx += amountsub * countersub
                              }                        
                              break;
                            case 'February':
                              amont = yearob.February
                              amont += amountsub
                              yearob.February = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January') {
                                earningsYearEx += amountsub
                                earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'March':
                              amont = yearob.March
                              amont += amountsub
                              yearob.March = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'April':
                              amont = yearob.April
                              amont += amountsub
                              yearob.April = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'May':
                              amont = yearob.May
                              amont += amountsub
                              yearob.May = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'June':
                              amont = yearob.June
                              amont += amountsub
                              yearob.June = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'July':
                              amont = yearob.July
                              amont += amountsub
                              yearob.July = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'August':
                              amont = yearob.August
                              amont += amountsub
                              yearob.August = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'September':
                              amont = yearob.September
                              amont += amountsub
                              yearob.September = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'October':
                              amont = yearob.October
                              amont += amountsub
                              yearob.October = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'November':
                              amont = yearob.November
                              amont += amountsub
                              yearob.November = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;
                            case 'December':
                              amont = yearob.December
                              amont += amountsub
                              yearob.December = amont
                              if (countersub === 1) {
                              if (monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October' && monthtoday !== 'November') {
                                earningsYearEx += amountsub
                              earningsTotalEx += amountsub
                              }
                            } else {
                              earningsYearEx += amountsub * countersub
                              earningsTotalEx += amountsub * countersub
                            }
                              break;  
                            default:
                              break;
                          }
                      }
                      }
                      if (typesub === 'subscription' && renewsub === 'yes' && methodsub !== 'card' && durationsub === 'month') {
                        earningsTotalEx += amountsub
                        if (yearsub === thisYearState) {
                          let monthAmount = 0
                          switch (monthsub) {
                            case 'January':
                              monthAmount = yearob.January
                              monthAmount += amountsub
                              yearob.January = monthAmount  
                            monthAmount = yearob.February
                              monthAmount += amountsub
                              yearob.February = monthAmount
                              monthAmount = yearob.March
                              monthAmount += amountsub
                              yearob.March = monthAmount
                              monthAmount = yearob.April
                              monthAmount += amountsub
                              yearob.April = monthAmount
                              monthAmount = yearob.May
                              monthAmount += amountsub
                              yearob.May = monthAmount
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'January':
                                  earningsYearEx += amountsub
                                  break;
                                case 'February':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'March':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'April':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'May':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'June':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 8)
                                  earningsTotalEx += (amountsub * 7)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 9)
                                  earningsTotalEx += (amountsub * 8)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 10)
                                  earningsTotalEx += (amountsub * 9)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 11)
                                  earningsTotalEx += (amountsub * 10)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 12)
                                  earningsTotalEx += (amountsub * 11)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'February':
                              monthAmount = yearob.February
                              monthAmount += amountsub
                              yearob.February = monthAmount
                              monthAmount = yearob.March
                              monthAmount += amountsub
                              yearob.March = monthAmount
                              monthAmount = yearob.April
                              monthAmount += amountsub
                              yearob.April = monthAmount
                              monthAmount = yearob.May
                              monthAmount += amountsub
                              yearob.May = monthAmount
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'February':
                                  earningsYearEx += amountsub
                                  break;
                                case 'March':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'April':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'May':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'June':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 8)
                                  earningsTotalEx += (amountsub * 7)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 9)
                                  earningsTotalEx += (amountsub * 8)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 10)
                                  earningsTotalEx += (amountsub * 9)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 11)
                                  earningsTotalEx += (amountsub * 10)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'March':
                              monthAmount = yearob.March
                              monthAmount += amountsub
                              yearob.March = monthAmount
                              monthAmount = yearob.April
                              monthAmount += amountsub
                              yearob.April = monthAmount
                              monthAmount = yearob.May
                              monthAmount += amountsub
                              yearob.May = monthAmount
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'March':
                                  earningsYearEx += amountsub
                                  break;
                                case 'April':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'May':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'June':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 8)
                                  earningsTotalEx += (amountsub * 7)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 9)
                                  earningsTotalEx += (amountsub * 8)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 10)
                                  earningsTotalEx += (amountsub * 9)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'April':
                              monthAmount = yearob.April
                              monthAmount += amountsub
                              yearob.April = monthAmount
                              monthAmount = yearob.May
                              monthAmount += amountsub
                              yearob.May = monthAmount
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'April':
                                  earningsYearEx += amountsub
                                  break;
                                case 'May':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'June':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 8)
                                  earningsTotalEx += (amountsub * 7)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 9)
                                  earningsTotalEx += (amountsub * 8)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'May':
                              monthAmount = yearob.May
                              monthAmount += amountsub
                              yearob.May = monthAmount
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'May':
                                  earningsYearEx += amountsub
                                  break;
                                case 'June':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 8)
                                  earningsTotalEx += (amountsub * 7)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'June':
                              monthAmount = yearob.June
                              monthAmount += amountsub
                              yearob.June = monthAmount
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'June':
                                  earningsYearEx += amountsub
                                  break;
                                case 'July':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 7)
                                  earningsTotalEx += (amountsub * 6)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'July':
                              monthAmount = yearob.July
                              monthAmount += amountsub
                              yearob.July = monthAmount
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'July':
                                  earningsYearEx += amountsub
                                  break;
                                case 'August':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 6)
                                  earningsTotalEx += (amountsub * 5)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'August':
                              monthAmount = yearob.August
                              monthAmount += amountsub
                              yearob.August = monthAmount
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'August':
                                  earningsYearEx += amountsub
                                  break;
                                case 'September':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 5)
                                  earningsTotalEx += (amountsub * 4)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'September':
                              monthAmount = yearob.September
                              monthAmount += amountsub
                              yearob.September = monthAmount
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'September':
                                  earningsYearEx += amountsub
                                  break;
                                case 'October':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 4)
                                  earningsTotalEx += (amountsub * 3)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'October':
                              monthAmount = yearob.October
                              monthAmount += amountsub
                              yearob.October = monthAmount
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              switch (monthtoday) {
                                case 'October':
                                  earningsYearEx += amountsub
                                  break;
                                case 'November':
                                  earningsYearEx += (amountsub * 2)
                                  earningsTotalEx += amountsub
                                  break;
                                case 'December':
                                  earningsYearEx += (amountsub * 3)
                                  earningsTotalEx += (amountsub * 2)
                                  break;
                                default:
                                  break;
                              }
                              break;
                            case 'November':
                              monthAmount = yearob.November
                              monthAmount += amountsub
                              yearob.November = monthAmount
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              if (monthtoday === 'November') {
                                earningsYearEx += amountsub
                              }
                              if (monthtoday === 'December') {
                                earningsYearEx += (amountsub * 2)
                                earningsTotalEx += amountsub
                              }
                              break;
                            case 'December':
                              monthAmount = yearob.December
                              monthAmount += amountsub
                              yearob.December = monthAmount
                              if (monthtoday === 'December') {
                                earningsYearEx += amountsub
                              }
                              break;
                            default:
                              break;
                          }
                        } else {
                          let amounth = 0
                          amounth = yearob.January
                          amounth += amountsub
                              yearob.January = amounth
                              amounth = yearob.February
                              amounth += amountsub
                              yearob.February = amounth
                              amounth = yearob.March
                              amounth += amountsub
                              yearob.March = amounth
                              amounth = yearob.April
                              amounth += amountsub
                              yearob.April = amounth
                              amounth = yearob.May
                              amounth += amountsub
                              yearob.May = amounth
                              amounth = yearob.June
                              amounth += amountsub
                              yearob.June = amounth
                              amounth = yearob.July
                              amounth += amountsub
                              yearob.July = amounth
                              amounth = yearob.August
                              amounth += amountsub
                              yearob.August = amounth
                              amounth = yearob.September
                              amounth += amountsub
                              yearob.September = amounth
                              amounth = yearob.October
                              amounth += amountsub
                              yearob.October = amounth
                              amounth = yearob.November
                              amounth += amountsub
                              yearob.November = amounth
                              amounth = yearob.December
                              amounth += amountsub
                              yearob.December = amounth
                              let countersub = parseInt(thisYearState) - parseInt(yearsub)
                              switch (monthsub) {
                                case 'January':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 7
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 8
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 9
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 10
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 10
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 11
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 11
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'February':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                      earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 7
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 8
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 9
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 10
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 10
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'March':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 7
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 8
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 9
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 9
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'April':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 7
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 8
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 8
                                      }
                                      break;
                                    default:
                                      break;
                                  }
                                  break;
                                case 'May':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 7
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 7
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'June':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 6
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 6
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'July':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)                      
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 5
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 5
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'August':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 7
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub                      
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 3
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 3
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 4
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 4
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'September':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 8
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 7
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2                   
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'October':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 9
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 8
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 7
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3                  
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub * 2
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub * 2
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'November':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * 2)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 10
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 9
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 8
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 7
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4                
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx += amountsub
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                case 'December':
                                  switch (monthtoday) {
                                    case 'January':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub
                                      earningsTotalEx += amountsub
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 11
                                    }
                                      break;
                                    case 'February':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * 2)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 2
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 10
                                      }
                                      break;
                                    case 'March':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * 3)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 3
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 9
                                      }
                                      break;
                                    case 'April':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * 4)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 4
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 8
                                      }
                                      break;
                                    case 'May':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * 5)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 5
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 7
                                      }
                                      break;
                                    case 'June':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * 6)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 6
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 6
                                      }
                                      break;
                                    case 'July':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * 7)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 7
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 5               
                                      }
                                      break;
                                    case 'August':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * 8)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 8
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 4
                                      }
                                      break;
                                    case 'September':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * 9)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 9
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 3
                                      }
                                      break;
                                    case 'October':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * 10)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 10
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub * 2
                                      }
                                      break;
                                    case 'November':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * 11)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 11
                                      earningsTotalEx += (amountsub * multy)
                                      earningsTotalEx -= amountsub
                                      }
                                      break;
                                    case 'December':
                                      if (countersub === 1) {
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * 12)
                                      } else {
                                        let multy = countersub * 12
                                        earningsYearEx += amountsub * 12
                                      earningsTotalEx += (amountsub * multy)
                                    }
                                    break;
                                  default:
                                    break;
                                }
                                  break;
                                default:
                                  break;
                              }
                        }
                      }
      if (typesub === 'upgrade') {
        earningsTotalEx += amountsub
        if (thisYearState === yearstamp) {
          earningsYearEx += amountsub
          let monthAmount = 0
  switch (monthstamp) {
    case 'January':
      monthAmount = yearob.January
      monthAmount += amountsub
      yearob.January = monthAmount
      break;
    case 'February':
      monthAmount = yearob.February
      monthAmount += amountsub
      yearob.February = monthAmount
      break;
    case 'March':
      monthAmount = yearob.March
      monthAmount += amountsub
      yearob.March = monthAmount
      break;
    case 'April':
      monthAmount = yearob.April
      monthAmount += amountsub
      yearob.April = monthAmount
      break;
    case 'May':
      monthAmount = yearob.May
      monthAmount += amountsub
      yearob.May = monthAmount
      break;
    case 'June':
      monthAmount = yearob.June
      monthAmount += amountsub
      yearob.June = monthAmount
      break;
    case 'July':
      monthAmount = yearob.July
      monthAmount += amountsub
      yearob.July = monthAmount
      break;
    case 'August':
      monthAmount = yearob.August
      monthAmount += amountsub
      yearob.August = monthAmount
      break;
    case 'September':
      monthAmount = yearob.September
      monthAmount += amountsub
      yearob.September = monthAmount
      break;
    case 'October':
      monthAmount = yearob.October
      monthAmount += amountsub
      yearob.October = monthAmount
      break;
    case 'November':
      monthAmount = yearob.November
      monthAmount += amountsub
      yearob.November = monthAmount
      break;
    case 'December':
      monthAmount = yearob.December
      monthAmount += amountsub
      yearob.December = monthAmount
      break;  
    default:
      break;
  }
        }
              }
    }
    console.log('yearob: ' + yearob.January + 'yearnotex: ' + earningsYear + 'totalex: ' + earningsTotalEx)
    return (
      <div style={{maxHeight: '35vh', overflowY: 'auto'}}>
        <Grid container verticalAlign="middle" columns="equal" style={{marginBottom: '8px'}}>
        <Grid.Row columns={3}>
                  <Grid.Column></Grid.Column>
                <Grid.Column color="orange" style={{fontWeight: "bold", fontSize: '13pt', margin: 'auto'}}>Earnings</Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
              <Grid.Row columns={6}>
              <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                <Grid.Column color='violet' style={{border: "2px solid #FFFFFF",fontWeight: "bold", fontSize: '12pt'}}>Year</Grid.Column>
                <Grid.Column color='violet' style={{border: "2px solid #FFFFFF",fontWeight: "bold", fontSize: '12pt'}}>Income</Grid.Column>
                <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
              </Grid.Row>
              <Grid.Row columns={6}>
              <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                <Grid.Column color='violet' style={{border: "2px solid #FFFFFF",fontWeight: "bold", color: '#6435c9'}} textAlign="center" verticalAlign="middle">         
          <select
              value={this.state.year}
              onChange={this.handleThisYear}
              style={{fontWeight: "bold", fontSize: '12pt', color: '#6435c9', backgroundColor: '#FFFFFF'}}
            >
              <option style={{fontWeight: "bold", fontSize: '12pt', color: '#6435c9'}} value="2022">2022</option>
            </select>
      </Grid.Column>
      <Grid.Column color='violet' style={{border: "2px solid #FFFFFF",fontWeight: "bold", fontSize: '12pt'}}>{earningsYearEx + ' ' + currency}</Grid.Column>
      <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
      </Grid.Row>
      <Grid.Row columns={6}>
      <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
      <Grid.Column color="violet" style={{border: "2px solid #FFFFFF",fontWeight: "bold", fontSize: '12pt'}}>All</Grid.Column>
                <Grid.Column color="violet" style={{border: "2px solid #FFFFFF",fontWeight: "bold", fontSize: '12pt'}}>{earningsTotal + ' ' + currency}</Grid.Column>
                <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
              </Grid.Row>
              </Grid>
              <Grid container  verticalAlign="middle" columns="equal" style={{marginBottom: '6px'}}>
                <Grid.Row columns={8}>
                  <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                  <Grid.Column textAlign="center" color="violet" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '11pt', overflow: 'auto'}}>
                    Earned
                  </Grid.Column>
                  <Grid.Column textAlign="center" color="orange" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '11pt', overflow: 'auto'}}>
                    Estimated
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid container celled padded verticalAlign="middle" style={{ backgroundColor: "white"}}>
              <Grid.Row columns={13}>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", color: '#6435c9', overflow: 'auto'}}>Month</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>January</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>February</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>March</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>April</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>May</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>June</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>July</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>August</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>September</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>October</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>November</Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', padding: "3px", overflow: 'auto'}}>December</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={13}>
              <Grid.Column textAlign="center" verticalAlign="middle" style={{fontWeight: "bold", fontSize: '10pt', color: '#6435c9', padding: "3px", overflow: 'auto'}}>Income</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.January + ' ' + currency}</Grid.Column>
                {(monthtoday === 'January') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.February + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.February + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.March + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.March + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.April + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.April + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.May + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.May + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.June + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.June + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.July + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.July + ' ' + currency}</Grid.Column>}                
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June' || monthtoday === 'July') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.August + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.August + ' ' + currency}</Grid.Column>}                
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June' || monthtoday === 'July' || monthtoday === 'August') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.September + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.September + ' ' + currency}</Grid.Column>}                
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June' || monthtoday === 'July' || monthtoday === 'August' || monthtoday === 'September') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.October + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.October + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June' || monthtoday === 'July' || monthtoday === 'August' || monthtoday === 'September' || monthtoday === 'October') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.November + ' ' + currency}</Grid.Column>}
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.November + ' ' + currency}</Grid.Column>}
                {(monthtoday === 'January' || monthtoday === 'February' || monthtoday === 'March' || monthtoday === 'April' || monthtoday === 'May' || monthtoday === 'June' || monthtoday === 'July' || monthtoday === 'August' || monthtoday === 'September' || monthtoday === 'October' || monthtoday === 'November') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="orange">{yearExpand.December + ' ' + currency}</Grid.Column>}                
                {(monthtoday !== 'January' && monthtoday !== 'February' && monthtoday !== 'March' && monthtoday !== 'April' && monthtoday !== 'May' && monthtoday !== 'June' && monthtoday !== 'July' && monthtoday !== 'August' && monthtoday !== 'September' && monthtoday !== 'October' && monthtoday !== 'November') && <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: "3px", fontSize: '10pt'}} textAlign="center" verticalAlign="middle" color="violet">{yearExpand.December + ' ' + currency}</Grid.Column>}
              </Grid.Row>
              </Grid>         
              </div>
    )
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return (
    <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                    {this.renderCompletePage()}                               
                </Grid.Column>
              </Grid.Row>            
            </Grid>
  )
}

}
