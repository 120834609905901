/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Header, Menu, Loader, Card, Form, TextArea, Divider, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { createCampaign, getUsers, getCoupons } from '../api/users-api'
import { CheckElementCampaign } from './CheckElementCampaign'
import { CardElement } from './CardElement'
import { UserModel } from '../types/UserModel'
import { ClipboardCopy } from './ClipboardCopy'
import { CampaignModelApi } from '../types/CampaignModel'
import { FileUploader } from './FileUploader'

interface CampaignProcessProps {
  auth: Auth,
  history: any
}

interface CampaignProcessState {
activeItem: string,
loading: boolean,
emailCheck: string[],
emailView: string[],
formInput: string,
selectCheck: boolean,
selectClear: boolean,
users: UserModel[],
hourCard: string,
dayCard: string[],
monthCard: string[],
yearCard: string,
hourSelect: string,
daySelect: string,
monthSelect: string,
modeForm: string,
yearForm: string,
campaignName: string,
campaignDescription: any,
campaignMessage: any,
start_date: string,
end_date: string,
loadingCards: boolean,
chooseMode: string,
statusUp: string,
coupon: string,
exp: string
}

export class CampaignProcess extends React.PureComponent<
CampaignProcessProps,
CampaignProcessState
> {
  state: CampaignProcessState = {
activeItem: 'step1',
emailCheck: [],
emailView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    loading: true,
    users: [],
    hourCard: 'none',
    dayCard: [],
    monthCard: [],
    yearCard: '',
    hourSelect: '00:00',
    daySelect: 'Monday',
    monthSelect: 'January',
    modeForm: 'One-Shot',
    yearForm: 'Only this year',
    campaignName: '',
    campaignDescription: '',
    campaignMessage: '',
    start_date: 'none',
end_date: 'none',
loadingCards: false,
chooseMode: 'none',
statusUp: 'not uploaded yet!',
coupon: 'none',
exp: 'none'
  }

  handleModeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
if (event.target.value === 'One-Shot') {
  this.setState({modeForm: event.target.value, yearForm: 'Only this year'})
} else {
  this.setState({modeForm: event.target.value, yearForm: 'Every year'})
}
  }

  handleMonthSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({monthSelect: event.target.value})
      }

      handleUploadSelect = () => {
        this.setState({chooseMode: 'upload'})
        }

        handleTargetsSelect = () => {
          this.setState({chooseMode: 'list'})
          }

          handleStartProcess = () => {
            this.setState({statusUp: 'uploading...'})
            }
            handleErrorProcess = (error: string) => {
              this.setState({statusUp: 'not uploaded yet!'})
              console.log({error})
            }
            handleReadSuccess = (result: string) => {
              try {
                const emailArray = result.split(',')
                if (emailArray.length === 0) {
                  throw new Error('No content in the file!')
                }
                this.setState({emailCheck: emailArray, statusUp: 'upload successful!'})
              } catch (error) {
                alert('Reading file error! Check correct format and extension.')
              }
            }

      handleDaySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({daySelect: event.target.value})
        }

        handleHourSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
          this.setState({hourSelect: event.target.value})
          }

          handleCampaignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let message = ''
            let value = event.target.value
            if (value) {
              if (typeof value === 'string') {                     
                let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
              }
            }
            this.setState({ campaignName: message })
          }

  handleCloseHour = () => {
    this.setState({loadingCards: true})
    this.setState({ hourCard: 'none'})
    this.setState({loadingCards: false})
  }

  handleCloseDay = (title: string) => {
    this.setState({loadingCards: true})
    let myIndex = this.state.dayCard.indexOf(title)
    if (myIndex !== -1) {
      let dayCardArr = this.state.dayCard
dayCardArr.splice(myIndex, 1)
      this.setState({ dayCard: dayCardArr})
    }
    this.setState({loadingCards: false})
  }

  handleCloseMonth = (title: string) => {
    this.setState({loadingCards: true})
    let myIndex = this.state.monthCard.indexOf(title)
    if (myIndex !== -1) {
      let monthCardArr = this.state.monthCard
monthCardArr.splice(myIndex, 1)
      this.setState({ monthCard: monthCardArr})
    }
    this.setState({loadingCards: false})
  }

  handleAddHour = () => {
    this.setState({loadingCards: true})
let {hourCard} = this.state
let {hourSelect} = this.state
if (hourCard === 'none') {
  if (hourSelect === 'none') {
    alert('You need to select an HOUR first!')
  } else {
    this.setState({hourCard: hourSelect})
  }
 } else {
alert('You already have scheduled your HOUR!')
 }
 this.setState({loadingCards: false})
  }

  handleAddDay = () => {
    this.setState({loadingCards: true})
    let {dayCard} = this.state
    let {daySelect} = this.state
    let dayCardString = dayCard.toString()
    if (dayCardString.includes(daySelect)) {
      alert('You already scheduled this OPTION!')
    } else {
      if (dayCard.length > 6) {
        alert('You already scheduled maximum number of DAYS!')
     } else {
      if (daySelect === 'none') {
        alert('You need to select a DAY first!')
      } else {
        if (dayCard[0] === 'All Week') {
          alert('You already scheduled all DAYS of the WEEK!')
        } else {
          if (dayCard.length > 0 && daySelect === 'All Week') {
            alert('You need to delete the DAYS you already scheduled before adding all WEEK!')
          } else {
            dayCard.push(daySelect)
            this.setState({dayCard})
          }  
        }
      }
     }
    } 
    this.setState({loadingCards: false})   
      }

  handleAddMonth = () => {    
    this.setState({loadingCards: true})
    let checkbool = false
    if (this.state.yearForm === 'Only this year') {
let monthtoday = new Date().toLocaleString('en-US', { month: 'long' })
let {monthSelect} = this.state
switch (monthtoday) {
    case 'February':
    if (monthSelect === 'January') {
      checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
    }
      break;
      case 'March':
        if (monthSelect === 'January' || monthSelect === 'February') {
          checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
              }
    break;
    case 'April':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'May':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'June':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'July':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'August':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June' || monthSelect === 'July') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'September':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June' || monthSelect === 'July' || monthSelect === 'August') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'October':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June' || monthSelect === 'July' || monthSelect === 'August' || monthSelect === 'September') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'November':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June' || monthSelect === 'July' || monthSelect === 'August' || monthSelect === 'September' || monthSelect === 'October') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
    case 'December':
      if (monthSelect === 'January' || monthSelect === 'February' || monthSelect === 'March' || monthSelect === 'April' || monthSelect === 'May' || monthSelect === 'June' || monthSelect === 'July' || monthSelect === 'August' || monthSelect === 'September' || monthSelect === 'October' || monthSelect === 'November') {
        checkbool = true
alert('Attention! The month you selected will not be activated. You need to set MODE to CONTINUOUS (every year)')
            }
    break;
  default:
    break;
}
    }
    if (!checkbool) {
    let {monthCard} = this.state
    let {monthSelect} = this.state
    let monthCardString = monthCard.toString()
    if (monthCardString.includes(monthSelect)) {
      alert('You already scheduled this OPTION!')
    } else {
      if (monthCard.length > 11) {
        alert('You already scheduled maximum number of MONTHS!')
     } else {
      if (monthSelect === 'none') {
        alert('You need to select a MONTH first!')
      } else {
        if (monthCard[0] === 'Every Month') {
          alert('You already scheduled every MONTH!')
        } else {
          if (monthSelect === 'Every Month' && monthCard.length > 0) {
            alert('You need to delete the MONTHS you already scheduled before adding every MONTH!') 
          } else {
            monthCard.push(monthSelect)
            this.setState({monthCard})
          }
      }
      }
     }
    }
  }
    this.setState({loadingCards: false})  
      }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ formInput: event.target.value })
}

  handleNextTwo = async () => {
if (this.state.emailCheck.length === 0) {
  alert('Attention! You need to select at least one user!')
} else {
    this.setState({ activeItem: 'step2'})
  }
  }

  handleNextFour = async () => {
    if (!this.state.campaignName || !this.state.campaignMessage || !this.state.campaignDescription) {
      alert('Attention! You need to insert all details of the campaign including Name, Description and Message')
      return
    }
    this.setState({ activeItem: 'step4' })
  }

  handleNextThree = async () => {
    if (this.state.hourCard === 'none' || this.state.dayCard.length === 0 || this.state.monthCard.length === 0) {
      alert('Attention! You need to add all the SCHEDULE (hour, day and month)')
      return
    }
    let monthCardString = this.state.monthCard.toString()
    console.log(monthCardString)
let dayCardString = this.state.dayCard.toString()
console.log(dayCardString)
let start = 0
let end = 0
let checkMonthExp = false
let thistoday = new Date()
let todayYear = thistoday.getFullYear()
    if (monthCardString.includes('Every Month')) {
let thisDate = new Date()
thisDate.setMonth(11)
if (dayCardString.includes('All Week')) {
  let cardArr = this.state.hourCard.split(':')
let hour = parseInt(cardArr[0])
let minutes = parseInt(cardArr[1])
thisDate.setMonth(12)
thisDate.setDate(0)
thisDate.setHours(hour)
thisDate.setMinutes(minutes)
end = thisDate.getTime()
let todayDate = new Date()
const current = todayDate.getHours() + ':' + todayDate.getMinutes()
if (current === this.state.hourCard) {
let date = todayDate.getDate()
todayDate.setDate(date + 1)
todayDate.setHours(hour)
todayDate.setMinutes(minutes)
start = todayDate.getTime()
} else {
  let cardArr = this.state.hourCard.split(':')
let hour = parseInt(cardArr[0])
let minutes = parseInt(cardArr[1])
let currentArr = current.split(':')
let currentHour = parseInt(currentArr[0])
let currentMinutes = parseInt(currentArr[1])
if (currentHour >= hour) {
  let date = todayDate.getDate()
  todayDate.setDate(date + 1)
  todayDate.setHours(hour)
  todayDate.setMinutes(minutes)
  start = todayDate.getTime()
} else {
  if (currentHour === hour) {
    if (currentMinutes >= minutes) {
      let date = todayDate.getDate()
      todayDate.setDate(date + 1)
      todayDate.setHours(hour)
      todayDate.setMinutes(minutes)
      start = todayDate.getTime()
    } else {
      todayDate.setHours(hour)
      todayDate.setMinutes(minutes)
      start = todayDate.getTime()
    }
  } else {
    todayDate.setHours(hour)
  todayDate.setMinutes(minutes)
  start = todayDate.getTime()
  }
}
}
} else {
  let daytoday = new Date()
  let toDateNumber = daytoday.getDay()
  let monthDate = new Date()
  let thisYear = monthDate.getFullYear()
  monthDate.setFullYear(thisYear + 1)
  monthDate.setMonth(0)
  monthDate.setDate(0)
  let endDateNumber = monthDate.getDay()
  let dayArr = dayCardString
  dayArr = dayArr.replace('Monday', '1').replace('Tuesday', '2').replace('Wednesday', '3').replace('Thursday', '4').replace('Friday', '5').replace('Saturday', '6').replace('Sunday', '0')
              let dayArrNumbers = dayArr.split(',')
              let daySubNumberStart = 0
              let dayAddNumberStart = 8
              let daySubNumberEnd = 0
              let dayAddNumberEnd = 8
              let equalBoolStart = false
              let equalBoolEnd = false
              for (let index = 0; index < dayArrNumbers.length; index++) {
                const element = dayArrNumbers[index]
                const intElement = parseInt(element)
                if (intElement > endDateNumber) {
                  if (daySubNumberEnd < (intElement - endDateNumber)) {
                    daySubNumberEnd = intElement - endDateNumber
                  }
                } else {
                  if (intElement === endDateNumber) {
                    equalBoolEnd = true
                  } else {
                    if (dayAddNumberEnd > (endDateNumber - intElement)) {
                      dayAddNumberEnd = endDateNumber - intElement
                    }        
                  }      
                }
                if (intElement > toDateNumber) {
                  if (dayAddNumberStart > (intElement - toDateNumber)) {
                    dayAddNumberStart = intElement - toDateNumber
                  }
                } else {
                  if (intElement ===  toDateNumber) {
                    equalBoolStart = true
                  } else {
                    if (daySubNumberStart < (toDateNumber - intElement)) {
                      daySubNumberStart = toDateNumber - intElement
                    }        
                  }      
                }
              }
              daySubNumberStart = 7 - daySubNumberStart
              if (equalBoolStart) {
                let todayDate = new Date()
              const current = todayDate.getHours() + ':' + todayDate.getMinutes()
              if (current === this.state.hourCard) {
              let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let date = todayDate.getDate()
              todayDate.setDate(date + 1)
              let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
              todayDate.setHours(hour)
              todayDate.setMinutes(minutes)
              start = todayDate.getTime()
              } else {
                let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let currentArr = current.split(':')
              let currentHour = parseInt(currentArr[0])
              let currentMinutes = parseInt(currentArr[1])
              if (currentHour >= hour) {
                let date = todayDate.getDate()
                todayDate.setDate(date + 1)
                let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
              } else {
                if (currentHour === hour) {
                  if (currentMinutes >= minutes) {
                    let date = todayDate.getDate()
                    todayDate.setDate(date + 1)
                    let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  } else {
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  }
                } else {
                  todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
                }
              }            
              }
              } else {
                if (dayAddNumberStart === 8) {
                  let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  let date = daytoday.getDate()
                  daytoday.setDate(date + daySubNumberStart)
                  daytoday.setHours(hour)
                  daytoday.setMinutes(minutes)
                  start = daytoday.getTime()
                } else {
                  let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  let date = daytoday.getDate()
                  daytoday.setDate(date + dayAddNumberStart)
                  daytoday.setHours(hour)
                  daytoday.setMinutes(minutes)
                  start = daytoday.getTime()
              }
              }  
              daySubNumberEnd = 7 - daySubNumberEnd
            if (equalBoolEnd) {
              let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  monthDate.setHours(hour)
                  monthDate.setMinutes(minutes)
                  end = monthDate.getTime()
            } else {
              if (dayAddNumberEnd === 8) {
                let cardArr = this.state.hourCard.split(':')
                let hour = parseInt(cardArr[0])
                let minutes = parseInt(cardArr[1])
                    let date = monthDate.getDate()
                    monthDate.setDate(date - daySubNumberEnd)
                    monthDate.setHours(hour)
                    monthDate.setMinutes(minutes)
                    end = monthDate.getTime()
              } else {
                let cardArr = this.state.hourCard.split(':')
                let hour = parseInt(cardArr[0])
                let minutes = parseInt(cardArr[1])
                    let date = monthDate.getDate()
                    monthDate.setDate(date - dayAddNumberEnd)                    
                    monthDate.setHours(hour)
                    monthDate.setMinutes(minutes)
                    end = monthDate.getTime()
              }
            }
}
    } else {
      let monthNumberStart = 0
      let monthNumberEnd = 0
          if (monthCardString.includes('February')) {
        monthNumberEnd = 1
if (!monthCardString.includes('January')) {
monthNumberStart = 1
}
          }
          if (monthCardString.includes('March')) {
            monthNumberEnd = 2
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              monthNumberStart = 2
              } else {
monthNumberStart = 1
              }
          }
        }
          if (monthCardString.includes('April')) {
        monthNumberEnd = 3
        if (!monthCardString.includes('January')) {
          if (!monthCardString.includes('February')) {
          if (!monthCardString.includes('March')) {
            monthNumberStart = 3
          } else {
            monthNumberStart = 2
          }
          } else {
monthNumberStart = 1
          }
      }
          }
          if (monthCardString.includes('May')) {
            monthNumberEnd = 4            
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  monthNumberStart = 4
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('June')) {
            monthNumberEnd = 5
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    monthNumberStart = 5
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('July')) {
            monthNumberEnd = 6
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    if (!monthCardString.includes('June')) {
                      monthNumberStart = 6
                    } else {
                      monthNumberStart = 5
                    }
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('August')) {
            monthNumberEnd = 7
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    if (!monthCardString.includes('June')) {
                      if (!monthCardString.includes('July')) {
                        monthNumberStart = 7
                      } else {
                        monthNumberStart = 6
                      }
                    } else {
                      monthNumberStart = 5
                    }
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('September')) {
            monthNumberEnd = 8
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    if (!monthCardString.includes('June')) {
                      if (!monthCardString.includes('July')) {
                        if (!monthCardString.includes('August')) {
                          monthNumberStart = 8
                        } else {
                          monthNumberStart = 7
                        }
                      } else {
                        monthNumberStart = 6
                      }
                    } else {
                      monthNumberStart = 5
                    }
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('October')) {
          monthNumberEnd = 9
          if (!monthCardString.includes('January')) {
            if (!monthCardString.includes('February')) {
            if (!monthCardString.includes('March')) {
              if (!monthCardString.includes('April')) {
                if (!monthCardString.includes('May')) {
                  if (!monthCardString.includes('June')) {
                    if (!monthCardString.includes('July')) {
                      if (!monthCardString.includes('August')) {
                        if (!monthCardString.includes('September')) {
                          monthNumberStart = 9
                        } else {
                          monthNumberStart = 8
                        }
                      } else {
                        monthNumberStart = 7
                      }
                    } else {
                      monthNumberStart = 6
                    }
                  } else {
                    monthNumberStart = 5
                  }
                } else {
                  monthNumberStart = 4
                }                  
              } else {
                monthNumberStart = 3
              }  
            } else {
              monthNumberStart = 2
            }
            } else {
  monthNumberStart = 1
            }
        }
          }
          if (monthCardString.includes('November')) {
            monthNumberEnd = 10
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    if (!monthCardString.includes('June')) {
                      if (!monthCardString.includes('July')) {
                        if (!monthCardString.includes('August')) {
                          if (!monthCardString.includes('September')) {
                            if (!monthCardString.includes('October')) {
                              monthNumberStart = 10
                            } else {
                              monthNumberStart = 9
                            }
                          } else {
                            monthNumberStart = 8
                          }
                        } else {
                          monthNumberStart = 7
                        }
                      } else {
                        monthNumberStart = 6
                      }
                    } else {
                      monthNumberStart = 5
                    }
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (monthCardString.includes('December')) {
            monthNumberEnd = 11
            if (!monthCardString.includes('January')) {
              if (!monthCardString.includes('February')) {
              if (!monthCardString.includes('March')) {
                if (!monthCardString.includes('April')) {
                  if (!monthCardString.includes('May')) {
                    if (!monthCardString.includes('June')) {
                      if (!monthCardString.includes('July')) {
                        if (!monthCardString.includes('August')) {
                          if (!monthCardString.includes('September')) {
                            if (!monthCardString.includes('October')) {
                              if (!monthCardString.includes('November')) {
                                monthNumberStart = 11
                              } else {
                                monthNumberStart = 10
                              }
                            } else {
                              monthNumberStart = 9
                            }
                          } else {
                            monthNumberStart = 8
                          }
                        } else {
                          monthNumberStart = 7
                        }
                      } else {
                        monthNumberStart = 6
                      }
                    } else {
                      monthNumberStart = 5
                    }
                  } else {
                    monthNumberStart = 4
                  }                  
                } else {
                  monthNumberStart = 3
                }  
              } else {
                monthNumberStart = 2
              }
              } else {
    monthNumberStart = 1
              }
          }
          }
          if (dayCardString.includes('All Week')) {
            let monthtoday = new Date().toLocaleString('en-US', { month: 'long' })
            let thisDate = new Date()
            thisDate.setDate(1)
thisDate.setMonth(monthNumberEnd + 1)
thisDate.setDate(0)
  let cardArr = this.state.hourCard.split(':')
let hour = parseInt(cardArr[0])
let minutes = parseInt(cardArr[1])
thisDate.setHours(hour)
thisDate.setMinutes(minutes)
end = thisDate.getTime()
            if (monthCardString.includes(monthtoday)) {
              let todayDate = new Date()
              const current = todayDate.getHours() + ':' + todayDate.getMinutes()
              if (current === this.state.hourCard) {
              let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let date = todayDate.getDate()
              todayDate.setDate(date + 1)
              let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              todayDate.setHours(hour)
              todayDate.setMinutes(minutes)
              start = todayDate.getTime()
              } else {
                let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let currentArr = current.split(':')
              let currentHour = parseInt(currentArr[0])
              let currentMinutes = parseInt(currentArr[1])
              if (currentHour >= hour) {
                let date = todayDate.getDate()
                todayDate.setDate(date + 1)
                let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
                todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
              } else {
                if (currentHour === hour) {
                  if (currentMinutes >= minutes) {
                    let date = todayDate.getDate()
                    todayDate.setDate(date + 1)
                    let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  } else {
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  }
                } else {
                  todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
                }
              }            
              }
            } else {
              let thisDate = new Date()
              thisDate.setMonth(monthNumberStart)
                let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              thisDate.setHours(hour)
              thisDate.setMinutes(minutes)
              start = thisDate.getTime()
            }            
          } else {
            let monthtoday = new Date().toLocaleString('en-US', { month: 'long' })
            let daytoday = new Date()
            let toDateNumber = daytoday.getDay()
            let monthDate = new Date()
            monthDate.setDate(1)
            monthDate.setMonth(monthNumberEnd +1)
            monthDate.setDate(0)
            let endDateNumber = monthDate.getDay()
            console.log('end date number: ' + endDateNumber)
            if (monthCardString.includes(monthtoday)) {
              let dayArr = dayCardString
              console.log(dayCardString)
              dayArr = dayArr.replace('Monday', '1').replace('Tuesday', '2').replace('Wednesday', '3').replace('Thursday', '4').replace('Friday', '5').replace('Saturday', '6').replace('Sunday', '0')
              let dayArrNumbers = dayArr.split(',')
              let daySubNumberStart = 0
              let dayAddNumberStart = 8
              let daySubNumberEnd = 0
              let dayAddNumberEnd = 8
              let equalBoolStart = false
              let equalBoolEnd = false
              for (let index = 0; index < dayArrNumbers.length; index++) {
                const element = dayArrNumbers[index]
                const intElement = parseInt(element)
                if (intElement > endDateNumber) {
                  if (daySubNumberEnd < (intElement - endDateNumber)) {
                    daySubNumberEnd = intElement - endDateNumber
                  }
                } else {
                  if (intElement === endDateNumber) {
                    equalBoolEnd = true
                  } else {
                    if (dayAddNumberEnd > (endDateNumber - intElement)) {
                      dayAddNumberEnd = endDateNumber - intElement
                    }        
                  }      
                }
                if (intElement > toDateNumber) {
                  if (dayAddNumberStart > (intElement - toDateNumber)) {
                    dayAddNumberStart = intElement - toDateNumber
                  }
                } else {
                  if (intElement ===  toDateNumber) {
                    equalBoolStart = true
                  } else {
                    if (daySubNumberStart < (toDateNumber - intElement)) {
                      daySubNumberStart = toDateNumber - intElement
                    }        
                  }      
                }
              }
              daySubNumberStart = 7 - daySubNumberStart
              if (equalBoolStart) {
                let todayDate = new Date()
              const current = todayDate.getHours() + ':' + todayDate.getMinutes()
              if (current === this.state.hourCard) {
              let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let date = todayDate.getDate()
              todayDate.setDate(date + 1)
              let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
              todayDate.setHours(hour)
              todayDate.setMinutes(minutes)
              start = todayDate.getTime()
              } else {
                let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
              let currentArr = current.split(':')
              let currentHour = parseInt(currentArr[0])
              let currentMinutes = parseInt(currentArr[1])
              if (currentHour >= hour) {
                let date = todayDate.getDate()
                todayDate.setDate(date + 1)
                let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
              } else {
                if (currentHour === hour) {
                  if (currentMinutes >= minutes) {
                    let date = todayDate.getDate()
                    todayDate.setDate(date + 1)
                    let stringMonth = todayDate.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  todayDate.setMonth(2)
  todayDate.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    todayDate.setMonth(3)
  todayDate.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      todayDate.setMonth(4)
  todayDate.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        todayDate.setMonth(5)
  todayDate.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          todayDate.setMonth(6)
  todayDate.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            todayDate.setMonth(7)
  todayDate.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              todayDate.setMonth(8)
  todayDate.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                todayDate.setMonth(9)
  todayDate.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  todayDate.setMonth(10)
  todayDate.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    todayDate.setMonth(11)
  todayDate.setDate(1)
                  } else {
                    let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      todayDate.setMonth(3)
  todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        todayDate.setMonth(4)
  todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          todayDate.setMonth(5)
  todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            todayDate.setMonth(6)
  todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              todayDate.setMonth(7)
  todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                todayDate.setMonth(8)
  todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  todayDate.setMonth(9)
  todayDate.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    todayDate.setMonth(10)
  todayDate.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      todayDate.setMonth(11)
  todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(0)
  todayDate.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = todayDate.getFullYear()
                    todayDate.setFullYear(todayYear + 1)
                    todayDate.setMonth(1)
  todayDate.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      todayDate.setMonth(4)
                todayDate.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        todayDate.setMonth(5)
                todayDate.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          todayDate.setMonth(6)
                todayDate.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            todayDate.setMonth(7)
                todayDate.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              todayDate.setMonth(8)
                todayDate.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                todayDate.setMonth(9)
                todayDate.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    todayDate.setMonth(11)
                todayDate.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = todayDate.getFullYear()
                                                          todayDate.setFullYear(todayYear + 1)
                                                          todayDate.setMonth(0)
                                        todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(1)
                                          todayDate.setDate(1)
                                                          } else {
                                                            let todayYear = todayDate.getFullYear()
                                                            todayDate.setFullYear(todayYear + 1)
                                                            todayDate.setMonth(2)
                                          todayDate.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        todayDate.setMonth(5)
                                todayDate.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          todayDate.setMonth(6)
                                todayDate.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            todayDate.setMonth(7)
                                todayDate.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              todayDate.setMonth(8)
                                todayDate.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                todayDate.setMonth(9)
                                todayDate.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  todayDate.setMonth(10)
                                todayDate.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    todayDate.setMonth(11)
                                todayDate.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                      todayDate.setFullYear(todayYear + 1)
                                                                      todayDate.setMonth(0)
                                                    todayDate.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(1)
                                                      todayDate.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = todayDate.getFullYear()
                                                                        todayDate.setFullYear(todayYear + 1)
                                                                        todayDate.setMonth(2)
                                                      todayDate.setDate(1)
                                                                              } else {
                                                                                let todayYear = todayDate.getFullYear()
                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                todayDate.setMonth(3)
                                                              todayDate.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          todayDate.setMonth(6)
                                                todayDate.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            todayDate.setMonth(7)
                                                todayDate.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              todayDate.setMonth(8)
                                                todayDate.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                todayDate.setMonth(9)
                                                todayDate.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  todayDate.setMonth(10)
                                                todayDate.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    todayDate.setMonth(11)
                                                todayDate.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                      todayDate.setMonth(0)
                                                                    todayDate.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(1)
                                                                      todayDate.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(2)
                                                                      todayDate.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                        todayDate.setMonth(3)
                                                                      todayDate.setDate(1)
                    } else {
                      let todayYear = todayDate.getFullYear()
                      todayDate.setFullYear(todayYear + 1)
                      todayDate.setMonth(4)
    todayDate.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            todayDate.setMonth(7)
                                                                todayDate.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              todayDate.setMonth(8)
                                                                todayDate.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                todayDate.setMonth(9)
                                                                todayDate.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  todayDate.setMonth(10)
                                                                todayDate.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    todayDate.setMonth(11)
                                                                todayDate.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                todayDate.setFullYear(todayYear + 1)
                                                                                                                todayDate.setMonth(0)
                                                                                              todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(1)
                                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(2)
                                                                                                todayDate.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(3)
                                                                                                todayDate.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                                                                                                  todayDate.setFullYear(todayYear + 1)
                                                                                                                  todayDate.setMonth(4)
                                                                                                todayDate.setDate(1)
                      } else {
                        let todayYear = todayDate.getFullYear()
                        todayDate.setFullYear(todayYear + 1)
                        todayDate.setMonth(5)
      todayDate.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              todayDate.setMonth(8)
                                                                                todayDate.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                todayDate.setMonth(9)
                                                                                todayDate.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  todayDate.setMonth(10)
                                                                                todayDate.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    todayDate.setMonth(11)
                                                                                todayDate.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                      todayDate.setMonth(0)
                                                                                                    todayDate.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(1)
                                                                                                      todayDate.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(2)
                                                                                                      todayDate.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(3)
                                                                                                      todayDate.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(4)
                                                                                                      todayDate.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                        todayDate.setMonth(5)
                                                                                                      todayDate.setDate(1)
                        } else {
                          let todayYear = todayDate.getFullYear()
                          todayDate.setFullYear(todayYear + 1)
                          todayDate.setMonth(6)
        todayDate.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                todayDate.setMonth(9)
                                                                                                todayDate.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  todayDate.setMonth(10)
                                                                                                todayDate.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    todayDate.setMonth(11)
                                                                                                todayDate.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                      todayDate.setMonth(0)
                                                                                                                    todayDate.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(1)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(2)
                                                                                                                      todayDate.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(3)
                                                                                                                      todayDate.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(4)
                                                                                                                      todayDate.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(5)
                                                                                                                      todayDate.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                        todayDate.setMonth(6)
                                                                                                                      todayDate.setDate(1)
                          } else {
                            let todayYear = todayDate.getFullYear()
                            todayDate.setFullYear(todayYear + 1)
                            todayDate.setMonth(7)
          todayDate.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  todayDate.setMonth(10)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                todayDate.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                      todayDate.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                      todayDate.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                      todayDate.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                      todayDate.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                      todayDate.setDate(1)
                            } else {
                              let todayYear = todayDate.getFullYear()
                              todayDate.setFullYear(todayYear + 1)
                              todayDate.setMonth(8)
            todayDate.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    todayDate.setMonth(11)
                                                                                                                                todayDate.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                      todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                      todayDate.setMonth(0)
                                                                                                                                                    todayDate.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(1)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(2)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(3)
                                                                                                                                                      todayDate.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(4)
                                                                                                                                                      todayDate.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(5)
                                                                                                                                                      todayDate.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(6)
                                                                                                                                                      todayDate.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(7)
                                                                                                                                                      todayDate.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                                                                                                                                                        todayDate.setFullYear(todayYear + 1)
                                                                                                                                                                        todayDate.setMonth(8)
                                                                                                                                                      todayDate.setDate(1)
                              } else {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(9)
              todayDate.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(0)
              todayDate.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(1)
              todayDate.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(2)
              todayDate.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(3)
              todayDate.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(4)
              todayDate.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(5)
              todayDate.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(6)
              todayDate.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(7)
              todayDate.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = todayDate.getFullYear()
                                todayDate.setFullYear(todayYear + 1)
                                todayDate.setMonth(8)
              todayDate.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(9)
                todayDate.setDate(1)
                                } else {
                                  let todayYear = todayDate.getFullYear()
                                  todayDate.setFullYear(todayYear + 1)
                                  todayDate.setMonth(10)
                todayDate.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              let dayCheck = todayDate.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  todayDate.setDate(todayDate.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    todayDate.setDate(todayDate.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      todayDate.setDate(todayDate.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        todayDate.setDate(todayDate.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          todayDate.setDate(todayDate.getDate() + 5)
        } else {
          todayDate.setDate(todayDate.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    todayDate.setDate(todayDate.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      todayDate.setDate(todayDate.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        todayDate.setDate(todayDate.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          todayDate.setDate(todayDate.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            todayDate.setDate(todayDate.getDate() + 5)
                          } else {
                            todayDate.setDate(todayDate.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  } else {
                    todayDate.setHours(hour)
                    todayDate.setMinutes(minutes)
                    start = todayDate.getTime()
                  }
                } else {
                  todayDate.setHours(hour)
                todayDate.setMinutes(minutes)
                start = todayDate.getTime()
                }
              }            
              }
              } else {
                if (dayAddNumberStart === 8) {
                  let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  let date = daytoday.getDate()
                  daytoday.setDate(date + daySubNumberStart)
                  let stringMonth = daytoday.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  daytoday.setMonth(2)
  daytoday.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    daytoday.setMonth(3)
  daytoday.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      daytoday.setMonth(4)
  daytoday.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        daytoday.setMonth(5)
  daytoday.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          daytoday.setMonth(6)
  daytoday.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            daytoday.setMonth(7)
  daytoday.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              daytoday.setMonth(8)
  daytoday.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                daytoday.setMonth(9)
  daytoday.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  daytoday.setMonth(10)
  daytoday.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    daytoday.setMonth(11)
  daytoday.setDate(1)
                  } else {
                    let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(0)
  daytoday.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      daytoday.setMonth(3)
  daytoday.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        daytoday.setMonth(4)
  daytoday.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          daytoday.setMonth(5)
  daytoday.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            daytoday.setMonth(6)
  daytoday.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              daytoday.setMonth(7)
  daytoday.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                daytoday.setMonth(8)
  daytoday.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  daytoday.setMonth(9)
  daytoday.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    daytoday.setMonth(10)
  daytoday.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      daytoday.setMonth(11)
  daytoday.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(0)
  daytoday.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(1)
  daytoday.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      daytoday.setMonth(4)
                daytoday.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        daytoday.setMonth(5)
                daytoday.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          daytoday.setMonth(6)
                daytoday.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            daytoday.setMonth(7)
                daytoday.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              daytoday.setMonth(8)
                daytoday.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                daytoday.setMonth(9)
                daytoday.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  daytoday.setMonth(10)
                daytoday.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    daytoday.setMonth(11)
                daytoday.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = daytoday.getFullYear()
                                                          daytoday.setFullYear(todayYear + 1)
                                                          daytoday.setMonth(0)
                                        daytoday.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = daytoday.getFullYear()
                                                            daytoday.setFullYear(todayYear + 1)
                                                            daytoday.setMonth(1)
                                          daytoday.setDate(1)
                                                          } else {
                                                            let todayYear = daytoday.getFullYear()
                                                            daytoday.setFullYear(todayYear + 1)
                                                            daytoday.setMonth(2)
                                          daytoday.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        daytoday.setMonth(5)
                                daytoday.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          daytoday.setMonth(6)
                                daytoday.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            daytoday.setMonth(7)
                                daytoday.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              daytoday.setMonth(8)
                                daytoday.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                daytoday.setMonth(9)
                                daytoday.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  daytoday.setMonth(10)
                                daytoday.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    daytoday.setMonth(11)
                                daytoday.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = daytoday.getFullYear()
                                                                      daytoday.setFullYear(todayYear + 1)
                                                                      daytoday.setMonth(0)
                                                    daytoday.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = daytoday.getFullYear()
                                                                        daytoday.setFullYear(todayYear + 1)
                                                                        daytoday.setMonth(1)
                                                      daytoday.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = daytoday.getFullYear()
                                                                        daytoday.setFullYear(todayYear + 1)
                                                                        daytoday.setMonth(2)
                                                      daytoday.setDate(1)
                                                                              } else {
                                                                                let todayYear = daytoday.getFullYear()
                                                                                daytoday.setFullYear(todayYear + 1)
                                                                                daytoday.setMonth(3)
                                                              daytoday.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          daytoday.setMonth(6)
                                                daytoday.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            daytoday.setMonth(7)
                                                daytoday.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              daytoday.setMonth(8)
                                                daytoday.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                daytoday.setMonth(9)
                                                daytoday.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  daytoday.setMonth(10)
                                                daytoday.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    daytoday.setMonth(11)
                                                daytoday.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = daytoday.getFullYear()
                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                      daytoday.setMonth(0)
                                                                    daytoday.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(1)
                                                                      daytoday.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(2)
                                                                      daytoday.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(3)
                                                                      daytoday.setDate(1)
                    } else {
                      let todayYear = daytoday.getFullYear()
                      daytoday.setFullYear(todayYear + 1)
                      daytoday.setMonth(4)
    daytoday.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            daytoday.setMonth(7)
                                                                daytoday.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              daytoday.setMonth(8)
                                                                daytoday.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                daytoday.setMonth(9)
                                                                daytoday.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  daytoday.setMonth(10)
                                                                daytoday.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    daytoday.setMonth(11)
                                                                daytoday.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                daytoday.setFullYear(todayYear + 1)
                                                                                                                daytoday.setMonth(0)
                                                                                              daytoday.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(1)
                                                                                                daytoday.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(2)
                                                                                                daytoday.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(3)
                                                                                                daytoday.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(4)
                                                                                                daytoday.setDate(1)
                      } else {
                        let todayYear = daytoday.getFullYear()
                        daytoday.setFullYear(todayYear + 1)
                        daytoday.setMonth(5)
      daytoday.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              daytoday.setMonth(8)
                                                                                daytoday.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                daytoday.setMonth(9)
                                                                                daytoday.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  daytoday.setMonth(10)
                                                                                daytoday.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    daytoday.setMonth(11)
                                                                                daytoday.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                      daytoday.setMonth(0)
                                                                                                    daytoday.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(1)
                                                                                                      daytoday.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(2)
                                                                                                      daytoday.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(3)
                                                                                                      daytoday.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(4)
                                                                                                      daytoday.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(5)
                                                                                                      daytoday.setDate(1)
                        } else {
                          let todayYear = daytoday.getFullYear()
                          daytoday.setFullYear(todayYear + 1)
                          daytoday.setMonth(6)
        daytoday.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                daytoday.setMonth(9)
                                                                                                daytoday.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  daytoday.setMonth(10)
                                                                                                daytoday.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    daytoday.setMonth(11)
                                                                                                daytoday.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                      daytoday.setMonth(0)
                                                                                                                    daytoday.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(1)
                                                                                                                      daytoday.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(2)
                                                                                                                      daytoday.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(3)
                                                                                                                      daytoday.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(4)
                                                                                                                      daytoday.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(5)
                                                                                                                      daytoday.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(6)
                                                                                                                      daytoday.setDate(1)
                          } else {
                            let todayYear = daytoday.getFullYear()
                            daytoday.setFullYear(todayYear + 1)
                            daytoday.setMonth(7)
          daytoday.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  daytoday.setMonth(10)
                                                                                                                daytoday.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    daytoday.setMonth(11)
                                                                                                                daytoday.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                                      daytoday.setMonth(0)
                                                                                                                                    daytoday.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(1)
                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(2)
                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(3)
                                                                                                                                      daytoday.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(4)
                                                                                                                                      daytoday.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(5)
                                                                                                                                      daytoday.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(6)
                                                                                                                                      daytoday.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(7)
                                                                                                                                      daytoday.setDate(1)
                            } else {
                              let todayYear = daytoday.getFullYear()
                              daytoday.setFullYear(todayYear + 1)
                              daytoday.setMonth(8)
            daytoday.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    daytoday.setMonth(11)
                                                                                                                                daytoday.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                      daytoday.setMonth(0)
                                                                                                                                                    daytoday.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(1)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(2)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(3)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(4)
                                                                                                                                                      daytoday.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(5)
                                                                                                                                                      daytoday.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(6)
                                                                                                                                                      daytoday.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(7)
                                                                                                                                                      daytoday.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(8)
                                                                                                                                                      daytoday.setDate(1)
                              } else {
                                let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(9)
              daytoday.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(0)
              daytoday.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(1)
              daytoday.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(2)
              daytoday.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(3)
              daytoday.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(4)
              daytoday.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(5)
              daytoday.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(6)
              daytoday.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(7)
              daytoday.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(8)
              daytoday.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = daytoday.getFullYear()
                                  daytoday.setFullYear(todayYear + 1)
                                  daytoday.setMonth(9)
                daytoday.setDate(1)
                                } else {
                                  let todayYear = daytoday.getFullYear()
                                  daytoday.setFullYear(todayYear + 1)
                                  daytoday.setMonth(10)
                daytoday.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              let dayCheck = daytoday.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  daytoday.setDate(daytoday.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    daytoday.setDate(daytoday.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      daytoday.setDate(daytoday.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        daytoday.setDate(daytoday.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          daytoday.setDate(daytoday.getDate() + 5)
        } else {
          daytoday.setDate(daytoday.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                  daytoday.setHours(hour)
                  daytoday.setMinutes(minutes)
                  start = daytoday.getTime()
                } else {
                  let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  let date = daytoday.getDate()
                  daytoday.setDate(date + dayAddNumberStart)
                  let stringMonth = daytoday.toLocaleString('en-US', { month: 'long' })
              if (!monthCardString.includes(stringMonth)) {
                if (stringMonth === 'February') {
if (monthCardString.includes('March')) {
  daytoday.setMonth(2)
  daytoday.setDate(1)
} else {
  if (monthCardString.includes('April')) {
    daytoday.setMonth(3)
  daytoday.setDate(1)
  } else {
    if (monthCardString.includes('May')) {
      daytoday.setMonth(4)
  daytoday.setDate(1)
    } else {
      if (monthCardString.includes('June')) {
        daytoday.setMonth(5)
  daytoday.setDate(1)
      } else {
        if (monthCardString.includes('July')) {
          daytoday.setMonth(6)
  daytoday.setDate(1)
        } else {
          if (monthCardString.includes('August')) {
            daytoday.setMonth(7)
  daytoday.setDate(1)
          } else {
            if (monthCardString.includes('September')) {
              daytoday.setMonth(8)
  daytoday.setDate(1)
            } else {
              if (monthCardString.includes('October')) {
                daytoday.setMonth(9)
  daytoday.setDate(1)
              } else {
                if (monthCardString.includes('November')) {
                  daytoday.setMonth(10)
  daytoday.setDate(1)
                } else {
                  if (monthCardString.includes('December')) {
                    daytoday.setMonth(11)
  daytoday.setDate(1)
                  } else {
                    let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(0)
  daytoday.setDate(1)
                    checkMonthExp = true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
                }
                if (stringMonth === 'March') {
                    if (monthCardString.includes('April')) {
                      daytoday.setMonth(3)
  daytoday.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        daytoday.setMonth(4)
  daytoday.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          daytoday.setMonth(5)
  daytoday.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            daytoday.setMonth(6)
  daytoday.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              daytoday.setMonth(7)
  daytoday.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                daytoday.setMonth(8)
  daytoday.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  daytoday.setMonth(9)
  daytoday.setDate(1)
                                } else {
                                  if (monthCardString.includes('November')) {
                                    daytoday.setMonth(10)
  daytoday.setDate(1)
                                  } else {
                                    if (monthCardString.includes('December')) {
                                      daytoday.setMonth(11)
  daytoday.setDate(1)
                                    } else {
                                      if (monthCardString.includes('January')) {
                                        let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(0)
  daytoday.setDate(1)
  checkMonthExp = true
                                      } else {
                                        let todayYear = daytoday.getFullYear()
                    daytoday.setFullYear(todayYear + 1)
                    daytoday.setMonth(1)
  daytoday.setDate(1)
  checkMonthExp = true
                                      }                                      
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                  if (stringMonth === 'April') {
                                    if (monthCardString.includes('May')) {
                                      daytoday.setMonth(4)
                daytoday.setDate(1)
                                    } else {
                                      if (monthCardString.includes('June')) {
                                        daytoday.setMonth(5)
                daytoday.setDate(1)
                                      } else {
                                        if (monthCardString.includes('July')) {
                                          daytoday.setMonth(6)
                daytoday.setDate(1)
                                        } else {
                                          if (monthCardString.includes('August')) {
                                            daytoday.setMonth(7)
                daytoday.setDate(1)
                                          } else {
                                            if (monthCardString.includes('September')) {
                                              daytoday.setMonth(8)
                daytoday.setDate(1)
                                            } else {
                                              if (monthCardString.includes('October')) {
                                                daytoday.setMonth(9)
                daytoday.setDate(1)
                                              } else {
                                                if (monthCardString.includes('November')) {
                                                  daytoday.setMonth(10)
                daytoday.setDate(1)
                                                } else {
                                                  if (monthCardString.includes('December')) {
                                                    daytoday.setMonth(11)
                daytoday.setDate(1)
                                                  } else {
                                                        if (monthCardString.includes('January')) {
                                                          let todayYear = daytoday.getFullYear()
                                                          daytoday.setFullYear(todayYear + 1)
                                                          daytoday.setMonth(0)
                                        daytoday.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('February')) {
                                                            let todayYear = daytoday.getFullYear()
                                                            daytoday.setFullYear(todayYear + 1)
                                                            daytoday.setMonth(1)
                                          daytoday.setDate(1)
                                                          } else {
                                                            let todayYear = daytoday.getFullYear()
                                                            daytoday.setFullYear(todayYear + 1)
                                                            daytoday.setMonth(2)
                                          daytoday.setDate(1)
                                                          }
                                                        }
                                                        checkMonthExp = true
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                                    if (stringMonth === 'May') {
                                                      if (monthCardString.includes('June')) {
                                                        daytoday.setMonth(5)
                                daytoday.setDate(1)
                                                      } else {
                                                        if (monthCardString.includes('July')) {
                                                          daytoday.setMonth(6)
                                daytoday.setDate(1)
                                                        } else {
                                                          if (monthCardString.includes('August')) {
                                                            daytoday.setMonth(7)
                                daytoday.setDate(1)
                                                          } else {
                                                            if (monthCardString.includes('September')) {
                                                              daytoday.setMonth(8)
                                daytoday.setDate(1)
                                                            } else {
                                                              if (monthCardString.includes('October')) {
                                                                daytoday.setMonth(9)
                                daytoday.setDate(1)
                                                              } else {
                                                                if (monthCardString.includes('November')) {
                                                                  daytoday.setMonth(10)
                                daytoday.setDate(1)
                                                                } else {
                                                                  if (monthCardString.includes('December')) {
                                                                    daytoday.setMonth(11)
                                daytoday.setDate(1)
                                                                  } else {
                                                                    if (monthCardString.includes('January')) {
                                                                      let todayYear = daytoday.getFullYear()
                                                                      daytoday.setFullYear(todayYear + 1)
                                                                      daytoday.setMonth(0)
                                                    daytoday.setDate(1)
                                                                    } else {
                                                                      if (monthCardString.includes('February')) {
                                                                        let todayYear = daytoday.getFullYear()
                                                                        daytoday.setFullYear(todayYear + 1)
                                                                        daytoday.setMonth(1)
                                                      daytoday.setDate(1)
                                                                      } else {
                                                                              if (monthCardString.includes('March')) {
                                                                                let todayYear = daytoday.getFullYear()
                                                                        daytoday.setFullYear(todayYear + 1)
                                                                        daytoday.setMonth(2)
                                                      daytoday.setDate(1)
                                                                              } else {
                                                                                let todayYear = daytoday.getFullYear()
                                                                                daytoday.setFullYear(todayYear + 1)
                                                                                daytoday.setMonth(3)
                                                              daytoday.setDate(1)
                  }
                                                                            }
                                                                          }
                                                                          checkMonthExp = true
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                                      if (stringMonth === 'June') {
                                                                        if (monthCardString.includes('July')) {
                                                                          daytoday.setMonth(6)
                                                daytoday.setDate(1)
                                                                        } else {
                                                                          if (monthCardString.includes('August')) {
                                                                            daytoday.setMonth(7)
                                                daytoday.setDate(1)
                                                                          } else {
                                                                            if (monthCardString.includes('September')) {
                                                                              daytoday.setMonth(8)
                                                daytoday.setDate(1)
                                                                            } else {
                                                                              if (monthCardString.includes('October')) {
                                                                                daytoday.setMonth(9)
                                                daytoday.setDate(1)
                                                                              } else {
                                                                                if (monthCardString.includes('November')) {
                                                                                  daytoday.setMonth(10)
                                                daytoday.setDate(1)
                                                                                } else {
                                                                                  if (monthCardString.includes('December')) {
                                                                                    daytoday.setMonth(11)
                                                daytoday.setDate(1)
                                                                                  } else {
                                                                                    if (monthCardString.includes('January')) {
                                                                                      let todayYear = daytoday.getFullYear()
                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                      daytoday.setMonth(0)
                                                                    daytoday.setDate(1)
                                                                                    } else {
                                                                                      if (monthCardString.includes('February')) {
                                                                                        let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(1)
                                                                      daytoday.setDate(1)
                                                                                      } else {
                                                                                              if (monthCardString.includes('March')) {
                                                                                                let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(2)
                                                                      daytoday.setDate(1)
                                                                                              } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = daytoday.getFullYear()
                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                        daytoday.setMonth(3)
                                                                      daytoday.setDate(1)
                    } else {
                      let todayYear = daytoday.getFullYear()
                      daytoday.setFullYear(todayYear + 1)
                      daytoday.setMonth(4)
    daytoday.setDate(1)
                    }
                  }
                                                                            }
                                                                          }
                                                                                            checkMonthExp = true
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                                        if (stringMonth === 'July') {
                                                                                          if (monthCardString.includes('August')) {
                                                                                            daytoday.setMonth(7)
                                                                daytoday.setDate(1)
                                                                                          } else {
                                                                                            if (monthCardString.includes('September')) {
                                                                                              daytoday.setMonth(8)
                                                                daytoday.setDate(1)
                                                                                            } else {
                                                                                              if (monthCardString.includes('October')) {
                                                                                                daytoday.setMonth(9)
                                                                daytoday.setDate(1)
                                                                                              } else {
                                                                                                if (monthCardString.includes('November')) {
                                                                                                  daytoday.setMonth(10)
                                                                daytoday.setDate(1)
                                                                                                } else {
                                                                                                  if (monthCardString.includes('December')) {
                                                                                                    daytoday.setMonth(11)
                                                                daytoday.setDate(1)
                                                                                                  } else {
                                                                                                              checkMonthExp = true
                                                                                                              if (monthCardString.includes('January')) {
                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                daytoday.setFullYear(todayYear + 1)
                                                                                                                daytoday.setMonth(0)
                                                                                              daytoday.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('February')) {
                                                                                                                  let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(1)
                                                                                                daytoday.setDate(1)
                                                                                                                } else {
                                                                                                                        if (monthCardString.includes('March')) {
                                                                                                                          let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(2)
                                                                                                daytoday.setDate(1)
                                                                                                                        } else {
                                              if (monthCardString.includes('April')) {
                                                let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(3)
                                                                                                daytoday.setDate(1)
                                              } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = daytoday.getFullYear()
                                                                                                                  daytoday.setFullYear(todayYear + 1)
                                                                                                                  daytoday.setMonth(4)
                                                                                                daytoday.setDate(1)
                      } else {
                        let todayYear = daytoday.getFullYear()
                        daytoday.setFullYear(todayYear + 1)
                        daytoday.setMonth(5)
      daytoday.setDate(1)
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                          if (stringMonth === 'August') {
                                                                                                            if (monthCardString.includes('September')) {
                                                                                                              daytoday.setMonth(8)
                                                                                daytoday.setDate(1)
                                                                                                            } else {
                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                daytoday.setMonth(9)
                                                                                daytoday.setDate(1)
                                                                                                              } else {
                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                  daytoday.setMonth(10)
                                                                                daytoday.setDate(1)
                                                                                                                } else {
                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                    daytoday.setMonth(11)
                                                                                daytoday.setDate(1)
                                                                                                                  } else {
                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                      daytoday.setMonth(0)
                                                                                                    daytoday.setDate(1)
                                                                                                                    } else {
                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(1)
                                                                                                      daytoday.setDate(1)
                                                                                                                      } else {
                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(2)
                                                                                                      daytoday.setDate(1)
                                                                                                                              } else {
                                                    if (monthCardString.includes('April')) {
                                                      let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(3)
                                                                                                      daytoday.setDate(1)
                                                    } else {
                            if (monthCardString.includes('May')) {
                              let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(4)
                                                                                                      daytoday.setDate(1)
                            } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = daytoday.getFullYear()
                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                        daytoday.setMonth(5)
                                                                                                      daytoday.setDate(1)
                        } else {
                          let todayYear = daytoday.getFullYear()
                          daytoday.setFullYear(todayYear + 1)
                          daytoday.setMonth(6)
        daytoday.setDate(1)
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                checkMonthExp = true
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                            if (stringMonth === 'September') {
                                                                                                                              if (monthCardString.includes('October')) {
                                                                                                                                daytoday.setMonth(9)
                                                                                                daytoday.setDate(1)
                                                                                                                              } else {
                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                  daytoday.setMonth(10)
                                                                                                daytoday.setDate(1)
                                                                                                                                } else {
                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                    daytoday.setMonth(11)
                                                                                                daytoday.setDate(1)
                                                                                                                                  } else {
                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                      daytoday.setMonth(0)
                                                                                                                    daytoday.setDate(1)
                                                                                                                                    } else {
                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(1)
                                                                                                                      daytoday.setDate(1)
                                                                                                                                      } else {
                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(2)
                                                                                                                      daytoday.setDate(1)
                                                                                                                                              } else {
                                                                    if (monthCardString.includes('April')) {
                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(3)
                                                                                                                      daytoday.setDate(1)
                                                                    } else {
                                            if (monthCardString.includes('May')) {
                                              let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(4)
                                                                                                                      daytoday.setDate(1)
                                            } else {
                                        if (monthCardString.includes('June')) {
                                          let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(5)
                                                                                                                      daytoday.setDate(1)
                                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = daytoday.getFullYear()
                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                        daytoday.setMonth(6)
                                                                                                                      daytoday.setDate(1)
                          } else {
                            let todayYear = daytoday.getFullYear()
                            daytoday.setFullYear(todayYear + 1)
                            daytoday.setMonth(7)
          daytoday.setDate(1)
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                  checkMonthExp = true
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                              if (stringMonth === 'October') {
                                                                                                                                                if (monthCardString.includes('November')) {
                                                                                                                                                  daytoday.setMonth(10)
                                                                                                                daytoday.setDate(1)
                                                                                                                                                } else {
                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                    daytoday.setMonth(11)
                                                                                                                daytoday.setDate(1)
                                                                                                                                                  } else {
                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                                      daytoday.setMonth(0)
                                                                                                                                    daytoday.setDate(1)
                                                                                                                                                    } else {
                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(1)
                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                      } else {
                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(2)
                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                              } else {
                                                                                    if (monthCardString.includes('April')) {
                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(3)
                                                                                                                                      daytoday.setDate(1)
                                                                                    } else {
                                                            if (monthCardString.includes('May')) {
                                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(4)
                                                                                                                                      daytoday.setDate(1)
                                                            } else {
                                                        if (monthCardString.includes('June')) {
                                                          let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(5)
                                                                                                                                      daytoday.setDate(1)
                                                        } else {
                                          if (monthCardString.includes('July')) {
                                            let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(6)
                                                                                                                                      daytoday.setDate(1)
                                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = daytoday.getFullYear()
                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                        daytoday.setMonth(7)
                                                                                                                                      daytoday.setDate(1)
                            } else {
                              let todayYear = daytoday.getFullYear()
                              daytoday.setFullYear(todayYear + 1)
                              daytoday.setMonth(8)
            daytoday.setDate(1)
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                                if (stringMonth === 'November') {
                                                                                                                                                                  if (monthCardString.includes('December')) {
                                                                                                                                                                    daytoday.setMonth(11)
                                                                                                                                daytoday.setDate(1)
                                                                                                                                                                  } else {
                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                                      daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                      daytoday.setMonth(0)
                                                                                                                                                    daytoday.setDate(1)
                                                                                                                                                                    } else {
                                                                                                                                                                      if (monthCardString.includes('February')) {
                                                                                                                                                                        let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(1)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                                      } else {
                                                                                                                                                                              if (monthCardString.includes('March')) {
                                                                                                                                                                                let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(2)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                                                                                              } else {
                                                                                                    if (monthCardString.includes('April')) {
                                                                                                      let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(3)
                                                                                                                                                      daytoday.setDate(1)
                                                                                                    } else {
                                                                            if (monthCardString.includes('May')) {
                                                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(4)
                                                                                                                                                      daytoday.setDate(1)
                                                                            } else {
                                                                        if (monthCardString.includes('June')) {
                                                                          let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(5)
                                                                                                                                                      daytoday.setDate(1)
                                                                        } else {
                                                          if (monthCardString.includes('July')) {
                                                            let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(6)
                                                                                                                                                      daytoday.setDate(1)
                                                          } else {
                                            if (monthCardString.includes('August')) {
                                              let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(7)
                                                                                                                                                      daytoday.setDate(1)
                                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = daytoday.getFullYear()
                                                                                                                                                                        daytoday.setFullYear(todayYear + 1)
                                                                                                                                                                        daytoday.setMonth(8)
                                                                                                                                                      daytoday.setDate(1)
                              } else {
                                let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(9)
              daytoday.setDate(1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                      checkMonthExp = true
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                  if (stringMonth === 'December') {
                                                                                                                                                                                    if (monthCardString.includes('January')) {
                                                                                                                                                                                      let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(0)
              daytoday.setDate(1)
                                                                                                                                                                                    } else {
                                                                            if (monthCardString.includes('February')) {
                                                                              let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(1)
              daytoday.setDate(1)
                                                                            } else {
                  if (monthCardString.includes('March')) {
                    let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(2)
              daytoday.setDate(1)
                  } else {
                    if (monthCardString.includes('April')) {
                      let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(3)
              daytoday.setDate(1)
                    } else {
                      if (monthCardString.includes('May')) {
                        let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(4)
              daytoday.setDate(1)
                      } else {
                        if (monthCardString.includes('June')) {
                          let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(5)
              daytoday.setDate(1)
                        } else {
                          if (monthCardString.includes('July')) {
                            let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(6)
              daytoday.setDate(1)
                          } else {
                            if (monthCardString.includes('August')) {
                              let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(7)
              daytoday.setDate(1)
                            } else {
                              if (monthCardString.includes('September')) {
                                let todayYear = daytoday.getFullYear()
                                daytoday.setFullYear(todayYear + 1)
                                daytoday.setMonth(8)
              daytoday.setDate(1)
                              } else {
                                if (monthCardString.includes('October')) {
                                  let todayYear = daytoday.getFullYear()
                                  daytoday.setFullYear(todayYear + 1)
                                  daytoday.setMonth(9)
                daytoday.setDate(1)
                                } else {
                                  let todayYear = daytoday.getFullYear()
                                  daytoday.setFullYear(todayYear + 1)
                                  daytoday.setMonth(10)
                daytoday.setDate(1)
                                }
                              }
                            } 
                          }
                        }
                      }
                    }
                  }
                                                                            }
                                                                          }
                                                                                                                                                                                    checkMonthExp = true
                                                                                                                                                                                                    }
              }
              let dayCheck = daytoday.toLocaleString('en-US', {weekday: 'long'})              
              if (!dayCardString.includes(dayCheck)) {
                if (dayCheck === 'Sunday') {
if (dayCardString.includes('Monday')) {
  daytoday.setDate(daytoday.getDate() + 1)
} else {
  if (dayCardString.includes('Tuesday')) {
    daytoday.setDate(daytoday.getDate() + 2)
  } else {
    if (dayCardString.includes('Wednesday')) {
      daytoday.setDate(daytoday.getDate() + 3)
    } else {
      if (dayCardString.includes('Thursday')) {
        daytoday.setDate(daytoday.getDate() + 4)
      } else {
        if (dayCardString.includes('Friday')) {
          daytoday.setDate(daytoday.getDate() + 5)
        } else {
          daytoday.setDate(daytoday.getDate() + 6)
        }
      }
    }
  }
}
                }
                if (dayCheck === 'Monday') {
                  if (dayCardString.includes('Tuesday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Wednesday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Thursday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Friday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Saturday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Tuesday') {
                  if (dayCardString.includes('Wednesday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Thursday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Friday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Saturday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Sunday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Wednesday') {
                  if (dayCardString.includes('Thursday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Friday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Saturday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Sunday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Monday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Thursday') {
                  if (dayCardString.includes('Friday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Saturday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Sunday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Monday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Tuesday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Friday') {
                  if (dayCardString.includes('Saturday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Sunday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Monday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Tuesday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Wednesday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
                if (dayCheck === 'Saturday') {
                  if (dayCardString.includes('Sunday')) {
                    daytoday.setDate(daytoday.getDate() + 1)
                  } else {
                    if (dayCardString.includes('Monday')) {
                      daytoday.setDate(daytoday.getDate() + 2)
                    } else {
                      if (dayCardString.includes('Tuesday')) {
                        daytoday.setDate(daytoday.getDate() + 3)
                      } else {
                        if (dayCardString.includes('Wednesday')) {
                          daytoday.setDate(daytoday.getDate() + 4)
                        } else {
                          if (dayCardString.includes('Thursday')) {
                            daytoday.setDate(daytoday.getDate() + 5)
                          } else {
                            daytoday.setDate(daytoday.getDate() + 6)
                          }
                        }
                      }
                    }
                  }
                }
              }
                  daytoday.setHours(hour)
                  daytoday.setMinutes(minutes)
                  start = daytoday.getTime()
              }
              }  
              daySubNumberEnd = 7 - daySubNumberEnd
            if (equalBoolEnd) {
              let cardArr = this.state.hourCard.split(':')
              let hour = parseInt(cardArr[0])
              let minutes = parseInt(cardArr[1])
                  monthDate.setHours(hour)
                  monthDate.setMinutes(minutes)
                  end = monthDate.getTime()
            } else {
              if (dayAddNumberEnd === 8) {
                let cardArr = this.state.hourCard.split(':')
                let hour = parseInt(cardArr[0])
                let minutes = parseInt(cardArr[1])
                    let date = monthDate.getDate()
                    monthDate.setDate(date - daySubNumberEnd)
                    monthDate.setHours(hour)
                    monthDate.setMinutes(minutes)
                    end = monthDate.getTime()
              } else {
                let cardArr = this.state.hourCard.split(':')
                let hour = parseInt(cardArr[0])
                let minutes = parseInt(cardArr[1])
                    let date = monthDate.getDate()
                    monthDate.setDate(date - dayAddNumberEnd)
                    monthDate.setHours(hour)
                    monthDate.setMinutes(minutes)
                    end = monthDate.getTime()
              }
            }
            } else {
              let dateStart = new Date()
              let dateEnd = new Date()
let dayStartNumber = 0
let dayEndNumber = 0
dateStart.setDate(1)
dateStart.setMonth(monthNumberStart)
dateStart.setDate(1)
dateEnd.setDate(1)
  dateEnd.setMonth(monthNumberEnd + 1)
  console.log('dateEndMonth Plus: ' + dateEnd.getMonth())
  dateEnd.setDate(0)
  dayStartNumber = dateStart.getDay()
  dayEndNumber = dateEnd.getDay()
  console.log('daystart: ' + dayStartNumber + ' ,dayend: ' + dayEndNumber + ' monthnumberend: ' + monthNumberEnd + ' dateEndMonth ' + dateEnd.getMonth() + ' dateEndDate ' + dateEnd.getDate())
  let dayArr = dayCardString
  console.log('daycardstring: ' + dayArr)
  dayArr = dayArr.replace('Monday', '1').replace('Tuesday', '2').replace('Wednesday', '3').replace('Thursday', '4').replace('Friday', '5').replace('Saturday', '6').replace('Sunday', '0')
  let dayArrNumbers = dayArr.split(',')
  let daySubNumberStart = 0
  let dayAddNumberStart = 8
  let daySubNumberEnd = 0
  let dayAddNumberEnd = 8
  let equalBoolStart = false
  let equalBoolEnd = false
  for (let index = 0; index < dayArrNumbers.length; index++) {
    const element = dayArrNumbers[index]
    const intElement = parseInt(element)
    console.log('intElement: ' + intElement)
    if (intElement > dayEndNumber) {
      if (daySubNumberEnd < (intElement - dayEndNumber)) {
        daySubNumberEnd = intElement - dayEndNumber
      }
    } else {
      if (intElement === dayEndNumber) {
        equalBoolEnd = true
      } else {
        if (dayAddNumberEnd > (dayEndNumber - intElement)) {
          dayAddNumberEnd = dayEndNumber - intElement
        }        
      }      
    }
    if (intElement > dayStartNumber) {
      if (dayAddNumberStart > (intElement - dayStartNumber)) {
        dayAddNumberStart = intElement - dayStartNumber
      }
    } else {
      if (intElement === dayStartNumber) {
        equalBoolStart = true
      } else {
        if (daySubNumberStart < (dayStartNumber - intElement)) {
          daySubNumberStart = dayStartNumber - intElement
        }        
      }      
    }
  }
  console.log('daysubnumberstart before subtraction: ' + daySubNumberStart)
  daySubNumberStart = 7 - daySubNumberStart
  if (equalBoolStart) {
    let cardArr = this.state.hourCard.split(':')
  let hour = parseInt(cardArr[0])
  let minutes = parseInt(cardArr[1])
      dateStart.setHours(hour)
      dateStart.setMinutes(minutes)
      start = dateStart.getTime()
  } else {
    if (dayAddNumberStart === 8) {
      let cardArr = this.state.hourCard.split(':')
  let hour = parseInt(cardArr[0])
  let minutes = parseInt(cardArr[1])
      let date = dateStart.getDate()
      console.log('datestart.getdate()=> ' + date)
      let dateTestSum = date + daySubNumberStart
      console.log('sum of date + daysubnumberstart: ' + dateTestSum)
      dateStart.setDate(date + daySubNumberStart)
      dateStart.setHours(hour)
      dateStart.setMinutes(minutes)
      start = dateStart.getTime()
    } else {
      let cardArr = this.state.hourCard.split(':')
  let hour = parseInt(cardArr[0])
  let minutes = parseInt(cardArr[1])
      let date = dateStart.getDate()
      dateStart.setDate(date + dayAddNumberStart)
      dateStart.setHours(hour)
      dateStart.setMinutes(minutes)
      start = dateStart.getTime()
  }
  }
  console.log('daysubnumberend before subtraction ' + daySubNumberEnd)
  console.log('equalboolend ' + equalBoolEnd)
  daySubNumberEnd = 7 - daySubNumberEnd
if (equalBoolEnd) {
  console.log('equalBoolend')
  let cardArr = this.state.hourCard.split(':')
  let hour = parseInt(cardArr[0])
  let minutes = parseInt(cardArr[1])
      dateEnd.setHours(hour)
      dateEnd.setMinutes(minutes)
      end = dateEnd.getTime()
} else {
  if (dayAddNumberEnd === 8) {
    console.log('dayaddnumberend8')
    let cardArr = this.state.hourCard.split(':')
    let hour = parseInt(cardArr[0])
    let minutes = parseInt(cardArr[1])
        let date = dateEnd.getDate()
        dateEnd.setDate(date - daySubNumberEnd)
        dateEnd.setHours(hour)
        dateEnd.setMinutes(minutes)
        end = dateEnd.getTime()
  } else {
    console.log('thirdchoice')
    let cardArr = this.state.hourCard.split(':')
    let hour = parseInt(cardArr[0])
    let minutes = parseInt(cardArr[1])
        let date = dateEnd.getDate()
        dateEnd.setDate(date - dayAddNumberEnd)
        dateEnd.setHours(hour)
        dateEnd.setMinutes(minutes)
        end = dateEnd.getTime()
  }
}
            }
          }
        }
        let startDate = new Date(start)
        let yearStart = startDate.getFullYear()       
        if (yearStart > todayYear) {
checkMonthExp = true
        }
        if (checkMonthExp) {
          if (this.state.yearForm === 'Only this year') {
            alert('You need to select EVERY YEAR option or your campaign will not start!')
          } else {
            this.setState({ activeItem: 'step3', start_date: start.toString() })
          }          
        } else {
          if (this.state.yearForm === 'Only this year') {
            this.setState({ activeItem: 'step3', start_date: start.toString(), end_date: end.toString() })
          } else {
            this.setState({ activeItem: 'step3', start_date: start.toString() })
          }    
        }
  }

  handleCreateCampaign = async () => {    
    let cardArr = this.state.hourCard.split(':')
let hour = cardArr[0]
let minutes = cardArr[1]
console.log('hour: ' + hour + ', minutes: ' + minutes)
let dateObj = new Date()
    let stepMinutes = minutes
    let stepHours = hour
    let stepDayofMonth = '?'
    let stepMonth = ''
    let stepDayofWeek = ''
    let stepYear = '*'
    let everyMonth = false
    let allWeek = false
    let monthArr: string[] = []
    let dayArr: string[] = []
    if (this.state.yearForm === 'Only this year') {
stepYear = dateObj.getFullYear().toString()
    }
    for (let index = 0; index < this.state.monthCard.length; index++) {
      let element = this.state.monthCard[index]
      if (element === 'Every Month') {
everyMonth = true
        break
      } else {
        switch (element) {
          case 'January':
            element = '1'
            break;
            case 'February':
            element = '2'
              break;
              case 'March':
            element = '3'
            break;
            case 'April':
            element = '4'
            break;
            case 'May':
            element = '5'
            break;
            case 'June':
            element = '6'
            break;
            case 'July':
            element = '7'
            break;
            case 'August':
            element = '8'
            break;
            case 'September':
            element = '9'
            break;
            case 'October':
            element = '10'
            break;
            case 'November':
            element = '11'
            break;
            case 'December':
            element = '12'
            break;
          default:
            break;
        }
      }      
monthArr.push(element)
    }
    for (let index = 0; index < this.state.dayCard.length; index++) {
      let element = this.state.dayCard[index]
      if (element === 'All Week') {
        allWeek = true
      } else {
        switch (element) {
          case 'Monday':
            element = 'MON'
            break;
            case 'Tuesday':
            element = 'TUE'
              break;
              case 'Wednesday':
            element = 'WED'
            break;
            case 'Thursday':
            element = 'THU'
            break;
            case 'Friday':
            element = 'FRI'
            break;
            case 'Saturday':
            element = 'SAT'
            break;
            case 'Sunday':
            element = 'SUN'
            break;
          default:
            break;
        }
      }      
dayArr.push(element)
    }
if (everyMonth) {
stepMonth = '*'
} else {
stepMonth = monthArr.toString()
}
if (allWeek) {
stepDayofWeek = '*'
} else {
stepDayofWeek = dayArr.toString()
}
let arr = this.state.emailCheck
for (let index = 0; index < arr.length; index++) {
  const element = arr[index];
  if (element === 'redemption' || element === 'upload' || element === 'digit') {
    arr.splice(index, 1)
  }
}
if (this.state.chooseMode === 'upload') {
  arr.push('upload')
}
const cronJob = `${stepMinutes} ${stepHours} ${stepDayofMonth} ${stepMonth} ${stepDayofWeek} ${stepYear}`
console.log(cronJob)
const campaignObj: CampaignModelApi = {
  cron: cronJob,
  start: this.state.start_date,
  end: this.state.end_date,
  targets: arr.toString(),
  email: this.state.campaignMessage,
  name: this.state.campaignName,
  description: this.state.campaignDescription
}
const Token = this.props.auth.getIdToken()
this.setState({loading: true})
    try {
    await createCampaign(Token, campaignObj)
    this.setState({loading: false, chooseMode: 'none', statusUp: 'not uploaded yet!', emailCheck: []})
    this.props.history.replace('/campaigncreate')
    } catch (error) {
      console.log('error getting users payment process ' + error);
        this.setState({loading: false, chooseMode: 'none', statusUp: 'not uploaded yet!', emailCheck: []})
            this.props.history.replace('/campaignfailure');
    }
  }

  handleSelectAll = () => {
    let emailAll = this.state.emailView
    if (this.state.selectCheck) {
      this.setState({ emailCheck: emailAll, selectCheck: false })
    } else {
    this.setState({ emailCheck: emailAll, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ emailCheck: [], selectClear: false })
    } else {
      this.setState({ emailCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userEmailarray: string[]) => {
    let emailCheckNew = userEmailarray
      this.setState({ emailView: emailCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ emailView: [] })
  }

  handleIdCheckPush = (userEmail: string) => {
    let emailCheckNew = this.state.emailCheck.concat([userEmail])
      this.setState({ emailCheck: emailCheckNew })
  }

  handleIdCheckDelete = (userEmail: string) => {
    let emailCheckNew = this.state.emailCheck.filter(
      id => id !== userEmail
    )
      this.setState({ emailCheck: emailCheckNew })
  }

  shouldComponentUpdate(nextProps: CampaignProcessProps, nextState: CampaignProcessState) {
    if (nextState.emailView !== this.state.emailView) {return false}
    return true
  }

  async componentDidMount() {
    try {
      const users = await getUsers(this.props.auth.getIdToken())
      const coupons = await getCoupons(this.props.auth.getIdToken())
      let coupon = 'none'
      let exp = 'none'
      for (let index = 0; index < coupons.length; index++) {
    if (parseInt(coupons[index].exp.S) > new Date().getTime()) {
coupon = coupons[index].Code.S
exp = coupons[index].exp.S
    }
      }
      this.setState({
        coupon,
        exp,
        users,
        loading: false
      })
    } catch (e) {
      this.setState({loading: false})
      this.props.history.replace('/campaignfailure');
    }
      
  }

  renderUsersList() {
    let usersState = 'zero'
    if (this.state.emailCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.emailCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZero()
      case 'many':
        return this.renderUsersMany()
      case 'one':
        return this.renderUsersOne()
      default:
        return this.renderUsersZero()
    }
  }

  renderUsersZero() {
    this.handleDeleteAllUserId()
    let emailArray: string[] = []
    let usercheck = false
    return (
      <div>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={3} centered>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '28vh', maxWidth: '48vh', overflow: 'auto'}}>
        <Grid.Row columns={7}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {
console.log(user.usergroup)
            if (user.type !== 'admin') {
              console.log(user.usergroup + 'after check')
              if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(emailArray)
             }
             usercheck = true
              return (
                  <CheckElementCampaign
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              }              
            }
          }                        
          })}
          {!usercheck && <Grid.Row column={7}>
          <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersMany() {
    this.handleDeleteAllUserId()
    let usercheck = false
    let emailArray: string[] = []
    return (
      <div>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={3} centered>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '28vh', maxWidth: '48vh', overflow: 'auto'}}>
        <Grid.Row columns={7}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {  
            console.log(user.usergroup)
            if (user.type !== 'admin') {         
              console.log(user.usergroup + 'after check')
              if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(emailArray)
             }
             usercheck = true
              return (
                  <CheckElementCampaign
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              }              
            }
          }         
          })}
          {!usercheck && <Grid.Row column={7}>
          <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow:'auto', padding:'6px'}}>None Available</Grid.Column>
              </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOne() {
    this.handleDeleteAllUserId()
    let usercheck = false
    let emailArray: string[] = []
    return (
      <div>
        <Grid centered relaxed columns='equal'>
          <Grid.Row columns={3} centered>
            <Grid.Column textAlign="center">
                  <input
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '28vh', maxWidth: '48vh', overflow: 'auto'}}>
        <Grid.Row columns={7}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Company Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '6px'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.users.map((user) => {
            console.log(user.usergroup)
            if (user.type !== 'admin') {
              console.log(user.usergroup + 'after check')
              if (!this.state.formInput) {
              emailArray.push(user.email)
              if (user.id === this.state.users[this.state.users.length - 1].id) {
                this.handleCreateAllUserId(emailArray)
             }
             usercheck = true
              return (
                  <CheckElementCampaign
                    user={user}
                    onCheck={this.handleIdCheckPush}
                    onUnCheck={this.handleIdCheckDelete}
                    userId={user.id}
                    userEmail={user.email}
                    onSelectCheck={this.state.selectCheck}
                    onSelectClear={this.state.selectClear}
                  />
              )
            } else {
              if (user.company_name) {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.company_name.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              } else {
                if (user.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || user.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  emailArray.push(user.email)
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                 usercheck = true
                  return (
                      <CheckElementCampaign
                        user={user}
                        onCheck={this.handleIdCheckPush}
                        onUnCheck={this.handleIdCheckDelete}
                        userId={user.id}
                        userEmail={user.email}
                        onSelectCheck={this.state.selectCheck}
                        onSelectClear={this.state.selectClear}
                      />
                  )
                } else {
                  if (user.id === this.state.users[this.state.users.length - 1].id) {
                    this.handleCreateAllUserId(emailArray)
                 }
                }
              }              
            }
          }
          })}
          {!usercheck && <Grid.Row column={7}>
          <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '6px'}}>None Available</Grid.Column>
              </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderLoading() {
    return (
      <Grid.Row>
        {this.state.activeItem !== 'step4' && <Loader indeterminate active inline="centered">
          Loading Campaign creation Process...
        </Loader>}
        {this.state.activeItem === 'step4' && <Loader indeterminate active inline="centered">
          Creating Campaign...
        </Loader>}
      </Grid.Row>
    )
  }

  renderRecapList() {
    let yearDate = new Date()
    let startDate = new Date(parseInt(this.state.start_date))
    let endDate = new Date(parseInt(this.state.end_date))
    let yearVar = yearDate.getFullYear().toString()
    let endVar = 'none'
    let startVar = 'none'
    const monthString = this.state.monthCard.toString()
    const dayString = this.state.dayCard.toString()
if (this.state.yearForm === 'Only this year') {
startVar = startDate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
endVar = endDate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
} else {
  startVar = startDate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
yearVar = 'All'
}
    return (
      <div>
<Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={7} centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Name
          </Card.Header>
          <Card.Description>{this.state.campaignName}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Time
          </Card.Header>
          <Card.Description style={{fontWeight: "bold"}}>{this.state.hourCard}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9', overflow: 'auto' }}>
            Day
          </Card.Header>
          <Card.Description style={{fontWeight: "bold", overflow: 'auto'}}>{dayString}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Month
          </Card.Header>
          <Card.Description style={{ overflow: 'auto' }}>{monthString}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
          Year
          </Card.Header>
          <Card.Description>{yearVar}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
            Start Date
          </Card.Header>
          <Card.Description>{startVar}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card>
        <Card.Content>
          <Card.Header style={{ color: '#6435c9' }}>
          End Date
          </Card.Header>
          <Card.Description>{endVar}</Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>        
          </Grid.Row>
</Grid>
</div>
    )
  }

  renderEmailList() {
    let dateExpiration = 'none'
    if (this.state.coupon !== 'none') {
const date = new Date(parseInt(this.state.exp))
dateExpiration = date.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
    }
    return (
      <div>
      <Grid container centered columns='equal'>
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
          <Form>
        <Form.Field>
            <label style={{fontWeight: "bold", color: '#dc6b33'}}>Name</label>
            <input
              placeholder="Name your campaign"
              value={this.state.campaignName}
              onChange={this.handleCampaignNameChange}
            />
          </Form.Field>
          <Form.Field>
            <label style={{fontWeight: "bold", color: '#dc6b33'}}>Description</label>
            <TextArea
              placeholder="Write your description..."
              value={this.state.campaignDescription}
              onChange={
                (e, { value }) => {
                  console.log(e)
                  let message = ""
                  if (value) {
                    if (typeof value === 'string') {                      
                      let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
                    }
                  }
                  this.setState({ campaignDescription: message})
                }
              }
            />
          </Form.Field>
          <Form.Field>
          <label style={{fontWeight: "bold", color: '#dc6b33'}}>Email</label>
            <TextArea
              placeholder="Write your message..."
              value={this.state.campaignMessage}
              onChange={
                (e, { value }) => {
                  console.log(e)
                  let message = ''
                  if (value) {
                    if (typeof value === 'string') {                      
                      let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
                    }
                  }
                  this.setState({ campaignMessage: message})
                }
              }
            />
          </Form.Field>
          <Form.Field>
          <label style={{fontWeight: "bold", color: '#dc6b33'}}>Coupon</label>
          {this.state.coupon !== 'none' && <ClipboardCopy copyText={this.state.coupon} />}
          {this.state.coupon === 'none' && <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'EE82EE', textAlign: 'center' }}>None Available</div>}
          </Form.Field>
          {this.state.coupon !== 'none' && <Form.Field>
          <Card fluid raised style={{ marginTop: '1px', backgroundColor: '#6435c9' }}><Card.Content textAlign="center" style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '13px' }}>
              {'Coupon Expiration: ' + dateExpiration}
          </Card.Content></Card>
          </Form.Field>}
        </Form>
        </Grid.Column>
          </Grid.Row>  
        </Grid>
        </div>
    )
  }

    renderHourCards() {
      let {hourCard} = this.state
      if (this.state.loadingCards) {
        return(
<Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
                  <Grid.Column textAlign="center" verticalAlign="middle">
            <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content>
            <Loader size='small' indeterminate active inline="centered" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
          Loading hour schedule...
        </Loader>
          </Card.Content></Card>
                  </Grid.Column>
            </Grid.Row>
            </Grid>       
        )
      } else {
        if (hourCard === 'none') {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
            None Scheduled
          </Card.Content></Card>
                  </Grid.Column>
            </Grid.Row>
            </Grid>
                  )
        } else {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
                    <Card centered raised style={{fontWeight: "bold", backgroundColor: '#6435c9' }}>
                    <Card.Content>
                      <Card.Header style={{fontWeight: "bold", color: '#FFFFFF', cursor: 'initial' }}>
                      {hourCard}
                      </Card.Header>
                      <Card.Description>
                      <div style={{fontWeight: "bold", color: '#FFFFFF', fontSize: '14px', cursor: 'pointer' }} onClick={this.handleCloseHour}>
    X
      </div>
                        </Card.Description>
                    </Card.Content>
                  </Card>
                  </Grid.Column>
            </Grid.Row>
            </Grid>
                  )
        }
      }      
    }

    renderDayCards() {
      let {dayCard} = this.state
      if (this.state.loadingCards) {
        return(
          <Grid container verticalAlign="middle" relaxed columns='equal'>
                      <Grid.Row>
                            <Grid.Column textAlign="center" verticalAlign="middle">
                      <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content>
                      <Loader size='small' indeterminate active inline="centered" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                    Loading day schedule...
                  </Loader>
                    </Card.Content></Card>
                            </Grid.Column>
                      </Grid.Row>
                      </Grid>       
                  )
      } else {
        if (dayCard.length === 0) {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
            None Scheduled
          </Card.Content></Card>
                  </Grid.Column>
            </Grid.Row>
            </Grid>
                  )
        } else {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
              {
                this.state.dayCard.map((day) => {
                  return (
                    <CardElement
                      title={day}
                      onClose={this.handleCloseDay}
                    />
                )
                })
              }
            </Grid.Row>
            </Grid>
                  )
        }
      }      
    }

    renderMonthCards() {
      let {monthCard} = this.state
      if (this.state.loadingCards) {
        return(
          <Grid container verticalAlign="middle" relaxed columns='equal'>
                      <Grid.Row>
                            <Grid.Column textAlign="center" verticalAlign="middle">
                      <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content>
                      <Loader size='small' indeterminate active inline="centered" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                    Loading month schedule...
                  </Loader>
                    </Card.Content></Card>
                            </Grid.Column>
                      </Grid.Row>
                      </Grid>       
                  )
      } else {
        if (monthCard.length === 0) {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
            None Scheduled
          </Card.Content></Card>
                  </Grid.Column>
            </Grid.Row>
            </Grid>
                  )
        } else {
          return(
            <Grid container verticalAlign="middle" relaxed columns='equal'>
            <Grid.Row>
              {
                this.state.monthCard.map((month) => {
                  return (
                    <CardElement
                      title={month}
                      onClose={this.handleCloseMonth}
                    />
                )
                })
              }
            </Grid.Row>
            </Grid>
                  )
        }
      }      
    }

    renderForm() {
      return(
        <div>
<Grid container  verticalAlign="middle" columns='equal'>
              <Grid.Row centered columns={2}>
              <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
            <Card style={{ backgroundColor: "#dc6b33"}}>
        <Card.Content style={{overflow: "auto"}}>
          <Card.Header style={{fontWeight:'bold', color:'#FFFFFF' }}>
          Select the Hour
          </Card.Header>
          <Card.Description>
          <select
              value={this.state.hourSelect}
              onChange={this.handleHourSelect}
            >
              <option value="00:00">00:00</option>
              <option value="00:30">00:30</option>
              <option value="01:00">01:00</option>
              <option value="01:30">01:30</option>
              <option value="02:00">02:00</option>
              <option value="02:30">02:30</option>
              <option value="03:00">03:00</option>
              <option value="03:30">03:30</option>
              <option value="04:00">04:00</option>
              <option value="04:30">04:30</option>
              <option value="05:00">05:00</option>
              <option value="05:30">05:30</option>
              <option value="06:00">06:00</option>
              <option value="06:30">06:30</option>
              <option value="07:00">07:00</option>
              <option value="07:30">07:30</option>
              <option value="08:00">08:00</option>
              <option value="08:30">08:30</option>
              <option value="09:00">09:00</option>
              <option value="09:30">09:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
              <option value="21:30">21:30</option>
              <option value="22:00">22:00</option>
              <option value="22:30">22:30</option>
              <option value="23:00">23:00</option>
              <option value="23:30">23:30</option>
            </select>
            </Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
                <Button
              color = "violet"
              size = 'large'
                onClick={this.handleAddHour}
              >
                Add to schedule
              </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
              <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
            <Card style={{ backgroundColor: "#dc6b33"}}>
        <Card.Content style={{overflow: "auto"}}>
          <Card.Header style={{fontWeight:'bold', color:'#FFFFFF' }}>
          Select the Day
          </Card.Header>
          <Card.Description>
          <select
              value={this.state.daySelect}
              onChange={this.handleDaySelect}
            >
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
              <option value="All Week">All Week</option>
            </select>
            </Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
                <Button
              color = "violet"
              size = 'large'
                onClick={this.handleAddDay}
              >
                Add to schedule
              </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
              <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
            <Card style={{ backgroundColor: "#dc6b33"}}>
        <Card.Content style={{overflow: "auto"}}>
          <Card.Header style={{fontWeight:'bold', color:'#FFFFFF' }}>
          Select the Month
          </Card.Header>
          <Card.Description>
          <select
              value={this.state.monthSelect}
              onChange={this.handleMonthSelect}
            >
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
              <option value="Every Month">Every Month</option>
            </select>
            </Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
                <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
                <Button
              color = "violet"
              size = 'large'
                onClick={this.handleAddMonth}
              >
                Add to schedule
              </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
              <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
            <Card style={{backgroundColor: '#dc6b33'}}>
        <Card.Content style={{overflow: "auto"}}>
          <Card.Header style={{fontWeight:'bold', color:'#FFFFFF' }}>
            Select the Mode
          </Card.Header>
          <Card.Description>
          <select
              value={this.state.modeForm}
              onChange={this.handleModeSelect}
            >
              <option value="One-Shot">One-Shot</option>
              <option value="Continuous">Continuous</option>
            </select>
            </Card.Description>
        </Card.Content>
      </Card>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
            <Card centered raised style={{ backgroundColor: '#6435c9' }}><Card.Content style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '14px' }}>
            {this.state.yearForm}
          </Card.Content></Card>
            </Grid.Column>
              </Grid.Row>
              </Grid>
              </div>
      )
    }

    renderSettings() {
      return (
        <div>
        <Grid  celled verticalAlign="middle" style={{backgroundColor: 'white'}}>
              <Grid.Row columns={1}>
                <Grid.Column><Header size="large">Schedule settings</Header></Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={4} style={{fontWeight: "bold", color: '#6435c9', fontSize: '15px'}}>Hour</Grid.Column>
                <Grid.Column width={12}>
                  {this.renderHourCards()}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={4} style={{fontWeight: "bold", color: '#6435c9', fontSize: '15px'}}>Day</Grid.Column>
                <Grid.Column width={12}>
                  {this.renderDayCards()}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={4} style={{fontWeight: "bold", color: '#6435c9', fontSize: '15px'}}>Month</Grid.Column>
                <Grid.Column width={12}>
                  {this.renderMonthCards()}
                </Grid.Column>
              </Grid.Row>
              </Grid>
              </div>
      )
    }

    renderSchedule() {
      return (
        <div style={{marginTop: '2px'}}>
        <Grid centered relaxed container columns='equal' verticalAlign="middle">
          <Grid.Row style={{ margin: "1px"}}>
          {this.renderForm()}
          </Grid.Row>
<Grid.Row style={{ margin: "1px"}}>
{this.renderSettings()}
</Grid.Row>
          </Grid>
        </div>
      )
    }

    renderChooseMode() {
      return (
        <div>
          <Grid centered relaxed columns='equal' style={{marginTop: '2px', marginBottom: '1px'}}>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
              <Button
                color="orange"
                  size="large"
                  className="select-button"
                  onClick={this.handleUploadSelect}
                >
                  Upload
                </Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Button
                color="orange"
                  size="large"
                  className="select-button"
                  onClick={this.handleTargetsSelect}
                >
                  Select
                </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    }

    renderUploadList() {
      return (
        <div>
          <Grid centered relaxed style={{marginBottom: '1px'}}>
          <Grid.Row columns={1} centered>            
          <Grid.Column>
              <Card fluid raised style={{ marginTop: '1px', backgroundColor: '#6435c9' }}><Card.Content textAlign="center" style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '16px' }}>
              Extension: txt - Format: emailaddress1,emailaddress2,emailaddress3,etc...
          </Card.Content></Card>
              </Grid.Column>
            </Grid.Row>
          <Grid.Row columns={1} centered>
          <Grid.Column textAlign="center">
              <Icon color='orange' style={{marginTop: '1px'}} name='angle double down' size='huge'/>
              </Grid.Column>              
            </Grid.Row>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
                <FileUploader color={'violet'} onStartProcess={this.handleStartProcess} onError={this.handleErrorProcess} onReadSuccess={this.handleReadSuccess} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered>
              <Grid.Column>
              <Header color='violet' as='h4' textAlign='center' content={`Status: ${this.state.statusUp}`} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered>
              <Grid.Column>
              {this.renderButtonOne()}
              </Grid.Column>
            </Grid.Row>
            </Grid>
        </div>
      )
    }

  renderCampaignstep1() {
    let { activeItem } = this.state
    return (
      <div>
        <HeaderCustom headerType="campaign_process"/>
        <Divider style={{margin: '2px'}} />
        <Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Choose your Targets</Header></Grid.Column>
          </Grid.Row>
          {this.state.chooseMode === 'none' && this.renderChooseMode()}
          {this.state.chooseMode === 'list' && this.renderUsersList()}
          {this.state.chooseMode === 'upload' && this.renderUploadList()}
          {this.state.chooseMode === 'list' && this.renderButtonOne()}
          <Menu attached='bottom' pointing secondary widths={10} color='violet'>
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            name='Targets'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Schedule'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Name'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step4'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

  renderCampaignstep2() {
    let { activeItem } = this.state
    return (
      <div>
        <HeaderCustom headerType="campaign_process"/>
        <Grid style={{margin: '2px'}} centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Schedule your Campaign</Header></Grid.Column>
          </Grid.Row>
          {this.renderSchedule()}

          {this.renderButtonTwo()}
          <Menu attached='bottom' pointing secondary widths={10} color='violet'>
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            name='Targets'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Schedule'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Name'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step4'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
        </Menu>
          </Grid>
      </div>
    )
  }

    renderCampaignstep3() {
      let { activeItem } = this.state
    return (
      <div>
        <HeaderCustom headerType="campaign_process"/>
        <Divider style={{margin: '2px'}} />
        <Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header size="large">Describe your Campaign</Header></Grid.Column>
          </Grid.Row>
          {this.renderEmailList()}

          {this.renderButtonThree()}
          <Menu attached='bottom' pointing secondary widths={10} color='violet'>
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            name='Targets'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Schedule'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Name'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step4'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
        </Menu>
          </Grid>
      </div>
    )
    }

    renderCampaignstep4() {
      let { activeItem } = this.state
    return (
      <div>
        <HeaderCustom headerType="campaign_process"/>
        <Divider style={{margin: '2px'}} />
        <Grid centered relaxed columns='equal' verticalAlign="middle">
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center" verticalAlign="middle"><Header color='violet' size="large">Campaign Recap</Header></Grid.Column>
          </Grid.Row>
          {this.renderRecapList()}

          {this.renderButtonFour()}
          <Menu attached='bottom' pointing secondary widths={10} color='violet'>
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            name='Targets'
            active={activeItem === 'step1'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Schedule'
            active={activeItem === 'step2'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Name'
            active={activeItem === 'step3'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            name='Recap'
            active={activeItem === 'step4'}
            style={{ backgroundColor: '#FFFFFF', color: '#6435c9' }}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
          <Menu.Item
            style={{ backgroundColor: '#E4DCDB'}}
          />
        </Menu>
          </Grid>
      </div>
    )
    }

    renderButtonOne() {
      return (
        <Grid centered relaxed columns='equal' style={{margin: '2px'}}>
                      <Grid.Row columns={1} centered>
                        <Grid.Column textAlign="center">
                        <Button
                            color="orange"
                            size="large"
                            className="action-button"
                            onClick={this.handleNextTwo}
                          >
                            Next Step
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                      </Grid>
      )
        }

        renderButtonTwo() {
          return (
            <Grid centered relaxed columns='equal' style={{margin: '2px'}}>
                          <Grid.Row columns={1} centered>
                            <Grid.Column textAlign="center">
                            <Button
                                color="orange"
                                size="large"
                                className="action-button"
                                onClick={this.handleNextThree}
                              >
                                Next Step
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                          </Grid>
          )
            }

            renderButtonThree() {
              return (
                <Grid centered relaxed columns='equal' style={{margin: '2px'}}>
                              <Grid.Row columns={1} centered>
                                <Grid.Column textAlign="center">
                                <Button
                                    color="orange"
                                    size="large"
                                    className="action-button"
                                    onClick={this.handleNextFour}
                                  >
                                    Final step
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>
                              </Grid>
              )
                }

                renderButtonFour() {
                  return (
                    <Grid centered relaxed columns='equal' style={{margin: '2px'}}>
                                  <Grid.Row columns={1} centered>
                                    <Grid.Column textAlign="center">
                                    <Button
                                        color="orange"
                                        size="large"
                                        className="action-button"
                                        onClick={this.handleCreateCampaign}
                                      >
                                        Create the Campaign
                                      </Button>
                                    </Grid.Column>
                                  </Grid.Row>
                                  </Grid>
                  )
                    }

  renderCampaignList() {
    let { activeItem } = this.state

      switch (activeItem) {
        case 'step1':
          return this.renderCampaignstep1()
        case 'step2':
          return this.renderCampaignstep2()
        case 'step3':
          return this.renderCampaignstep3()
        case 'step4':
          return this.renderCampaignstep4()
        default:
          return this.renderCampaignstep1()
      }    
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderCampaignList()
  }
}
