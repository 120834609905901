import * as React from 'react'
import { Grid, Button, Message, Header, Loader, Icon } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'

interface GoldenconfirmationProps {
  auth: Auth,
  history: any,
  match: {
    params: {
      mode: string
    }
  }
}

interface GoldenconfirmationState {
  loading: boolean
}

export class Goldenconfirmation extends React.PureComponent<
GoldenconfirmationProps,
GoldenconfirmationState
> {
  state: GoldenconfirmationState = {
    loading: false
  }

  handleHome = () => {
    this.props.history.replace(`/golden`)
  }

   renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Verifying User...
        </Loader>
      </Grid.Row>
    )
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    if (this.props.match.params.mode === 'activation') {
      return (
        <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                <Header size="large" icon color='violet'>
        <Icon name='sign language' />
        Golden Coin account successfully created!
      </Header>
      </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
          <Grid.Column textAlign='center'>
          <Message size='large' color='violet'>
          <Message.Header>Attention!</Message.Header>
          <p>Check the email we just sent you to learn how to access the Atlanticoin Platform.</p>
          </Message>
</Grid.Column>
          </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                  <Button
                  color = "orange"
                    size="large"
                    className="action-button"
                    onClick={this.handleHome}
                    style={{margin: '0.1em'}}
                  >
                    OK
                  </Button>
                </Grid.Column>
              </Grid.Row>
              </Grid>
              )
    } else {
      return (
        <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                <Header size="large" icon color='violet'>
        <Icon name='sign language' />
        Golden Coin allocation completed!
      </Header>
      </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
          <Grid.Column textAlign='center'>
          <Message size='large' color='violet'>
          <Message.Header>Success!</Message.Header>
          <p>Now you can check the Golden Balance of your employees on your Dashboard.</p>
          </Message>
</Grid.Column>
          </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column textAlign="center">
                  <Button
                  color = "orange"
                    size="large"
                    className="action-button"
                    onClick={this.handleHome}
                    style={{margin: '0.1em'}}
                  >
                    OK
                  </Button>
                </Grid.Column>
              </Grid.Row>
              </Grid>
              )
    }
}
}
