import React from "react";
import { Grid } from 'semantic-ui-react';
import PaypalButton from "./PaypalButton";

const Paypal = (props) => {
  console.log('mounting paypal container');
  return (
    <div>
      <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
      <PaypalButton infoPay={props.infoObj} history={props.history} vault={props.vault} type={props.type} token={props.token} upgrade={props.upgrade} contract={props.contract}/>
      </Grid.Column>
              </Grid.Row>
            </Grid>
    </div>
  );
};

export default Paypal;