/* eslint-disable array-callback-return */
import * as React from 'react'
import { Loader, Form, Grid, Button, TextArea, Icon, Divider } from 'semantic-ui-react'
import ChatLayout from './TroubleshootingChat'
import { getTickets, updateTicket,  } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { TicketMessageModel, TicketMessageModelApi } from '../types/TicketMessageModel'

interface OpenTroubleshootingProps {
  match: {
    params: {
      ticketArr: string
    }
  },
  history: any,
  auth: Auth
}

interface OpenTroubleshootingState {
  loading: boolean,
  tickets: any[],
  loadingButton: boolean,
  update: boolean,
  subject: string,
  message: any,
  email: string,
  status: string,
  loadingmessage: boolean
}

export class OpenTroubleshooting extends React.PureComponent<
  OpenTroubleshootingProps,
  OpenTroubleshootingState
> {
  state: OpenTroubleshootingState = {
    loading: true,
    tickets: [],
    loadingButton: false,
    update: false,
    subject: '',
    message: '',
    email: '',
    status: '',
    loadingmessage: false
  }

  scrollTarget = React.createRef<HTMLDivElement>();

  scrollToBottom = () => {
    const node: HTMLDivElement | null = this.scrollTarget.current; //get the element via ref

    if (node) { //current ref can be null, so we have to check
        node.scrollIntoView({behavior: 'smooth'}); //scroll to the targeted element
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.tickets.length !== this.state.tickets.length) {
      this.scrollToBottom()
          }
    if (prevState.update === this.state.update) {
      let ticketArrFormatted: any[] = []
      try {
        const tickets: TicketMessageModel[] = await getTickets(this.props.auth.getIdToken())
        const ticketArr = this.props.match.params.ticketArr.split(',')
        for (let index = 0; index < tickets.length; index++) {
          let ticketFormatted: any = {
            timeStamp: 0,
            name: '',
            message: '',
            group: ''
          }
          if (tickets[index].ticket.S === ticketArr[2]) {
          let timeFormatted = parseInt(tickets[index].timeStamp.S)
          ticketFormatted.name = tickets[index].name.S
          ticketFormatted.message = tickets[index].message.S
          ticketFormatted.group = tickets[index].group.S
          ticketFormatted.timeStamp = timeFormatted
          ticketArrFormatted.push(ticketFormatted)
        }
        }
        ticketArrFormatted.sort((a, b) => {
          return a.timeStamp - b.timeStamp
        })
  this.setState({ tickets: ticketArrFormatted, update: !prevState.update })
    } catch (e) {
      console.log(e)
      this.props.history.replace(`/ticketcreate`)
    }
    }
  }

  async componentDidMount() {
    let subject = ''
    let email = ''
    let status = ''
    let ticketArrFormatted: any[] = []
    try {
const tickets: TicketMessageModel[] = await getTickets(this.props.auth.getIdToken())
const ticketArr = this.props.match.params.ticketArr.split(',')
for (let index = 0; index < tickets.length; index++) {
  let ticketFormatted: any = {
    timeStamp: 0,
    name: '',
    message: '',
    group: ''
  }
  if (tickets[index].ticket.S === ticketArr[2]) {
  let timeFormatted = parseInt(tickets[index].timeStamp.S)
  ticketFormatted.name = tickets[index].name.S
  ticketFormatted.message = tickets[index].message.S
  ticketFormatted.group = tickets[index].group.S
  ticketFormatted.timeStamp = timeFormatted
  ticketArrFormatted.push(ticketFormatted)
  if (tickets[index].type.S === 'ticket') {
    subject = tickets[index].subject.S
    email = tickets[index].email.S
    status = tickets[index].status.S
  }
}
}
ticketArrFormatted.sort((a, b) => {
  return a.timeStamp - b.timeStamp
})
this.setState({ tickets: ticketArrFormatted, loading: false, subject, email, status })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
    this.props.history.replace(`/ticketcreate`)
  }
  }

handlePost = async () => {
  this.setState({loadingmessage: true})
  const date = new Date().getTime()
  const ticketArr = this.props.match.params.ticketArr.split(',')
  const ticketObj: TicketMessageModelApi = {
    type: 'message',
    email: this.state.email,
    subject: this.state.subject,
    message: this.state.message,
    timeStamp: date.toString(),
    group: ticketArr[1],
    name: ticketArr[0],
    status: 'OPEN',
    ticket: ticketArr[2]
          }
  try {
    const response = await updateTicket(this.props.auth.getIdToken(), ticketObj)
  console.log(response)
  this.setState({loadingmessage: false, message: ''})
  } catch (error) {
    this.setState({loadingmessage: false})
    console.log(error)
    this.props.history.replace(`/ticketcreate`)
  }
}

handleReturn = () => {
  this.setState({message: ''})
  this.props.history.replace(`/ticketcreate`)
}

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Messages
        </Loader>
      </Grid.Row>
    )
  }

  renderTicketMessages() {
    const ticketArr = this.props.match.params.ticketArr.split(',')
      return (
        <div>
            <Grid container celled  verticalAlign="middle" style={{ backgroundColor: "white"}}>
                <Grid.Row columns={2}>
          <Grid.Column style={{fontSize: '0.8rem', fontWeight:'bold', color: '#6435c9'}}>
            Ticket ID
        </Grid.Column>
        <Grid.Column style={{fontSize: '0.8rem'}}>
          {ticketArr[2]}
        </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
          <Grid.Column style={{fontSize: '0.8rem', fontWeight:'bold', color: '#6435c9'}}>
        Subject
        </Grid.Column>
        <Grid.Column style={{fontSize: '0.8rem'}}>
          {this.state.subject}
        </Grid.Column>
                </Grid.Row>
                </Grid>
                <Divider clearing />
                <div>
                <Grid>
                <Grid.Row centered columns={1} style={{maxHeight:'80vh', overflow:'auto'}}>
        <Grid.Column style={{maxWidth:'100vh'}}>
<ChatLayout data={this.state.tickets}></ChatLayout>
<div style={{float:'left', clear: 'both'}}
ref={this.scrollTarget} data-explanation="This is where we scroll to"></div>
        </Grid.Column>
                </Grid.Row>
                <Divider clearing />
                <Grid.Row columns={2}>
        <Grid.Column width={14}>
          <Form>
          <Form.Field>
        <TextArea
              placeholder="Write your message..."
              value={this.state.message}              
              onChange={
                (e, { value }) => {
                  console.log(e)
                  let message = ''
                  if (value) {
                    if (typeof value === 'string') {                      
                      let upper = value.charAt(0).toUpperCase()
message = upper + value.slice(1)
                    }
                  }
                  this.setState({ message})
                }
              }
            />
            </Form.Field>
            </Form>
        </Grid.Column>
        {this.state.status === 'OPEN' && <Grid.Column width={2} verticalAlign='middle' textAlign='center'>
        <div onClick={this.handlePost}>{!this.state.loadingmessage && <Icon size='huge' name='paper plane' style={{color: '#6435c9', cursor:'pointer'}} />}{this.state.loadingmessage && <Icon size='huge' loading name='spinner' style={{color: '#6435c9'}} />}</div>
              </Grid.Column>}
              {this.state.status === 'CLOSED' && <Grid.Column width={2} verticalAlign='middle' textAlign='center'>
        <div><Icon size='huge' name='paper plane' style={{color: '#3f3f3f', cursor:'pointer'}} /></div>
              </Grid.Column>}
                </Grid.Row>
                </Grid>
                </div>
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "orange"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
                </div>
      )
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return this.renderTicketMessages()
}

}